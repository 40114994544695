import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  Entry,
  EntryQueryRequest,
  EntryQueryVariables,
  AccountFragment
} from "~/types/gen/api";
import { resetState } from "~/store/utils";
import { APIError } from "~/network/api-error";

export type EntryState = {
  account: AccountFragment | null;
  entry: Entry | null;
};

export const state = (): EntryState => ({
  account: null,
  entry: null
});

export const mutations = mutationTree(state, {
  receiveAccount(state, payload: AccountFragment) {
    state.account = payload;
  },
  receiveEntry(state, payload: Entry) {
    state.entry = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async init(context, payload: EntryQueryVariables): Promise<void> {
      this.$accessor.presentation.showLoading(null);

      try {
        const account = this.$accessor.account.account;
        const req = new EntryQueryRequest(payload);
        const res = await this.$apiClient.query(req);

        // Read from on memory
        if (account) {
          context.commit("receiveAccount", account);
        }

        if (res.entryDetail) {
          context.commit("receiveEntry", res.entryDetail);
        } else {
          throw new APIError(404, "");
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
