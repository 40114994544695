import { Context } from "@nuxt/types";
import { nativeBridge } from "~/foundations/native-bridge/native-bridge";

/**
 * requireAuth
 * @param context
 */
export default async function requireAuth(context: Context) {
  // Redirect to introduction page
  const { hasCredential, hasAccount, account } = context.app.$accessor.account;
  if (!hasCredential) {
    context.redirect(makePath("/intro", context.route.path));
    return;
  }
  if (!hasAccount) {
    await context.app.$accessor.account.loadAccount();
  }

  if (!context.app.$accessor.systemSetting.hasSettings) {
    await context.app.$accessor.systemSetting.loadSystemSetting();
  }
  // UserIdのpush
  if (process.client && hasAccount && context.app.$accessor.spadApp.isIosInApp) {
    if (account?.id) {
      nativeBridge.SetUserId(account?.id);
    }
  }
}

/**
 * make path with page path redirect from
 * @param path
 * @param from
 */
function makePath(path: string, from: string | null = null): string {
  if (from) {
    return `${path}?from=${from}`;
  }
  return path;
}
