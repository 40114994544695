import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { resetState } from "~/store/utils";
import {
  Payee,
  PayeeQueryRequest,
  UpdatePayeeMutationVariables,
  UpdatePayeeMutationRequest
} from "~/types/gen/api";

export type PayeeState = {
  payee: Payee | null;
};

export const state = (): PayeeState => ({
  payee: null
});

export const mutations = mutationTree(state, {
  receivePayee(state, payload: Payee) {
    state.payee = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async savePayee(
      context,
      payload: UpdatePayeeMutationVariables
    ): Promise<void> {
      this.$accessor.presentation.showLoading(null);

      try {
        const req = new UpdatePayeeMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);

        if (res.createPayee) {
          context.commit("receivePayee", res.createPayee);

          this.$accessor.account.receivePayee(res.createPayee);

          this.$accessor.toast.show({
            message: this.$i18n.tc("page.payee.message.saved"),
            type: "success"
          });

          const from = this.$router.currentRoute.query.from;
          if (typeof from === "string") {
            this.$router.replace(from);
          }
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async getPayee(context): Promise<void> {
      this.$accessor.presentation.showLoading(null);

      try {
        const req = new PayeeQueryRequest({});
        const res = await this.$apiClient.query(req);

        if (res.payee) {
          context.commit("receivePayee", res.payee);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
