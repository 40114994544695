import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  InstagramDraftFragment,
  TwitterDraftFragment,
  YouTubeDraftFragment,
  TikTokDraftFragment,
  DraftQueryRequest,
  InstagramDraft,
  TwitterDraft,
  YouTubeDraft,
  TikTokDraft,
  InstagramDraftAttachment,
  TwitterDraftAttachment,
  YouTubeDraftAttachment,
  TikTokDraftAttachment,
  ProjectProgress
} from "~/types/gen/api";
import { ImageAsset } from "~/utils/asset";
import { toImageAsset } from "~/domain/draft";
import { resetState } from "~/store/utils";

type DraftFragment = InstagramDraftFragment | TwitterDraftFragment | YouTubeDraftFragment | TikTokDraftFragment
type Draft = InstagramDraft | TwitterDraft | YouTubeDraft | TikTokDraft

export type DraftState = {
  draft: DraftFragment | null;
  assets: ImageAsset[];
};

export const state = (): DraftState => ({
  draft: null,
  assets: []
});

export const mutations = mutationTree(state, {
  receiveDraft(state, projectProgress: ProjectProgress) {
    const draft = projectProgress.draft as Draft;
    state.draft = draft;
    switch (draft.__typename) {
      case "InstagramDraft":
        state.assets = draft.draftAttachments!.map((draftAttachment: InstagramDraftAttachment) => toImageAsset<InstagramDraftAttachment>(draftAttachment)) || [];
        break;
      case "TwitterDraft":
        state.assets = draft.draftAttachments!.map((draftAttachment: TwitterDraftAttachment) => toImageAsset<TwitterDraftAttachment>(draftAttachment)) || [];
        break;
      case "YouTubeDraft":
        state.assets = draft.draftAttachments!.map((draftAttachment: YouTubeDraftAttachment) => toImageAsset<YouTubeDraftAttachment>(draftAttachment)) || [];
        break;
      case "TikTokDraft":
        state.assets = draft.draftAttachments!.map((draftAttachment: TikTokDraftAttachment) => toImageAsset<TikTokDraftAttachment>(draftAttachment)) || [];
        break;
    }
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async init(context, progressId: string): Promise<void> {
      this.$accessor.presentation.showLoading(null);

      const req = new DraftQueryRequest({ progressId });

      try {
        const draftResponse = await this.$apiClient.query(req);
        if (draftResponse.draftDetail) {
          context.commit("receiveDraft", draftResponse.draftDetail as ProjectProgress);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
