

































import Vue from "vue";
import Overlay from "./overlay.vue";
import Modal from "./mixins/modal";

export const ContentModal = Vue.extend({
  name: "ContentModal",
  components: {
    Overlay
  },
  mixins: [Modal]
});

export function isContentModal(
  component: any
): component is typeof ContentModal {
  return component.$options.name === "ContentModal";
}

export default ContentModal;
