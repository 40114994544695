import { actionTree, mutationTree, getterTree } from "nuxt-typed-vuex";
import { MypageQueryRequest } from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type MypageState = {};

export const state = (): MypageState => ({});

export const getters = getterTree(state, {});

export const mutations = mutationTree(state, {
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations, getters },
  {
    async loadMypage(): Promise<void> {
      this.$accessor.presentation.showLoading(null);
      try {
        // Make request
        const req = new MypageQueryRequest({});
        // Exec request
        await this.$apiClient.query(req);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        // Dismiss refreshing
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
