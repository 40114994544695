

















import Vue from "vue";
import { ToastPayload } from "~/store/toast/index";
import Icon from "~/components/ui/icon.vue";
export default Vue.extend({
  components: {
    Icon
  },
  props: {
    toastPayload: {
      type: Object as () => ToastPayload,
      default: undefined
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    toggleShowClassName(): string {
      return `-${this.isShow ? "show" : "hide"}`;
    },
    iconName(): string {
      const toastType = this.toastPayload.type;
      switch (toastType) {
        case "success":
          return "check";
        case "error":
          return "warning";
      }
      return "";
    }
  }
});
