







import Vue from "vue";
import Toast from "~/components/ui/toast/toast.vue";
import { ToastPayload } from "~/store/toast/index";

export default Vue.extend({
  components: {
    Toast
  },
  computed: {
    isShow(): Boolean {
      return this.$accessor.toast.isShow;
    },
    toastPayload(): ToastPayload {
      return this.$accessor.toast.toastPayload;
    }
  }
});
