export default {
  label: {
    current_email: "現在のメールアドレス",
    new_email: "新しいメールアドレス",
    current_password: "現在のパスワード",
    new_password: "新しいパスワード",
    new_password_confirmation: "新しいパスワードの確認",
    change_email: "メールアドレス変更",
    change_password: "パスワード変更",
    name: "名前"
  },
  button: {
    update_email: "メールアドレスの変更",
    register_email: "メールアドレスの登録",
    update_password: "パスワードの変更"
  },
  message: {
    verify_email: "メールアドレスの確認が取れました！",
    sent_password_reset: "パスワード再設定リンクを送信しました。",
    update_password_success: "パスワードの変更が完了しました。",
    update_email_success: "メールアドレスの変更が完了しました。",
    update_profile_success: "アカウント情報を変更しました。",
    resent_email: "確認メールを再送信しました。"
  },
  error: {
    invalid_link: "リンクの認証が切れました",
    verify_failed:
      "このリンクは有効期限切れとなっているか、ページの設定により閲覧できなくなっている可能性があります。"
  }
};
