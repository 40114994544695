import { actionTree, mutationTree, getterTree } from "nuxt-typed-vuex";
import { CLOSE_BANNER_BY_USER } from "~/utils/device";

export interface BannerState {
  isBannerClosed: boolean;
  isIos: boolean;
}

export const state = (): BannerState => {
  return {
    isBannerClosed: false,
    isIos: false
  };
};

export const mutations = mutationTree(state, {
  closeBanner(state: BannerState) {
    state.isBannerClosed = true;
  },
  setIosFlag(state: BannerState, isIos) {
    state.isIos = isIos;
  }
});

export const getters = getterTree(state, {
  isOpen(state) {
    return !state.isBannerClosed && state.isIos;
  }
});

export const actions = actionTree(
  {
    state,
    mutations
  },
  {
    close(context): void {
      this.$cookies.set(CLOSE_BANNER_BY_USER, true, {
        expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 21)
      });
      context.commit("closeBanner");
    }
  }
);
