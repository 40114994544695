import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { LinkAccountToInstagramMutationVariables, LinkAccountToInstagramMutationRequest } from "~/types/gen/api";

export type InstagramRegisterState = {
  result: Boolean;
};

export const state = (): InstagramRegisterState => ({
  result: false
});

export const mutations = mutationTree(state, {
  setLinkAccountResponse(state, payload) {
    state.result = payload.linkAccountToInstagram;
  }
});

export const actions = actionTree(
  {
    state,
    mutations
  },
  {
    async linkAccountToInstagram(
      context,
      payload: LinkAccountToInstagramMutationVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new LinkAccountToInstagramMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        context.commit("setLinkAccountResponse", res);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
