import { MimeType } from "./mime-type";
import * as FileUtils from "./file";
import { normalizedImageData } from "./image";

interface Asset {
  type: "url" | "file";
}

export interface FileAsset extends Asset {
  type: "file";
  file: File;
}

export interface URLAsset extends Asset {
  type: "url";
  id: string;
  url: string;
}

export type ImageAsset = FileAsset | URLAsset;

/**
 * Type guard for FileAsset
 * @param imageSource
 */
export function isFileAsset(imageSource: ImageAsset): imageSource is FileAsset {
  return imageSource.type === "file";
}

/**
 * Type guard for URLAsset
 * @param imageSource
 */
export function isURLAsset(imageSource: ImageAsset): imageSource is URLAsset {
  return imageSource.type === "url";
}

/**
 * Type guard for AVAsset
 * @param imageSource
 */
export function isAVAsset(imageSource: ImageAsset): boolean {
  return /^video\/.+$/.test(getMimeType(imageSource));
}

/**
 * Get mime type from ImageAsset
 * @param imageSource
 */
export function getMimeType(imageSource: ImageAsset): MimeType {
  if (isFileAsset(imageSource)) {
    return imageSource.file.type as MimeType;
  }
  if (isURLAsset(imageSource)) {
    const extension = imageSource.url.split(".").pop();
    switch (extension) {
      case "mov":
        return "video/quicktime";
      case "avi":
        return "video/avi";
      case "mpeg":
        return "video/mpeg";
      case "mp4":
        return "video/mp4";
      case "bmp":
        return "image/bmp";
      case "jpeg":
      case "jpg":
        return "image/jpeg";
      case "png":
        return "image/png";
      case "gif":
        return "image/gif";
    }
  }
  throw new Error("Invalid type");
}

/**
 * Get unique ID of ImageAsset
 * @param imageSource
 */
export function getID(imageSource: ImageAsset): string {
  if (imageSource.type === "url") {
    return imageSource.url;
  } else {
    return imageSource.file.name;
  }
}

/**
 * Get src for <img /> from ImageAsset
 * @param imageSource
 */
export async function getSource(imageSource: ImageAsset): Promise<string> {
  if (isFileAsset(imageSource)) {
    const data = await normalizedImageData(imageSource.file);
    return FileUtils.readAsDataURL(data);
  }

  return Promise.resolve(imageSource.url);
}

/**
 * Make ImageAsset from File
 * @param file
 */
export function makeAsset(file: File): ImageAsset {
  return {
    type: "file",
    file
  };
}
