import { actionTree, mutationTree, getterTree } from "nuxt-typed-vuex";
import { BillingFragment, PaymentsQueryRequest, PaymentsQuery } from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type PaymentsState = {
  paidBillings: BillingFragment[];
  unpaidBillings: BillingFragment[];
  isPristine: boolean;
};

export const state = (): PaymentsState => ({
  paidBillings: [],
  unpaidBillings: [],
  isPristine: true
});

export const getters = getterTree(state, {
  isEmpty(state) {
    return (
      state.paidBillings.length === 0 &&
      state.unpaidBillings.length === 0 &&
      !state.isPristine
    );
  }
});

export const mutations = mutationTree(state, {
  receiveBillings(state, payload: PaymentsQuery) {
    state.unpaidBillings = payload.unpaid.edges.map((v) => v.node);
    state.paidBillings = payload.paid.edges.map((v) => v.node);
    state.isPristine = false;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations, getters },
  {
    async loadPayments(context): Promise<void> {
      this.$accessor.presentation.showLoading(null);
      try {
        // Make request
        const req = new PaymentsQueryRequest({});
        // Exec request
        const res = await this.$apiClient.query(req);
        context.commit("receiveBillings", res);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        // Dismiss refreshing
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
