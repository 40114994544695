import { ApolloError } from "apollo-client";
import { GraphQLError } from "graphql";
import { Context } from "@nuxt/types";
import { i18n } from "../plugins/i18n/index";
import { APIErrorExtensions } from "./types";

export interface APIErrorObject extends Error {
  name: string;
  status: number;
  message: string;
  debugMessage?: string;
}

/*
 * APIError
 * normalize error response from api
 */
export class APIError implements APIErrorObject {
  name: string = "APIError";

  constructor(
    public status: number,
    public message: string,
    public debugMessage?: string
  ) {}

  static fromGraphQLErrors(errors: GraphQLError[], nuxtCtx: Context) {
    // GQLの200コードでエラーが返された場合
    if (errors.length > 0 && errors[0].extensions) {
      const extensions = errors[0].extensions! as APIErrorExtensions;
      const status = extensions.code || 500;
      const debugMessage = errors[0].debugMessage;
      const message = errors[0].message;

      if (extensions.category) {
        switch (extensions.category) {
          case "auth" : {
            nuxtCtx.app.$accessor.account.logoutWithError();
            return new APIError(status, i18n.t("common.error.status_code.401") as string, debugMessage);
          }
          case "validation": {
            const message = Object.keys(extensions.validation)
              .map((key) => extensions.validation[key])
              .reduce((acc, error) => {
                return [...acc, ...error];
              }, [])
              .join("\n");
            return new APIError(status, message, debugMessage);
          }
          case "custom": {
            return new APIError(status, message, debugMessage);
          }
        }
      }

      // エラーコードでハンドリングする場合ここに追記する
      switch (status) {
        default:
          // Error message is not user fiendly
          return new APIError(
            status,
            i18n.t("common.error.status_code.500") as string,
            debugMessage
          );
      }
    }

    // No information
    return new APIError(500, "", "Unknown Error");
  }

  static fromNetworkErrors(errors: any, nuxtCtx: Context) {
    // ネットワークエラー 200以外の処理
    const { response } = errors as any;
    const { isIosInApp } = nuxtCtx.app.$accessor.spadApp;
    const { hasCredential } = nuxtCtx.app.$accessor.account;

    switch (response.status) {
      case 302:
        // app update
        if (isIosInApp) {
          nuxtCtx.redirect("/version-up");
          return new APIError(302, i18n.t("common.error.status_code.302") as string, "Unknown Error");
        }
        break;
      case 401:
        // Token expired
        if (hasCredential) {
          nuxtCtx.app.$accessor.account.logoutWithError();
        }
        break;
    }

    // No information
    return new APIError(500, i18n.t("common.error.status_code.500") as string, "Unknown Error");
  }

  static fromApolloError(error: ApolloError, nuxtCtx: Context): APIError {
    const { networkError, graphQLErrors } = error;
    // handle graphQLErrors or networkError
    if (graphQLErrors && graphQLErrors.length > 0) {
      return this.fromGraphQLErrors(graphQLErrors as GraphQLError[], nuxtCtx);
    } else if (networkError) {
      return this.fromNetworkErrors(networkError as any, nuxtCtx);
    }

    return new APIError(500, "Unknown error");
  }

  public get asObject(): APIErrorObject {
    return {
      name: this.name,
      status: this.status,
      message: this.message,
      debugMessage: this.debugMessage
    };
  }
}
