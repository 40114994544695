/**
 * Must not import this file anywhere, because this is loaded by VeeValidate.
 * https://baianat.github.io/vee-validate/guide/localization.html#vuei18n-integration
 *
 * Should be according to the VeeValidate error messages regulation.
 * https://baianat.github.io/vee-validate/guide/messages.html#message-generators
 */
import jp from "~/plugins/i18n/locales/ja/common/index";

const ja = require("vee-validate/dist/locale/ja");

const formatFileSize = (size: string | number): string => {
  const units: string[] = [
    "Byte",
    "KB",
    "MB",
    "GB",
    "TB",
    "PB",
    "EB",
    "ZB",
    "YB"
  ];
  const threshold: number = 1024;
  const calcedSize: number = Number(size) * threshold;
  const i: number =
    size === 0 ? 0 : Math.floor(Math.log(calcedSize) / Math.log(threshold));
  return `${(calcedSize / Math.pow(threshold, i)).toFixed(2)} ${units[i]}`;
};

export default {
  attributes: {
    email: jp.label.email,
    currentEmail: jp.label.email,
    firstName: jp.label.first_name,
    firstNameKana: jp.label.kana_first_name,
    lastName: jp.label.last_name,
    lastNameKana: jp.label.kana_last_name,
    password: jp.label.password,
    currentPassword: jp.label.password,
    passwordConfirmation: jp.label.password,
    phoneNumber: jp.label.phone_number,
    term: jp.label.term,
    zipcode: jp.label.zipcode,
    cityName: jp.label.city,
    prefectureName: jp.label.prefecture,
    address: jp.label.address,
    content: jp.label.content,
    influencerName: jp.label.influencer_name,
    building: jp.label.building,
    text: jp.label.text,
    bankName: jp.label.bank_name,
    branchName: jp.label.branch_name,
    accountHolder: jp.label.account_holder,
    hasWithholdingTax: jp.label.has_withholding_tax,
    accountNumber: jp.label.account_number,
    userId: jp.label.user_id,
    accountType: jp.label.account_type,
    postedUrl: jp.label.posted_url,
    ngReasonId: jp.label.ng_reason
  },
  messages: {
    ...ja.messages,
    _default: (field: string) => `${field}の値が不正です`,
    after: (field: string, [target]: string[]) =>
      `${field}は${target}の後でなければなりません`,
    alpha_dash: (field: string) =>
      `${field}は英数字とハイフン・アンダースコアのみ使用できます`,
    alpha_num: (field: string) => `${field}は英数字のみ使用できます`,
    alpha_spaces: (field: string) =>
      `${field}はアルファベットと空白のみ使用できます`,
    alpha: (field: string) => `${field}はアルファベットのみ使用できます`,
    before: (field: string, [target]: string[]) =>
      `${field}は${target}よりも前でなければなりません`,
    between: (field: string, [min, max]: string[]) =>
      `${field}は${min}から${max}の間でなければなりません`,
    confirmed: (field: string) => `${field}が一致しません`,
    credit_card: (field: string) => `${field}が正しくありません`,
    date_between: (field: string, [min, max]: string[]) =>
      `${field}は${min}から${max}の間でなければなりません`,
    date_format: (field: string, [format]: string[]) =>
      `${field}は${format}形式でなけれななりません`,
    decimal: (field: string, [decimals = "*"] = []) =>
      `${field}は整数及び小数点以下${
        decimals === "*" ? "" : decimals
      }桁までの数字にしてください`,
    digits: (field: string, [length]: string[]) =>
      `${field}は${length}桁の数字でなければなりません`,
    dimensions: (field: string, [width, height]: string[]) =>
      `${field}は幅${width}px・高さ${height}px以内でなければなりません`,
    email: (field: string) => `${field}は有効なメールアドレスではありません`,
    ext: (field: string) => `${field}は有効なファイル形式ではありません`,
    image: (field: string) => `${field}は有効な画像形式ではありません`,
    included: (field: string) => `${field}は有効な値ではありません`,
    ip: (field: string) => `${field}は有効なIPアドレスではありません`,
    is: (field: string) => `${field}が一致しません`,
    is_not: (field: string) => `${field}が一致しています`,
    length: (field: string, [length, max]: string[]) => {
      if (max) {
        return `${field}は${length}文字以上${max}文字以下でなければなりません`;
      }
      return `${field}は${length}文字でなければなりません`;
    },
    max: (field: string, [length]: string[]) =>
      `${field}は${length}文字以内にしてください`,
    max_value: (field: string, [max]: string[]) =>
      `${field}は${max}以下でなければなりません`,
    mimes: (field: string) => `${field}は有効なファイル形式ではありません`,
    min: (field: string, [length]: string[]) =>
      `${field}は${length}文字以上でなければなりません`,
    min_value: (field: string, [min]: string[]) =>
      `${field}は${min}以上でなければなりません`,
    excluded: (field: string) => `${field}は不正な値です`,
    numeric: (field: string) => `${field}は数字のみ使用できます`,
    regex: (field: string) => `${field}のフォーマットが正しくありません`,
    required: (field: string) => `${field}は必須項目です`,
    size: (field: string, [size]: string[]) =>
      `${field}は${formatFileSize(size)}以内でなければなりません`,
    url: (field: string) => `${field}は有効なURLではありません`,

    // for custom rules
    kana: (field: string) => `${field}はカタカナで入力してください`,
    password: "パスワードの形式が違います",
    bankAccountName: (field: string) =>
      `${field}はカタカナ、または英数字で入力してください`,
    phoneNumber: (field: string) =>
      `${field}は10〜11桁の半角数字で入力してください`
  }
};
