import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  SignUpMutationRequest,
  SignUpMutationVariables
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type SignUpState = {};

export const state = (): SignUpState => ({});

export const mutations = mutationTree(state, {
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async signUp(_, payload: SignUpMutationVariables): Promise<void> {
      this.$accessor.presentation.showLoading(null);
      try {
        const req = new SignUpMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);

        if (res.signUp) {
          this.$accessor.account.saveCredential(res.signUp);
          this.$router.replace("/signup/complete");
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
