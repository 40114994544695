import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { ListLpProjectQuery, ListLpProjectQueryRequest, LpProject, ProjectMediaType } from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type Response = ListLpProjectQuery["lpProjectList"];

export type LpProjectListState = {
  lpProjectListTwitter: LpProject[];
  lpProjectListYoutube: LpProject[];
};

export const state = (): LpProjectListState => ({
  lpProjectListTwitter: [],
  lpProjectListYoutube: []
});

export const mutations = mutationTree(state, {
  receiveLpProjectListTwitter(state: LpProjectListState, payload: Response) {
    state.lpProjectListTwitter = payload!;
  },
  receiveLpProjectListYoutube(state: LpProjectListState, payload: Response) {
    state.lpProjectListYoutube = payload!;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async init(context): Promise<void> {
      this.$accessor.presentation.showLoading(null);

      try {
        const reqYoutube = new ListLpProjectQueryRequest({ mediaType: ProjectMediaType.Youtube });
        const resYoutube = await this.$apiClient.query(reqYoutube);
        context.commit("receiveLpProjectListYoutube", resYoutube.lpProjectList);

        const reqTwitter = new ListLpProjectQueryRequest({ mediaType: ProjectMediaType.Twitter });
        const resTwitter = await this.$apiClient.query(reqTwitter);
        context.commit("receiveLpProjectListTwitter", resTwitter.lpProjectList);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
