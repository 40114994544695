import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { SaveDeviceTokenMutationRequest, DeviceType } from "~/types/gen/api.ts";
export const ANDROID_DEVICE_TOKEN = "android_deveice_token";
export const APP_VERSION = "app-ver";
export const DEVICE_TYPE = "device-type";
export interface SpadAppState {
  isIosInApp: boolean;
  isAndroidInApp: boolean;
  appVersion: string;
  deviceType: string;
}

export const state = (): SpadAppState => {
  return {
    isIosInApp: false,
    isAndroidInApp: false,
    appVersion: "",
    deviceType: ""
  };
};

export const mutations = mutationTree(state, {
  setIosAppFlag(state: SpadAppState, isIosInApp) {
    state.isIosInApp = isIosInApp;
  },
  setAndroidAppFlag(state: SpadAppState, isAndroidInApp) {
    state.isAndroidInApp = isAndroidInApp;
  },
  setAppVersion(state: SpadAppState, payload) {
    state.appVersion = payload;
  },
  setDeviceType(state: SpadAppState, payload) {
    state.deviceType = payload;
  }
});

export const actions = actionTree(
  {
    state,
    mutations
  },
  {
    async setAndroidDeviceToken(_) {
      const token = this.$cookies.get(ANDROID_DEVICE_TOKEN);
      if (typeof token !== "string") { return; }

      try {
        const req = new SaveDeviceTokenMutationRequest({
          token,
          deviceType: DeviceType.Android
        });
        await this.$apiClient.mutate(req);
      } catch (e) {
        this.$accessor.error.showError(e);
      }
    }
  }
);
