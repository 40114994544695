// Vuex module
import { getAccessorType } from "nuxt-typed-vuex";
import { Context } from "@nuxt/types";
import { ActionContext } from "vuex";
import * as account from "./account";
import * as toast from "./toast";
import * as error from "./error";
import * as presentation from "./presentation";
import * as appBanner from "./appBanner";
import * as spadApp from "./spadApp";

// Page module
import * as notifications from "./pages/notifications";
import * as projectSearch from "./pages/projectSearch";
import * as projectProgressList from "./pages/projectProgressList";
import * as projectProgress from "./pages/projectProgress";
import * as entryProject from "./pages/entryProject";
import * as shippingAddress from "./pages/shippingAddress";
import * as payee from "./pages/payee";
import * as offerProject from "./pages/offerProject";
import * as entry from "./pages/entry";
import * as contractedProject from "./pages/contractedProject";
import * as offerProjectList from "./pages/offerProjectList";
import * as draftEdit from "./pages/draftEdit";
import * as acceptance from "./pages/acceptance";
import * as draft from "./pages/draft";
import * as instagramRegister from "./pages/instagramRegister";
import * as mypage from "./pages/mypage";
import * as oldPayments from "./pages/oldPayments";
import * as payment from "./pages/payment";
import * as payments from "./pages/payments";
import * as faqCategoryList from "./pages/faqCategoryList";
import * as faqList from "./pages/faqList";
import * as faq from "./pages/faq";
import * as signUp from "./pages/signUp";
import * as announcement from "./pages/announcement";
import * as contact from "./pages/contact";
import * as rejectedEntry from "./pages/rejectedEntry";
import * as directMessage from "./pages/directMessage";
import * as contactReply from "./pages/contactReply";
import * as profile from "./pages/profile";
import * as email from "./pages/email";
import * as password from "./pages/password";
import * as registerCredentials from "./pages/registerCredentials";
import * as emailVerify from "./pages/emailVerify";
import * as login from "./pages/login";
import * as resetPassword from "./pages/resetPassword";
import * as resendEmail from "./pages/resendEmail";
import * as lineAuth from "./pages/lineAuth";
import * as appleAuth from "./pages/appleAuth";
import * as instagram from "./pages/instagram";
import * as twitterActivation from "./pages/twitterActivation";
import * as youtubeActivation from "./pages/youtubeActivation";
import * as tiktokActivation from "./pages/tiktokActivation";
import * as entryProjectChecklist from "./pages/entryProjectChecklist";
import * as offerProjectChecklist from "./pages/offerProjectChecklist";
import * as emailRegistration from "./pages/emailRegistration";
import * as enquete from "./pages/enquete";
import * as lpProject from "./pages/lpProject";
import * as lpProjectList from "./pages/lpProjectList";
import * as linkSocialMedia from "./pages/linkSocialMedia";
import * as twitterRegister from "./pages/twitterRegister";
import * as youtubeRegister from "./pages/youtubeRegister";
import * as tiktokRegister from "./pages/tiktokRegister";
import * as deactivateSocialAccount from "./pages/deactivateSocialAccount";
import * as deleteRequest from "./pages/deleteRequest";

// Libs

// Utils
import {
  initIosNativeAppSetting,
  initAndroidNativeAppSetting,
  initWebAppBannerSetting
} from "~/utils/device";

/**
 * For nuxt SSR
 */
export const actions = {
  async nuxtServerInit(context: ActionContext<{}, {}>, nuxtContext: Context) {
    // settings of some enviroment
    initIosNativeAppSetting(context, nuxtContext);
    initAndroidNativeAppSetting(context, nuxtContext);
    initWebAppBannerSetting(context, nuxtContext);

    // @ts-ignore
    const token: string = nuxtContext.app.$auth.strategy.token.get();
    const { isIosInApp } = nuxtContext.app.$accessor.spadApp;

    // iOS device settings
    if (isIosInApp) {
      const appVersion: string = nuxtContext.app.$cookies.get(spadApp.APP_VERSION);
      const deviceType: string = nuxtContext.app.$cookies.get(spadApp.DEVICE_TYPE);
      context.commit("spadApp/setAppVersion", appVersion);
      context.commit("spadApp/setDeviceType", deviceType);
    }

    // credential settings
    if (token) {
      context.commit("account/loggedIn", token);
    }

    // Load system setting
    await context.dispatch("systemSetting/loadSystemSetting");
  }
};

/**
 * Setup accessor
 */
export const accessorType = getAccessorType({
  modules: {
    pages: {
      modules: {
        notifications,
        projectSearch,
        projectProgressList,
        projectProgress,
        entryProject,
        shippingAddress,
        payee,
        offerProject,
        entry,
        contractedProject,
        offerProjectList,
        draftEdit,
        acceptance,
        draft,
        instagramRegister,
        mypage,
        payment,
        payments,
        oldPayments,
        faqCategoryList,
        faqList,
        faq,
        contact,
        signUp,
        announcement,
        rejectedEntry,
        directMessage,
        contactReply,
        profile,
        email,
        password,
        registerCredentials,
        login,
        emailVerify,
        resetPassword,
        appBanner,
        resendEmail,
        lineAuth,
        appleAuth,
        instagram,
        twitterActivation,
        youtubeActivation,
        tiktokActivation,
        entryProjectChecklist,
        offerProjectChecklist,
        emailRegistration,
        enquete,
        linkSocialMedia,
        twitterRegister,
        youtubeRegister,
        tiktokRegister,
        lpProject,
        lpProjectList,
        deactivateSocialAccount,
        deleteRequest
      }
    },
    presentation,
    error,
    account,
    toast,
    spadApp
  }
});
