import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  SendPasswordResetEmailMutationVariables,
  SendPasswordResetEmailMutationRequest,
  ResetPasswordMutationVariables,
  ResetPasswordMutationRequest
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type ResetPasswordState = {
  success: boolean;
};

export const state = (): ResetPasswordState => ({
  success: false
});

export const mutations = mutationTree(state, {
  requestSucceeded(state) {
    state.success = true;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async sendPasswordResetEmail(
      context,
      payload: SendPasswordResetEmailMutationVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const res = await this.$apiClient.mutate(
          new SendPasswordResetEmailMutationRequest(payload)
        );
        if (res.sendPasswordResetEmail) {
          context.commit("requestSucceeded");
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async resetPassword(
      _,
      payload: ResetPasswordMutationVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const res = await this.$apiClient.mutate(
          new ResetPasswordMutationRequest(payload)
        );
        if (res.resetPassword) {
          this.$accessor.account.saveCredential(res.resetPassword);
          this.$accessor.toast.show({
            type: "success",
            message: this.$i18n.tc(
              "page.reset_password.message.pw_change_success"
            )
          });
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
