import { actionTree, mutationTree, getterTree } from "nuxt-typed-vuex";
import { convertTabs } from "./utils";
import {
  TopBanner,
  NavigationTabListQueryRequest,
  TopBannerListQueryRequest,
  SearchProjectListQueryRequest,
  SearchProjectConditionGroupInput,
  SearchProjectListQuery,
  OfferProject,
  OfferProjectTotalQueryRequest,
  InfluencerEntryProject
} from "~/types/gen/api";
import { Pagination } from "~/types/utils";

const initialPage: number = 0;
const initialPagination = {
  total: 0,
  perPage: 10,
  currentPage: initialPage,
  hasMorePages: true
};

export type Tab = {
  id: number;
  title: string;
  searchConditions: SearchProjectConditionGroupInput[];
};

export type ProjectsList = {
  [k in Tab["id"]]: (InfluencerEntryProject | OfferProject)[]
};

export type ProjectSearchState = {
  topBannerList: TopBanner[];
  // APIから取得した時の順番のタブ
  tabs: Tab[];
  currentTabId: Tab["id"];
  hasOffer: boolean;
  loadingFlags: { [k in Tab["id"]]: boolean };
  paginations: { [k in Tab["id"]]: Pagination };
  projectsList: ProjectsList;
};

export const state = (): ProjectSearchState => ({
  hasOffer: false,
  topBannerList: [],
  tabs: [],
  currentTabId: 0,
  loadingFlags: {},
  paginations: {},
  projectsList: {}
});

export const mutations = mutationTree(state, {
  initTabRelatedState(state, { tabId }: { tabId: Tab["id"] }) {
    state.projectsList[tabId] = [];
    state.loadingFlags[tabId] = false;
    state.paginations[tabId] = initialPagination;
  },
  setTopBannerList(state, topBannerList: TopBanner[]) {
    state.topBannerList = topBannerList;
  },
  setTabs(state, tabs: Tab[]) {
    state.tabs = tabs;
  },
  changeCurrentTab(state, tabId: Tab["id"]) {
    state.currentTabId = tabId;
  },
  setProjects(
    state,
    payload: SearchProjectListQuery["searchProjectList"] & { tabId: Tab["id"] }
  ) {
    const { edges, tabId, paginatorInfo } = payload;
    const projects = edges.map((v) => v.node!);
    state.projectsList[tabId].push(...projects);
    state.loadingFlags[tabId] = false;
    state.paginations[tabId] = paginatorInfo;
  },
  receiveOfferList(state, total: number) {
    state.hasOffer = total > 0;
  },
  startLoading(state, tabId: Tab["id"]) {
    state.loadingFlags[tabId] = true;
  },
  endLoading(state, tabId: Tab["id"]) {
    state.loadingFlags[tabId] = false;
  },
  endAllLoading(state) {
    for (const tabId of Object.keys(state.loadingFlags)) {
      state.loadingFlags[Number(tabId)] = false;
    }
  }
});

export const getters = getterTree(state, {
  nextPage: (state) => (tabId: Tab["id"]) => {
    const targetPagination = state.paginations[tabId];
    if (targetPagination.hasMorePages) {
      return targetPagination.currentPage + 1;
    }
    return undefined;
  }
});

export const actions = actionTree(
  {
    state,
    getters,
    mutations
  },
  {
    initTabRelatedState({ state, commit }): void {
      const tabs = state.tabs;
      for (const tab of tabs) {
        const tabId = tab.id;
        commit("initTabRelatedState", { tabId });
      }
    },
    async initLoad({ commit }): Promise<void> {
      // 「オファー案件の有無」と「タブの配列」をAPIより取得
      this.$accessor.presentation.showLoading(null);
      try {
        const bannerReq = new TopBannerListQueryRequest({});
        const tabReq = new NavigationTabListQueryRequest({});
        const [
          { topBannerList },
          { navigationTabList }
        ] = await Promise.all([
          this.$apiClient.query(bannerReq),
          this.$apiClient.query(tabReq)
        ]);
        const tabs = convertTabs(navigationTabList);
        commit("setTopBannerList", topBannerList);
        commit("setTabs", tabs);
      } catch (e) {
        this.$accessor.error.showError(e);
        commit("endAllLoading", e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async loadProjects({ state, getters, commit }, { tabId }: { tabId: Tab["id"]; }): Promise<void> {
      const nextPage = getters.nextPage(tabId);
      if (!nextPage) {
        return;
      }
      const isLoading = state.loadingFlags[tabId];
      if (isLoading) {
        return;
      }
      commit("startLoading", tabId); // そのタブのloadingフラグをtrueにする。
      try {
        const tab = state.tabs.find((tab) => tab.id === tabId);
        if (!tab) {
          return;
        }
        const req = new SearchProjectListQueryRequest({
          searchProjectConditionGroupInput: tab.searchConditions,
          perPage: 10,
          page: nextPage
        });
        const res = await this.$apiClient.query(req);
        const searchResult = { ...res.searchProjectList, tabId };
        commit("setProjects", searchResult);
      } catch (e) {
        this.$accessor.error.showError(e);
        commit("endLoading", tabId);
      } finally {
        // Dismiss refreshing
        this.$accessor.presentation.dismissLoading();
      }
    },
    async getOffferTotal({ commit }): Promise<void> {
      // 「オファー案件の有無」をAPIより取得
      try {
        const offerReq = new OfferProjectTotalQueryRequest({
          perPage: 1,
          page: 1
        });
        const { offerProjectList } = await this.$apiClient.query(offerReq);
        commit("receiveOfferList", offerProjectList.paginatorInfo.total);
      } catch (e) {
        this.$accessor.error.showError(e);
        commit("endAllLoading", e);
      }
    },
    changeCurrentTab({ commit }, tabId: Tab["id"]) {
      commit("changeCurrentTab", tabId);
    }
  }
);
