import { getterTree, actionTree, mutationTree } from "nuxt-typed-vuex";
import { initialListState } from "~/utils/list";
import { ListState } from "~/types/utils";
import {
  EntryEdge, ListEntryQuery,
  ListEntryQueryVariables,
  ListEntryQueryRequest
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type State = ListState<EntryEdge>;

export const state: () => State = initialListState;

export const getters = getterTree(state, {
  nextPage(state) {
    if (state.pagination.hasMorePages) {
      return state.pagination.currentPage + 1;
    }
    return undefined;
  }
});

export const mutations = mutationTree(state, {
  requestProjectList(state) {
    state.isLoading = true;
  },
  receiveItems(state, payload: ListEntryQuery["entryList"]) {
    if (payload.paginatorInfo.currentPage === 1) {
      state.items = payload.edges;
    } else {
      state.items = [...state.items, ...payload.edges];
    }
    state.isLoading = false;
    state.pagination = payload.paginatorInfo;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations, getters },
  {
    // Load entry projects
    async loadEntries(
      context,
      payload: ListEntryQueryVariables
    ): Promise<void> {
      const isRefresh = payload.page === 1;

      if (isRefresh) {
        // Initial load or refresh
        this.$accessor.presentation.showLoading(null);
      } else {
        // Load more
        context.commit("requestProjectList");
      }

      try {
        // Make request
        const req = new ListEntryQueryRequest(payload);
        // Exec request
        const res = await this.$apiClient.query(req);
        context.commit("receiveItems", res.entryList);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        // Dismiss refreshing
        this.$accessor.error.dismissError();
      }
    }
  }
);
