export default {
  message: {
    saved: "発送先住所を登録しました"
  },
  example: {
    zipcode: "例) 1234567",
    city_name: "例) 東京都",
    prefecture_name: "例) 渋谷区神泉町",
    address: "例) 1-2-3",
    building: "例) 大橋ビル 5F",
    phone_number: "例) 12312341234"
  }
};
