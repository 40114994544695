import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  AccountQueryRequest,
  AccountFragment,
  UpdateProfileMutationRequest,
  UpdateProfileMutationVariables
} from "~/types/gen/api";
import { APIError } from "~/network/api-error";
import { resetState } from "~/store/utils";

export type ProfileState = {
  account: AccountFragment | null;
};

export const state = (): ProfileState => ({
  account: null
});

export const mutations = mutationTree(state, {
  receiveAccount(state: ProfileState, payload: AccountFragment) {
    state.account = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async updateAccount(
      _context,
      payload: UpdateProfileMutationVariables
    ): Promise<void> {
      this.$accessor.presentation.showLoading(null);
      try {
        // Update account
        const update = await this.$apiClient.mutate(
          new UpdateProfileMutationRequest(payload)
        );

        if (!update.updateAccount) {
          throw new APIError(500, "Invalid Response");
        }

        // Re-fetch account
        const query = await this.$apiClient.query(new AccountQueryRequest({}));

        if (!query.me) {
          throw new APIError(500, "Invalid Response");
        }

        // Sync with account store
        this.$accessor.account.receiveAccount(query.me);

        // Show feedback
        this.$accessor.toast.show({
          type: "success",
          message: this.$i18n.tc(
            "page.account_settings.message.update_profile_success"
          )
        });
        // if there is "from" sentence in route, replace with it.
        const from = this.$router.currentRoute.query.from;
        if (typeof from === "string") {
          this.$router.replace(from);
        } else {
          // Replace route to mypage
          this.$router.replace("/mypage");
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async loadAccount(context): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const res = await this.$apiClient.query(new AccountQueryRequest({}));

        if (res.me) {
          context.commit("receiveAccount", res.me);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
