import { Context } from "@nuxt/types";

/**
 * loadAccount
 * @param context
 */
export default async function loadAccount(context: Context) {
  if (!context.app.$accessor.account.hasCredential) {
    return;
  }
  // If account is logged in, then fetch account data before rendering
  if (!context.app.$accessor.account.hasAccount) {
    await context.app.$accessor.account.loadAccount();
  }
}
