import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  AppleLoginMutationRequest,
  AppleLoginMutationVariables,
  AppleCredential
} from "~/types/gen/api";
import { APIError } from "~/network/api-error";

export type AppleLoginState = {
  appleCredential: AppleCredential;
};

export const state = (): AppleLoginState => ({
  appleCredential: {
    isFirstLogin: true,
    token: ""
  }
});

export const mutations = mutationTree(state, {
  appleLogin(state, payload: AppleCredential) {
    state.appleCredential = payload;
  }
});

export const actions = actionTree(
  {
    state,
    mutations
  },
  {
    async appleAuth(ctx, payload: AppleLoginMutationVariables): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);

        const req = new AppleLoginMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        // auth error
        if (!res.appleLogin) {
          throw new APIError(401, "Authentication failed");
        }
        ctx.commit("appleLogin", res.appleLogin);
        const credential = res.appleLogin as Pick<AppleCredential, "token">;
        this.$accessor.account.saveCredential(credential);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
