import { actionTree, mutationTree, getterTree } from "nuxt-typed-vuex";
import {
  OfferProjectsQueryRequest,
  OfferProject,
  OfferProjectsQuery,
  OfferProjectsQueryVariables
} from "~/types/gen/api";
import { Pagination } from "~/types/utils";
import { resetState } from "~/store/utils";

export type Response = OfferProjectsQuery["offerProjectList"];

export type OfferProjectListState = {
  isLoading: boolean;
  items: OfferProject[];
  pagination: Pagination;
};

export const state = (): OfferProjectListState => ({
  isLoading: true,
  items: [],
  pagination: {
    total: 0,
    perPage: 10,
    currentPage: 1,
    hasMorePages: false
  }
});

export const mutations = mutationTree(state, {
  requestProjectList(state: OfferProjectListState) {
    state.isLoading = true;
  },
  receiveProjectList(state: OfferProjectListState, payload: Response) {
    if (payload.paginatorInfo.currentPage === 1) {
      state.items = payload.edges.map((v) => v.node!);
    } else {
      state.items = [...state.items, ...payload.edges.map((v) => v.node!)];
    }
    state.isLoading = false;
    state.pagination = payload.paginatorInfo;
  },
  fail(state: OfferProjectListState) {
    state.isLoading = false;
  },
  reset: resetState(state)
});

export const getters = getterTree(state, {
  nextPage(state: OfferProjectListState) {
    if (state.pagination.hasMorePages) {
      return state.pagination.currentPage + 1;
    }
    return undefined;
  }
});

export const actions = actionTree(
  { state, getters, mutations },
  {
    // Load entry projects
    async loadProjects(
      context,
      payload: OfferProjectsQueryVariables
    ): Promise<void> {
      const isRefresh = payload.page === 1;

      if (isRefresh) {
        // Initial load or refresh
        this.$accessor.presentation.showLoading(null);
      } else {
        // Load more
        context.commit("requestProjectList");
      }
      try {
        // Make request
        const req = new OfferProjectsQueryRequest({
          perPage: 10,
          page: payload.page
        });
        // Exec request
        const res = await this.$apiClient.query(req);
        context.commit("receiveProjectList", res.offerProjectList);
      } catch (e) {
        this.$accessor.error.showError(e);
        context.commit("fail", e);
      } finally {
        // Dismiss refreshing
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
