export default {
  message: {
    delivery: {
      waiting: "運営者からの商品発送を待ちます",
      next_step: "商品発送のない案件は、３番へ",
      attention: "※案件によって、商品をご購入いただく場合がございます"
    },
    email_notification: "商品発送後、アプリ内で通知が来ます",
    post: {
      image: "SNSへ画像を投稿します",
      draft: "下書きがある投稿の場合、投稿前にアプリから下書きを提出します"
    },
    post_draft: {
      send: "SPADに下書きを送信します",
      next_step: "下書きの審査でOKが出たらSNSへ投稿しましょう！"
    },
    account: {
      payment: "指定の口座へ入金されます",
      billing:
        "案件終了後の翌月の20日前後に支払通知書が発行され問題がなければ入金がされます"
    }
  }
};
