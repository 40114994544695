import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { ManagedUpload } from "aws-sdk/clients/s3";
import { S3Client } from "~/network/s3-client";
import { APIError } from "~/network/api-error";
import { toImageAsset } from "~/domain/draft";
import {
  InstagramDraftFragment,
  TwitterDraftFragment,
  YouTubeDraftFragment,
  CreateDraftMutationRequest,
  InfluencerSaveDraft,
  CreateDraftMutationVariables,
  DraftQueryRequest,
  UpdateDraftMutationRequest,
  CompleteDraftMutationVariables,
  CompleteDraftMutationRequest,
  InstagramDraftAttachment,
  TwitterDraftAttachment,
  YouTubeDraftAttachment,
  InstagramDraft,
  TwitterDraft,
  YouTubeDraft,
  ProjectProgress,
  ContractedProject
} from "~/types/gen/api";
import {
  FileAsset,
  ImageAsset,
  getMimeType,
  isURLAsset,
  isFileAsset,
  isAVAsset
} from "~/utils/asset";
import { resetState } from "~/store/utils";
import { normalizedImageData } from "~/utils/image";
import ErrorMessage from "~/plugins/i18n/locales/ja/common/index";

type DraftFragment = InstagramDraftFragment | TwitterDraftFragment | YouTubeDraftFragment
type Draft = InstagramDraft | TwitterDraft | YouTubeDraft

export type DraftEditState = {
  draft: DraftFragment | null;
  caption: string;
  limitedUrl: string;
  assets: ImageAsset[];
  isComplete: boolean;
  isUploading: boolean;
  isLoading: boolean;
  contractedProject: ContractedProject | null;
  progress: ManagedUpload.Progress;
  projectProgress: ProjectProgress | null;
};

export const state = (): DraftEditState => ({
  draft: null,
  caption: "",
  limitedUrl: "",
  assets: [],
  progress: { total: 0, loaded: 0 },
  isComplete: false,
  isUploading: false,
  isLoading: false,
  contractedProject: null,
  projectProgress: null
});

export const mutations = mutationTree(state, {
  receiveDraft(state, payload: ProjectProgress) {
    state.projectProgress = payload;
    state.contractedProject = payload.contractedProject;
    const draft = payload.draft as Draft;
    state.draft = draft;

    if (!draft) {
      state.draft = null;
      state.caption = "";
      state.limitedUrl = "";
      state.assets = [];
      return;
    }

    switch (draft.__typename) {
      case "InstagramDraft":
        state.assets = draft.draftAttachments!.map((draftAttachment: InstagramDraftAttachment) => toImageAsset<InstagramDraftAttachment>(draftAttachment)) || [];
        state.caption = (draft as InstagramDraft).caption || "";
        break;
      case "TwitterDraft":
        state.assets = draft.draftAttachments!.map((draftAttachment: TwitterDraftAttachment) => toImageAsset<TwitterDraftAttachment>(draftAttachment)) || [];
        state.caption = (draft as TwitterDraft).caption || "";
        break;
      case "YouTubeDraft":
        state.assets = draft.draftAttachments!.map((draftAttachment: YouTubeDraftAttachment) => toImageAsset<YouTubeDraftAttachment>(draftAttachment)) || [];
        state.limitedUrl = (draft as YouTubeDraft).limitedShareUrl || "";
        break;
    }
  },
  uploadProgress(state, payload: ManagedUpload.Progress) {
    state.progress = payload;
  },
  captionUpdated(state, caption: string) {
    state.caption = caption;
  },
  limitedUrlUpdated(state, limitedUrl: string) {
    state.limitedUrl = limitedUrl;
  },
  assetSelected(state, assets: FileAsset[]) {
    state.assets = assets;
  },
  complete(state) {
    state.isComplete = true;
  },
  requestStart(state) {
    state.isLoading = true;
  },
  uploadStart(state) {
    state.isUploading = true;
  },
  requestEnd(state) {
    state.isLoading = false;
    state.isUploading = false;
  },
  reset: resetState(state)
});

export function isInstagramDraft(draft: InstagramDraft | TwitterDraft | YouTubeDraft): draft is InstagramDraft {
  return draft.__typename === "InstagramDraft";
};

export function isTwitterDraft(draft: InstagramDraft | TwitterDraft | YouTubeDraft): draft is TwitterDraft {
  return draft.__typename === "TwitterDraft";
};

export function isYouTubeDraft(draft: InstagramDraft | TwitterDraft | YouTubeDraft): draft is YouTubeDraft {
  return draft.__typename === "YouTubeDraft";
};

export function captionUpdatedPayload<T extends InstagramDraft | TwitterDraft | YouTubeDraft>(draft: T): string {
  if (isInstagramDraft(draft)) {
    return draft.rewriteCaption || "";
  }
  if (isTwitterDraft(draft)) {
    return draft.rewriteCaption || "";
  }
  if (isYouTubeDraft(draft)) {
    return "";
  }
  return "";
}
export function limitedUrlUpdatedPayload<T extends InstagramDraft | TwitterDraft | YouTubeDraft>(draft: T): string {
  if (isInstagramDraft(draft)) {
    return "";
  }
  if (isTwitterDraft(draft)) {
    return "";
  }
  if (isYouTubeDraft(draft)) {
    return draft.limitedShareUrl || "";
  }
  return "";
}

export const actions = actionTree(
  { state, mutations },
  {
    async init(
      context,
      { progressId, rewrite }: { progressId: string; rewrite: boolean }
    ): Promise<void> {
      this.$accessor.presentation.showLoading(null);

      const req = new DraftQueryRequest({ progressId });

      try {
        const draftResponse = await this.$apiClient.query(req);
        if (draftResponse.draftDetail) {
          context.commit("receiveDraft", draftResponse.draftDetail as ProjectProgress);
          // Update caption with rewrite caption
          if (!(
            rewrite &&
            draftResponse.draftDetail &&
            draftResponse.draftDetail.draft)) {
            return;
          }
          const draft = draftResponse.draftDetail.draft as InstagramDraft | TwitterDraft | YouTubeDraft;
          context.commit(
            "captionUpdated",
            captionUpdatedPayload(draft)
          );
          context.commit(
            "limitedUrlUpdated",
            limitedUrlUpdatedPayload(draft)
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async updateDraft(context, progressId: string): Promise<void> {
      const caption = context.state.caption;
      const limitedShareUrl = context.state.limitedUrl;
      const assets = context.state.assets;

      if (assets.length === 0) {
        this.$accessor.toast.show({
          type: "error",
          message: this.$i18n.tc("domain.draft.error.assets_required")
        });
        return;
      }

      context.commit("requestStart");

      const files = assets.filter(isFileAsset) as FileAsset[];
      const filesPayload = context.state.assets.map(toAttachment);

      try {
        const res: InfluencerSaveDraft | null | undefined = await this.$accessor.pages.draftEdit.saveDraft({
          progressId,
          caption,
          limitedShareUrl,
          files: filesPayload
        });

        if (!res) {
          throw new APIError(500, "Invalid Response");
        }

        const credential = res.credential;
        type SNSDraftAttachment = InstagramDraftAttachment[] | TwitterDraftAttachment[] | YouTubeDraftAttachment[];
        const attachments = res.draft.draftAttachments as SNSDraftAttachment;
        const payload = await makeUploadPayload(assets, attachments!);

        if (files.length > 0) {
          context.commit("uploadStart");

          await new S3Client(credential)
            .multipartUploadFiles(payload)
            .progress((progresses) => {
              const progress = progresses.reduce(
                (res, next) => {
                  return {
                    total: res.total + next.total,
                    loaded: res.loaded + next.loaded
                  };
                },
                { total: 0, loaded: 0 }
              );
              context.commit("uploadProgress", progress);
            })
            .catch((_) => {
              throw new APIError(500, ErrorMessage.message.fail_to_upload);
            });

          await this.$accessor.pages.draftEdit.completeDraft({
            progressId
          });
          context.commit("complete");
          return;
        }

        await this.$accessor.pages.draftEdit.completeDraft({
          progressId
        });
        context.commit("complete");
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        context.commit("requestEnd");
      }
    },
    async completeDraft(
      _,
      payload: CompleteDraftMutationVariables
    ): Promise<void> {
      await this.$apiClient.mutate(new CompleteDraftMutationRequest(payload));
    },
    async saveDraft(
      context,
      payload: CreateDraftMutationVariables
    ): Promise<InfluencerSaveDraft | null | undefined> {
      if (context.state.draft) {
        const res = await this.$apiClient.mutate(
          new UpdateDraftMutationRequest(payload)
        );
        return res.updateDraft;
      } else {
        const res = await this.$apiClient.mutate(
          new CreateDraftMutationRequest(payload)
        );
        return res.createDraft;
      }
    },
    selectAssets(context, payload: FileAsset[]) {
      context.commit("assetSelected", payload);
    },
    changeCaption(context, payload: string) {
      context.commit("captionUpdated", payload);
    },
    changeLimtiedUrl(context, payload: string) {
      context.commit("limitedUrlUpdated", payload);
    }
  }
);

function toAttachment(asset: ImageAsset) {
  if (isURLAsset(asset)) {
    return {
      attachment: {
        id: asset.id,
        mimeType: getMimeType(asset)
      }
    };
  }
  return {
    attachment: {
      mimeType: getMimeType(asset)
    }
  };
};

async function makeUploadPayload(
  assets: ImageAsset[],
  attachments: InstagramDraftAttachment[] | TwitterDraftAttachment[] | YouTubeDraftAttachment[]
) {
  return await Promise.all(
    assets
      .map((asset, i) => {
        const attachment = attachments![i];
        return {
          fileKey: attachment.fileKey,
          asset: isFileAsset(asset) ? asset : null
        };
      })
      .filter((v) => !!v && v.asset !== null)
      .map(async (payload) => {
        if (isAVAsset(payload!.asset!)) {
          return {
            fileKey: payload!.fileKey,
            data: payload!.asset!.file
          };
        } else {
          const data = await normalizedImageData(payload!.asset!.file);
          return {
            fileKey: payload!.fileKey,
            data
          };
        }
      })
  );
};
