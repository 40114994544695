import {
  SearchProjectCondition,
  NavigationTabListQuery,
  SearchProjectConditionGroup,
  SearchProjectConditionInput,
  SearchProjectConditionGroupInput
} from "~/types/gen/api";
import { Tab } from "~/store/pages/projectSearch/index";

// SearchProjectCondition[]=>SearchProjectConditionInput[]
export function convertCondition(
  conditions: SearchProjectCondition[]
): SearchProjectConditionInput[] {
  return conditions.map(({ field, value }) => {
    return { [field]: value };
  });
}

// SearchProjectConditionGroup[]=>SearchProjectConditionGroupInput[]
export function convertConditionGroup(
  conditionGroup: SearchProjectConditionGroup[]
): SearchProjectConditionGroupInput[] {
  const convertedSearchConditions = [] as SearchProjectConditionGroupInput[];
  for (const { conjunction, searchProjectCondition } of conditionGroup) {
    const convertedSearchCondition = {
      conjunction,
      searchProjectConditionInput: convertCondition(searchProjectCondition)
    };
    convertedSearchConditions.push(convertedSearchCondition);
  }
  return convertedSearchConditions;
}

// tabオブジェクトのsearchProjectConditionGroupプロパティをsearchProjectConditionGroupInputに整形(検索クエリに投げるために)。
export function convertTabs(
  tabs: NavigationTabListQuery["navigationTabList"]
): Tab[] {
  const convertedTabs = [] as Tab[];
  for (const tab of tabs) {
    const formattedSearchConditions = convertConditionGroup(
      tab.searchProjectConditionGroup
    );
    const formattedTab = {
      id: tab.id,
      title: tab.title,
      searchConditions: formattedSearchConditions
    };
    convertedTabs.push(formattedTab);
  }
  return convertedTabs;
}
