export default {
  label: {
    type: {
      ORDINARY: "普通",
      CURRENT: "当座"
    },
    holder_type: {
      INDIVIDUAL: "個人名義",
      CORPORATE: "法人名義"
    }
  }
};
