





























import Vue from "vue";
import Button from "~/components/ui/buttons/button.vue";
import ContentModal from "~/components/ui/modal/modal.vue";
import { APIErrorObject } from "~/network/api-error";

export default Vue.extend({
  components: {
    Button,
    ContentModal
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default(): String {
        return this.$t("common.error.message") as string;
      }
    },
    error: {
      type: Object as () => APIErrorObject,
      default: null
    }
  },
  computed: {
    shouldShowDebugMessage(): boolean {
      return (
        process.env.APP_ENV !== "production" &&
        !!this.error &&
        !!this.error.debugMessage
      );
    }
  },
  methods: {
    onClick() {
      this.$accessor.error.dismissError();
    }
  }
});
