import { actionTree } from "nuxt-typed-vuex";
import {
  CreateContactMutationRequest,
  CreateContactMutationVariables
} from "~/types/gen/api";

export const state = (): any => ({});

export const actions = actionTree(
  { state },
  {
    async createContact(
      _,
      payload: CreateContactMutationVariables
    ): Promise<void> {
      this.$accessor.presentation.showLoading(null);

      try {
        const req = new CreateContactMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);

        if (res.createContact) {
          this.$router.replace("/mypage");

          this.$accessor.toast.show({
            message: this.$i18n.tc("page.contact.message.created"),
            type: "success"
          });
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
