export default {
  message: {
    term_required: "利用規約に同意する必要があります",
    attention: "※記入すると、案件が受けやすくなります",
    agree: "に同意する",
    complete_registration: "アカウント登録を完了してください",
    requirement_to_send: `
      請求書処理のタイミングでメールを送信するため必ず必要となります。<br>
      またログアウトされた場合、再ログインできませんのでご登録の程よろしくお願いします。
    `
  },
  button: {
    term: "利用規約",
    register_account: "アカウント登録する",
    term_agreed: "この登録をもって{term}に同意したことになります。"
  },
  label: {
    register_line: "LINEで登録する",
    register_email: "メールアドレスで登録する",
    invitation_code: "招待コード",
    input_invitation_code:
      "友達招待のコードをお持ちの方はこちらからご入力ください"
  }
};
