/**
 * These are collections of Unicode code points for SPAD.
 *
 * Unicode references:
 * Unicode 11.0 Character Code Charts
 * http://www.unicode.org/charts/
 * UAX #44: Unicode Character Database
 * https://www.unicode.org/reports/tr44/
 */

export const hira = "\u3041-\u3094\u309D-\u309E\u30FB\u30FC";
export const kana = "\u30A1-\u30FE\uFF66-\uFF9F";

// Based on `GMOPG送金サービス(プロトコルタイプIF仕様)_BALANCE_2.03` p.8 & p.18
// Allow `ー (U+30fc)` exceptionally
export const bankAccountNameKana =
  "ｦ-ｯｱ-ﾟァ-ワヲンヴヵヶヷヺa-zA-Z0-9ａ-ｚＡ-Ｚ０-９" +
  "\u0020\u0028\u0029\u002C-\u002F\u00A5\u3000\u300C\u300D\u30FC\uFF08\uFF09\uFF0C-\uFF0F\uFF62\uFF63\uFF70\uFFE5";
