import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  UpdateEmailMutationVariables,
  UpdateEmailMutationRequest,
  AccountFragment
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type EmailState = {
  account: AccountFragment | null;
  success: boolean;
};

export const state = (): EmailState => ({
  account: null,
  success: false
});

export const mutations = mutationTree(state, {
  receiveAccount(state: EmailState, payload: AccountFragment) {
    state.account = payload;
  },
  requestSucceeded(state) {
    state.success = true;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    init(context): void {
      const account = this.$accessor.account.account;
      if (account) {
        context.commit("receiveAccount", account);
      }
    },
    async updateEmail(
      context,
      payload: UpdateEmailMutationVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const res = await this.$apiClient.mutate(
          new UpdateEmailMutationRequest(payload)
        );
        if (res.updateEmail) {
          context.commit("requestSucceeded");
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
