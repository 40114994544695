import { actionTree, getterTree, mutationTree } from "nuxt-typed-vuex";
import { DateTime } from "luxon";
import {
  MeQueryRequest,
  Credential,
  AccountFragment,
  ShippingAddressFragment,
  Payee,
  OnetimeLoginMutationRequest,
  AccountQueryRequest,
  InstagramAccountFragment,
  SocialAccount,
  Notification,
  InfluencerAuthorizedStatus,
  TwitterAccountFragment,
  YouTubeAccountFragment,
  TikTokAccountFragment
} from "~/types/gen/api";
import { APIError } from "~/network/api-error";
import { nativeBridge } from "~/foundations/native-bridge/native-bridge";

export const TOKEN_KEY = "spad-token";

export type AccountState = {
  token: string | null;
  account: AccountFragment | null;
  shippingAddress: ShippingAddressFragment | null;
  payee: Payee | null;
  unreadNotificationCount: number;
};

export const state = (): AccountState => ({
  account: null,
  shippingAddress: null,
  payee: null,
  token: null,
  unreadNotificationCount: 0
});

export const mutations = mutationTree(state, {
  receiveShippingAddress(state, payload: ShippingAddressFragment) {
    state.shippingAddress = payload;
  },
  receivePayee(state, payload: Payee) {
    state.payee = payload;
  },
  receiveAccount(state, payload: AccountFragment) {
    state.account = payload;
  },
  receiveNotificationCount(state, payload: number) {
    state.unreadNotificationCount = payload;
  },
  notificationRead(state) {
    state.unreadNotificationCount--;
  },
  onetimeLoggedIn(state) {
    state.account = null;
    state.payee = null;
    state.shippingAddress = null;
  },
  loggedIn(state, value: string) {
    state.token = value;
  },
  loggedOut(state) {
    state.account = null;
    state.token = null;
    state.shippingAddress = null;
    state.payee = null;
  }
});

export const getters = getterTree(state, {
  hasAccount(state) {
    return !!state.account;
  },
  hasShippingAddress(state) {
    return !!state.shippingAddress;
  },
  hasPayee(state) {
    return !!state.payee;
  },
  hasCredential(state) {
    return !!state.token;
  },
  isRegistered(state) {
    return !!state.account && !!state.account.email;
  },
  influencerName(state) {
    return state.account!.influencerName;
  },
  socialAccount(state): SocialAccount | null | undefined {
    return state?.account?.socialAccount;
  },
  instagramAccount(_state, getters): InstagramAccountFragment | null {
    return getters.socialAccount && getters.socialAccount.instagramAccount;
  },
  twitterAccount(_state, getters): TwitterAccountFragment | null {
    return getters.socialAccount && getters.socialAccount.twitterAccount;
  },
  youtubeAccount(_state, getters): YouTubeAccountFragment | null {
    return getters.socialAccount && getters.socialAccount.youtubeAccount;
  },
  tiktokAccount(_state, getters): TikTokAccountFragment | null {
    return getters.socialAccount && getters.socialAccount.tiktokAccount;
  },
  hasSocialAccount(state) {
    return !!state.account!.socialAccount;
  },
  socialAccountState(state) {
    return state.account!.socialAccount;
  },
  influencerAuthorizedStatus(state) {
    return (
      state.account?.socialAccount?.instagramAccount?.influencerAuthorizedStatus
      // TODO 全てのアカウントの登録がないとエントリー出来ないので単独連携もしくはマルチメディアで改善
      // && state.account?.socialAccount?.twitterAccount?.twitterAuthorizedStatus
      // && state.account?.socialAccount?.youtubeAccount?.youtubeAuthorizedStatus
    );
  },
  isFilledProfile(state) {
    return (
      !!state.account &&
      !!state.account.influencerName &&
      !!state.account.sex &&
      !!state.account.birthday
    );
  },
  isEmailVerifiedAt(state) {
    return (
      !!state.account &&
      !!state.account.email &&
      !!state.account.emailVerifiedAt
    );
  },
  hasVerifiedEmail(state) {
    return (
      !!state.account && !!state.account.email && state.account.hasVerifiedEmail
    );
  },
  isInstagramAuthorizationCompleted(_, getters) {
    return (
      getters.influencerAuthorizedStatus === InfluencerAuthorizedStatus.Complete
    );
  },
  hasLinkedSocialMedia(_, getters) {
    return getters.isInstagramAuthorizationCompleted;
  },
  hasEmail(state) {
    return !!state.account && !!state.account.hasEmail;
  },
  hasPassword(state) {
    return !!state.account && !!state.account.hasPassword;
  },
  hasPasswordWithoutEmail(state) {
    return (
      !!state.account && state.account.hasPassword && !state.account.hasEmail
    );
  }
});

export const actions = actionTree(
  { state, getters, mutations },
  {
    async loadAccount(context): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const res = await this.$apiClient.query(new MeQueryRequest({}));
        if (res.me) {
          context.commit("receiveAccount", res.me);
        }
        if (res.shippingAddress) {
          context.commit("receiveShippingAddress", res.shippingAddress);
        }
        if (res.payee) {
          context.commit("receivePayee", res.payee);
        }
        context.commit("receiveNotificationCount", res.unreadNotificationCount);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async fetchAccountBackground(context): Promise<void> {
      try {
        const res = await this.$apiClient.query(new AccountQueryRequest({}));
        if (res.me) {
          context.commit("receiveAccount", res.me);
        }
      } catch (e) {
        // Do nothing
      }
    },
    saveCredential(context, payload: Credential): void {
      const token = payload.token;
      const expiredAt = DateTime.local().plus({ year: 100 });
      // Persist token
      this.$cookies.set(TOKEN_KEY, token, {
        path: "/",
        expires: expiredAt.toJSDate()
      });
      context.commit("loggedIn", token);
      nativeBridge.loggedIn(token);
    },
    async onetimeLogin(context, token: string): Promise<void> {
      this.$accessor.presentation.showLoading(null);

      try {
        // Login
        const res = await this.$apiClient.mutate(
          new OnetimeLoginMutationRequest({
            token
          })
        );

        if (!res.onetimeLogin) {
          throw new APIError(400, "Invalid token");
        }

        // Save tokens
        this.$accessor.account.saveCredential(res.onetimeLogin);

        context.commit("onetimeLoggedIn");
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    markAsRead(context, _notification: Notification): void {
      context.commit("notificationRead");
    },
    logout(context): void {
      this.$cookies.remove(TOKEN_KEY);
      context.commit("loggedOut");
      nativeBridge.loggedOut();
    },
    logoutWithError(context): void {
      this.$cookies.remove(TOKEN_KEY);
      context.commit("loggedOut");
      nativeBridge.loggedOut();
    }
  }
);
