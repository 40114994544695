type Executor<T> = (
  resolve: (value: T) => Promise<T> | void,
  reject: (reason: any) => void,
  progress: (value: any) => void
) => void;

type ProgressHandler<T> = (value: T) => void;

export default class ProgressPromise<T, U> extends Promise<T> {
  private progressHandlers: ((progress: U) => void)[];

  constructor(executor: Executor<T>) {
    const setProgress = (progress: U) => {
      (async () => {
        // We should wait for the next microtask tick so `super` is called before we use `this`
        await Promise.resolve();

        for (const handler of this.progressHandlers) {
          handler(progress);
        }
      })();
    };
    super((resolve, reject) =>
      executor(resolve, reject, (value: U) => setProgress(value))
    );
    this.progressHandlers = [];
  }

  progress(handler: ProgressHandler<U>) {
    this.progressHandlers.push(handler);
    return this;
  }

  static allProgress<T, U>(
    promises: ProgressPromise<T, U>[]
  ): ProgressPromise<T[], U[]> {
    const values: T[] = [];
    const progresses: U[] = [];
    return new ProgressPromise<T[], U[]>((resolve, reject, notify) => {
      promises.forEach((promise, index) => {
        promise
          .progress((progress) => {
            progresses[index] = progress;
            if (progresses.length === promises.length) {
              notify(progresses);
            }
          })
          .then((value) => {
            values.push(value);
            if (values.length === progresses.length) {
              resolve(values);
            }
          })
          .catch(reject);
      });
    });
  }
}
