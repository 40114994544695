export default {
  message: {
    saved: "お支払口座を登録しました"
  },
  label: {
    identification: "ご本人様確認",
    payee: "お振込先",
    saving_account: "普通",
    current_account: "当座",
    private: "個人",
    corporate: "法人"
  },
  placeholder: {
    bank_Name: "銀行名",
    branch_name: "支店名",
    account_number: "口座番号",
    account_holder: "口座名義 (カナ)"
  },
  button: {
    save: "保存する"
  }
};
