import gql from "graphql-tag";
import { QueryOptions, MutationOptions } from "apollo-client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export interface QueryRequest<TResult, TVariable> {
          _result?: TResult;
          options: QueryOptions<TVariable>;
        }
export interface MutationRequest<TResult, TVariable> {
          options: MutationOptions<TResult, TVariable>;
        }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-01-01 13:00:00`. */
  DateTime: any;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
};

export type Query = {
  __typename?: "Query";
  user?: Maybe<User>;
  deleteAccountDetail?: Maybe<DeleteAccount>;
  faqCategoryList: Array<FaqCategory>;
  socialMediaAccountActivationList: Array<SocialMediaAccountActivation>;
  twitterAuth: TwitterAuth;
  twitterAuthForShow: TwitterAuth;
  announcementDetail: Announcement;
  billingDetail?: Maybe<Billing>;
  billingList: BillingListConnection;
  contactReplyDetail: ContactReply;
  directMessageDetail?: Maybe<DirectMessage>;
  /** @deprecated draftは`projectProgressDetail`を使用して、`ProjectProgress.draft`から取得する */
  draftDetail?: Maybe<Draft>;
  enqueteDetail: Enquete;
  entryDetail?: Maybe<Entry>;
  entryList: EntryListConnection;
  faqDetail: Faq;
  faqList: Array<Faq>;
  influencerEntryProjectDetail: InfluencerEntryProject;
  instagramAccountActivationDetail: InstagramAccountActivation;
  instagramAccountProfileDetail?: Maybe<InstagramAccountProfile>;
  internalMe: Account;
  lpProjectDetail?: Maybe<LpProject>;
  lpProjectList: Array<LpProject>;
  me: Account;
  navigationTabList: Array<NavigationTab>;
  notificationList: NotificationListConnection;
  offerNgReasonList: Array<OfferNgReason>;
  offerProjectDetail?: Maybe<OfferProject>;
  offerProjectList: OfferProjectListConnection;
  payee?: Maybe<Payee>;
  projectProgressDetail?: Maybe<ProjectProgress>;
  projectProgressList: ProjectProgressListConnection;
  searchEntryProject: InfluencerEntryProjectListConnection;
  searchProjectList: ProjectListConnection;
  shippingAddress?: Maybe<ShippingAddress>;
  systemSetting: SystemSetting;
  tikTokAccountActivationDetail: TikTokAccountActivation;
  topBannerList: Array<TopBanner>;
  twitterAccountActivationDetail: TwitterAccountActivation;
  unreadNotificationCount: Scalars["Int"];
  youtubeAccountActivationDetail: YouTubeAccountActivation;
  zipCodeDetail?: Maybe<ZipCode>;
};

export type QueryUserArgs = {
  id?: Maybe<Scalars["ID"]>;
};

export type QueryAnnouncementDetailArgs = {
  id: Scalars["ID"];
};

export type QueryBillingDetailArgs = {
  id: Scalars["ID"];
};

export type QueryBillingListArgs = {
  billingStatus: BillingStatus;
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
};

export type QueryContactReplyDetailArgs = {
  id: Scalars["ID"];
};

export type QueryDirectMessageDetailArgs = {
  id: Scalars["ID"];
};

export type QueryDraftDetailArgs = {
  id: Scalars["ID"];
};

export type QueryEnqueteDetailArgs = {
  id: Scalars["ID"];
};

export type QueryEntryDetailArgs = {
  id: Scalars["ID"];
};

export type QueryEntryListArgs = {
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
};

export type QueryFaqDetailArgs = {
  id: Scalars["ID"];
};

export type QueryFaqListArgs = {
  categoryId?: Maybe<Scalars["ID"]>;
};

export type QueryInfluencerEntryProjectDetailArgs = {
  id: Scalars["ID"];
};

export type QueryInstagramAccountProfileDetailArgs = {
  username: Scalars["String"];
};

export type QueryLpProjectDetailArgs = {
  slug: Scalars["String"];
};

export type QueryLpProjectListArgs = {
  mediaType?: Maybe<ProjectMediaType>;
};

export type QueryNotificationListArgs = {
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
};

export type QueryOfferProjectDetailArgs = {
  id: Scalars["ID"];
};

export type QueryOfferProjectListArgs = {
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
};

export type QueryProjectProgressDetailArgs = {
  id: Scalars["ID"];
};

export type QueryProjectProgressListArgs = {
  projectProgressStatus: ProjectProgressStatus;
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
};

export type QuerySearchEntryProjectArgs = {
  projectRewardType?: Maybe<ProjectRewardType>;
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
};

export type QuerySearchProjectListArgs = {
  searchProjectConditionGroupInput: Array<SearchProjectConditionGroupInput>;
  page: Scalars["Int"];
  perPage?: Maybe<Scalars["Int"]>;
};

export type QueryZipCodeDetailArgs = {
  zipcode: Scalars["String"];
};

export type User = {
  __typename?: "User";
  id: Scalars["ID"];
  name: Scalars["String"];
  email: Scalars["String"];
};

export type DeleteAccount = {
  __typename?: "DeleteAccount";
  reason?: Maybe<Scalars["String"]>;
  requestedAt: Scalars["DateTime"];
};

export type FaqCategory = {
  __typename?: "FaqCategory";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type SocialMediaAccountActivation = {
  id: Scalars["ID"];
  mediaType: MediaType;
  authorizedStatus: InfluencerAuthorizedStatus;
  followerCountBorder: Scalars["Int"];
  postCountBorder: Scalars["Int"];
  username?: Maybe<Scalars["String"]>;
  followerCount?: Maybe<Scalars["Int"]>;
  postCount?: Maybe<Scalars["Int"]>;
  canRequestReview?: Maybe<Scalars["Boolean"]>;
};

/** media_type */
export enum MediaType {
  Twitter = "TWITTER",
  Instagram = "INSTAGRAM",
  Youtube = "YOUTUBE",
  Tiktok = "TIKTOK"
}

export enum InfluencerAuthorizedStatus {
  /** 本人確認中 */
  Identification = "IDENTIFICATION",
  /** フォロワー数・投稿数を審査中 */
  InsightJudgment = "INSIGHT_JUDGMENT",
  /** 完了 */
  Complete = "COMPLETE",
  /** 更新中 */
  Updating = "UPDATING"
}

export type TwitterAuth = {
  __typename?: "TwitterAuth";
  oauthToken: Scalars["String"];
};

export type Announcement = {
  __typename?: "Announcement";
  title: Scalars["String"];
  body: Scalars["String"];
  createdAt: Scalars["Date"];
};

export type Billing = {
  __typename?: "Billing";
  id: Scalars["ID"];
  number: Scalars["Int"];
  year: Scalars["Int"];
  month: Scalars["Int"];
  status: BillingStatus;
  publishDate: Scalars["Date"];
  expireDate: Scalars["Date"];
  agencyName: Scalars["String"];
  subTotal: Scalars["Int"];
  consumptionTax: Scalars["Int"];
  withholdingTax: Scalars["Int"];
  taxedTotal: Scalars["Int"];
  payee: PayeeSnapshot;
  items: Array<BillingItem>;
};

export enum BillingStatus {
  Unpaid = "UNPAID",
  Complete = "COMPLETE"
}

export type PayeeSnapshot = PayeeLike & {
  __typename?: "PayeeSnapshot";
  lastName: Scalars["String"];
  firstName: Scalars["String"];
  hasWithholdingTax: Scalars["Boolean"];
  bankAccount?: Maybe<BankAccount>;
};

export type PayeeLike = {
  lastName: Scalars["String"];
  firstName: Scalars["String"];
  hasWithholdingTax: Scalars["Boolean"];
  bankAccount?: Maybe<BankAccount>;
};

export type BankAccount = {
  __typename?: "BankAccount";
  bankName: Scalars["String"];
  branchName: Scalars["String"];
  number: Scalars["String"];
  accountHolder: Scalars["String"];
  type?: Maybe<BankAccountType>;
};

export enum BankAccountType {
  /** 普通 */
  Ordinary = "ORDINARY",
  /** 当座 */
  Current = "CURRENT"
}

export type BillingItem = {
  __typename?: "BillingItem";
  projectName: Scalars["String"];
  amount: Scalars["Int"];
  postedAt: Scalars["DateTime"];
};

export type BillingListConnection = Connection & {
  __typename?: "BillingListConnection";
  paginatorInfo: PaginatorInfo;
  edges: Array<BillingEdge>;
};

export type Connection = {
  paginatorInfo?: Maybe<PaginatorInfo>;
  edges: Array<Edge>;
};

/** Pagination information about the corresponding list of items. */
export type PaginatorInfo = {
  __typename?: "PaginatorInfo";
  /** Total count of available items in the page. */
  count: Scalars["Int"];
  /** Current pagination page. */
  currentPage: Scalars["Int"];
  /** Index of first item in the current page. */
  firstItem?: Maybe<Scalars["Int"]>;
  /** If collection has more pages. */
  hasMorePages: Scalars["Boolean"];
  /** Index of last item in the current page. */
  lastItem?: Maybe<Scalars["Int"]>;
  /** Last page number of the collection. */
  lastPage: Scalars["Int"];
  /** Number of items per page in the collection. */
  perPage: Scalars["Int"];
  /** Total items available in the collection. */
  total: Scalars["Int"];
};

export type Edge = {
  cursor: Scalars["ID"];
};

export type BillingEdge = Edge & {
  __typename?: "BillingEdge";
  cursor: Scalars["ID"];
  node: Billing;
};

export type ContactReply = {
  __typename?: "ContactReply";
  title?: Maybe<Scalars["String"]>;
  body?: Maybe<Scalars["String"]>;
  contact?: Maybe<Contact>;
  createdAt: Scalars["DateTime"];
};

export type Contact = {
  __typename?: "Contact";
  content: Scalars["String"];
};

export type DirectMessage = {
  __typename?: "DirectMessage";
  id: Scalars["ID"];
  title: Scalars["String"];
  body: Scalars["String"];
  createdAt: Scalars["DateTime"];
};

export type Draft = {
  id: Scalars["ID"];
  status: DraftStatus;
  confirmedAt?: Maybe<Scalars["DateTime"]>;
  draftAttachments: Array<DraftAttachment>;
};

export enum DraftStatus {
  /** 添付ファイルアップロード中 */
  AttachmentFileUpload = "ATTACHMENT_FILE_UPLOAD",
  /** 確認中 */
  Requesting = "REQUESTING",
  /** OK */
  Ok = "OK",
  /** NG */
  Ng = "NG"
}

export type DraftAttachment = {
  id: Scalars["ID"];
};

export type Enquete = {
  __typename?: "Enquete";
  id: Scalars["ID"];
  title: Scalars["String"];
  body: Scalars["String"];
  pageType?: Maybe<EnquetePageType>;
  /** アンケートの回答必須フラグ */
  required: Scalars["Boolean"];
  /** アンケート回答期限From */
  responsePeriodFrom: Scalars["Date"];
  /** アンケート回答期限To */
  responsePeriodTo: Scalars["Date"];
  alreadyAnswered?: Maybe<Scalars["Boolean"]>;
  enqueteQuestions: Array<EnqueteQuestion>;
};

/** アンケートを表示するページの種類 */
export enum EnquetePageType {
  /** 案件エントリー前 */
  BeforeEntryProject = "BEFORE_ENTRY_PROJECT",
  /** 案件エントリー後 */
  AfterEntryProject = "AFTER_ENTRY_PROJECT",
  /** 案件報告前 */
  BeforeReportProject = "BEFORE_REPORT_PROJECT",
  /** 案件報告後 */
  AfterReportProject = "AFTER_REPORT_PROJECT"
}

export type EnqueteQuestion = {
  __typename?: "EnqueteQuestion";
  id: Scalars["ID"];
  title?: Maybe<Scalars["String"]>;
  node: EnqueteQuestionNode;
  /** 質問の回答必須フラグ */
  required: Scalars["Boolean"];
  sort: Scalars["Int"];
};

export type EnqueteQuestionNode = EnqueteQuestionText | EnqueteQuestionTextArea | EnqueteQuestionCheckbox | EnqueteQuestionPulldown | EnqueteQuestionRadio | EnqueteQuestionRange;

/** 質問の種類: テキスト（一行） */
export type EnqueteQuestionText = {
  __typename?: "EnqueteQuestionText";
  id: Scalars["ID"];
  minSize?: Maybe<Scalars["Int"]>;
  maxSize?: Maybe<Scalars["Int"]>;
};

/** 質問の種類: テキストエリア（複数行） */
export type EnqueteQuestionTextArea = {
  __typename?: "EnqueteQuestionTextArea";
  id: Scalars["ID"];
  minSize?: Maybe<Scalars["Int"]>;
  maxSize?: Maybe<Scalars["Int"]>;
};

/** 質問の種類: チェックボックス */
export type EnqueteQuestionCheckbox = {
  __typename?: "EnqueteQuestionCheckbox";
  items: Array<Maybe<EnqueteQuestionCheckboxItem>>;
};

export type EnqueteQuestionCheckboxItem = {
  __typename?: "EnqueteQuestionCheckboxItem";
  id: Scalars["ID"];
  label: Scalars["String"];
  sort: Scalars["Int"];
};

/** 質問の種類: プルダウン */
export type EnqueteQuestionPulldown = {
  __typename?: "EnqueteQuestionPulldown";
  items: Array<Maybe<EnqueteQuestionPulldownItem>>;
};

export type EnqueteQuestionPulldownItem = {
  __typename?: "EnqueteQuestionPulldownItem";
  id: Scalars["ID"];
  label: Scalars["String"];
  sort: Scalars["Int"];
};

/** 質問の種類: ラジオ */
export type EnqueteQuestionRadio = {
  __typename?: "EnqueteQuestionRadio";
  items: Array<Maybe<EnqueteQuestionRadioItem>>;
};

export type EnqueteQuestionRadioItem = {
  __typename?: "EnqueteQuestionRadioItem";
  id: Scalars["ID"];
  label: Scalars["String"];
  sort: Scalars["Int"];
};

/** 質問の種類: 均等目盛り */
export type EnqueteQuestionRange = {
  __typename?: "EnqueteQuestionRange";
  id: Scalars["ID"];
  minLabel: Scalars["String"];
  minValue: Scalars["Int"];
  maxLabel: Scalars["String"];
  maxValue: Scalars["Int"];
  /** 値同士の間隔。目盛り */
  scale: Scalars["Int"];
};

export type Entry = {
  __typename?: "Entry";
  id: Scalars["ID"];
  entryProject: EntryProject;
  entryStatus: EntryStatus;
  createdAt: Scalars["DateTime"];
  rejectedAt?: Maybe<Scalars["DateTime"]>;
};

export type EntryProject = ContractableProject & {
  __typename?: "EntryProject";
  id: Scalars["ID"];
  project: Project;
  price: Scalars["Int"];
  hasPayment: Scalars["Boolean"];
  projectRewardType: ProjectRewardType;
  projectType: ProjectType;
  entrySetting: EntrySetting;
  enquetes: Array<Enquete>;
};

export type ContractableProject = {
  project: Project;
  price: Scalars["Int"];
  hasPayment: Scalars["Boolean"];
  projectRewardType: ProjectRewardType;
  projectType: ProjectType;
};

export type Project = {
  __typename?: "Project";
  id: Scalars["ID"];
  name: Scalars["String"];
  mediaType: ProjectMediaType;
  appealContent: Scalars["String"];
  hashTagArray: Array<Maybe<Scalars["String"]>>;
  howTakePicture?: Maybe<Scalars["String"]>;
  externalLinkUrl?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  postPeriodStartAt: Scalars["DateTime"];
  postPeriodEndAt: Scalars["DateTime"];
  ngConditions?: Maybe<Scalars["String"]>;
  videoSecondaryUse?: Maybe<Scalars["String"]>;
  videoDescription?: Maybe<Scalars["String"]>;
  hasShippingStep: Scalars["Boolean"];
  item: Item;
  client: Client;
};

/** projects.media_type */
export enum ProjectMediaType {
  Twitter = "TWITTER",
  Instagram = "INSTAGRAM",
  Youtube = "YOUTUBE",
  Tiktok = "TIKTOK"
}

export type Item = {
  __typename?: "Item";
  id: Scalars["ID"];
  name: Scalars["String"];
  detail: Scalars["String"];
  imageUrl: Scalars["String"];
};

export type Client = {
  __typename?: "Client";
  id: Scalars["ID"];
  companyName: Scalars["String"];
};

/** entry_settings.price */
export enum ProjectRewardType {
  /** モニター型 */
  Monitor = "MONITOR",
  /** 投稿報酬型 */
  Reward = "REWARD",
  /** おすすめ */
  Recommend = "RECOMMEND",
  /** コロナ応援 */
  Covid19 = "COVID19"
}

/** project.project_type */
export enum ProjectType {
  /** 私募案件 */
  Offer = "OFFER",
  /** 公募案件 */
  Entry = "ENTRY"
}

export type EntrySetting = {
  __typename?: "EntrySetting";
  id: Scalars["ID"];
  minFollowerCount?: Maybe<Scalars["Int"]>;
  sex?: Maybe<Sex>;
  ageFrom?: Maybe<Scalars["Int"]>;
  ageTo?: Maybe<Scalars["Int"]>;
  residencePlace: Scalars["String"];
  selectableInfluencersLimit: Scalars["Int"];
  entryStartAt: Scalars["DateTime"];
  entryEndAt: Scalars["DateTime"];
};

/** sex */
export enum Sex {
  /** Male */
  Male = "MALE",
  /** Female */
  Female = "FEMALE"
}

export enum EntryStatus {
  Waiting = "WAITING",
  Winning = "WINNING",
  Rejected = "REJECTED"
}

export type EntryListConnection = Connection & {
  __typename?: "EntryListConnection";
  paginatorInfo: PaginatorInfo;
  edges: Array<EntryEdge>;
};

export type EntryEdge = Edge & {
  __typename?: "EntryEdge";
  cursor: Scalars["ID"];
  node: Entry;
};

export type Faq = {
  __typename?: "Faq";
  id: Scalars["ID"];
  title: Scalars["String"];
  body: Scalars["String"];
};

export type InfluencerEntryProject = {
  __typename?: "InfluencerEntryProject";
  id: Scalars["ID"];
  entryProject: EntryProject;
  isEntried: Scalars["Boolean"];
  isSatisfiedMinFollowerCount: Scalars["Boolean"];
  isSatisfiedSex: Scalars["Boolean"];
  isSatisfiedAge: Scalars["Boolean"];
  /** 案件に紐付いているSNSの審査が突破しているかどうか（ex Instagramの案件であればInstagramのアカウントの審査が突破しているか） */
  isSatisfiedSocialMedia: Scalars["Boolean"];
  enquetes: Array<Enquete>;
};

export type InstagramAccountActivation = SocialMediaAccountActivation & {
  __typename?: "InstagramAccountActivation";
  id: Scalars["ID"];
  mediaType: MediaType;
  /** @deprecated 元々Instagramのみの認証だったが、他のSNSも増やすので、authorizedStatusで代用 */
  status: InstagramAccountActivationStatus;
  authorizedStatus: InfluencerAuthorizedStatus;
  identificationCode: Scalars["String"];
  followerCountBorder: Scalars["Int"];
  postCountBorder: Scalars["Int"];
  username?: Maybe<Scalars["String"]>;
  followerCount?: Maybe<Scalars["Int"]>;
  postCount?: Maybe<Scalars["Int"]>;
  canRequestReview?: Maybe<Scalars["Boolean"]>;
};

export enum InstagramAccountActivationStatus {
  /** 本人確認中 */
  Identification = "IDENTIFICATION",
  /** フォロワー数・投稿数を審査中 */
  InsightJudgment = "INSIGHT_JUDGMENT",
  /** 完了 */
  Complete = "COMPLETE",
  /** 更新中 */
  Updating = "UPDATING"
}

export type InstagramAccountProfile = {
  __typename?: "InstagramAccountProfile";
  username: Scalars["String"];
  name: Scalars["String"];
  imageUrl: Scalars["String"];
};

export type Account = {
  __typename?: "Account";
  id: Scalars["ID"];
  /** @deprecated use `SocialAccount.InstagramAccount.influencerAuthorizedStatus instead.` */
  isInstagramAccountActivated: Scalars["Boolean"];
  influencerName?: Maybe<Scalars["String"]>;
  influencerImageUrl?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  emailVerifiedAt?: Maybe<Scalars["DateTime"]>;
  hasVerifiedEmail: Scalars["Boolean"];
  sex?: Maybe<Sex>;
  birthday?: Maybe<Scalars["Date"]>;
  /** @deprecated use `SocialAccount.InstagramAccount.followerCount instead.` */
  instagramInsight?: Maybe<InstagramInsight>;
  shippingAddress?: Maybe<ShippingAddress>;
  payee?: Maybe<Payee>;
  socialAccount?: Maybe<SocialAccount>;
  loginCredential?: Maybe<LoginCredential>;
  hasEmail: Scalars["Boolean"];
  hasPassword: Scalars["Boolean"];
};

export type InstagramInsight = {
  __typename?: "InstagramInsight";
  followerCount: Scalars["Int"];
  influencerSocialAccount: InfluencerSocialAccount;
};

export type InfluencerSocialAccount = {
  __typename?: "InfluencerSocialAccount";
  socialAccountId: Scalars["String"];
};

export type ShippingAddress = {
  __typename?: "ShippingAddress";
  id: Scalars["ID"];
  lastName: Scalars["String"];
  firstName: Scalars["String"];
  lastNameKana: Scalars["String"];
  firstNameKana: Scalars["String"];
  zipcode: Scalars["String"];
  prefectureName: Scalars["String"];
  cityName: Scalars["String"];
  address: Scalars["String"];
  building?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type Payee = PayeeLike & {
  __typename?: "Payee";
  id: Scalars["ID"];
  lastName: Scalars["String"];
  firstName: Scalars["String"];
  hasWithholdingTax: Scalars["Boolean"];
  bankAccount?: Maybe<BankAccount>;
};

export type SocialAccount = {
  __typename?: "SocialAccount";
  instagramAccount?: Maybe<InstagramAccount>;
  twitterAccount?: Maybe<TwitterAccount>;
  youtubeAccount?: Maybe<YouTubeAccount>;
  tiktokAccount?: Maybe<TikTokAccount>;
};

export type InstagramAccount = {
  __typename?: "InstagramAccount";
  influencerAuthorizedStatus?: Maybe<InfluencerAuthorizedStatus>;
  identificationCode?: Maybe<Scalars["String"]>;
  followerCountBorder: Scalars["Int"];
  postCountBorder: Scalars["Int"];
  /** 個人を一意に特定するために設定をされていてアカウント作成後の変更不可なID e.g.)702118422 */
  userId?: Maybe<Scalars["String"]>;
  /** ユーザーをInstagram上で検索する時に使い、各ユーザーの詳細ページのURLに文字列として含まれているID e.g.)anriworld */
  username?: Maybe<Scalars["String"]>;
  followerCount?: Maybe<Scalars["Int"]>;
  postCount?: Maybe<Scalars["Int"]>;
};

export type TwitterAccount = {
  __typename?: "TwitterAccount";
  username?: Maybe<Scalars["String"]>;
  followerCount?: Maybe<Scalars["Int"]>;
  twitterAuthorizedStatus?: Maybe<InfluencerAuthorizedStatus>;
};

export type YouTubeAccount = {
  __typename?: "YouTubeAccount";
  title?: Maybe<Scalars["String"]>;
  followerCount?: Maybe<Scalars["Int"]>;
  youtubeAuthorizedStatus?: Maybe<InfluencerAuthorizedStatus>;
};

export type TikTokAccount = {
  __typename?: "TikTokAccount";
  title?: Maybe<Scalars["String"]>;
  followerCount?: Maybe<Scalars["Int"]>;
  tiktokAuthorizedStatus?: Maybe<InfluencerAuthorizedStatus>;
  identificationCode?: Maybe<Scalars["String"]>;
};

export type LoginCredential = {
  __typename?: "LoginCredential";
  email?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
};

export type LpProject = {
  __typename?: "LpProject";
  id: Scalars["ID"];
  enqueteId: Scalars["Int"];
  mediaType: ProjectMediaType;
  projectTitle?: Maybe<Scalars["String"]>;
  productName?: Maybe<Scalars["String"]>;
  productImgUrl?: Maybe<Scalars["String"]>;
  productUrl?: Maybe<Scalars["String"]>;
  productDescription?: Maybe<Scalars["String"]>;
  clientName?: Maybe<Scalars["String"]>;
  requirements?: Maybe<Scalars["String"]>;
  checkPost?: Maybe<Scalars["String"]>;
  entryOpenings?: Maybe<Scalars["Int"]>;
  entryOpeningsFilled?: Maybe<Scalars["Boolean"]>;
  appealPoints?: Maybe<Scalars["String"]>;
  pictureHowTo?: Maybe<Scalars["String"]>;
  hashtags?: Maybe<Scalars["String"]>;
  videoSecondaryUsage?: Maybe<Scalars["String"]>;
  intendedMessage?: Maybe<Scalars["String"]>;
  ng?: Maybe<Scalars["String"]>;
  videoDescription?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  paymentValue?: Maybe<Scalars["Int"]>;
  paymentDate?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  published?: Maybe<Scalars["Boolean"]>;
  sort?: Maybe<Scalars["Int"]>;
  entryStart?: Maybe<Scalars["DateTime"]>;
  entryEnd?: Maybe<Scalars["DateTime"]>;
  postStart?: Maybe<Scalars["DateTime"]>;
  postEnd?: Maybe<Scalars["DateTime"]>;
  alreadyAnswered?: Maybe<Scalars["Boolean"]>;
};

export type NavigationTab = {
  __typename?: "NavigationTab";
  id: Scalars["Int"];
  title: Scalars["String"];
  searchProjectConditionGroup: Array<SearchProjectConditionGroup>;
};

export type SearchProjectConditionGroup = {
  __typename?: "SearchProjectConditionGroup";
  conjunction?: Maybe<ConjunctionType>;
  searchProjectCondition: Array<SearchProjectCondition>;
};

export enum ConjunctionType {
  And = "AND",
  Or = "OR"
}

export type SearchProjectCondition = {
  __typename?: "SearchProjectCondition";
  field: Scalars["String"];
  value: Scalars["String"];
};

export type NotificationListConnection = Connection & {
  __typename?: "NotificationListConnection";
  paginatorInfo: PaginatorInfo;
  edges: Array<NotificationEdge>;
};

export type NotificationEdge = Edge & {
  __typename?: "NotificationEdge";
  cursor: Scalars["ID"];
  node: Notification;
};

export type Notification = {
  __typename?: "Notification";
  id: Scalars["ID"];
  notifiableId: Scalars["ID"];
  type: NotificationType;
  title: Scalars["String"];
  readFlag?: Maybe<Scalars["Boolean"]>;
  createdAt: Scalars["DateTime"];
};

/** app_influencer_account_notifications.type */
export enum NotificationType {
  Announcement = "ANNOUNCEMENT",
  Order = "ORDER",
  Billing = "BILLING",
  Shipping = "SHIPPING",
  AcceptanceOk = "ACCEPTANCE_OK",
  AcceptanceNg = "ACCEPTANCE_NG",
  BillingNg = "BILLING_NG",
  OrderDeadline = "ORDER_DEADLINE",
  PublicationDeadline = "PUBLICATION_DEADLINE",
  Draft = "DRAFT",
  BillingComplete = "BILLING_COMPLETE",
  InstagramActivationComplete = "INSTAGRAM_ACTIVATION_COMPLETE",
  TwitterActivationComplete = "TWITTER_ACTIVATION_COMPLETE",
  YoutubeActivationComplete = "YOUTUBE_ACTIVATION_COMPLETE",
  TiktokActivationComplete = "TIKTOK_ACTIVATION_COMPLETE",
  AcceptEntryProject = "ACCEPT_ENTRY_PROJECT",
  RejectEntryProject = "REJECT_ENTRY_PROJECT",
  DraftDeadline = "DRAFT_DEADLINE",
  ReplyContact = "REPLY_CONTACT",
  DirectMessage = "DIRECT_MESSAGE"
}

export type OfferNgReason = {
  __typename?: "OfferNgReason";
  id: Scalars["ID"];
  content: Scalars["String"];
};

export type OfferProject = ContractableProject & {
  __typename?: "OfferProject";
  id: Scalars["ID"];
  project: Project;
  price: Scalars["Int"];
  hasPayment: Scalars["Boolean"];
  projectRewardType: ProjectRewardType;
  projectType: ProjectType;
  offerExpiredAt: Scalars["DateTime"];
  offerAnswerStatus: OfferAnswerStatus;
  enquetes: Array<Enquete>;
};

export enum OfferAnswerStatus {
  Waiting = "WAITING",
  Accepted = "ACCEPTED",
  Rejected = "REJECTED"
}

export type OfferProjectListConnection = Connection & {
  __typename?: "OfferProjectListConnection";
  paginatorInfo: PaginatorInfo;
  edges: Array<OfferProjectEdge>;
};

export type OfferProjectEdge = Edge & {
  __typename?: "OfferProjectEdge";
  cursor: Scalars["ID"];
  node: OfferProject;
};

export type ProjectProgress = {
  __typename?: "ProjectProgress";
  id: Scalars["ID"];
  stepId: ProjectFlowStepId;
  previousStepId: ProjectFlowStepId;
  projectFlowSteps: Array<ProjectFlowStepId>;
  projectProgressStatus: ProjectProgressStatus;
  contractedProject: ContractedProject;
  contractDocumentPdfUrl: Scalars["String"];
  enquetes: Array<Enquete>;
  draft?: Maybe<Draft>;
  acceptance?: Maybe<Acceptance>;
  billing?: Maybe<Billing>;
};

export enum ProjectFlowStepId {
  Waiting = "WAITING",
  Shipping = "SHIPPING",
  Draft = "DRAFT",
  Publication = "PUBLICATION",
  Billing = "BILLING",
  Payment = "PAYMENT"
}

/** 進行中 or 完了した案件かどうか */
export enum ProjectProgressStatus {
  /** 進行中案件 */
  Incomplete = "INCOMPLETE",
  /** 完了した案件 */
  Complete = "COMPLETE"
}

export type ContractedProject = EntryProject | OfferProject;

export type Acceptance = {
  __typename?: "Acceptance";
  id: Scalars["ID"];
  url: Scalars["String"];
  status: AcceptanceStatus;
  postedAt: Scalars["DateTime"];
  note?: Maybe<Scalars["String"]>;
  ngReason?: Maybe<Scalars["String"]>;
  ngReasonNote?: Maybe<Scalars["String"]>;
};

export enum AcceptanceStatus {
  /** 確認中 */
  Confirm = "CONFIRM",
  /** OK */
  Ok = "OK",
  /** NG */
  Ng = "NG"
}

export type ProjectProgressListConnection = Connection & {
  __typename?: "ProjectProgressListConnection";
  paginatorInfo: PaginatorInfo;
  edges: Array<ProjectProgressEdge>;
};

export type ProjectProgressEdge = Edge & {
  __typename?: "ProjectProgressEdge";
  cursor: Scalars["ID"];
  node: ProjectProgress;
};

export type InfluencerEntryProjectListConnection = Connection & {
  __typename?: "InfluencerEntryProjectListConnection";
  paginatorInfo: PaginatorInfo;
  edges: Array<InfluencerEntryProjectEdge>;
};

export type InfluencerEntryProjectEdge = Edge & {
  __typename?: "InfluencerEntryProjectEdge";
  cursor: Scalars["ID"];
  node: InfluencerEntryProject;
};

export type SearchProjectConditionGroupInput = {
  searchProjectConditionInput: Array<SearchProjectConditionInput>;
  conjunction?: Maybe<ConjunctionType>;
};

export type SearchProjectConditionInput = {
  projectMediaType?: Maybe<ProjectMediaType>;
  projectType?: Maybe<ProjectType>;
  projectRewardType?: Maybe<ProjectRewardType>;
  campaignCode?: Maybe<Scalars["String"]>;
};

export type ProjectListConnection = Connection & {
  __typename?: "ProjectListConnection";
  paginatorInfo: PaginatorInfo;
  edges: Array<ProjectEdge>;
};

export type ProjectEdge = Edge & {
  __typename?: "ProjectEdge";
  cursor: Scalars["ID"];
  node: UnionProject;
};

export type UnionProject = InfluencerEntryProject | OfferProject;

export type SystemSetting = {
  __typename?: "SystemSetting";
  socialMediaSetting: SocialMediaSetting;
};

export type SocialMediaSetting = {
  __typename?: "SocialMediaSetting";
  line: Line;
  apple: Apple;
  instagram: Instagram;
  youtube: YouTube;
};

export type Line = SocialMediaProvider & {
  __typename?: "Line";
  clientId: Scalars["String"];
  redirectUri: Scalars["String"];
};

export type SocialMediaProvider = {
  clientId: Scalars["String"];
};

export type Apple = SocialMediaProvider & {
  __typename?: "Apple";
  clientId: Scalars["String"];
  redirectUri: Scalars["String"];
};

export type Instagram = SocialMediaProvider & {
  __typename?: "Instagram";
  clientId: Scalars["String"];
  redirectUri: Scalars["String"];
};

export type YouTube = SocialMediaProvider & {
  __typename?: "YouTube";
  clientId: Scalars["String"];
  redirectUri: Scalars["String"];
};

export type TikTokAccountActivation = SocialMediaAccountActivation & {
  __typename?: "TikTokAccountActivation";
  id: Scalars["ID"];
  mediaType: MediaType;
  authorizedStatus: InfluencerAuthorizedStatus;
  followerCountBorder: Scalars["Int"];
  postCountBorder: Scalars["Int"];
  username?: Maybe<Scalars["String"]>;
  followerCount?: Maybe<Scalars["Int"]>;
  postCount?: Maybe<Scalars["Int"]>;
  canRequestReview?: Maybe<Scalars["Boolean"]>;
};

export type TopBanner = {
  __typename?: "TopBanner";
  href?: Maybe<Scalars["String"]>;
  imageUrl: Scalars["String"];
  isTargetBlank: Scalars["Boolean"];
};

export type TwitterAccountActivation = SocialMediaAccountActivation & {
  __typename?: "TwitterAccountActivation";
  id: Scalars["ID"];
  mediaType: MediaType;
  authorizedStatus: InfluencerAuthorizedStatus;
  followerCountBorder: Scalars["Int"];
  postCountBorder: Scalars["Int"];
  username?: Maybe<Scalars["String"]>;
  followerCount?: Maybe<Scalars["Int"]>;
  postCount?: Maybe<Scalars["Int"]>;
  canRequestReview?: Maybe<Scalars["Boolean"]>;
};

export type YouTubeAccountActivation = SocialMediaAccountActivation & {
  __typename?: "YouTubeAccountActivation";
  id: Scalars["ID"];
  mediaType: MediaType;
  authorizedStatus: InfluencerAuthorizedStatus;
  followerCountBorder: Scalars["Int"];
  postCountBorder: Scalars["Int"];
  username?: Maybe<Scalars["String"]>;
  followerCount?: Maybe<Scalars["Int"]>;
  postCount?: Maybe<Scalars["Int"]>;
  canRequestReview?: Maybe<Scalars["Boolean"]>;
};

export type ZipCode = {
  __typename?: "ZipCode";
  id: Scalars["ID"];
  zipcode: Scalars["String"];
  prefectureName: Scalars["String"];
  cityName: Scalars["String"];
  address?: Maybe<Scalars["String"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  createUser?: Maybe<User>;
  saveShippingAddress?: Maybe<ShippingAddress>;
  acceptOffer?: Maybe<ProjectProgress>;
  answerEnquete?: Maybe<Scalars["Boolean"]>;
  appleLogin?: Maybe<AppleCredential>;
  applyForEntryProject?: Maybe<Entry>;
  checkInstagramIdentificationCode?: Maybe<Scalars["Boolean"]>;
  completeDraftAttachmentUpload?: Maybe<Scalars["Boolean"]>;
  createContact?: Maybe<Scalars["Boolean"]>;
  createDraft?: Maybe<InfluencerSaveDraft>;
  createPayee?: Maybe<Payee>;
  deactivateSocialAccount?: Maybe<Scalars["Boolean"]>;
  lineLogin?: Maybe<LineCredential>;
  linkAccountToInstagram: Scalars["Boolean"];
  linkAccountToInstagramForShow: Scalars["Boolean"];
  linkAccountToTikTok: Scalars["Boolean"];
  linkAccountToTwitter: Scalars["Boolean"];
  linkAccountToTwitterForShow: Scalars["Boolean"];
  linkAccountToYouTube: Scalars["Boolean"];
  linkAccountToYouTubeForShow: Scalars["Boolean"];
  login?: Maybe<Credential>;
  onetimeLogin?: Maybe<Credential>;
  registerCredential?: Maybe<Scalars["Boolean"]>;
  rejectOffer?: Maybe<Scalars["Boolean"]>;
  requestDeleteAccount?: Maybe<Scalars["Boolean"]>;
  requestInstagramReview: Scalars["Boolean"];
  requestTikTokReview: Scalars["Boolean"];
  requestTwitterReview: Scalars["Boolean"];
  requestYouTubeReview: Scalars["Boolean"];
  resendEmailActivation: Scalars["Boolean"];
  resetPassword: Credential;
  saveAcceptance: Scalars["Boolean"];
  saveDeviceToken: Scalars["Boolean"];
  sendPasswordResetEmail: Scalars["Boolean"];
  signUp?: Maybe<Credential>;
  updateAccount?: Maybe<Scalars["Boolean"]>;
  updateDraft?: Maybe<InfluencerSaveDraft>;
  updateEmail?: Maybe<Scalars["Boolean"]>;
  updatePassword?: Maybe<Scalars["Boolean"]>;
  verifyEmail?: Maybe<Scalars["Boolean"]>;
};

export type MutationCreateUserArgs = {
  name?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
};

export type MutationSaveShippingAddressArgs = {
  lastName: Scalars["String"];
  firstName: Scalars["String"];
  lastNameKana: Scalars["String"];
  firstNameKana: Scalars["String"];
  zipcode: Scalars["String"];
  prefectureName: Scalars["String"];
  cityName: Scalars["String"];
  address: Scalars["String"];
  building?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type MutationAcceptOfferArgs = {
  id: Scalars["ID"];
};

export type MutationAnswerEnqueteArgs = {
  input: EnqueteAnswerInput;
};

export type MutationAppleLoginArgs = {
  code: Scalars["String"];
  deviceType?: Maybe<DeviceType>;
};

export type MutationApplyForEntryProjectArgs = {
  projectId: Scalars["Int"];
};

export type MutationCheckInstagramIdentificationCodeArgs = {
  username: Scalars["String"];
};

export type MutationCompleteDraftAttachmentUploadArgs = {
  projectProgressId: Scalars["ID"];
};

export type MutationCreateContactArgs = {
  content: Scalars["String"];
  referrerType?: Maybe<ContactReferrerType>;
  referrerId?: Maybe<Scalars["ID"]>;
};

export type MutationCreateDraftArgs = {
  projectProgressId: Scalars["ID"];
  caption?: Maybe<Scalars["String"]>;
  limitedShareUrl?: Maybe<Scalars["String"]>;
  files?: Maybe<Array<DraftFile>>;
};

export type MutationCreatePayeeArgs = {
  lastName: Scalars["String"];
  firstName: Scalars["String"];
  bankName: Scalars["String"];
  branchName: Scalars["String"];
  type: BankAccountType;
  number: Scalars["String"];
  accountHolder: Scalars["String"];
  hasWithholdingTax: Scalars["Boolean"];
};

export type MutationDeactivateSocialAccountArgs = {
  mediaType: MediaType;
};

export type MutationLineLoginArgs = {
  code: Scalars["String"];
  deviceType?: Maybe<DeviceType>;
};

export type MutationLinkAccountToInstagramArgs = {
  code: Scalars["String"];
};

export type MutationLinkAccountToInstagramForShowArgs = {
  code: Scalars["String"];
};

export type MutationLinkAccountToTikTokArgs = {
  username: Scalars["String"];
};

export type MutationLinkAccountToTwitterArgs = {
  oauthToken: Scalars["String"];
  oauthVerifier: Scalars["String"];
};

export type MutationLinkAccountToTwitterForShowArgs = {
  oauthToken: Scalars["String"];
  oauthVerifier: Scalars["String"];
};

export type MutationLinkAccountToYouTubeArgs = {
  code: Scalars["String"];
};

export type MutationLinkAccountToYouTubeForShowArgs = {
  code: Scalars["String"];
};

export type MutationLoginArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type MutationOnetimeLoginArgs = {
  token: Scalars["String"];
};

export type MutationRegisterCredentialArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
  passwordConfirmation: Scalars["String"];
};

export type MutationRejectOfferArgs = {
  id: Scalars["ID"];
  ngReasonId: Scalars["ID"];
  ngReason?: Maybe<Scalars["String"]>;
};

export type MutationRequestDeleteAccountArgs = {
  reason?: Maybe<Scalars["String"]>;
};

export type MutationResendEmailActivationArgs = {
  transitionTarget: Scalars["String"];
};

export type MutationResetPasswordArgs = {
  token: Scalars["String"];
  email: Scalars["String"];
  password: Scalars["String"];
  passwordConfirmation: Scalars["String"];
};

export type MutationSaveAcceptanceArgs = {
  contractId: Scalars["ID"];
  url: Scalars["String"];
  note?: Maybe<Scalars["String"]>;
};

export type MutationSaveDeviceTokenArgs = {
  deviceType: DeviceType;
  token: Scalars["String"];
};

export type MutationSendPasswordResetEmailArgs = {
  email: Scalars["String"];
};

export type MutationSignUpArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
  passwordConfirmation: Scalars["String"];
  sex?: Maybe<Sex>;
  birthday?: Maybe<Scalars["Date"]>;
  friendCode?: Maybe<Scalars["String"]>;
};

export type MutationUpdateAccountArgs = {
  influencerName: Scalars["String"];
  sex?: Maybe<Sex>;
  birthday?: Maybe<Scalars["Date"]>;
};

export type MutationUpdateDraftArgs = {
  projectProgressId: Scalars["ID"];
  caption?: Maybe<Scalars["String"]>;
  limitedShareUrl?: Maybe<Scalars["String"]>;
  files?: Maybe<Array<DraftFile>>;
};

export type MutationUpdateEmailArgs = {
  email: Scalars["String"];
};

export type MutationUpdatePasswordArgs = {
  currentPassword: Scalars["String"];
  password: Scalars["String"];
  passwordConfirmation: Scalars["String"];
};

export type MutationVerifyEmailArgs = {
  token: Scalars["String"];
};

export type EnqueteAnswerInput = {
  enqueteId: Scalars["ID"];
  enqueteQuestionAnswers: Array<EnqueteQuestionAnswerInput>;
  /** アンケートのスキップフラグ */
  skip: Scalars["Boolean"];
};

export type EnqueteQuestionAnswerInput = {
  enqueteQuestionId: Scalars["ID"];
  enqueteQuestionAnswerNodes: EnqueteQuestionAnswerNodeInput;
};

export type EnqueteQuestionAnswerNodeInput = {
  answers: Array<Maybe<Scalars["String"]>>;
};

/** app_influencer_accounts.device_type */
export enum DeviceType {
  /** iOS */
  Ios = "IOS",
  /** Android */
  Android = "ANDROID"
}

export type AppleCredential = {
  __typename?: "AppleCredential";
  token: Scalars["String"];
  isFirstLogin: Scalars["Boolean"];
};

export enum ContactReferrerType {
  Project = "PROJECT",
  Billing = "BILLING"
}

export type DraftFile = {
  attachment: Attachment;
};

export type Attachment = {
  id?: Maybe<Scalars["ID"]>;
  mimeType: Scalars["String"];
};

export type InfluencerSaveDraft = {
  __typename?: "InfluencerSaveDraft";
  draft: Draft;
  credential: AwsStsCredential;
};

export type AwsStsCredential = {
  __typename?: "AwsStsCredential";
  accessKeyId: Scalars["String"];
  secretAccessKey: Scalars["String"];
  sessionToken: Scalars["String"];
  expiration: Scalars["DateTime"];
};

export type LineCredential = {
  __typename?: "LineCredential";
  token: Scalars["String"];
  isFirstLogin: Scalars["Boolean"];
};

export type Credential = {
  __typename?: "Credential";
  token: Scalars["String"];
};

/** influencer_payee_bank_accounts.type */
export enum InfluencerPayeeBankAccountType {
  /** 普通 */
  Ordinary = "ORDINARY",
  /** 当座 */
  Current = "CURRENT"
}

export enum TwitterAccountActivationStatus {
  /** 本人確認中 */
  Identification = "IDENTIFICATION",
  /** フォロワー数・投稿数を審査中 */
  InsightJudgment = "INSIGHT_JUDGMENT",
  /** 完了 */
  Complete = "COMPLETE",
  /** 更新中 */
  Updating = "UPDATING"
}

export enum YoutubeAccountActivationStatus {
  /** 本人確認中 */
  Identification = "IDENTIFICATION",
  /** フォロワー数・投稿数を審査中 */
  InsightJudgment = "INSIGHT_JUDGMENT",
  /** 完了 */
  Complete = "COMPLETE",
  /** 更新中 */
  Updating = "UPDATING"
}

/** app_influencer_contacts.referrer_type */
export enum AppInfluencerContactReferrerType {
  /** 案件 */
  Project = "PROJECT",
  /** 請求書 */
  Billing = "BILLING"
}

export type InstagramDraft = Draft & {
  __typename?: "InstagramDraft";
  id: Scalars["ID"];
  caption?: Maybe<Scalars["String"]>;
  status: DraftStatus;
  ngReason?: Maybe<Scalars["String"]>;
  rewriteCaption?: Maybe<Scalars["String"]>;
  confirmedAt?: Maybe<Scalars["DateTime"]>;
  draftAttachments: Array<InstagramDraftAttachment>;
};

export type InstagramDraftAttachment = DraftAttachment & {
  __typename?: "InstagramDraftAttachment";
  id: Scalars["ID"];
  attachmentFileUrl: Scalars["String"];
  sort: Scalars["Int"];
  fileKey: Scalars["String"];
};

export enum SocialAccountType {
  Twitter = "TWITTER",
  Instagram = "INSTAGRAM",
  Youtube = "YOUTUBE",
  Tiktok = "TIKTOK"
}

export type TikTokDraft = Draft & {
  __typename?: "TikTokDraft";
  id: Scalars["ID"];
  caption?: Maybe<Scalars["String"]>;
  status: DraftStatus;
  ngReason?: Maybe<Scalars["String"]>;
  rewriteCaption?: Maybe<Scalars["String"]>;
  confirmedAt?: Maybe<Scalars["DateTime"]>;
  draftAttachments: Array<TikTokDraftAttachment>;
};

export type TikTokDraftAttachment = DraftAttachment & {
  __typename?: "TikTokDraftAttachment";
  id: Scalars["ID"];
  attachmentFileUrl: Scalars["String"];
  sort: Scalars["Int"];
  fileKey: Scalars["String"];
};

export type TwitterDraft = Draft & {
  __typename?: "TwitterDraft";
  id: Scalars["ID"];
  caption?: Maybe<Scalars["String"]>;
  status: DraftStatus;
  ngReason?: Maybe<Scalars["String"]>;
  rewriteCaption?: Maybe<Scalars["String"]>;
  confirmedAt?: Maybe<Scalars["DateTime"]>;
  draftAttachments: Array<TwitterDraftAttachment>;
};

export type TwitterDraftAttachment = DraftAttachment & {
  __typename?: "TwitterDraftAttachment";
  id: Scalars["ID"];
  attachmentFileUrl: Scalars["String"];
  sort: Scalars["Int"];
  fileKey: Scalars["String"];
};

export type YouTubeDraft = Draft & {
  __typename?: "YouTubeDraft";
  id: Scalars["ID"];
  status: DraftStatus;
  limitedShareUrl?: Maybe<Scalars["String"]>;
  ngReason?: Maybe<Scalars["String"]>;
  confirmedAt?: Maybe<Scalars["DateTime"]>;
  draftAttachments: Array<YouTubeDraftAttachment>;
};

export type YouTubeDraftAttachment = DraftAttachment & {
  __typename?: "YouTubeDraftAttachment";
  id: Scalars["ID"];
  thumbnailUrl: Scalars["String"];
  fileKey: Scalars["String"];
};

/** Pagination information about the corresponding list of items. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** Total number of node in connection. */
  total?: Maybe<Scalars["Int"]>;
  /** Count of nodes in current request. */
  count?: Maybe<Scalars["Int"]>;
  /** Current page of request. */
  currentPage?: Maybe<Scalars["Int"]>;
  /** Last page in connection. */
  lastPage?: Maybe<Scalars["Int"]>;
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = "ONLY",
  /** Return both trashed and non-trashed results. */
  With = "WITH",
  /** Only return non-trashed results. */
  Without = "WITHOUT"
}

/** The available directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = "ASC",
  /** Sort records in descending order. */
  Desc = "DESC"
}

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  field: Scalars["String"];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type AnnouncementFragment = (
  { __typename?: "Announcement" }
  & Pick<Announcement, "title" | "body" | "createdAt">
);

export type AwsstsCredentialFragment = (
  { __typename?: "AwsStsCredential" }
  & Pick<AwsStsCredential, "accessKeyId" | "secretAccessKey" | "sessionToken" | "expiration">
);

export type BillingFragment = (
  { __typename?: "Billing" }
  & Pick<Billing, "id" | "number" | "year" | "month" | "status" | "publishDate" | "expireDate" | "agencyName" | "subTotal" | "consumptionTax" | "withholdingTax" | "taxedTotal">
  & { payee: (
    { __typename?: "PayeeSnapshot" }
    & PayeeSnapshotFragment
  ), items: Array<(
    { __typename?: "BillingItem" }
    & BillingItemFragment
  )> }
);

export type BillingItemFragment = (
  { __typename?: "BillingItem" }
  & Pick<BillingItem, "projectName" | "amount" | "postedAt">
);

export type EnqueteFragment = (
  { __typename?: "Enquete" }
  & Pick<Enquete, "id" | "title" | "body" | "pageType" | "required" | "responsePeriodFrom" | "responsePeriodTo" | "alreadyAnswered">
  & { enqueteQuestions: Array<(
    { __typename?: "EnqueteQuestion" }
    & Pick<EnqueteQuestion, "id" | "title" | "required" | "sort">
    & { node: (
      { __typename: "EnqueteQuestionText" }
      & Pick<EnqueteQuestionText, "id" | "minSize" | "maxSize">
    ) | (
      { __typename: "EnqueteQuestionTextArea" }
      & Pick<EnqueteQuestionTextArea, "id" | "minSize" | "maxSize">
    ) | (
      { __typename: "EnqueteQuestionCheckbox" }
      & { items: Array<Maybe<(
        { __typename?: "EnqueteQuestionCheckboxItem" }
        & Pick<EnqueteQuestionCheckboxItem, "id" | "label" | "sort">
      )>> }
    ) | (
      { __typename: "EnqueteQuestionPulldown" }
      & { items: Array<Maybe<(
        { __typename?: "EnqueteQuestionPulldownItem" }
        & Pick<EnqueteQuestionPulldownItem, "id" | "label" | "sort">
      )>> }
    ) | (
      { __typename: "EnqueteQuestionRadio" }
      & { items: Array<Maybe<(
        { __typename?: "EnqueteQuestionRadioItem" }
        & Pick<EnqueteQuestionRadioItem, "id" | "label" | "sort">
      )>> }
    ) | (
      { __typename: "EnqueteQuestionRange" }
      & Pick<EnqueteQuestionRange, "id" | "minLabel" | "minValue" | "maxLabel" | "maxValue" | "scale">
    ) }
  )> }
);

export type EntryProjectFragment = (
  { __typename?: "EntryProject" }
  & Pick<EntryProject, "id" | "price" | "hasPayment" | "projectRewardType" | "projectType">
  & { entrySetting: (
    { __typename?: "EntrySetting" }
    & EntrySettingFragment
  ), project: (
    { __typename?: "Project" }
    & ProjectFragment
  ), enquetes: Array<(
    { __typename?: "Enquete" }
    & EnqueteFragment
  )> }
);

export type EntrySettingFragment = (
  { __typename?: "EntrySetting" }
  & Pick<EntrySetting, "id" | "residencePlace" | "ageTo" | "ageFrom" | "entryEndAt" | "entryStartAt" | "sex" | "selectableInfluencersLimit" | "minFollowerCount">
);

export type EntryFragment = (
  { __typename?: "Entry" }
  & Pick<Entry, "id" | "entryStatus" | "createdAt" | "rejectedAt">
  & { entryProject: (
    { __typename?: "EntryProject" }
    & EntryProjectFragment
  ) }
);

export type FaqCategoryFragment = (
  { __typename?: "FaqCategory" }
  & Pick<FaqCategory, "id" | "name">
);

export type FaqFragment = (
  { __typename?: "Faq" }
  & Pick<Faq, "id" | "title" | "body">
);

export type InfluencerEntryProjectFragment = (
  { __typename?: "InfluencerEntryProject" }
  & Pick<InfluencerEntryProject, "id" | "isEntried" | "isSatisfiedMinFollowerCount" | "isSatisfiedSex" | "isSatisfiedAge" | "isSatisfiedSocialMedia">
  & { entryProject: (
    { __typename?: "EntryProject" }
    & EntryProjectFragment
  ), enquetes: Array<(
    { __typename?: "Enquete" }
    & EnqueteFragment
  )> }
);

export type InstagramDraftAttachmentFragment = (
  { __typename?: "InstagramDraftAttachment" }
  & Pick<InstagramDraftAttachment, "id" | "attachmentFileUrl" | "sort" | "fileKey">
);

type InstagramDraftFragment_InstagramDraft_ = (
  { __typename?: "InstagramDraft" }
  & Pick<InstagramDraft, "id" | "caption" | "ngReason" | "confirmedAt" | "rewriteCaption" | "status">
  & { draftAttachments: Array<(
    { __typename?: "InstagramDraftAttachment" }
    & InstagramDraftAttachmentFragment
  )> }
);

type InstagramDraftFragment_TikTokDraft_ = (
  { __typename?: "TikTokDraft" }
  & Pick<TikTokDraft, "id" | "status">
);

type InstagramDraftFragment_TwitterDraft_ = (
  { __typename?: "TwitterDraft" }
  & Pick<TwitterDraft, "id" | "status">
);

type InstagramDraftFragment_YouTubeDraft_ = (
  { __typename?: "YouTubeDraft" }
  & Pick<YouTubeDraft, "id" | "status">
);

export type InstagramDraftFragment = InstagramDraftFragment_InstagramDraft_ | InstagramDraftFragment_TikTokDraft_ | InstagramDraftFragment_TwitterDraft_ | InstagramDraftFragment_YouTubeDraft_;

export type LpProjectFragment = (
  { __typename?: "LpProject" }
  & Pick<LpProject, "id" | "enqueteId" | "mediaType" | "projectTitle" | "productName" | "productImgUrl" | "productUrl" | "productDescription" | "clientName" | "requirements" | "checkPost" | "entryOpenings" | "entryOpeningsFilled" | "appealPoints" | "pictureHowTo" | "hashtags" | "videoSecondaryUsage" | "intendedMessage" | "ng" | "videoDescription" | "note" | "paymentValue" | "paymentDate" | "slug" | "published" | "sort" | "entryStart" | "entryEnd" | "postStart" | "postEnd" | "alreadyAnswered">
);

export type NotificationFragment = (
  { __typename?: "Notification" }
  & Pick<Notification, "id" | "notifiableId" | "type" | "title" | "readFlag" | "createdAt">
);

export type OfferProjectFragment = (
  { __typename?: "OfferProject" }
  & Pick<OfferProject, "id" | "projectRewardType" | "projectType" | "price" | "hasPayment" | "offerExpiredAt" | "offerAnswerStatus">
  & { project: (
    { __typename?: "Project" }
    & ProjectFragment
  ), enquetes: Array<(
    { __typename?: "Enquete" }
    & EnqueteFragment
  )> }
);

export type PaginatorInfoFragment = (
  { __typename?: "PaginatorInfo" }
  & Pick<PaginatorInfo, "count" | "currentPage" | "lastPage" | "perPage" | "total" | "hasMorePages">
);

export type PayeeFragment = (
  { __typename?: "Payee" }
  & Pick<Payee, "id" | "lastName" | "firstName" | "hasWithholdingTax">
  & { bankAccount?: Maybe<(
    { __typename?: "BankAccount" }
    & BankAccountFragment
  )> }
);

export type PayeeSnapshotFragment = (
  { __typename?: "PayeeSnapshot" }
  & Pick<PayeeSnapshot, "lastName" | "firstName" | "hasWithholdingTax">
  & { bankAccount?: Maybe<(
    { __typename?: "BankAccount" }
    & BankAccountFragment
  )> }
);

export type BankAccountFragment = (
  { __typename?: "BankAccount" }
  & Pick<BankAccount, "bankName" | "branchName" | "number" | "accountHolder" | "type">
);

export type ProjectProgressFragment = (
  { __typename?: "ProjectProgress" }
  & Pick<ProjectProgress, "id" | "stepId" | "previousStepId" | "projectProgressStatus" | "projectFlowSteps" | "contractDocumentPdfUrl">
  & { billing?: Maybe<(
    { __typename?: "Billing" }
    & ProgressBillingFragment
  )>, draft?: Maybe<(
    { __typename?: "InstagramDraft" }
    & ProgressDraftFragment_InstagramDraft_
  ) | (
    { __typename?: "TikTokDraft" }
    & ProgressDraftFragment_TikTokDraft_
  ) | (
    { __typename?: "TwitterDraft" }
    & ProgressDraftFragment_TwitterDraft_
  ) | (
    { __typename?: "YouTubeDraft" }
    & ProgressDraftFragment_YouTubeDraft_
  )>, acceptance?: Maybe<(
    { __typename?: "Acceptance" }
    & ProgressAcceptanceFragment
  )>, contractedProject: (
    { __typename?: "EntryProject" }
    & EntryProjectFragment
  ) | (
    { __typename?: "OfferProject" }
    & OfferProjectFragment
  ), enquetes: Array<(
    { __typename?: "Enquete" }
    & EnqueteFragment
  )> }
);

type ProgressDraftFragment_InstagramDraft_ = (
  { __typename?: "InstagramDraft" }
  & Pick<InstagramDraft, "caption" | "ngReason" | "rewriteCaption" | "id" | "status">
);

type ProgressDraftFragment_TikTokDraft_ = (
  { __typename?: "TikTokDraft" }
  & Pick<TikTokDraft, "caption" | "ngReason" | "rewriteCaption" | "id" | "status">
);

type ProgressDraftFragment_TwitterDraft_ = (
  { __typename?: "TwitterDraft" }
  & Pick<TwitterDraft, "caption" | "ngReason" | "rewriteCaption" | "id" | "status">
);

type ProgressDraftFragment_YouTubeDraft_ = (
  { __typename?: "YouTubeDraft" }
  & Pick<YouTubeDraft, "ngReason" | "limitedShareUrl" | "id" | "status">
);

export type ProgressDraftFragment = ProgressDraftFragment_InstagramDraft_ | ProgressDraftFragment_TikTokDraft_ | ProgressDraftFragment_TwitterDraft_ | ProgressDraftFragment_YouTubeDraft_;

export type ProgressBillingFragment = (
  { __typename?: "Billing" }
  & Pick<Billing, "id" | "status">
);

export type ProgressAcceptanceFragment = (
  { __typename?: "Acceptance" }
  & Pick<Acceptance, "id" | "status" | "url" | "ngReason" | "ngReasonNote">
);

export type ProjectFragment = (
  { __typename?: "Project" }
  & Pick<Project, "id" | "name" | "hasShippingStep" | "mediaType" | "postPeriodStartAt" | "postPeriodEndAt" | "appealContent" | "hashTagArray" | "howTakePicture" | "externalLinkUrl" | "note" | "videoSecondaryUse" | "ngConditions" | "videoDescription">
  & { client: (
    { __typename?: "Client" }
    & Pick<Client, "id" | "companyName">
  ), item: (
    { __typename?: "Item" }
    & Pick<Item, "id" | "name" | "detail" | "imageUrl">
  ) }
);

export type TikTokDraftAttachmentFragment = (
  { __typename?: "TikTokDraftAttachment" }
  & Pick<TikTokDraftAttachment, "id" | "attachmentFileUrl" | "sort" | "fileKey">
);

type TikTokDraftFragment_InstagramDraft_ = (
  { __typename?: "InstagramDraft" }
  & Pick<InstagramDraft, "id" | "status">
);

type TikTokDraftFragment_TikTokDraft_ = (
  { __typename?: "TikTokDraft" }
  & Pick<TikTokDraft, "caption" | "ngReason" | "confirmedAt" | "rewriteCaption" | "id" | "status">
  & { draftAttachments: Array<(
    { __typename?: "TikTokDraftAttachment" }
    & TikTokDraftAttachmentFragment
  )> }
);

type TikTokDraftFragment_TwitterDraft_ = (
  { __typename?: "TwitterDraft" }
  & Pick<TwitterDraft, "id" | "status">
);

type TikTokDraftFragment_YouTubeDraft_ = (
  { __typename?: "YouTubeDraft" }
  & Pick<YouTubeDraft, "id" | "status">
);

export type TikTokDraftFragment = TikTokDraftFragment_InstagramDraft_ | TikTokDraftFragment_TikTokDraft_ | TikTokDraftFragment_TwitterDraft_ | TikTokDraftFragment_YouTubeDraft_;

export type TwitterDraftAttachmentFragment = (
  { __typename?: "TwitterDraftAttachment" }
  & Pick<TwitterDraftAttachment, "id" | "attachmentFileUrl" | "sort" | "fileKey">
);

type TwitterDraftFragment_InstagramDraft_ = (
  { __typename?: "InstagramDraft" }
  & Pick<InstagramDraft, "id" | "status">
);

type TwitterDraftFragment_TikTokDraft_ = (
  { __typename?: "TikTokDraft" }
  & Pick<TikTokDraft, "id" | "status">
);

type TwitterDraftFragment_TwitterDraft_ = (
  { __typename?: "TwitterDraft" }
  & Pick<TwitterDraft, "caption" | "ngReason" | "confirmedAt" | "rewriteCaption" | "id" | "status">
  & { draftAttachments: Array<(
    { __typename?: "TwitterDraftAttachment" }
    & TwitterDraftAttachmentFragment
  )> }
);

type TwitterDraftFragment_YouTubeDraft_ = (
  { __typename?: "YouTubeDraft" }
  & Pick<YouTubeDraft, "id" | "status">
);

export type TwitterDraftFragment = TwitterDraftFragment_InstagramDraft_ | TwitterDraftFragment_TikTokDraft_ | TwitterDraftFragment_TwitterDraft_ | TwitterDraftFragment_YouTubeDraft_;

export type YouTubeDraftAttachmentFragment = (
  { __typename?: "YouTubeDraftAttachment" }
  & Pick<YouTubeDraftAttachment, "id" | "fileKey" | "thumbnailUrl">
);

type YouTubeDraftFragment_InstagramDraft_ = (
  { __typename?: "InstagramDraft" }
  & Pick<InstagramDraft, "id" | "status">
);

type YouTubeDraftFragment_TikTokDraft_ = (
  { __typename?: "TikTokDraft" }
  & Pick<TikTokDraft, "id" | "status">
);

type YouTubeDraftFragment_TwitterDraft_ = (
  { __typename?: "TwitterDraft" }
  & Pick<TwitterDraft, "id" | "status">
);

type YouTubeDraftFragment_YouTubeDraft_ = (
  { __typename?: "YouTubeDraft" }
  & Pick<YouTubeDraft, "limitedShareUrl" | "ngReason" | "confirmedAt" | "id" | "status">
  & { draftAttachments: Array<(
    { __typename?: "YouTubeDraftAttachment" }
    & YouTubeDraftAttachmentFragment
  )> }
);

export type YouTubeDraftFragment = YouTubeDraftFragment_InstagramDraft_ | YouTubeDraftFragment_TikTokDraft_ | YouTubeDraftFragment_TwitterDraft_ | YouTubeDraftFragment_YouTubeDraft_;

export type AnswerEnqueteMutationVariables = Exact<{
  input: EnqueteAnswerInput;
}>;

export type AnswerEnqueteMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "answerEnquete">
);

export type AppleLoginMutationVariables = Exact<{
  code: Scalars["String"];
  deviceType?: Maybe<DeviceType>;
}>;

export type AppleLoginMutation = (
  { __typename?: "Mutation" }
  & { appleLogin?: Maybe<(
    { __typename?: "AppleCredential" }
    & Pick<AppleCredential, "token" | "isFirstLogin">
  )> }
);

export type CompleteDraftMutationVariables = Exact<{
  progressId: Scalars["ID"];
}>;

export type CompleteDraftMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "completeDraftAttachmentUpload">
);

export type CreateAcceptanceMutationVariables = Exact<{
  progressId: Scalars["ID"];
  url: Scalars["String"];
  note?: Maybe<Scalars["String"]>;
}>;

export type CreateAcceptanceMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "saveAcceptance">
);

export type CreateContactMutationVariables = Exact<{
  content: Scalars["String"];
  referrerId?: Maybe<Scalars["ID"]>;
  referrerType?: Maybe<ContactReferrerType>;
}>;

export type CreateContactMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "createContact">
);

export type CreateDraftMutationVariables = Exact<{
  progressId: Scalars["ID"];
  limitedShareUrl?: Maybe<Scalars["String"]>;
  caption?: Maybe<Scalars["String"]>;
  files?: Maybe<Array<DraftFile>>;
}>;

export type CreateDraftMutation = (
  { __typename?: "Mutation" }
  & { createDraft?: Maybe<(
    { __typename?: "InfluencerSaveDraft" }
    & { draft: (
      { __typename?: "InstagramDraft" }
      & InstagramDraftFragment_InstagramDraft_
      & TwitterDraftFragment_InstagramDraft_
      & YouTubeDraftFragment_InstagramDraft_
      & TikTokDraftFragment_InstagramDraft_
    ) | (
      { __typename?: "TikTokDraft" }
      & InstagramDraftFragment_TikTokDraft_
      & TwitterDraftFragment_TikTokDraft_
      & YouTubeDraftFragment_TikTokDraft_
      & TikTokDraftFragment_TikTokDraft_
    ) | (
      { __typename?: "TwitterDraft" }
      & InstagramDraftFragment_TwitterDraft_
      & TwitterDraftFragment_TwitterDraft_
      & YouTubeDraftFragment_TwitterDraft_
      & TikTokDraftFragment_TwitterDraft_
    ) | (
      { __typename?: "YouTubeDraft" }
      & InstagramDraftFragment_YouTubeDraft_
      & TwitterDraftFragment_YouTubeDraft_
      & YouTubeDraftFragment_YouTubeDraft_
      & TikTokDraftFragment_YouTubeDraft_
    ), credential: (
      { __typename?: "AwsStsCredential" }
      & AwsstsCredentialFragment
    ) }
  )> }
);

export type DeactivateSocialAccountMutationVariables = Exact<{
  mediaType: MediaType;
}>;

export type DeactivateSocialAccountMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "deactivateSocialAccount">
);

export type LineLoginMutationVariables = Exact<{
  code: Scalars["String"];
  deviceType?: Maybe<DeviceType>;
}>;

export type LineLoginMutation = (
  { __typename?: "Mutation" }
  & { lineLogin?: Maybe<(
    { __typename?: "LineCredential" }
    & Pick<LineCredential, "token" | "isFirstLogin">
  )> }
);

export type LinkAccountToInstagramMutationVariables = Exact<{
  code: Scalars["String"];
}>;

export type LinkAccountToInstagramMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "linkAccountToInstagram">
);

export type LinkAccountToTikTokMutationVariables = Exact<{
  username: Scalars["String"];
}>;

export type LinkAccountToTikTokMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "linkAccountToTikTok">
);

export type LinkAccountToTwitterMutationVariables = Exact<{
  oauthToken: Scalars["String"];
  oauthVerifier: Scalars["String"];
}>;

export type LinkAccountToTwitterMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "linkAccountToTwitter">
);

export type LinkAccountToYouTubeMutationVariables = Exact<{
  code: Scalars["String"];
}>;

export type LinkAccountToYouTubeMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "linkAccountToYouTube">
);

export type LoginMutationVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
}>;

export type LoginMutation = (
  { __typename?: "Mutation" }
  & { login?: Maybe<(
    { __typename?: "Credential" }
    & Pick<Credential, "token">
  )> }
);

export type OnetimeLoginMutationVariables = Exact<{
  token: Scalars["String"];
}>;

export type OnetimeLoginMutation = (
  { __typename?: "Mutation" }
  & { onetimeLogin?: Maybe<(
    { __typename?: "Credential" }
    & Pick<Credential, "token">
  )> }
);

export type AcceptOfferMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AcceptOfferMutation = (
  { __typename?: "Mutation" }
  & { acceptOffer?: Maybe<(
    { __typename?: "ProjectProgress" }
    & ProjectProgressFragment
  )> }
);

export type ApplyForEntryProjectMutationVariables = Exact<{
  projectId: Scalars["Int"];
}>;

export type ApplyForEntryProjectMutation = (
  { __typename?: "Mutation" }
  & { applyForEntryProject?: Maybe<(
    { __typename?: "Entry" }
    & EntryFragment
  )> }
);

export type RejectOfferMutationVariables = Exact<{
  id: Scalars["ID"];
  ngReasonId: Scalars["ID"];
  ngReason?: Maybe<Scalars["String"]>;
}>;

export type RejectOfferMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "rejectOffer">
);

export type RegisterCredentialsMutationVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
  passwordConfirmation: Scalars["String"];
}>;

export type RegisterCredentialsMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "registerCredential">
);

export type RequestDeleteAccountMutationVariables = Exact<{
  reason?: Maybe<Scalars["String"]>;
}>;

export type RequestDeleteAccountMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "requestDeleteAccount">
);

export type RequestInstagramReviewMutationVariables = Exact<{ [key: string]: never; }>;

export type RequestInstagramReviewMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "requestInstagramReview">
);

export type RequestTikTokReviewMutationVariables = Exact<{ [key: string]: never; }>;

export type RequestTikTokReviewMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "requestTikTokReview">
);

export type RequestTwitterReviewMutationVariables = Exact<{ [key: string]: never; }>;

export type RequestTwitterReviewMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "requestTwitterReview">
);

export type RequestYouTubeReviewMutationVariables = Exact<{ [key: string]: never; }>;

export type RequestYouTubeReviewMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "requestYouTubeReview">
);

export type ResendEmailActivationMutationVariables = Exact<{
  transitionTarget: Scalars["String"];
}>;

export type ResendEmailActivationMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "resendEmailActivation">
);

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars["String"];
  email: Scalars["String"];
  password: Scalars["String"];
  passwordConfirmation: Scalars["String"];
}>;

export type ResetPasswordMutation = (
  { __typename?: "Mutation" }
  & { resetPassword: (
    { __typename?: "Credential" }
    & Pick<Credential, "token">
  ) }
);

export type SaveDeviceTokenMutationVariables = Exact<{
  deviceType: DeviceType;
  token: Scalars["String"];
}>;

export type SaveDeviceTokenMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "saveDeviceToken">
);

export type SendPasswordResetEmailMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type SendPasswordResetEmailMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "sendPasswordResetEmail">
);

export type SignUpMutationVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
  passwordConfirmation: Scalars["String"];
  sex?: Maybe<Sex>;
  birthday?: Maybe<Scalars["Date"]>;
  friendCode?: Maybe<Scalars["String"]>;
}>;

export type SignUpMutation = (
  { __typename?: "Mutation" }
  & { signUp?: Maybe<(
    { __typename?: "Credential" }
    & Pick<Credential, "token">
  )> }
);

export type UpdateDraftMutationVariables = Exact<{
  progressId: Scalars["ID"];
  limitedShareUrl?: Maybe<Scalars["String"]>;
  caption?: Maybe<Scalars["String"]>;
  files?: Maybe<Array<DraftFile>>;
}>;

export type UpdateDraftMutation = (
  { __typename?: "Mutation" }
  & { updateDraft?: Maybe<(
    { __typename?: "InfluencerSaveDraft" }
    & { draft: (
      { __typename?: "InstagramDraft" }
      & InstagramDraftFragment_InstagramDraft_
      & TwitterDraftFragment_InstagramDraft_
      & YouTubeDraftFragment_InstagramDraft_
      & TikTokDraftFragment_InstagramDraft_
    ) | (
      { __typename?: "TikTokDraft" }
      & InstagramDraftFragment_TikTokDraft_
      & TwitterDraftFragment_TikTokDraft_
      & YouTubeDraftFragment_TikTokDraft_
      & TikTokDraftFragment_TikTokDraft_
    ) | (
      { __typename?: "TwitterDraft" }
      & InstagramDraftFragment_TwitterDraft_
      & TwitterDraftFragment_TwitterDraft_
      & YouTubeDraftFragment_TwitterDraft_
      & TikTokDraftFragment_TwitterDraft_
    ) | (
      { __typename?: "YouTubeDraft" }
      & InstagramDraftFragment_YouTubeDraft_
      & TwitterDraftFragment_YouTubeDraft_
      & YouTubeDraftFragment_YouTubeDraft_
      & TikTokDraftFragment_YouTubeDraft_
    ), credential: (
      { __typename?: "AwsStsCredential" }
      & AwsstsCredentialFragment
    ) }
  )> }
);

export type UpdateEmailMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type UpdateEmailMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "updateEmail">
);

export type UpdatePasswordMutationVariables = Exact<{
  currentPassword: Scalars["String"];
  password: Scalars["String"];
  passwordConfirmation: Scalars["String"];
}>;

export type UpdatePasswordMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "updatePassword">
);

export type UpdatePayeeMutationVariables = Exact<{
  lastName: Scalars["String"];
  firstName: Scalars["String"];
  bankName: Scalars["String"];
  branchName: Scalars["String"];
  type: BankAccountType;
  number: Scalars["String"];
  accountHolder: Scalars["String"];
  hasWithholdingTax: Scalars["Boolean"];
}>;

export type UpdatePayeeMutation = (
  { __typename?: "Mutation" }
  & { createPayee?: Maybe<(
    { __typename?: "Payee" }
    & PayeeFragment
  )> }
);

export type UpdateProfileMutationVariables = Exact<{
  influencerName: Scalars["String"];
  sex?: Maybe<Sex>;
  birthday?: Maybe<Scalars["Date"]>;
}>;

export type UpdateProfileMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "updateAccount">
);

export type UpdateShippingAddressMutationVariables = Exact<{
  lastName: Scalars["String"];
  firstName: Scalars["String"];
  lastNameKana: Scalars["String"];
  firstNameKana: Scalars["String"];
  zipcode: Scalars["String"];
  prefectureName: Scalars["String"];
  cityName: Scalars["String"];
  address: Scalars["String"];
  building?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
}>;

export type UpdateShippingAddressMutation = (
  { __typename?: "Mutation" }
  & { saveShippingAddress?: Maybe<(
    { __typename?: "ShippingAddress" }
    & ShippingAddressFragment
  )> }
);

export type AccountActivationQueryVariables = Exact<{ [key: string]: never; }>;

export type AccountActivationQuery = (
  { __typename?: "Query" }
  & { me: (
    { __typename?: "Account" }
    & AccountFragment
  ), accountActivation: (
    { __typename?: "InstagramAccountActivation" }
    & Pick<InstagramAccountActivation, "id" | "postCount" | "postCountBorder" | "followerCount" | "followerCountBorder" | "status" | "username" | "identificationCode" | "canRequestReview">
  ) }
);

export type AccountQueryVariables = Exact<{ [key: string]: never; }>;

export type AccountQuery = (
  { __typename?: "Query" }
  & { me: (
    { __typename?: "Account" }
    & AccountFragment
  ) }
);

export type AnnouncementQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AnnouncementQuery = (
  { __typename?: "Query" }
  & { announcementDetail: (
    { __typename?: "Announcement" }
    & AnnouncementFragment
  ) }
);

export type BillingQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type BillingQuery = (
  { __typename?: "Query" }
  & { billingDetail?: Maybe<(
    { __typename?: "Billing" }
    & BillingFragment
  )> }
);

export type CheckInstagramPostMutationVariables = Exact<{
  username: Scalars["String"];
}>;

export type CheckInstagramPostMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "checkInstagramIdentificationCode">
);

export type ContactReplyQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ContactReplyQuery = (
  { __typename?: "Query" }
  & { contactReplyDetail: (
    { __typename?: "ContactReply" }
    & Pick<ContactReply, "title" | "body" | "createdAt">
    & { contact?: Maybe<(
      { __typename?: "Contact" }
      & Pick<Contact, "content">
    )> }
  ) }
);

export type Covid19ProjectQueryVariables = Exact<{ [key: string]: never; }>;

export type Covid19ProjectQuery = (
  { __typename?: "Query" }
  & { Covid19Project1: (
    { __typename?: "InfluencerEntryProject" }
    & InfluencerEntryProjectFragment
  ), Covid19Project2: (
    { __typename?: "InfluencerEntryProject" }
    & InfluencerEntryProjectFragment
  ) }
);

export type DeleteAccountQueryVariables = Exact<{ [key: string]: never; }>;

export type DeleteAccountQuery = (
  { __typename?: "Query" }
  & { deleteAccountDetail?: Maybe<(
    { __typename?: "DeleteAccount" }
    & Pick<DeleteAccount, "reason" | "requestedAt">
  )> }
);

export type DirectMessageQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DirectMessageQuery = (
  { __typename?: "Query" }
  & { directMessageDetail?: Maybe<(
    { __typename?: "DirectMessage" }
    & Pick<DirectMessage, "id" | "title" | "body" | "createdAt">
  )> }
);

export type DraftQueryVariables = Exact<{
  progressId: Scalars["ID"];
}>;

export type DraftQuery = (
  { __typename?: "Query" }
  & { draftDetail?: Maybe<(
    { __typename?: "ProjectProgress" }
    & Pick<ProjectProgress, "id">
    & { contractedProject: (
      { __typename?: "EntryProject" }
      & Pick<EntryProject, "projectType">
      & EntryProjectFragment
    ) | (
      { __typename?: "OfferProject" }
      & Pick<OfferProject, "projectType">
      & OfferProjectFragment
    ), draft?: Maybe<(
      { __typename?: "InstagramDraft" }
      & InstagramDraftFragment_InstagramDraft_
      & TwitterDraftFragment_InstagramDraft_
      & YouTubeDraftFragment_InstagramDraft_
      & TikTokDraftFragment_InstagramDraft_
    ) | (
      { __typename?: "TikTokDraft" }
      & InstagramDraftFragment_TikTokDraft_
      & TwitterDraftFragment_TikTokDraft_
      & YouTubeDraftFragment_TikTokDraft_
      & TikTokDraftFragment_TikTokDraft_
    ) | (
      { __typename?: "TwitterDraft" }
      & InstagramDraftFragment_TwitterDraft_
      & TwitterDraftFragment_TwitterDraft_
      & YouTubeDraftFragment_TwitterDraft_
      & TikTokDraftFragment_TwitterDraft_
    ) | (
      { __typename?: "YouTubeDraft" }
      & InstagramDraftFragment_YouTubeDraft_
      & TwitterDraftFragment_YouTubeDraft_
      & YouTubeDraftFragment_YouTubeDraft_
      & TikTokDraftFragment_YouTubeDraft_
    )> }
  )> }
);

export type EnqueteQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type EnqueteQuery = (
  { __typename?: "Query" }
  & { enqueteDetail: (
    { __typename?: "Enquete" }
    & EnqueteFragment
  ) }
);

export type EntryProjectChecklistQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type EntryProjectChecklistQuery = (
  { __typename?: "Query" }
  & { influencerEntryProjectDetail: (
    { __typename?: "InfluencerEntryProject" }
    & Pick<InfluencerEntryProject, "id">
    & { entryProject: (
      { __typename?: "EntryProject" }
      & Pick<EntryProject, "id" | "price">
      & { project: (
        { __typename?: "Project" }
        & Pick<Project, "id" | "postPeriodEndAt">
      ) }
    ), enquetes: Array<(
      { __typename?: "Enquete" }
      & EnqueteFragment
    )> }
  ), payee?: Maybe<(
    { __typename?: "Payee" }
    & Pick<Payee, "id">
    & { bankAccount?: Maybe<(
      { __typename?: "BankAccount" }
      & Pick<BankAccount, "bankName" | "branchName">
    )> }
  )>, shippingAddress?: Maybe<(
    { __typename?: "ShippingAddress" }
    & Pick<ShippingAddress, "id" | "prefectureName" | "cityName" | "address" | "building">
  )> }
);

export type EntryQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type EntryQuery = (
  { __typename?: "Query" }
  & { entryDetail?: Maybe<(
    { __typename?: "Entry" }
    & EntryFragment
  )> }
);

export type FaqQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FaqQuery = (
  { __typename?: "Query" }
  & { faqDetail: (
    { __typename?: "Faq" }
    & FaqFragment
  ) }
);

export type ListBillingsQueryVariables = Exact<{
  status: BillingStatus;
  page: Scalars["Int"];
}>;

export type ListBillingsQuery = (
  { __typename?: "Query" }
  & { billingList: (
    { __typename?: "BillingListConnection" }
    & { edges: Array<(
      { __typename?: "BillingEdge" }
      & BillingEdgeFragment
    )>, paginatorInfo: (
      { __typename?: "PaginatorInfo" }
      & PaginatorInfoFragment
    ) }
  ) }
);

export type ListFaqCategoryQueryVariables = Exact<{ [key: string]: never; }>;

export type ListFaqCategoryQuery = (
  { __typename?: "Query" }
  & { faqCategoryList: Array<(
    { __typename?: "FaqCategory" }
    & FaqCategoryFragment
  )> }
);

export type ListFaqQueryVariables = Exact<{
  categoryId?: Maybe<Scalars["ID"]>;
}>;

export type ListFaqQuery = (
  { __typename?: "Query" }
  & { faqList: Array<(
    { __typename?: "Faq" }
    & FaqFragment
  )> }
);

export type ListNotificationsQueryVariables = Exact<{
  page: Scalars["Int"];
}>;

export type ListNotificationsQuery = (
  { __typename?: "Query" }
  & { unreadCount: Query["unreadNotificationCount"] }
  & { notificationList: (
    { __typename?: "NotificationListConnection" }
    & { edges: Array<(
      { __typename?: "NotificationEdge" }
      & NotificationEdgeFragment
    )>, paginatorInfo: (
      { __typename?: "PaginatorInfo" }
      & PaginatorInfoFragment
    ) }
  ) }
);

export type NotificationEdgeFragment = (
  { __typename?: "NotificationEdge" }
  & Pick<NotificationEdge, "cursor">
  & { node: (
    { __typename?: "Notification" }
    & NotificationFragment
  ) }
);

export type IchakuLpQueryVariables = Exact<{ [key: string]: never; }>;

export type IchakuLpQuery = (
  { __typename?: "Query" }
  & { project1: (
    { __typename?: "InfluencerEntryProject" }
    & InfluencerEntryProjectFragment
  ), project2: (
    { __typename?: "InfluencerEntryProject" }
    & InfluencerEntryProjectFragment
  ) }
);

export type LpProjectQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type LpProjectQuery = (
  { __typename?: "Query" }
  & { lpProjectDetail?: Maybe<(
    { __typename?: "LpProject" }
    & LpProjectFragment
  )> }
);

export type ListLpProjectQueryVariables = Exact<{
  mediaType?: Maybe<ProjectMediaType>;
}>;

export type ListLpProjectQuery = (
  { __typename?: "Query" }
  & { lpProjectList: Array<(
    { __typename?: "LpProject" }
    & LpProjectFragment
  )> }
);

export type SakamaLpQueryVariables = Exact<{ [key: string]: never; }>;

export type SakamaLpQuery = (
  { __typename?: "Query" }
  & { searchProjectList: (
    { __typename?: "ProjectListConnection" }
    & { paginatorInfo: (
      { __typename?: "PaginatorInfo" }
      & PaginatorInfoFragment
    ), edges: Array<(
      { __typename?: "ProjectEdge" }
      & Pick<ProjectEdge, "cursor">
      & { node: (
        { __typename?: "InfluencerEntryProject" }
        & InfluencerEntryProjectFragment
      ) | (
        { __typename?: "OfferProject" }
        & OfferProjectFragment
      ) }
    )> }
  ) }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;

export type MeQuery = (
  { __typename?: "Query" }
  & Pick<Query, "unreadNotificationCount">
  & { me: (
    { __typename?: "Account" }
    & AccountFragment
  ), shippingAddress?: Maybe<(
    { __typename?: "ShippingAddress" }
    & ShippingAddressFragment
  )>, payee?: Maybe<(
    { __typename?: "Payee" }
    & PayeeFragment
  )> }
);

export type AccountFragment = (
  { __typename?: "Account" }
  & Pick<Account, "id" | "influencerName" | "influencerImageUrl" | "isInstagramAccountActivated" | "email" | "sex" | "birthday" | "emailVerifiedAt" | "hasVerifiedEmail" | "hasEmail" | "hasPassword">
  & { socialAccount?: Maybe<(
    { __typename?: "SocialAccount" }
    & { instagramAccount?: Maybe<(
      { __typename?: "InstagramAccount" }
      & InstagramAccountFragment
    )>, twitterAccount?: Maybe<(
      { __typename?: "TwitterAccount" }
      & TwitterAccountFragment
    )>, youtubeAccount?: Maybe<(
      { __typename?: "YouTubeAccount" }
      & YouTubeAccountFragment
    )>, tiktokAccount?: Maybe<(
      { __typename?: "TikTokAccount" }
      & TikTokAccountFragment
    )> }
  )>, loginCredential?: Maybe<(
    { __typename?: "LoginCredential" }
    & Pick<LoginCredential, "email" | "password">
  )> }
);

export type InstagramAccountFragment = (
  { __typename?: "InstagramAccount" }
  & Pick<InstagramAccount, "influencerAuthorizedStatus" | "identificationCode" | "followerCountBorder" | "postCountBorder" | "userId" | "username" | "followerCount" | "postCount">
);

export type TwitterAccountFragment = (
  { __typename?: "TwitterAccount" }
  & Pick<TwitterAccount, "username" | "followerCount" | "twitterAuthorizedStatus">
);

export type YouTubeAccountFragment = (
  { __typename?: "YouTubeAccount" }
  & Pick<YouTubeAccount, "title" | "followerCount" | "youtubeAuthorizedStatus">
);

export type TikTokAccountFragment = (
  { __typename?: "TikTokAccount" }
  & Pick<TikTokAccount, "title" | "followerCount" | "tiktokAuthorizedStatus" | "identificationCode">
);

export type ShippingAddressFragment = (
  { __typename?: "ShippingAddress" }
  & Pick<ShippingAddress, "id" | "lastName" | "firstName" | "lastNameKana" | "firstNameKana" | "address" | "cityName" | "prefectureName" | "zipcode" | "building" | "phoneNumber">
);

export type MypageQueryVariables = Exact<{ [key: string]: never; }>;

export type MypageQuery = (
  { __typename?: "Query" }
  & { me: (
    { __typename?: "Account" }
    & AccountFragment
  ) }
);

export type NavigationTabListQueryVariables = Exact<{ [key: string]: never; }>;

export type NavigationTabListQuery = (
  { __typename?: "Query" }
  & { navigationTabList: Array<(
    { __typename?: "NavigationTab" }
    & Pick<NavigationTab, "id" | "title">
    & { searchProjectConditionGroup: Array<(
      { __typename?: "SearchProjectConditionGroup" }
      & Pick<SearchProjectConditionGroup, "conjunction">
      & { searchProjectCondition: Array<(
        { __typename?: "SearchProjectCondition" }
        & Pick<SearchProjectCondition, "field" | "value">
      )> }
    )> }
  )> }
);

export type OfferProjectChecklistQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type OfferProjectChecklistQuery = (
  { __typename?: "Query" }
  & { offerProjectDetail?: Maybe<(
    { __typename?: "OfferProject" }
    & Pick<OfferProject, "id" | "price">
    & { project: (
      { __typename?: "Project" }
      & Pick<Project, "id" | "postPeriodEndAt">
    ) }
  )>, payee?: Maybe<(
    { __typename?: "Payee" }
    & Pick<Payee, "id">
    & { bankAccount?: Maybe<(
      { __typename?: "BankAccount" }
      & Pick<BankAccount, "bankName" | "branchName">
    )> }
  )>, shippingAddress?: Maybe<(
    { __typename?: "ShippingAddress" }
    & Pick<ShippingAddress, "id" | "prefectureName" | "cityName" | "address" | "building">
  )> }
);

export type OfferProjectTotalQueryVariables = Exact<{
  perPage: Scalars["Int"];
  page: Scalars["Int"];
}>;

export type OfferProjectTotalQuery = (
  { __typename?: "Query" }
  & { offerProjectList: (
    { __typename?: "OfferProjectListConnection" }
    & { paginatorInfo: (
      { __typename?: "PaginatorInfo" }
      & Pick<PaginatorInfo, "total">
    ) }
  ) }
);

export type PayeeQueryVariables = Exact<{ [key: string]: never; }>;

export type PayeeQuery = (
  { __typename?: "Query" }
  & { payee?: Maybe<(
    { __typename?: "Payee" }
    & PayeeFragment
  )> }
);

export type PaymentsQueryVariables = Exact<{ [key: string]: never; }>;

export type PaymentsQuery = (
  { __typename?: "Query" }
  & { unpaid: (
    { __typename?: "BillingListConnection" }
    & { edges: Array<(
      { __typename?: "BillingEdge" }
      & BillingEdgeFragment
    )>, paginatorInfo: (
      { __typename?: "PaginatorInfo" }
      & PaginatorInfoFragment
    ) }
  ), paid: (
    { __typename?: "BillingListConnection" }
    & { edges: Array<(
      { __typename?: "BillingEdge" }
      & BillingEdgeFragment
    )>, paginatorInfo: (
      { __typename?: "PaginatorInfo" }
      & PaginatorInfoFragment
    ) }
  ) }
);

export type BillingEdgeFragment = (
  { __typename?: "BillingEdge" }
  & Pick<BillingEdge, "cursor">
  & { node: (
    { __typename?: "Billing" }
    & BillingFragment
  ) }
);

export type ListEntryQueryVariables = Exact<{
  page: Scalars["Int"];
}>;

export type ListEntryQuery = (
  { __typename?: "Query" }
  & { entryList: (
    { __typename?: "EntryListConnection" }
    & { edges: Array<(
      { __typename?: "EntryEdge" }
      & Pick<EntryEdge, "cursor">
      & { node: (
        { __typename?: "Entry" }
        & EntryFragment
      ) }
    )>, paginatorInfo: (
      { __typename?: "PaginatorInfo" }
      & PaginatorInfoFragment
    ) }
  ) }
);

export type ListProgressAllQueryVariables = Exact<{ [key: string]: never; }>;

export type ListProgressAllQuery = (
  { __typename?: "Query" }
  & { offerProjectList: (
    { __typename?: "OfferProjectListConnection" }
    & { paginatorInfo: (
      { __typename?: "PaginatorInfo" }
      & PaginatorInfoFragment
    ) }
  ), entry: (
    { __typename?: "EntryListConnection" }
    & { edges: Array<(
      { __typename?: "EntryEdge" }
      & Pick<EntryEdge, "cursor">
      & { node: (
        { __typename?: "Entry" }
        & EntryFragment
      ) }
    )>, paginatorInfo: (
      { __typename?: "PaginatorInfo" }
      & PaginatorInfoFragment
    ) }
  ), incomplete: (
    { __typename?: "ProjectProgressListConnection" }
    & { edges: Array<(
      { __typename?: "ProjectProgressEdge" }
      & ProjectProgressEdgeFragment
    )>, paginatorInfo: (
      { __typename?: "PaginatorInfo" }
      & PaginatorInfoFragment
    ) }
  ), complete: (
    { __typename?: "ProjectProgressListConnection" }
    & { edges: Array<(
      { __typename?: "ProjectProgressEdge" }
      & ProjectProgressEdgeFragment
    )>, paginatorInfo: (
      { __typename?: "PaginatorInfo" }
      & PaginatorInfoFragment
    ) }
  ) }
);

export type ListProgressQueryVariables = Exact<{
  status: ProjectProgressStatus;
  page: Scalars["Int"];
}>;

export type ListProgressQuery = (
  { __typename?: "Query" }
  & { projectProgressList: (
    { __typename?: "ProjectProgressListConnection" }
    & { edges: Array<(
      { __typename?: "ProjectProgressEdge" }
      & ProjectProgressEdgeFragment
    )>, paginatorInfo: (
      { __typename?: "PaginatorInfo" }
      & PaginatorInfoFragment
    ) }
  ) }
);

export type ProjectProgressEdgeFragment = (
  { __typename?: "ProjectProgressEdge" }
  & Pick<ProjectProgressEdge, "cursor">
  & { node: (
    { __typename?: "ProjectProgress" }
    & ProjectProgressFragment
  ) }
);

export type ProjectProgressQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ProjectProgressQuery = (
  { __typename?: "Query" }
  & { projectProgressDetail?: Maybe<(
    { __typename?: "ProjectProgress" }
    & ProjectProgressFragment
  )> }
);

export type ProjectProgressDetailQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ProjectProgressDetailQuery = (
  { __typename?: "Query" }
  & { projectProgressDetail?: Maybe<(
    { __typename?: "ProjectProgress" }
    & ProjectProgressFragment
  )> }
);

export type InfluencerEntryProjectDetailQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type InfluencerEntryProjectDetailQuery = (
  { __typename?: "Query" }
  & { influencerEntryProjectDetail: (
    { __typename?: "InfluencerEntryProject" }
    & InfluencerEntryProjectFragment
  ) }
);

export type OfferNgReasonListQueryVariables = Exact<{ [key: string]: never; }>;

export type OfferNgReasonListQuery = (
  { __typename?: "Query" }
  & { offerNgReasonList: Array<(
    { __typename?: "OfferNgReason" }
    & Pick<OfferNgReason, "id" | "content">
  )> }
);

export type OfferProjectDetailQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type OfferProjectDetailQuery = (
  { __typename?: "Query" }
  & { offerProjectDetail?: Maybe<(
    { __typename?: "OfferProject" }
    & OfferProjectFragment
  )> }
);

export type OfferProjectsQueryVariables = Exact<{
  perPage: Scalars["Int"];
  page: Scalars["Int"];
}>;

export type OfferProjectsQuery = (
  { __typename?: "Query" }
  & { offerProjectList: (
    { __typename?: "OfferProjectListConnection" }
    & { paginatorInfo: (
      { __typename?: "PaginatorInfo" }
      & PaginatorInfoFragment
    ), edges: Array<(
      { __typename?: "OfferProjectEdge" }
      & Pick<OfferProjectEdge, "cursor">
      & { node: (
        { __typename?: "OfferProject" }
        & OfferProjectFragment
      ) }
    )> }
  ) }
);

export type SearchProjectListQueryVariables = Exact<{
  searchProjectConditionGroupInput: Array<SearchProjectConditionGroupInput>;
  perPage: Scalars["Int"];
  page: Scalars["Int"];
}>;

export type SearchProjectListQuery = (
  { __typename?: "Query" }
  & { searchProjectList: (
    { __typename?: "ProjectListConnection" }
    & { paginatorInfo: (
      { __typename?: "PaginatorInfo" }
      & PaginatorInfoFragment
    ), edges: Array<(
      { __typename?: "ProjectEdge" }
      & Pick<ProjectEdge, "cursor">
      & { node: (
        { __typename?: "InfluencerEntryProject" }
        & InfluencerEntryProjectFragment
      ) | (
        { __typename?: "OfferProject" }
        & OfferProjectFragment
      ) }
    )> }
  ) }
);

export type SearchInstagramAccountQueryVariables = Exact<{
  username: Scalars["String"];
}>;

export type SearchInstagramAccountQuery = (
  { __typename?: "Query" }
  & { instagramAccountProfileDetail?: Maybe<(
    { __typename?: "InstagramAccountProfile" }
    & Pick<InstagramAccountProfile, "username" | "name" | "imageUrl">
  )> }
);

export type ShippingAddressQueryVariables = Exact<{ [key: string]: never; }>;

export type ShippingAddressQuery = (
  { __typename?: "Query" }
  & { shippingAddress?: Maybe<(
    { __typename?: "ShippingAddress" }
    & Pick<ShippingAddress, "id" | "lastName" | "firstName" | "lastNameKana" | "firstNameKana" | "zipcode" | "prefectureName" | "cityName" | "address" | "building" | "phoneNumber">
  )> }
);

export type SystemSettingQueryVariables = Exact<{ [key: string]: never; }>;

export type SystemSettingQuery = (
  { __typename?: "Query" }
  & { systemSetting: (
    { __typename?: "SystemSetting" }
    & { socialMediaSetting: (
      { __typename?: "SocialMediaSetting" }
      & { line: (
        { __typename?: "Line" }
        & Pick<Line, "clientId" | "redirectUri">
      ), apple: (
        { __typename?: "Apple" }
        & Pick<Apple, "clientId" | "redirectUri">
      ), instagram: (
        { __typename?: "Instagram" }
        & Pick<Instagram, "clientId" | "redirectUri">
      ), youtube: (
        { __typename?: "YouTube" }
        & Pick<YouTube, "clientId" | "redirectUri">
      ) }
    ) }
  ) }
);

export type TikTokAccountActivationQueryVariables = Exact<{ [key: string]: never; }>;

export type TikTokAccountActivationQuery = (
  { __typename?: "Query" }
  & { accountActivation: (
    { __typename?: "TikTokAccountActivation" }
    & Pick<TikTokAccountActivation, "id" | "postCount" | "postCountBorder" | "followerCount" | "followerCountBorder" | "authorizedStatus" | "username" | "canRequestReview">
  ) }
);

export type TopBannerListQueryVariables = Exact<{ [key: string]: never; }>;

export type TopBannerListQuery = (
  { __typename?: "Query" }
  & { topBannerList: Array<(
    { __typename?: "TopBanner" }
    & Pick<TopBanner, "href" | "imageUrl" | "isTargetBlank">
  )> }
);

export type TwitterAccountActivationQueryVariables = Exact<{ [key: string]: never; }>;

export type TwitterAccountActivationQuery = (
  { __typename?: "Query" }
  & { accountActivation: (
    { __typename?: "TwitterAccountActivation" }
    & Pick<TwitterAccountActivation, "id" | "postCount" | "postCountBorder" | "followerCount" | "followerCountBorder" | "authorizedStatus" | "username" | "canRequestReview">
  ) }
);

export type TwitterAuthQueryVariables = Exact<{ [key: string]: never; }>;

export type TwitterAuthQuery = (
  { __typename?: "Query" }
  & { twitterAuth: (
    { __typename?: "TwitterAuth" }
    & Pick<TwitterAuth, "oauthToken">
  ) }
);

export type VerifyEmailMutationVariables = Exact<{
  token: Scalars["String"];
}>;

export type VerifyEmailMutation = (
  { __typename?: "Mutation" }
  & Pick<Mutation, "verifyEmail">
);

export type YouTubeAccountActivationQueryVariables = Exact<{ [key: string]: never; }>;

export type YouTubeAccountActivationQuery = (
  { __typename?: "Query" }
  & { accountActivation: (
    { __typename?: "YouTubeAccountActivation" }
    & Pick<YouTubeAccountActivation, "id" | "postCount" | "postCountBorder" | "followerCount" | "followerCountBorder" | "authorizedStatus" | "username" | "canRequestReview">
  ) }
);

export type ZipCodeDetailQueryVariables = Exact<{
  zipcode: Scalars["String"];
}>;

export type ZipCodeDetailQuery = (
  { __typename?: "Query" }
  & { zipCodeDetail?: Maybe<(
    { __typename?: "ZipCode" }
    & Pick<ZipCode, "id" | "zipcode" | "prefectureName" | "cityName" | "address">
  )> }
);

export const AnnouncementFragmentDoc = gql`
    fragment AnnouncementFragment on Announcement {
  title
  body
  createdAt
}
    `;
export const AwsstsCredentialFragmentDoc = gql`
    fragment AWSSTSCredentialFragment on AwsStsCredential {
  accessKeyId
  secretAccessKey
  sessionToken
  expiration
}
    `;
export const EntrySettingFragmentDoc = gql`
    fragment EntrySettingFragment on EntrySetting {
  id
  residencePlace
  ageTo
  ageFrom
  entryEndAt
  entryStartAt
  sex
  selectableInfluencersLimit
  minFollowerCount
}
    `;
export const ProjectFragmentDoc = gql`
    fragment ProjectFragment on Project {
  id
  name
  hasShippingStep
  client {
    id
    companyName
  }
  item {
    id
    name
    detail
    imageUrl
  }
  mediaType
  postPeriodStartAt
  postPeriodEndAt
  appealContent
  hashTagArray
  howTakePicture
  externalLinkUrl
  note
  videoSecondaryUse
  ngConditions
  videoDescription
}
    `;
export const EnqueteFragmentDoc = gql`
    fragment EnqueteFragment on Enquete {
  id
  title
  body
  pageType
  required
  responsePeriodFrom
  responsePeriodTo
  alreadyAnswered
  enqueteQuestions {
    id
    title
    required
    node {
      __typename
      ... on EnqueteQuestionText {
        id
        minSize
        maxSize
      }
      ... on EnqueteQuestionTextArea {
        id
        minSize
        maxSize
      }
      ... on EnqueteQuestionCheckbox {
        items {
          id
          label
          sort
        }
      }
      ... on EnqueteQuestionPulldown {
        items {
          id
          label
          sort
        }
      }
      ... on EnqueteQuestionRadio {
        items {
          id
          label
          sort
        }
      }
      ... on EnqueteQuestionRange {
        id
        minLabel
        minValue
        maxLabel
        maxValue
        scale
      }
    }
    required
    sort
  }
}
    `;
export const EntryProjectFragmentDoc = gql`
    fragment EntryProjectFragment on EntryProject {
  id
  price
  hasPayment
  projectRewardType
  projectType
  entrySetting {
    ...EntrySettingFragment
  }
  project {
    ...ProjectFragment
  }
  enquetes {
    ...EnqueteFragment
  }
}
    ${EntrySettingFragmentDoc}
${ProjectFragmentDoc}
${EnqueteFragmentDoc}`;
export const EntryFragmentDoc = gql`
    fragment EntryFragment on Entry {
  id
  entryStatus
  createdAt
  rejectedAt
  entryProject {
    ...EntryProjectFragment
  }
}
    ${EntryProjectFragmentDoc}`;
export const FaqCategoryFragmentDoc = gql`
    fragment FaqCategoryFragment on FaqCategory {
  id
  name
}
    `;
export const FaqFragmentDoc = gql`
    fragment FaqFragment on Faq {
  id
  title
  body
}
    `;
export const InfluencerEntryProjectFragmentDoc = gql`
    fragment InfluencerEntryProjectFragment on InfluencerEntryProject {
  id
  entryProject {
    ...EntryProjectFragment
  }
  isEntried
  isSatisfiedMinFollowerCount
  isSatisfiedSex
  isSatisfiedAge
  isSatisfiedSocialMedia
  enquetes {
    ...EnqueteFragment
  }
}
    ${EntryProjectFragmentDoc}
${EnqueteFragmentDoc}`;
export const InstagramDraftAttachmentFragmentDoc = gql`
    fragment InstagramDraftAttachmentFragment on InstagramDraftAttachment {
  id
  attachmentFileUrl
  sort
  fileKey
}
    `;
export const InstagramDraftFragmentDoc = gql`
    fragment InstagramDraftFragment on Draft {
  id
  status
  ... on InstagramDraft {
    id
    caption
    ngReason
    confirmedAt
    rewriteCaption
    draftAttachments {
      ... on InstagramDraftAttachment {
        ...InstagramDraftAttachmentFragment
      }
    }
  }
}
    ${InstagramDraftAttachmentFragmentDoc}`;
export const LpProjectFragmentDoc = gql`
    fragment LpProjectFragment on LpProject {
  id
  enqueteId
  mediaType
  projectTitle
  productName
  productImgUrl
  productUrl
  productDescription
  clientName
  requirements
  checkPost
  entryOpenings
  entryOpeningsFilled
  appealPoints
  pictureHowTo
  hashtags
  videoSecondaryUsage
  intendedMessage
  ng
  videoDescription
  note
  paymentValue
  paymentDate
  slug
  published
  sort
  entryStart
  entryEnd
  postStart
  postEnd
  alreadyAnswered
}
    `;
export const PaginatorInfoFragmentDoc = gql`
    fragment PaginatorInfoFragment on PaginatorInfo {
  count
  currentPage
  lastPage
  perPage
  total
  hasMorePages
}
    `;
export const BankAccountFragmentDoc = gql`
    fragment BankAccountFragment on BankAccount {
  bankName
  branchName
  number
  accountHolder
  type
}
    `;
export const PayeeFragmentDoc = gql`
    fragment PayeeFragment on Payee {
  id
  lastName
  firstName
  hasWithholdingTax
  bankAccount {
    ...BankAccountFragment
  }
}
    ${BankAccountFragmentDoc}`;
export const TikTokDraftAttachmentFragmentDoc = gql`
    fragment TikTokDraftAttachmentFragment on TikTokDraftAttachment {
  id
  attachmentFileUrl
  sort
  fileKey
}
    `;
export const TikTokDraftFragmentDoc = gql`
    fragment TikTokDraftFragment on Draft {
  id
  status
  ... on TikTokDraft {
    caption
    ngReason
    confirmedAt
    rewriteCaption
    draftAttachments {
      ... on TikTokDraftAttachment {
        ...TikTokDraftAttachmentFragment
      }
    }
  }
}
    ${TikTokDraftAttachmentFragmentDoc}`;
export const TwitterDraftAttachmentFragmentDoc = gql`
    fragment TwitterDraftAttachmentFragment on TwitterDraftAttachment {
  id
  attachmentFileUrl
  sort
  fileKey
}
    `;
export const TwitterDraftFragmentDoc = gql`
    fragment TwitterDraftFragment on Draft {
  id
  status
  ... on TwitterDraft {
    caption
    ngReason
    confirmedAt
    rewriteCaption
    draftAttachments {
      ... on TwitterDraftAttachment {
        ...TwitterDraftAttachmentFragment
      }
    }
  }
}
    ${TwitterDraftAttachmentFragmentDoc}`;
export const YouTubeDraftAttachmentFragmentDoc = gql`
    fragment YouTubeDraftAttachmentFragment on YouTubeDraftAttachment {
  id
  fileKey
  thumbnailUrl
}
    `;
export const YouTubeDraftFragmentDoc = gql`
    fragment YouTubeDraftFragment on Draft {
  id
  status
  ... on YouTubeDraft {
    limitedShareUrl
    ngReason
    confirmedAt
    draftAttachments {
      ... on YouTubeDraftAttachment {
        ...YouTubeDraftAttachmentFragment
      }
    }
  }
}
    ${YouTubeDraftAttachmentFragmentDoc}`;
export const NotificationFragmentDoc = gql`
    fragment NotificationFragment on Notification {
  id
  notifiableId
  type
  title
  readFlag
  createdAt
}
    `;
export const NotificationEdgeFragmentDoc = gql`
    fragment NotificationEdgeFragment on NotificationEdge {
  cursor
  node {
    ...NotificationFragment
  }
}
    ${NotificationFragmentDoc}`;
export const InstagramAccountFragmentDoc = gql`
    fragment InstagramAccountFragment on InstagramAccount {
  influencerAuthorizedStatus
  identificationCode
  followerCountBorder
  postCountBorder
  userId
  username
  followerCount
  postCount
}
    `;
export const TwitterAccountFragmentDoc = gql`
    fragment TwitterAccountFragment on TwitterAccount {
  username
  followerCount
  twitterAuthorizedStatus
}
    `;
export const YouTubeAccountFragmentDoc = gql`
    fragment YouTubeAccountFragment on YouTubeAccount {
  title
  followerCount
  youtubeAuthorizedStatus
}
    `;
export const TikTokAccountFragmentDoc = gql`
    fragment TikTokAccountFragment on TikTokAccount {
  title
  followerCount
  tiktokAuthorizedStatus
  identificationCode
}
    `;
export const AccountFragmentDoc = gql`
    fragment AccountFragment on Account {
  id
  influencerName
  influencerImageUrl
  isInstagramAccountActivated
  email
  sex
  birthday
  emailVerifiedAt
  hasVerifiedEmail
  socialAccount {
    instagramAccount {
      ...InstagramAccountFragment
    }
    twitterAccount {
      ...TwitterAccountFragment
    }
    youtubeAccount {
      ...YouTubeAccountFragment
    }
    tiktokAccount {
      ...TikTokAccountFragment
    }
  }
  loginCredential {
    email
    password
  }
  hasEmail
  hasPassword
}
    ${InstagramAccountFragmentDoc}
${TwitterAccountFragmentDoc}
${YouTubeAccountFragmentDoc}
${TikTokAccountFragmentDoc}`;
export const ShippingAddressFragmentDoc = gql`
    fragment ShippingAddressFragment on ShippingAddress {
  id
  lastName
  firstName
  lastNameKana
  firstNameKana
  address
  cityName
  prefectureName
  zipcode
  building
  phoneNumber
}
    `;
export const PayeeSnapshotFragmentDoc = gql`
    fragment PayeeSnapshotFragment on PayeeSnapshot {
  lastName
  firstName
  hasWithholdingTax
  bankAccount {
    ...BankAccountFragment
  }
}
    ${BankAccountFragmentDoc}`;
export const BillingItemFragmentDoc = gql`
    fragment BillingItemFragment on BillingItem {
  projectName
  amount
  postedAt
}
    `;
export const BillingFragmentDoc = gql`
    fragment BillingFragment on Billing {
  id
  number
  year
  month
  status
  publishDate
  expireDate
  agencyName
  subTotal
  consumptionTax
  withholdingTax
  taxedTotal
  payee {
    ...PayeeSnapshotFragment
  }
  items {
    ...BillingItemFragment
  }
}
    ${PayeeSnapshotFragmentDoc}
${BillingItemFragmentDoc}`;
export const BillingEdgeFragmentDoc = gql`
    fragment BillingEdgeFragment on BillingEdge {
  cursor
  node {
    ...BillingFragment
  }
}
    ${BillingFragmentDoc}`;
export const ProgressBillingFragmentDoc = gql`
    fragment ProgressBillingFragment on Billing {
  id
  status
}
    `;
export const ProgressDraftFragmentDoc = gql`
    fragment ProgressDraftFragment on Draft {
  id
  status
  ... on InstagramDraft {
    caption
    ngReason
    rewriteCaption
  }
  ... on TwitterDraft {
    caption
    ngReason
    rewriteCaption
  }
  ... on YouTubeDraft {
    ngReason
    limitedShareUrl
  }
  ... on TikTokDraft {
    caption
    ngReason
    rewriteCaption
  }
}
    `;
export const ProgressAcceptanceFragmentDoc = gql`
    fragment ProgressAcceptanceFragment on Acceptance {
  id
  status
  url
  ngReason
  ngReasonNote
}
    `;
export const OfferProjectFragmentDoc = gql`
    fragment OfferProjectFragment on OfferProject {
  id
  projectRewardType
  projectType
  price
  hasPayment
  offerExpiredAt
  offerAnswerStatus
  project {
    ...ProjectFragment
  }
  enquetes {
    ...EnqueteFragment
  }
}
    ${ProjectFragmentDoc}
${EnqueteFragmentDoc}`;
export const ProjectProgressFragmentDoc = gql`
    fragment ProjectProgressFragment on ProjectProgress {
  id
  billing {
    ...ProgressBillingFragment
  }
  draft {
    ...ProgressDraftFragment
  }
  acceptance {
    ...ProgressAcceptanceFragment
  }
  contractedProject {
    ... on OfferProject {
      ...OfferProjectFragment
    }
    ... on EntryProject {
      ...EntryProjectFragment
    }
  }
  enquetes {
    ...EnqueteFragment
  }
  stepId
  previousStepId
  projectProgressStatus
  projectFlowSteps
  contractDocumentPdfUrl
}
    ${ProgressBillingFragmentDoc}
${ProgressDraftFragmentDoc}
${ProgressAcceptanceFragmentDoc}
${OfferProjectFragmentDoc}
${EntryProjectFragmentDoc}
${EnqueteFragmentDoc}`;
export const ProjectProgressEdgeFragmentDoc = gql`
    fragment ProjectProgressEdgeFragment on ProjectProgressEdge {
  cursor
  node {
    ...ProjectProgressFragment
  }
}
    ${ProjectProgressFragmentDoc}`;
export const AnswerEnqueteDocument = gql`
    mutation AnswerEnquete($input: EnqueteAnswerInput!) {
  answerEnquete(input: $input)
}
    `;
export class AnswerEnqueteMutationRequest implements MutationRequest<AnswerEnqueteMutation, AnswerEnqueteMutationVariables> {
        options: MutationOptions<AnswerEnqueteMutation, AnswerEnqueteMutationVariables>
        constructor(variables: AnswerEnqueteMutationVariables) {
          this.options = {
            mutation: AnswerEnqueteDocument,
            variables
          };
        }
}
export const AppleLoginDocument = gql`
    mutation AppleLogin($code: String!, $deviceType: DeviceType) {
  appleLogin(code: $code, deviceType: $deviceType) {
    token
    isFirstLogin
  }
}
    `;
export class AppleLoginMutationRequest implements MutationRequest<AppleLoginMutation, AppleLoginMutationVariables> {
        options: MutationOptions<AppleLoginMutation, AppleLoginMutationVariables>
        constructor(variables: AppleLoginMutationVariables) {
          this.options = {
            mutation: AppleLoginDocument,
            variables
          };
        }
}
export const CompleteDraftDocument = gql`
    mutation CompleteDraft($progressId: ID!) {
  completeDraftAttachmentUpload(projectProgressId: $progressId)
}
    `;
export class CompleteDraftMutationRequest implements MutationRequest<CompleteDraftMutation, CompleteDraftMutationVariables> {
        options: MutationOptions<CompleteDraftMutation, CompleteDraftMutationVariables>
        constructor(variables: CompleteDraftMutationVariables) {
          this.options = {
            mutation: CompleteDraftDocument,
            variables
          };
        }
}
export const CreateAcceptanceDocument = gql`
    mutation CreateAcceptance($progressId: ID!, $url: String!, $note: String) {
  saveAcceptance(contractId: $progressId, url: $url, note: $note)
}
    `;
export class CreateAcceptanceMutationRequest implements MutationRequest<CreateAcceptanceMutation, CreateAcceptanceMutationVariables> {
        options: MutationOptions<CreateAcceptanceMutation, CreateAcceptanceMutationVariables>
        constructor(variables: CreateAcceptanceMutationVariables) {
          this.options = {
            mutation: CreateAcceptanceDocument,
            variables
          };
        }
}
export const CreateContactDocument = gql`
    mutation createContact($content: String!, $referrerId: ID, $referrerType: ContactReferrerType) {
  createContact(content: $content, referrerId: $referrerId, referrerType: $referrerType)
}
    `;
export class CreateContactMutationRequest implements MutationRequest<CreateContactMutation, CreateContactMutationVariables> {
        options: MutationOptions<CreateContactMutation, CreateContactMutationVariables>
        constructor(variables: CreateContactMutationVariables) {
          this.options = {
            mutation: CreateContactDocument,
            variables
          };
        }
}
export const CreateDraftDocument = gql`
    mutation CreateDraft($progressId: ID!, $limitedShareUrl: String, $caption: String, $files: [DraftFile!]) {
  createDraft(projectProgressId: $progressId, limitedShareUrl: $limitedShareUrl, caption: $caption, files: $files) {
    draft {
      ...InstagramDraftFragment
      ...TwitterDraftFragment
      ...YouTubeDraftFragment
      ...TikTokDraftFragment
    }
    credential {
      ...AWSSTSCredentialFragment
    }
  }
}
    ${InstagramDraftFragmentDoc}
${TwitterDraftFragmentDoc}
${YouTubeDraftFragmentDoc}
${TikTokDraftFragmentDoc}
${AwsstsCredentialFragmentDoc}`;
export class CreateDraftMutationRequest implements MutationRequest<CreateDraftMutation, CreateDraftMutationVariables> {
        options: MutationOptions<CreateDraftMutation, CreateDraftMutationVariables>
        constructor(variables: CreateDraftMutationVariables) {
          this.options = {
            mutation: CreateDraftDocument,
            variables
          };
        }
}
export const DeactivateSocialAccountDocument = gql`
    mutation DeactivateSocialAccount($mediaType: MediaType!) {
  deactivateSocialAccount(mediaType: $mediaType)
}
    `;
export class DeactivateSocialAccountMutationRequest implements MutationRequest<DeactivateSocialAccountMutation, DeactivateSocialAccountMutationVariables> {
        options: MutationOptions<DeactivateSocialAccountMutation, DeactivateSocialAccountMutationVariables>
        constructor(variables: DeactivateSocialAccountMutationVariables) {
          this.options = {
            mutation: DeactivateSocialAccountDocument,
            variables
          };
        }
}
export const LineLoginDocument = gql`
    mutation LineLogin($code: String!, $deviceType: DeviceType) {
  lineLogin(code: $code, deviceType: $deviceType) {
    token
    isFirstLogin
  }
}
    `;
export class LineLoginMutationRequest implements MutationRequest<LineLoginMutation, LineLoginMutationVariables> {
        options: MutationOptions<LineLoginMutation, LineLoginMutationVariables>
        constructor(variables: LineLoginMutationVariables) {
          this.options = {
            mutation: LineLoginDocument,
            variables
          };
        }
}
export const LinkAccountToInstagramDocument = gql`
    mutation LinkAccountToInstagram($code: String!) {
  linkAccountToInstagram(code: $code)
}
    `;
export class LinkAccountToInstagramMutationRequest implements MutationRequest<LinkAccountToInstagramMutation, LinkAccountToInstagramMutationVariables> {
        options: MutationOptions<LinkAccountToInstagramMutation, LinkAccountToInstagramMutationVariables>
        constructor(variables: LinkAccountToInstagramMutationVariables) {
          this.options = {
            mutation: LinkAccountToInstagramDocument,
            variables
          };
        }
}
export const LinkAccountToTikTokDocument = gql`
    mutation LinkAccountToTikTok($username: String!) {
  linkAccountToTikTok(username: $username)
}
    `;
export class LinkAccountToTikTokMutationRequest implements MutationRequest<LinkAccountToTikTokMutation, LinkAccountToTikTokMutationVariables> {
        options: MutationOptions<LinkAccountToTikTokMutation, LinkAccountToTikTokMutationVariables>
        constructor(variables: LinkAccountToTikTokMutationVariables) {
          this.options = {
            mutation: LinkAccountToTikTokDocument,
            variables
          };
        }
}
export const LinkAccountToTwitterDocument = gql`
    mutation LinkAccountToTwitter($oauthToken: String!, $oauthVerifier: String!) {
  linkAccountToTwitter(oauthToken: $oauthToken, oauthVerifier: $oauthVerifier)
}
    `;
export class LinkAccountToTwitterMutationRequest implements MutationRequest<LinkAccountToTwitterMutation, LinkAccountToTwitterMutationVariables> {
        options: MutationOptions<LinkAccountToTwitterMutation, LinkAccountToTwitterMutationVariables>
        constructor(variables: LinkAccountToTwitterMutationVariables) {
          this.options = {
            mutation: LinkAccountToTwitterDocument,
            variables
          };
        }
}
export const LinkAccountToYouTubeDocument = gql`
    mutation LinkAccountToYouTube($code: String!) {
  linkAccountToYouTube(code: $code)
}
    `;
export class LinkAccountToYouTubeMutationRequest implements MutationRequest<LinkAccountToYouTubeMutation, LinkAccountToYouTubeMutationVariables> {
        options: MutationOptions<LinkAccountToYouTubeMutation, LinkAccountToYouTubeMutationVariables>
        constructor(variables: LinkAccountToYouTubeMutationVariables) {
          this.options = {
            mutation: LinkAccountToYouTubeDocument,
            variables
          };
        }
}
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    token
  }
}
    `;
export class LoginMutationRequest implements MutationRequest<LoginMutation, LoginMutationVariables> {
        options: MutationOptions<LoginMutation, LoginMutationVariables>
        constructor(variables: LoginMutationVariables) {
          this.options = {
            mutation: LoginDocument,
            variables
          };
        }
}
export const OnetimeLoginDocument = gql`
    mutation OnetimeLogin($token: String!) {
  onetimeLogin(token: $token) {
    token
  }
}
    `;
export class OnetimeLoginMutationRequest implements MutationRequest<OnetimeLoginMutation, OnetimeLoginMutationVariables> {
        options: MutationOptions<OnetimeLoginMutation, OnetimeLoginMutationVariables>
        constructor(variables: OnetimeLoginMutationVariables) {
          this.options = {
            mutation: OnetimeLoginDocument,
            variables
          };
        }
}
export const AcceptOfferDocument = gql`
    mutation AcceptOffer($id: ID!) {
  acceptOffer(id: $id) {
    ...ProjectProgressFragment
  }
}
    ${ProjectProgressFragmentDoc}`;
export class AcceptOfferMutationRequest implements MutationRequest<AcceptOfferMutation, AcceptOfferMutationVariables> {
        options: MutationOptions<AcceptOfferMutation, AcceptOfferMutationVariables>
        constructor(variables: AcceptOfferMutationVariables) {
          this.options = {
            mutation: AcceptOfferDocument,
            variables
          };
        }
}
export const ApplyForEntryProjectDocument = gql`
    mutation ApplyForEntryProject($projectId: Int!) {
  applyForEntryProject(projectId: $projectId) {
    ...EntryFragment
  }
}
    ${EntryFragmentDoc}`;
export class ApplyForEntryProjectMutationRequest implements MutationRequest<ApplyForEntryProjectMutation, ApplyForEntryProjectMutationVariables> {
        options: MutationOptions<ApplyForEntryProjectMutation, ApplyForEntryProjectMutationVariables>
        constructor(variables: ApplyForEntryProjectMutationVariables) {
          this.options = {
            mutation: ApplyForEntryProjectDocument,
            variables
          };
        }
}
export const RejectOfferDocument = gql`
    mutation RejectOffer($id: ID!, $ngReasonId: ID!, $ngReason: String) {
  rejectOffer(id: $id, ngReasonId: $ngReasonId, ngReason: $ngReason)
}
    `;
export class RejectOfferMutationRequest implements MutationRequest<RejectOfferMutation, RejectOfferMutationVariables> {
        options: MutationOptions<RejectOfferMutation, RejectOfferMutationVariables>
        constructor(variables: RejectOfferMutationVariables) {
          this.options = {
            mutation: RejectOfferDocument,
            variables
          };
        }
}
export const RegisterCredentialsDocument = gql`
    mutation RegisterCredentials($email: String!, $password: String!, $passwordConfirmation: String!) {
  registerCredential(email: $email, password: $password, passwordConfirmation: $passwordConfirmation)
}
    `;
export class RegisterCredentialsMutationRequest implements MutationRequest<RegisterCredentialsMutation, RegisterCredentialsMutationVariables> {
        options: MutationOptions<RegisterCredentialsMutation, RegisterCredentialsMutationVariables>
        constructor(variables: RegisterCredentialsMutationVariables) {
          this.options = {
            mutation: RegisterCredentialsDocument,
            variables
          };
        }
}
export const RequestDeleteAccountDocument = gql`
    mutation RequestDeleteAccount($reason: String) {
  requestDeleteAccount(reason: $reason)
}
    `;
export class RequestDeleteAccountMutationRequest implements MutationRequest<RequestDeleteAccountMutation, RequestDeleteAccountMutationVariables> {
        options: MutationOptions<RequestDeleteAccountMutation, RequestDeleteAccountMutationVariables>
        constructor(variables: RequestDeleteAccountMutationVariables) {
          this.options = {
            mutation: RequestDeleteAccountDocument,
            variables
          };
        }
}
export const RequestInstagramReviewDocument = gql`
    mutation RequestInstagramReview {
  requestInstagramReview
}
    `;
export class RequestInstagramReviewMutationRequest implements MutationRequest<RequestInstagramReviewMutation, RequestInstagramReviewMutationVariables> {
        options: MutationOptions<RequestInstagramReviewMutation, RequestInstagramReviewMutationVariables>
        constructor(variables: RequestInstagramReviewMutationVariables) {
          this.options = {
            mutation: RequestInstagramReviewDocument,
            variables
          };
        }
}
export const RequestTikTokReviewDocument = gql`
    mutation RequestTikTokReview {
  requestTikTokReview
}
    `;
export class RequestTikTokReviewMutationRequest implements MutationRequest<RequestTikTokReviewMutation, RequestTikTokReviewMutationVariables> {
        options: MutationOptions<RequestTikTokReviewMutation, RequestTikTokReviewMutationVariables>
        constructor(variables: RequestTikTokReviewMutationVariables) {
          this.options = {
            mutation: RequestTikTokReviewDocument,
            variables
          };
        }
}
export const RequestTwitterReviewDocument = gql`
    mutation RequestTwitterReview {
  requestTwitterReview
}
    `;
export class RequestTwitterReviewMutationRequest implements MutationRequest<RequestTwitterReviewMutation, RequestTwitterReviewMutationVariables> {
        options: MutationOptions<RequestTwitterReviewMutation, RequestTwitterReviewMutationVariables>
        constructor(variables: RequestTwitterReviewMutationVariables) {
          this.options = {
            mutation: RequestTwitterReviewDocument,
            variables
          };
        }
}
export const RequestYouTubeReviewDocument = gql`
    mutation RequestYouTubeReview {
  requestYouTubeReview
}
    `;
export class RequestYouTubeReviewMutationRequest implements MutationRequest<RequestYouTubeReviewMutation, RequestYouTubeReviewMutationVariables> {
        options: MutationOptions<RequestYouTubeReviewMutation, RequestYouTubeReviewMutationVariables>
        constructor(variables: RequestYouTubeReviewMutationVariables) {
          this.options = {
            mutation: RequestYouTubeReviewDocument,
            variables
          };
        }
}
export const ResendEmailActivationDocument = gql`
    mutation ResendEmailActivation($transitionTarget: String!) {
  resendEmailActivation(transitionTarget: $transitionTarget)
}
    `;
export class ResendEmailActivationMutationRequest implements MutationRequest<ResendEmailActivationMutation, ResendEmailActivationMutationVariables> {
        options: MutationOptions<ResendEmailActivationMutation, ResendEmailActivationMutationVariables>
        constructor(variables: ResendEmailActivationMutationVariables) {
          this.options = {
            mutation: ResendEmailActivationDocument,
            variables
          };
        }
}
export const ResetPasswordDocument = gql`
    mutation ResetPassword($token: String!, $email: String!, $password: String!, $passwordConfirmation: String!) {
  resetPassword(token: $token, email: $email, password: $password, passwordConfirmation: $passwordConfirmation) {
    token
  }
}
    `;
export class ResetPasswordMutationRequest implements MutationRequest<ResetPasswordMutation, ResetPasswordMutationVariables> {
        options: MutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>
        constructor(variables: ResetPasswordMutationVariables) {
          this.options = {
            mutation: ResetPasswordDocument,
            variables
          };
        }
}
export const SaveDeviceTokenDocument = gql`
    mutation SaveDeviceToken($deviceType: DeviceType!, $token: String!) {
  saveDeviceToken(deviceType: $deviceType, token: $token)
}
    `;
export class SaveDeviceTokenMutationRequest implements MutationRequest<SaveDeviceTokenMutation, SaveDeviceTokenMutationVariables> {
        options: MutationOptions<SaveDeviceTokenMutation, SaveDeviceTokenMutationVariables>
        constructor(variables: SaveDeviceTokenMutationVariables) {
          this.options = {
            mutation: SaveDeviceTokenDocument,
            variables
          };
        }
}
export const SendPasswordResetEmailDocument = gql`
    mutation SendPasswordResetEmail($email: String!) {
  sendPasswordResetEmail(email: $email)
}
    `;
export class SendPasswordResetEmailMutationRequest implements MutationRequest<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables> {
        options: MutationOptions<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>
        constructor(variables: SendPasswordResetEmailMutationVariables) {
          this.options = {
            mutation: SendPasswordResetEmailDocument,
            variables
          };
        }
}
export const SignUpDocument = gql`
    mutation SignUp($email: String!, $password: String!, $passwordConfirmation: String!, $sex: Sex, $birthday: Date, $friendCode: String) {
  signUp(email: $email, password: $password, passwordConfirmation: $passwordConfirmation, sex: $sex, birthday: $birthday, friendCode: $friendCode) {
    token
  }
}
    `;
export class SignUpMutationRequest implements MutationRequest<SignUpMutation, SignUpMutationVariables> {
        options: MutationOptions<SignUpMutation, SignUpMutationVariables>
        constructor(variables: SignUpMutationVariables) {
          this.options = {
            mutation: SignUpDocument,
            variables
          };
        }
}
export const UpdateDraftDocument = gql`
    mutation UpdateDraft($progressId: ID!, $limitedShareUrl: String, $caption: String, $files: [DraftFile!]) {
  updateDraft(projectProgressId: $progressId, limitedShareUrl: $limitedShareUrl, caption: $caption, files: $files) {
    draft {
      ...InstagramDraftFragment
      ...TwitterDraftFragment
      ...YouTubeDraftFragment
      ...TikTokDraftFragment
    }
    credential {
      ...AWSSTSCredentialFragment
    }
  }
}
    ${InstagramDraftFragmentDoc}
${TwitterDraftFragmentDoc}
${YouTubeDraftFragmentDoc}
${TikTokDraftFragmentDoc}
${AwsstsCredentialFragmentDoc}`;
export class UpdateDraftMutationRequest implements MutationRequest<UpdateDraftMutation, UpdateDraftMutationVariables> {
        options: MutationOptions<UpdateDraftMutation, UpdateDraftMutationVariables>
        constructor(variables: UpdateDraftMutationVariables) {
          this.options = {
            mutation: UpdateDraftDocument,
            variables
          };
        }
}
export const UpdateEmailDocument = gql`
    mutation UpdateEmail($email: String!) {
  updateEmail(email: $email)
}
    `;
export class UpdateEmailMutationRequest implements MutationRequest<UpdateEmailMutation, UpdateEmailMutationVariables> {
        options: MutationOptions<UpdateEmailMutation, UpdateEmailMutationVariables>
        constructor(variables: UpdateEmailMutationVariables) {
          this.options = {
            mutation: UpdateEmailDocument,
            variables
          };
        }
}
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($currentPassword: String!, $password: String!, $passwordConfirmation: String!) {
  updatePassword(currentPassword: $currentPassword, password: $password, passwordConfirmation: $passwordConfirmation)
}
    `;
export class UpdatePasswordMutationRequest implements MutationRequest<UpdatePasswordMutation, UpdatePasswordMutationVariables> {
        options: MutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>
        constructor(variables: UpdatePasswordMutationVariables) {
          this.options = {
            mutation: UpdatePasswordDocument,
            variables
          };
        }
}
export const UpdatePayeeDocument = gql`
    mutation UpdatePayee($lastName: String!, $firstName: String!, $bankName: String!, $branchName: String!, $type: BankAccountType!, $number: String!, $accountHolder: String!, $hasWithholdingTax: Boolean!) {
  createPayee(lastName: $lastName, firstName: $firstName, bankName: $bankName, branchName: $branchName, type: $type, number: $number, accountHolder: $accountHolder, hasWithholdingTax: $hasWithholdingTax) {
    ...PayeeFragment
  }
}
    ${PayeeFragmentDoc}`;
export class UpdatePayeeMutationRequest implements MutationRequest<UpdatePayeeMutation, UpdatePayeeMutationVariables> {
        options: MutationOptions<UpdatePayeeMutation, UpdatePayeeMutationVariables>
        constructor(variables: UpdatePayeeMutationVariables) {
          this.options = {
            mutation: UpdatePayeeDocument,
            variables
          };
        }
}
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($influencerName: String!, $sex: Sex, $birthday: Date) {
  updateAccount(influencerName: $influencerName, sex: $sex, birthday: $birthday)
}
    `;
export class UpdateProfileMutationRequest implements MutationRequest<UpdateProfileMutation, UpdateProfileMutationVariables> {
        options: MutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>
        constructor(variables: UpdateProfileMutationVariables) {
          this.options = {
            mutation: UpdateProfileDocument,
            variables
          };
        }
}
export const UpdateShippingAddressDocument = gql`
    mutation UpdateShippingAddress($lastName: String!, $firstName: String!, $lastNameKana: String!, $firstNameKana: String!, $zipcode: String!, $prefectureName: String!, $cityName: String!, $address: String!, $building: String, $phoneNumber: String) {
  saveShippingAddress(lastName: $lastName, firstName: $firstName, lastNameKana: $lastNameKana, firstNameKana: $firstNameKana, zipcode: $zipcode, prefectureName: $prefectureName, cityName: $cityName, address: $address, building: $building, phoneNumber: $phoneNumber) {
    ...ShippingAddressFragment
  }
}
    ${ShippingAddressFragmentDoc}`;
export class UpdateShippingAddressMutationRequest implements MutationRequest<UpdateShippingAddressMutation, UpdateShippingAddressMutationVariables> {
        options: MutationOptions<UpdateShippingAddressMutation, UpdateShippingAddressMutationVariables>
        constructor(variables: UpdateShippingAddressMutationVariables) {
          this.options = {
            mutation: UpdateShippingAddressDocument,
            variables
          };
        }
}
export const AccountActivationDocument = gql`
    query AccountActivation {
  me {
    ...AccountFragment
  }
  accountActivation: instagramAccountActivationDetail {
    id
    postCount
    postCountBorder
    followerCount
    followerCountBorder
    status
    username
    identificationCode
    canRequestReview
  }
}
    ${AccountFragmentDoc}`;
export class AccountActivationQueryRequest implements QueryRequest<AccountActivationQuery, AccountActivationQueryVariables> {
        _result?: AccountActivationQuery
        options: QueryOptions<AccountActivationQueryVariables>
        constructor(variables: AccountActivationQueryVariables) {
          this.options = {
            query: AccountActivationDocument,
            variables
          };
        }
}
export const AccountDocument = gql`
    query Account {
  me {
    ...AccountFragment
  }
}
    ${AccountFragmentDoc}`;
export class AccountQueryRequest implements QueryRequest<AccountQuery, AccountQueryVariables> {
        _result?: AccountQuery
        options: QueryOptions<AccountQueryVariables>
        constructor(variables: AccountQueryVariables) {
          this.options = {
            query: AccountDocument,
            variables
          };
        }
}
export const AnnouncementDocument = gql`
    query Announcement($id: ID!) {
  announcementDetail(id: $id) {
    ...AnnouncementFragment
  }
}
    ${AnnouncementFragmentDoc}`;
export class AnnouncementQueryRequest implements QueryRequest<AnnouncementQuery, AnnouncementQueryVariables> {
        _result?: AnnouncementQuery
        options: QueryOptions<AnnouncementQueryVariables>
        constructor(variables: AnnouncementQueryVariables) {
          this.options = {
            query: AnnouncementDocument,
            variables
          };
        }
}
export const BillingDocument = gql`
    query Billing($id: ID!) {
  billingDetail(id: $id) {
    ...BillingFragment
  }
}
    ${BillingFragmentDoc}`;
export class BillingQueryRequest implements QueryRequest<BillingQuery, BillingQueryVariables> {
        _result?: BillingQuery
        options: QueryOptions<BillingQueryVariables>
        constructor(variables: BillingQueryVariables) {
          this.options = {
            query: BillingDocument,
            variables
          };
        }
}
export const CheckInstagramPostDocument = gql`
    mutation CheckInstagramPost($username: String!) {
  checkInstagramIdentificationCode(username: $username)
}
    `;
export class CheckInstagramPostMutationRequest implements MutationRequest<CheckInstagramPostMutation, CheckInstagramPostMutationVariables> {
        options: MutationOptions<CheckInstagramPostMutation, CheckInstagramPostMutationVariables>
        constructor(variables: CheckInstagramPostMutationVariables) {
          this.options = {
            mutation: CheckInstagramPostDocument,
            variables
          };
        }
}
export const ContactReplyDocument = gql`
    query ContactReply($id: ID!) {
  contactReplyDetail(id: $id) {
    title
    body
    contact {
      content
    }
    createdAt
  }
}
    `;
export class ContactReplyQueryRequest implements QueryRequest<ContactReplyQuery, ContactReplyQueryVariables> {
        _result?: ContactReplyQuery
        options: QueryOptions<ContactReplyQueryVariables>
        constructor(variables: ContactReplyQueryVariables) {
          this.options = {
            query: ContactReplyDocument,
            variables
          };
        }
}
export const Covid19ProjectDocument = gql`
    query Covid19Project {
  Covid19Project1: influencerEntryProjectDetail(id: 1410) {
    ...InfluencerEntryProjectFragment
  }
  Covid19Project2: influencerEntryProjectDetail(id: 1409) {
    ...InfluencerEntryProjectFragment
  }
}
    ${InfluencerEntryProjectFragmentDoc}`;
export class Covid19ProjectQueryRequest implements QueryRequest<Covid19ProjectQuery, Covid19ProjectQueryVariables> {
        _result?: Covid19ProjectQuery
        options: QueryOptions<Covid19ProjectQueryVariables>
        constructor(variables: Covid19ProjectQueryVariables) {
          this.options = {
            query: Covid19ProjectDocument,
            variables
          };
        }
}
export const DeleteAccountDocument = gql`
    query DeleteAccount {
  deleteAccountDetail {
    reason
    requestedAt
  }
}
    `;
export class DeleteAccountQueryRequest implements QueryRequest<DeleteAccountQuery, DeleteAccountQueryVariables> {
        _result?: DeleteAccountQuery
        options: QueryOptions<DeleteAccountQueryVariables>
        constructor(variables: DeleteAccountQueryVariables) {
          this.options = {
            query: DeleteAccountDocument,
            variables
          };
        }
}
export const DirectMessageDocument = gql`
    query DirectMessage($id: ID!) {
  directMessageDetail(id: $id) {
    id
    title
    body
    createdAt
  }
}
    `;
export class DirectMessageQueryRequest implements QueryRequest<DirectMessageQuery, DirectMessageQueryVariables> {
        _result?: DirectMessageQuery
        options: QueryOptions<DirectMessageQueryVariables>
        constructor(variables: DirectMessageQueryVariables) {
          this.options = {
            query: DirectMessageDocument,
            variables
          };
        }
}
export const DraftDocument = gql`
    query Draft($progressId: ID!) {
  draftDetail: projectProgressDetail(id: $progressId) {
    id
    contractedProject {
      ... on OfferProject {
        projectType
        ...OfferProjectFragment
      }
      ... on EntryProject {
        projectType
        ...EntryProjectFragment
      }
    }
    draft {
      ...InstagramDraftFragment
      ...TwitterDraftFragment
      ...YouTubeDraftFragment
      ...TikTokDraftFragment
    }
  }
}
    ${OfferProjectFragmentDoc}
${EntryProjectFragmentDoc}
${InstagramDraftFragmentDoc}
${TwitterDraftFragmentDoc}
${YouTubeDraftFragmentDoc}
${TikTokDraftFragmentDoc}`;
export class DraftQueryRequest implements QueryRequest<DraftQuery, DraftQueryVariables> {
        _result?: DraftQuery
        options: QueryOptions<DraftQueryVariables>
        constructor(variables: DraftQueryVariables) {
          this.options = {
            query: DraftDocument,
            variables
          };
        }
}
export const EnqueteDocument = gql`
    query Enquete($id: ID!) {
  enqueteDetail(id: $id) {
    ...EnqueteFragment
  }
}
    ${EnqueteFragmentDoc}`;
export class EnqueteQueryRequest implements QueryRequest<EnqueteQuery, EnqueteQueryVariables> {
        _result?: EnqueteQuery
        options: QueryOptions<EnqueteQueryVariables>
        constructor(variables: EnqueteQueryVariables) {
          this.options = {
            query: EnqueteDocument,
            variables
          };
        }
}
export const EntryProjectChecklistDocument = gql`
    query EntryProjectChecklist($id: ID!) {
  influencerEntryProjectDetail: influencerEntryProjectDetail(id: $id) {
    id
    entryProject {
      id
      project {
        id
        postPeriodEndAt
      }
      price
    }
    enquetes {
      ...EnqueteFragment
    }
  }
  payee: payee {
    id
    bankAccount {
      bankName
      branchName
    }
  }
  shippingAddress: shippingAddress {
    id
    prefectureName
    cityName
    address
    building
  }
}
    ${EnqueteFragmentDoc}`;
export class EntryProjectChecklistQueryRequest implements QueryRequest<EntryProjectChecklistQuery, EntryProjectChecklistQueryVariables> {
        _result?: EntryProjectChecklistQuery
        options: QueryOptions<EntryProjectChecklistQueryVariables>
        constructor(variables: EntryProjectChecklistQueryVariables) {
          this.options = {
            query: EntryProjectChecklistDocument,
            variables
          };
        }
}
export const EntryDocument = gql`
    query Entry($id: ID!) {
  entryDetail(id: $id) {
    ...EntryFragment
  }
}
    ${EntryFragmentDoc}`;
export class EntryQueryRequest implements QueryRequest<EntryQuery, EntryQueryVariables> {
        _result?: EntryQuery
        options: QueryOptions<EntryQueryVariables>
        constructor(variables: EntryQueryVariables) {
          this.options = {
            query: EntryDocument,
            variables
          };
        }
}
export const FaqDocument = gql`
    query Faq($id: ID!) {
  faqDetail(id: $id) {
    ...FaqFragment
  }
}
    ${FaqFragmentDoc}`;
export class FaqQueryRequest implements QueryRequest<FaqQuery, FaqQueryVariables> {
        _result?: FaqQuery
        options: QueryOptions<FaqQueryVariables>
        constructor(variables: FaqQueryVariables) {
          this.options = {
            query: FaqDocument,
            variables
          };
        }
}
export const ListBillingsDocument = gql`
    query ListBillings($status: BillingStatus!, $page: Int!) {
  billingList(billingStatus: $status, perPage: 10, page: $page) {
    edges {
      ...BillingEdgeFragment
    }
    paginatorInfo {
      ...PaginatorInfoFragment
    }
  }
}
    ${BillingEdgeFragmentDoc}
${PaginatorInfoFragmentDoc}`;
export class ListBillingsQueryRequest implements QueryRequest<ListBillingsQuery, ListBillingsQueryVariables> {
        _result?: ListBillingsQuery
        options: QueryOptions<ListBillingsQueryVariables>
        constructor(variables: ListBillingsQueryVariables) {
          this.options = {
            query: ListBillingsDocument,
            variables
          };
        }
}
export const ListFaqCategoryDocument = gql`
    query ListFaqCategory {
  faqCategoryList {
    ...FaqCategoryFragment
  }
}
    ${FaqCategoryFragmentDoc}`;
export class ListFaqCategoryQueryRequest implements QueryRequest<ListFaqCategoryQuery, ListFaqCategoryQueryVariables> {
        _result?: ListFaqCategoryQuery
        options: QueryOptions<ListFaqCategoryQueryVariables>
        constructor(variables: ListFaqCategoryQueryVariables) {
          this.options = {
            query: ListFaqCategoryDocument,
            variables
          };
        }
}
export const ListFaqDocument = gql`
    query ListFaq($categoryId: ID) {
  faqList(categoryId: $categoryId) {
    ...FaqFragment
  }
}
    ${FaqFragmentDoc}`;
export class ListFaqQueryRequest implements QueryRequest<ListFaqQuery, ListFaqQueryVariables> {
        _result?: ListFaqQuery
        options: QueryOptions<ListFaqQueryVariables>
        constructor(variables: ListFaqQueryVariables) {
          this.options = {
            query: ListFaqDocument,
            variables
          };
        }
}
export const ListNotificationsDocument = gql`
    query ListNotifications($page: Int!) {
  unreadCount: unreadNotificationCount
  notificationList(perPage: 20, page: $page) {
    edges {
      ...NotificationEdgeFragment
    }
    paginatorInfo {
      ...PaginatorInfoFragment
    }
  }
}
    ${NotificationEdgeFragmentDoc}
${PaginatorInfoFragmentDoc}`;
export class ListNotificationsQueryRequest implements QueryRequest<ListNotificationsQuery, ListNotificationsQueryVariables> {
        _result?: ListNotificationsQuery
        options: QueryOptions<ListNotificationsQueryVariables>
        constructor(variables: ListNotificationsQueryVariables) {
          this.options = {
            query: ListNotificationsDocument,
            variables
          };
        }
}
export const IchakuLpDocument = gql`
    query ichakuLp {
  project1: influencerEntryProjectDetail(id: 2133) {
    ...InfluencerEntryProjectFragment
  }
  project2: influencerEntryProjectDetail(id: 2135) {
    ...InfluencerEntryProjectFragment
  }
}
    ${InfluencerEntryProjectFragmentDoc}`;
export class IchakuLpQueryRequest implements QueryRequest<IchakuLpQuery, IchakuLpQueryVariables> {
        _result?: IchakuLpQuery
        options: QueryOptions<IchakuLpQueryVariables>
        constructor(variables: IchakuLpQueryVariables) {
          this.options = {
            query: IchakuLpDocument,
            variables
          };
        }
}
export const LpProjectDocument = gql`
    query LpProject($slug: String!) {
  lpProjectDetail(slug: $slug) {
    ...LpProjectFragment
  }
}
    ${LpProjectFragmentDoc}`;
export class LpProjectQueryRequest implements QueryRequest<LpProjectQuery, LpProjectQueryVariables> {
        _result?: LpProjectQuery
        options: QueryOptions<LpProjectQueryVariables>
        constructor(variables: LpProjectQueryVariables) {
          this.options = {
            query: LpProjectDocument,
            variables
          };
        }
}
export const ListLpProjectDocument = gql`
    query ListLpProject($mediaType: ProjectMediaType) {
  lpProjectList(mediaType: $mediaType) {
    ...LpProjectFragment
  }
}
    ${LpProjectFragmentDoc}`;
export class ListLpProjectQueryRequest implements QueryRequest<ListLpProjectQuery, ListLpProjectQueryVariables> {
        _result?: ListLpProjectQuery
        options: QueryOptions<ListLpProjectQueryVariables>
        constructor(variables: ListLpProjectQueryVariables) {
          this.options = {
            query: ListLpProjectDocument,
            variables
          };
        }
}
export const SakamaLpDocument = gql`
    query sakamaLp {
  searchProjectList(searchProjectConditionGroupInput: {conjunction: AND, searchProjectConditionInput: [{projectType: ENTRY, campaignCode: "SAKAMA"}]}, perPage: 2, page: 1) {
    paginatorInfo {
      ...PaginatorInfoFragment
    }
    edges {
      cursor
      node {
        ...InfluencerEntryProjectFragment
        ...OfferProjectFragment
      }
    }
  }
}
    ${PaginatorInfoFragmentDoc}
${InfluencerEntryProjectFragmentDoc}
${OfferProjectFragmentDoc}`;
export class SakamaLpQueryRequest implements QueryRequest<SakamaLpQuery, SakamaLpQueryVariables> {
        _result?: SakamaLpQuery
        options: QueryOptions<SakamaLpQueryVariables>
        constructor(variables: SakamaLpQueryVariables) {
          this.options = {
            query: SakamaLpDocument,
            variables
          };
        }
}
export const MeDocument = gql`
    query Me {
  me {
    ...AccountFragment
  }
  unreadNotificationCount
  shippingAddress {
    ...ShippingAddressFragment
  }
  payee {
    ...PayeeFragment
  }
}
    ${AccountFragmentDoc}
${ShippingAddressFragmentDoc}
${PayeeFragmentDoc}`;
export class MeQueryRequest implements QueryRequest<MeQuery, MeQueryVariables> {
        _result?: MeQuery
        options: QueryOptions<MeQueryVariables>
        constructor(variables: MeQueryVariables) {
          this.options = {
            query: MeDocument,
            variables
          };
        }
}
export const MypageDocument = gql`
    query Mypage {
  me {
    ...AccountFragment
  }
}
    ${AccountFragmentDoc}`;
export class MypageQueryRequest implements QueryRequest<MypageQuery, MypageQueryVariables> {
        _result?: MypageQuery
        options: QueryOptions<MypageQueryVariables>
        constructor(variables: MypageQueryVariables) {
          this.options = {
            query: MypageDocument,
            variables
          };
        }
}
export const NavigationTabListDocument = gql`
    query NavigationTabList {
  navigationTabList {
    id
    title
    searchProjectConditionGroup {
      conjunction
      searchProjectCondition {
        field
        value
      }
    }
  }
}
    `;
export class NavigationTabListQueryRequest implements QueryRequest<NavigationTabListQuery, NavigationTabListQueryVariables> {
        _result?: NavigationTabListQuery
        options: QueryOptions<NavigationTabListQueryVariables>
        constructor(variables: NavigationTabListQueryVariables) {
          this.options = {
            query: NavigationTabListDocument,
            variables
          };
        }
}
export const OfferProjectChecklistDocument = gql`
    query OfferProjectChecklist($id: ID!) {
  offerProjectDetail: offerProjectDetail(id: $id) {
    id
    price
    project {
      id
      postPeriodEndAt
    }
  }
  payee: payee {
    id
    bankAccount {
      bankName
      branchName
    }
  }
  shippingAddress: shippingAddress {
    id
    prefectureName
    cityName
    address
    building
  }
}
    `;
export class OfferProjectChecklistQueryRequest implements QueryRequest<OfferProjectChecklistQuery, OfferProjectChecklistQueryVariables> {
        _result?: OfferProjectChecklistQuery
        options: QueryOptions<OfferProjectChecklistQueryVariables>
        constructor(variables: OfferProjectChecklistQueryVariables) {
          this.options = {
            query: OfferProjectChecklistDocument,
            variables
          };
        }
}
export const OfferProjectTotalDocument = gql`
    query OfferProjectTotal($perPage: Int!, $page: Int!) {
  offerProjectList(perPage: $perPage, page: $page) {
    paginatorInfo {
      total
    }
  }
}
    `;
export class OfferProjectTotalQueryRequest implements QueryRequest<OfferProjectTotalQuery, OfferProjectTotalQueryVariables> {
        _result?: OfferProjectTotalQuery
        options: QueryOptions<OfferProjectTotalQueryVariables>
        constructor(variables: OfferProjectTotalQueryVariables) {
          this.options = {
            query: OfferProjectTotalDocument,
            variables
          };
        }
}
export const PayeeDocument = gql`
    query Payee {
  payee {
    ...PayeeFragment
  }
}
    ${PayeeFragmentDoc}`;
export class PayeeQueryRequest implements QueryRequest<PayeeQuery, PayeeQueryVariables> {
        _result?: PayeeQuery
        options: QueryOptions<PayeeQueryVariables>
        constructor(variables: PayeeQueryVariables) {
          this.options = {
            query: PayeeDocument,
            variables
          };
        }
}
export const PaymentsDocument = gql`
    query Payments {
  unpaid: billingList(billingStatus: UNPAID, perPage: 10, page: 1) {
    edges {
      ...BillingEdgeFragment
    }
    paginatorInfo {
      ...PaginatorInfoFragment
    }
  }
  paid: billingList(billingStatus: COMPLETE, perPage: 10, page: 1) {
    edges {
      ...BillingEdgeFragment
    }
    paginatorInfo {
      ...PaginatorInfoFragment
    }
  }
}
    ${BillingEdgeFragmentDoc}
${PaginatorInfoFragmentDoc}`;
export class PaymentsQueryRequest implements QueryRequest<PaymentsQuery, PaymentsQueryVariables> {
        _result?: PaymentsQuery
        options: QueryOptions<PaymentsQueryVariables>
        constructor(variables: PaymentsQueryVariables) {
          this.options = {
            query: PaymentsDocument,
            variables
          };
        }
}
export const ListEntryDocument = gql`
    query ListEntry($page: Int!) {
  entryList(page: $page, perPage: 10) {
    edges {
      cursor
      node {
        ...EntryFragment
      }
    }
    paginatorInfo {
      ...PaginatorInfoFragment
    }
  }
}
    ${EntryFragmentDoc}
${PaginatorInfoFragmentDoc}`;
export class ListEntryQueryRequest implements QueryRequest<ListEntryQuery, ListEntryQueryVariables> {
        _result?: ListEntryQuery
        options: QueryOptions<ListEntryQueryVariables>
        constructor(variables: ListEntryQueryVariables) {
          this.options = {
            query: ListEntryDocument,
            variables
          };
        }
}
export const ListProgressAllDocument = gql`
    query ListProgressAll {
  offerProjectList(perPage: 1, page: 1) {
    paginatorInfo {
      ...PaginatorInfoFragment
    }
  }
  entry: entryList(page: 1, perPage: 10) {
    edges {
      cursor
      node {
        ...EntryFragment
      }
    }
    paginatorInfo {
      ...PaginatorInfoFragment
    }
  }
  incomplete: projectProgressList(projectProgressStatus: INCOMPLETE, page: 1, perPage: 10) {
    edges {
      ...ProjectProgressEdgeFragment
    }
    paginatorInfo {
      ...PaginatorInfoFragment
    }
  }
  complete: projectProgressList(projectProgressStatus: COMPLETE, page: 1, perPage: 10) {
    edges {
      ...ProjectProgressEdgeFragment
    }
    paginatorInfo {
      ...PaginatorInfoFragment
    }
  }
}
    ${PaginatorInfoFragmentDoc}
${EntryFragmentDoc}
${ProjectProgressEdgeFragmentDoc}`;
export class ListProgressAllQueryRequest implements QueryRequest<ListProgressAllQuery, ListProgressAllQueryVariables> {
        _result?: ListProgressAllQuery
        options: QueryOptions<ListProgressAllQueryVariables>
        constructor(variables: ListProgressAllQueryVariables) {
          this.options = {
            query: ListProgressAllDocument,
            variables
          };
        }
}
export const ListProgressDocument = gql`
    query ListProgress($status: ProjectProgressStatus!, $page: Int!) {
  projectProgressList(projectProgressStatus: $status, page: $page, perPage: 10) {
    edges {
      ...ProjectProgressEdgeFragment
    }
    paginatorInfo {
      ...PaginatorInfoFragment
    }
  }
}
    ${ProjectProgressEdgeFragmentDoc}
${PaginatorInfoFragmentDoc}`;
export class ListProgressQueryRequest implements QueryRequest<ListProgressQuery, ListProgressQueryVariables> {
        _result?: ListProgressQuery
        options: QueryOptions<ListProgressQueryVariables>
        constructor(variables: ListProgressQueryVariables) {
          this.options = {
            query: ListProgressDocument,
            variables
          };
        }
}
export const ProjectProgressDocument = gql`
    query ProjectProgress($id: ID!) {
  projectProgressDetail(id: $id) {
    ...ProjectProgressFragment
  }
}
    ${ProjectProgressFragmentDoc}`;
export class ProjectProgressQueryRequest implements QueryRequest<ProjectProgressQuery, ProjectProgressQueryVariables> {
        _result?: ProjectProgressQuery
        options: QueryOptions<ProjectProgressQueryVariables>
        constructor(variables: ProjectProgressQueryVariables) {
          this.options = {
            query: ProjectProgressDocument,
            variables
          };
        }
}
export const ProjectProgressDetailDocument = gql`
    query ProjectProgressDetail($id: ID!) {
  projectProgressDetail(id: $id) {
    ...ProjectProgressFragment
  }
}
    ${ProjectProgressFragmentDoc}`;
export class ProjectProgressDetailQueryRequest implements QueryRequest<ProjectProgressDetailQuery, ProjectProgressDetailQueryVariables> {
        _result?: ProjectProgressDetailQuery
        options: QueryOptions<ProjectProgressDetailQueryVariables>
        constructor(variables: ProjectProgressDetailQueryVariables) {
          this.options = {
            query: ProjectProgressDetailDocument,
            variables
          };
        }
}
export const InfluencerEntryProjectDetailDocument = gql`
    query InfluencerEntryProjectDetail($id: ID!) {
  influencerEntryProjectDetail(id: $id) {
    ...InfluencerEntryProjectFragment
  }
}
    ${InfluencerEntryProjectFragmentDoc}`;
export class InfluencerEntryProjectDetailQueryRequest implements QueryRequest<InfluencerEntryProjectDetailQuery, InfluencerEntryProjectDetailQueryVariables> {
        _result?: InfluencerEntryProjectDetailQuery
        options: QueryOptions<InfluencerEntryProjectDetailQueryVariables>
        constructor(variables: InfluencerEntryProjectDetailQueryVariables) {
          this.options = {
            query: InfluencerEntryProjectDetailDocument,
            variables
          };
        }
}
export const OfferNgReasonListDocument = gql`
    query OfferNgReasonList {
  offerNgReasonList {
    id
    content
  }
}
    `;
export class OfferNgReasonListQueryRequest implements QueryRequest<OfferNgReasonListQuery, OfferNgReasonListQueryVariables> {
        _result?: OfferNgReasonListQuery
        options: QueryOptions<OfferNgReasonListQueryVariables>
        constructor(variables: OfferNgReasonListQueryVariables) {
          this.options = {
            query: OfferNgReasonListDocument,
            variables
          };
        }
}
export const OfferProjectDetailDocument = gql`
    query OfferProjectDetail($id: ID!) {
  offerProjectDetail(id: $id) {
    ...OfferProjectFragment
  }
}
    ${OfferProjectFragmentDoc}`;
export class OfferProjectDetailQueryRequest implements QueryRequest<OfferProjectDetailQuery, OfferProjectDetailQueryVariables> {
        _result?: OfferProjectDetailQuery
        options: QueryOptions<OfferProjectDetailQueryVariables>
        constructor(variables: OfferProjectDetailQueryVariables) {
          this.options = {
            query: OfferProjectDetailDocument,
            variables
          };
        }
}
export const OfferProjectsDocument = gql`
    query OfferProjects($perPage: Int!, $page: Int!) {
  offerProjectList(perPage: $perPage, page: $page) {
    paginatorInfo {
      ...PaginatorInfoFragment
    }
    edges {
      cursor
      node {
        ...OfferProjectFragment
      }
    }
  }
}
    ${PaginatorInfoFragmentDoc}
${OfferProjectFragmentDoc}`;
export class OfferProjectsQueryRequest implements QueryRequest<OfferProjectsQuery, OfferProjectsQueryVariables> {
        _result?: OfferProjectsQuery
        options: QueryOptions<OfferProjectsQueryVariables>
        constructor(variables: OfferProjectsQueryVariables) {
          this.options = {
            query: OfferProjectsDocument,
            variables
          };
        }
}
export const SearchProjectListDocument = gql`
    query SearchProjectList($searchProjectConditionGroupInput: [searchProjectConditionGroupInput!]!, $perPage: Int!, $page: Int!) {
  searchProjectList(searchProjectConditionGroupInput: $searchProjectConditionGroupInput, perPage: $perPage, page: $page) {
    paginatorInfo {
      ...PaginatorInfoFragment
    }
    edges {
      cursor
      node {
        ...InfluencerEntryProjectFragment
        ...OfferProjectFragment
      }
    }
  }
}
    ${PaginatorInfoFragmentDoc}
${InfluencerEntryProjectFragmentDoc}
${OfferProjectFragmentDoc}`;
export class SearchProjectListQueryRequest implements QueryRequest<SearchProjectListQuery, SearchProjectListQueryVariables> {
        _result?: SearchProjectListQuery
        options: QueryOptions<SearchProjectListQueryVariables>
        constructor(variables: SearchProjectListQueryVariables) {
          this.options = {
            query: SearchProjectListDocument,
            variables
          };
        }
}
export const SearchInstagramAccountDocument = gql`
    query SearchInstagramAccount($username: String!) {
  instagramAccountProfileDetail(username: $username) {
    username
    name
    imageUrl
  }
}
    `;
export class SearchInstagramAccountQueryRequest implements QueryRequest<SearchInstagramAccountQuery, SearchInstagramAccountQueryVariables> {
        _result?: SearchInstagramAccountQuery
        options: QueryOptions<SearchInstagramAccountQueryVariables>
        constructor(variables: SearchInstagramAccountQueryVariables) {
          this.options = {
            query: SearchInstagramAccountDocument,
            variables
          };
        }
}
export const ShippingAddressDocument = gql`
    query ShippingAddress {
  shippingAddress {
    id
    lastName
    firstName
    lastNameKana
    firstNameKana
    zipcode
    prefectureName
    cityName
    address
    building
    phoneNumber
  }
}
    `;
export class ShippingAddressQueryRequest implements QueryRequest<ShippingAddressQuery, ShippingAddressQueryVariables> {
        _result?: ShippingAddressQuery
        options: QueryOptions<ShippingAddressQueryVariables>
        constructor(variables: ShippingAddressQueryVariables) {
          this.options = {
            query: ShippingAddressDocument,
            variables
          };
        }
}
export const SystemSettingDocument = gql`
    query SystemSetting {
  systemSetting {
    socialMediaSetting {
      line {
        clientId
        redirectUri
      }
      apple {
        clientId
        redirectUri
      }
      instagram {
        clientId
        redirectUri
      }
      youtube {
        clientId
        redirectUri
      }
    }
  }
}
    `;
export class SystemSettingQueryRequest implements QueryRequest<SystemSettingQuery, SystemSettingQueryVariables> {
        _result?: SystemSettingQuery
        options: QueryOptions<SystemSettingQueryVariables>
        constructor(variables: SystemSettingQueryVariables) {
          this.options = {
            query: SystemSettingDocument,
            variables
          };
        }
}
export const TikTokAccountActivationDocument = gql`
    query TikTokAccountActivation {
  accountActivation: tikTokAccountActivationDetail {
    id
    postCount
    postCountBorder
    followerCount
    followerCountBorder
    authorizedStatus
    username
    canRequestReview
  }
}
    `;
export class TikTokAccountActivationQueryRequest implements QueryRequest<TikTokAccountActivationQuery, TikTokAccountActivationQueryVariables> {
        _result?: TikTokAccountActivationQuery
        options: QueryOptions<TikTokAccountActivationQueryVariables>
        constructor(variables: TikTokAccountActivationQueryVariables) {
          this.options = {
            query: TikTokAccountActivationDocument,
            variables
          };
        }
}
export const TopBannerListDocument = gql`
    query TopBannerList {
  topBannerList {
    href
    imageUrl
    isTargetBlank
  }
}
    `;
export class TopBannerListQueryRequest implements QueryRequest<TopBannerListQuery, TopBannerListQueryVariables> {
        _result?: TopBannerListQuery
        options: QueryOptions<TopBannerListQueryVariables>
        constructor(variables: TopBannerListQueryVariables) {
          this.options = {
            query: TopBannerListDocument,
            variables
          };
        }
}
export const TwitterAccountActivationDocument = gql`
    query TwitterAccountActivation {
  accountActivation: twitterAccountActivationDetail {
    id
    postCount
    postCountBorder
    followerCount
    followerCountBorder
    authorizedStatus
    username
    canRequestReview
  }
}
    `;
export class TwitterAccountActivationQueryRequest implements QueryRequest<TwitterAccountActivationQuery, TwitterAccountActivationQueryVariables> {
        _result?: TwitterAccountActivationQuery
        options: QueryOptions<TwitterAccountActivationQueryVariables>
        constructor(variables: TwitterAccountActivationQueryVariables) {
          this.options = {
            query: TwitterAccountActivationDocument,
            variables
          };
        }
}
export const TwitterAuthDocument = gql`
    query TwitterAuth {
  twitterAuth {
    oauthToken
  }
}
    `;
export class TwitterAuthQueryRequest implements QueryRequest<TwitterAuthQuery, TwitterAuthQueryVariables> {
        _result?: TwitterAuthQuery
        options: QueryOptions<TwitterAuthQueryVariables>
        constructor(variables: TwitterAuthQueryVariables) {
          this.options = {
            query: TwitterAuthDocument,
            variables
          };
        }
}
export const VerifyEmailDocument = gql`
    mutation VerifyEmail($token: String!) {
  verifyEmail(token: $token)
}
    `;
export class VerifyEmailMutationRequest implements MutationRequest<VerifyEmailMutation, VerifyEmailMutationVariables> {
        options: MutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>
        constructor(variables: VerifyEmailMutationVariables) {
          this.options = {
            mutation: VerifyEmailDocument,
            variables
          };
        }
}
export const YouTubeAccountActivationDocument = gql`
    query YouTubeAccountActivation {
  accountActivation: youtubeAccountActivationDetail {
    id
    postCount
    postCountBorder
    followerCount
    followerCountBorder
    authorizedStatus
    username
    canRequestReview
  }
}
    `;
export class YouTubeAccountActivationQueryRequest implements QueryRequest<YouTubeAccountActivationQuery, YouTubeAccountActivationQueryVariables> {
        _result?: YouTubeAccountActivationQuery
        options: QueryOptions<YouTubeAccountActivationQueryVariables>
        constructor(variables: YouTubeAccountActivationQueryVariables) {
          this.options = {
            query: YouTubeAccountActivationDocument,
            variables
          };
        }
}
export const ZipCodeDetailDocument = gql`
    query ZipCodeDetail($zipcode: String!) {
  zipCodeDetail(zipcode: $zipcode) {
    id
    zipcode
    prefectureName
    cityName
    address
  }
}
    `;
export class ZipCodeDetailQueryRequest implements QueryRequest<ZipCodeDetailQuery, ZipCodeDetailQueryVariables> {
        _result?: ZipCodeDetailQuery
        options: QueryOptions<ZipCodeDetailQueryVariables>
        constructor(variables: ZipCodeDetailQueryVariables) {
          this.options = {
            query: ZipCodeDetailDocument,
            variables
          };
        }
}
