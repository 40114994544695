






































import Vue from "vue";
import Icon from "~/components/ui/icon.vue";

export default Vue.extend({
  components: {
    Icon
  },
  computed: {
    state() {
      return this.$accessor.appBanner;
    },
    isOpen() {
      return this.$accessor.appBanner.isOpen;
    }
  },
  methods: {
    closeBanner() {
      this.$accessor.appBanner.close();
    }
  }
});
