export default {
  label: {
    is_activated: "連携済み",
    is_insight_judgement: "審査中",
    is_updating: "アカウント変更申請済み",
    not_activated: "基準値に達しておりません",
    authentication: "本人確認中",
    instagram_user_id: "InstagramユーザーID",
    twitter_user_id: "TwitterユーザーID",
    youtube_user_id: "YouTubeユーザーID",
    tiktok_user_id: "TikTokユーザーID",
    follower_count: "フォロワー数",
    changed_id_user_link: "ユーザーIDを変更された方はこちら"
  },
  button: {
    update: "アカウント情報の更新",
    youtube_update: "チャンネル登録者数を更新する"
  },
  message: {
    instagram_judge_note: "Instagramの審査に関して",
    twitter_judge_note: "Twitterの審査に関して",
    youtube_judge_note: "YouTubeの審査に関して",
    tiktok_judge_note: "TikTokの審査に関して",
    review_note: "フォロワー数、投稿数の反映に関して",
    youtube_review_note: "チャンネル登録者数、投稿数の反映に関して",
    requirement: `
      SPADではInstagram案件の実施に最低でも<br/>
        ・フォロワー数 300以上<br/>
        ・投稿数 40投稿以上<br/>
      を必要としております。<br/>
      基準値に達した方は、ボタンよりご報告お願いします`,
    twitter_requirement: `
      SPADではTwitter案件の実施に最低でも<br/>
        ・フォロワー数 300以上<br/>
        ・投稿数 40投稿以上<br/>
      を必要としております。<br/>
      基準値に達した方は、ボタンよりご報告お願いします`,
    youtube_requirement: `
      SPADではYouTube案件の実施に最低でも<br/>
        ・チャンネル登録者数100人以上<br/>
        ・投稿数3件以上<br/>
      のいずれかを必要としております。<br/>
      基準値に達した方は、ボタンよりご報告お願いします`,
    tiktok_requirement: `
      SPADではTwitter案件の実施に最低でも<br/>
        ・フォロワー数 300以上<br/>
      を必要としております。<br/>
      基準値に達した方は、ボタンよりご報告お願いします`,
    review_description:
      "ユーザー数拡大につき、フォロワー数の反映に時間がかかっております。上のボタンよりご報告をお願いします。ボタンを押しても反映されない場合、問い合わせよりご連絡をお願いいたします。",
    youtube_review_description:
      "ユーザー数拡大につき、チャンネル登録者数の反映に時間がかかっております。上のボタンよりご報告をお願いします。ボタンを押しても反映されない場合、問い合わせよりご連絡をお願いいたします。",
    access_denied: "Instagramの連携に失敗しました。何度も失敗する場合は、お問い合わせください。",
    twitter_access_denied: "Twitterの連携に失敗しました。何度も失敗する場合は、お問い合わせください。",
    youtube_access_denied: "YouTubeの連携に失敗しました。何度も失敗する場合は、お問い合わせください。",
    instagram_authorization_required: "Instagramの認証後、連携できます",
    press_update_data_button_if_change_id: "アカウントIDの変更、フォロワー数の更新をしたい場合は「アカウント情報の更新」ボタンを押してください"
  },
  link: {
    change_account: "アカウントを切り替えたい方はこちら"
  }
};
