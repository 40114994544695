

































import Vue from "vue";
import ErrorDialog from "../components/the/error-dialog.vue";
import Toast from "../components/the/toast.vue";
import NavigationBar from "~/components/the/app-navigation-bar.vue";
import NavigationBarButton from "~/components/ui/buttons/navigation-bar-button.vue";
import AppLoading from "~/components/the/app-loading.vue";
import AppBanner from "~/components/the/app-banner.vue";

export default Vue.extend({
  components: {
    NavigationBar,
    NavigationBarButton,
    AppLoading,
    Toast,
    ErrorDialog,
    AppBanner
  },
  computed: {
    hasCredential(): boolean {
      return this.$accessor.account.hasCredential;
    },
    error(): Error | null {
      return this.$accessor.error.error;
    },
    isIosInApp(): boolean {
      return this.$accessor.spadApp.isIosInApp;
    }
  },
  watch: {
    hasCredential(value) {
      if (!value) {
        // if remove credential
        this.$router.replace("/login");
      }

      // set DeviceToken from andoid native app
      if (this.$accessor.spadApp.isAndroidInApp) {
        this.$accessor.spadApp.setAndroidDeviceToken();
      }

      // replace url
      const query = this.$router.currentRoute.query;
      const path = (query && (query.from as string)) || "/";
      this.$router.replace(path);
    }
  }
});
