export function isHTMLElement(el: any): el is HTMLElement {
  return el instanceof HTMLElement;
}

export function addClass(el: HTMLElement, className: string): void {
  el.classList.add(className);
}

export function hasClass(el: HTMLElement, className: string): boolean {
  return el.classList.contains(className);
}

export function removeClass(el: HTMLElement, className: string): void {
  el.classList.remove(className);
}
