export default {
  label: {
    post_url: "あなたが投稿したURL",
    note: "備考",
    copy_instagram_url: "Instagramの投稿URLコピー方法",
    copy_twitter_url: "Twitterの投稿URLコピー方法",
    copy_youtube_url: "YouTubeの投稿URLコピー方法",
    copy_tiktok_url: "TikTokの投稿URLコピー方法",
    post_image: "投稿画像",
    caption: "キャプション",
    revised_caption: "修正キャプション",
    reject_explanation: "棄却内容の説明"
  },
  title: {
    post_report: "投稿報告",
    complete_report: "投稿報告の完了",
    report_draft: "下書きを報告しました"
  },
  message: {
    what_is_post_report: "投稿報告とは？",
    report_post_url:
      "あなたがこの案件でSNSに投稿した画像などのURLをアプリから報告します。投稿のURLで報告をしてください。",
    report_post: "投稿報告する",
    complete_reporting:
      "投稿の報告が完了しました。確認いたしますのでしばらくお待ち下さい。",
    upload_video: "動画像をアップロード中です",
    update_draft: "下書きを更新しています",
    wait_for_check: "確認までお待ちください。",
    not_posting_period: "まだ投稿期間内になっていません。"
  },
  placeholder: {
    write_post_url: "投稿したURLを記入してください"
  },
  button: {
    copy_caption: "キャプションをコピーする",
    edit_draft_with_caption: "修正キャプションで下書きを編集する",
    edit_draft: "下書きを編集する",
    send_draft: "下書きを送る",
    copy_hashtag: "ハッシュタグのコピー",
    check_reject_reason: "棄却内容を確認する",
    check_draft: "下書きを確認する"
  }
};
