export default {
  button: {
    check_project: "支援案件をみる",
    check_other_projects: "すべての支援案件をみる"
  },
  label: {
    emergency_declaration: "「緊急事態宣言」",
    attempt: "SPADでの取り組み",
    another_support: "支援案件はこちら",
    support_covid19: "新型コロナウイルス 被害への支援"
  },
  message: {
    prevent_corona_virus: "新型コロナウイルスの感染拡大防止のため、全国に",
    declair: "が発令されました。",
    stop_activity:
      "外⾷産業に対する休業要請、休校による学校給食の停止、外出自粛や入国制限による観光客減少など様々な納品先の経済活動がストップしています。",
    how_to_support_first:
      "SNSを活用したインフルエンサーマーケティングを展開している弊社において、そうした事業者様の支援を出来ないかと考え",
    how_to_support_second:
      "対象事業者様にインフルエンサープロモーションを無償で提供",
    how_to_support_third: "させていただくことにいたしました。",
    appreciate_your_cooperation: "ご協力をお願いいたします",
    interest_support:
      " TOPページより、コロナ支援タブを増設しております。この支援に興味ございましたら案件にエントリーいただきご支援に協力いただければと思います。",
    pr_product: `
    インフルエンサーの皆様には、<br>
    事業者様の商品をお届けして、<br>
    商品PRをしていただきたいです
    `
  },
  placeholder: {
    password: "6文字以上の半角英数字",
    content: "内容を記入してください"
  },
  title: {
    introduction: "はじめに",
    how_to_support: "SPADでできる支援"
  },

  help: {
    project_type: "案件の種類について",
    spad: "SPADの使い方"
  }
};
