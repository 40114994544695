
export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: "INTERFACE",
        name: "SocialMediaAccountActivation",
        possibleTypes: [
          {
            name: "InstagramAccountActivation"
          },
          {
            name: "TikTokAccountActivation"
          },
          {
            name: "TwitterAccountActivation"
          },
          {
            name: "YouTubeAccountActivation"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "PayeeLike",
        possibleTypes: [
          {
            name: "PayeeSnapshot"
          },
          {
            name: "Payee"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "Connection",
        possibleTypes: [
          {
            name: "BillingListConnection"
          },
          {
            name: "EntryListConnection"
          },
          {
            name: "NotificationListConnection"
          },
          {
            name: "OfferProjectListConnection"
          },
          {
            name: "ProjectProgressListConnection"
          },
          {
            name: "InfluencerEntryProjectListConnection"
          },
          {
            name: "ProjectListConnection"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "Edge",
        possibleTypes: [
          {
            name: "BillingEdge"
          },
          {
            name: "EntryEdge"
          },
          {
            name: "NotificationEdge"
          },
          {
            name: "OfferProjectEdge"
          },
          {
            name: "ProjectProgressEdge"
          },
          {
            name: "InfluencerEntryProjectEdge"
          },
          {
            name: "ProjectEdge"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "Draft",
        possibleTypes: [
          {
            name: "InstagramDraft"
          },
          {
            name: "TikTokDraft"
          },
          {
            name: "TwitterDraft"
          },
          {
            name: "YouTubeDraft"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "DraftAttachment",
        possibleTypes: [
          {
            name: "InstagramDraftAttachment"
          },
          {
            name: "TikTokDraftAttachment"
          },
          {
            name: "TwitterDraftAttachment"
          },
          {
            name: "YouTubeDraftAttachment"
          }
        ]
      },
      {
        kind: "UNION",
        name: "EnqueteQuestionNode",
        possibleTypes: [
          {
            name: "EnqueteQuestionText"
          },
          {
            name: "EnqueteQuestionTextArea"
          },
          {
            name: "EnqueteQuestionCheckbox"
          },
          {
            name: "EnqueteQuestionPulldown"
          },
          {
            name: "EnqueteQuestionRadio"
          },
          {
            name: "EnqueteQuestionRange"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "ContractableProject",
        possibleTypes: [
          {
            name: "EntryProject"
          },
          {
            name: "OfferProject"
          }
        ]
      },
      {
        kind: "UNION",
        name: "ContractedProject",
        possibleTypes: [
          {
            name: "EntryProject"
          },
          {
            name: "OfferProject"
          }
        ]
      },
      {
        kind: "UNION",
        name: "UnionProject",
        possibleTypes: [
          {
            name: "InfluencerEntryProject"
          },
          {
            name: "OfferProject"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "SocialMediaProvider",
        possibleTypes: [
          {
            name: "Line"
          },
          {
            name: "Apple"
          },
          {
            name: "Instagram"
          },
          {
            name: "YouTube"
          }
        ]
      }
    ]
  }
};
export default result;
