export default {
  message: {
    review_checklist: "以下の情報を設定してください",
    accept_agreement:
      "下のボタンを選択することで、{here}に同意したことになります。",
    end_of_next_month: "投稿月の翌月末"
  },
  label: {
    register_login: "ログイン情報の登録",
    check_email: "メールアドレスの確認",
    register_account: "お支払口座の登録",
    register_address: "発送先住所の登録",
    reciever: "受領者",
    posting_date: "投稿予定日",
    total_payment: "お支払金額",
    payment_deadline: "お支払期日",
    confirm: "この内容で確定する",
    edit: "編集する",
    accept_date: "受領日"
  },
  title: {
    project_summary: "案件の内容"
  },
  button: {
    confirm_to_entry: "この内容で確定する"
  }
};
