import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { resetState } from "~/store/utils";
import { ContactReply, ContactReplyQueryRequest } from "~/types/gen/api";

export type DirectMessageState = {
  reply: ContactReply | null;
};

export const state = (): DirectMessageState => ({
  reply: null
});

export const mutations = mutationTree(state, {
  receiveReply(state, payload: ContactReply) {
    state.reply = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async loadReply(context, payload: string): Promise<void> {
      this.$accessor.presentation.showLoading(null);

      try {
        const req = new ContactReplyQueryRequest({
          id: payload
        });
        const res = await this.$apiClient.query(req);

        if (res.contactReplyDetail) {
          context.commit("receiveReply", res.contactReplyDetail);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
