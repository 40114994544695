import fetch from "node-fetch";
import ApolloClient from "apollo-client";
import { Context } from "@nuxt/types";
import { HttpLink } from "apollo-link-http";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from "apollo-cache-inmemory";
import { ApolloLink, from } from "apollo-link";
import { APIClient } from "../network/api-client";
import introspectionQueryResultData from "../types/gen/introspection-result";

export default function(
  ctx: Context,
  inject: (key: string, value: any) => void
) {
  // Auth middleware
  const auth = new ApolloLink((operation, forward) => {
    const token = ctx.app.$accessor.account.token;
    const { isIosInApp, appVersion, deviceType } = ctx.app.$accessor.spadApp;
    const ua = process.server ? ctx.req.headers["user-agent"] : window.navigator.userAgent;
    if (isIosInApp && token) {
      operation.setContext({
        headers: {
          Authorization: `${token}`,
          app_ver: appVersion !== "" ? appVersion : "",
          device_type: deviceType !== "" ? deviceType : "",
          "User-Agent": ua
        }
      });
    } else if (token) {
      operation.setContext({
        headers: {
          Authorization: `${token}`,
          "User-Agent": ua
        }
      });
    } else {
      operation.setContext({
        headers: {
          "User-Agent": ua
        }
      });
    }
    return forward!(operation);
  });

  // Setup source
  const link = new HttpLink({
    uri: process.server ? process.env.API_URL : process.env.API_BROWSER_URL,
    fetch: (fetch as unknown) as WindowOrWorkerGlobalScope["fetch"]
  });

  // Setup fragment matcher for cache
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  });

  // Setup cache
  const cache = new InMemoryCache({
    fragmentMatcher
  });

  // Setup client
  const client = new ApolloClient({
    link: from([auth, link]),
    cache,
    connectToDevTools: process.env.NODE_ENV === "development"
  });

  const apiClient = new APIClient(client, ctx);

  // Enable to access $apollo from vue and vuex
  inject("apiClient", apiClient);
}
