export default {
  message: {
    copy_caption: "キャプションをコピーしました",
    upload_required: "再度、下書きの投稿をお願いします"
  },
  error: {
    assets_required: "動画、もしくは画像を一つ以上登録してください",
    upload_failed: "下書きの投稿に失敗しています"
  },
  button: {
    re_upload: "下書きを再投稿する"
  }
};
