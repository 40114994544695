export default {
  error: {
    login: "メールアドレス、またはパスワードが間違っています"
  },
  title: {
    app_for_influencer: `
      インフルエンサー向け<br>
      お仕事探しアプリ
    `
  },
  label: {
    login: "ログインする",
    register_account: "アカウント登録をする",
    forget_password: "パスワードをお忘れの方はこちら",
    first_time: "はじめての方",
    has_account: "すでにアカウントをお持ちの方はこちら"
  }
};
