export default {
  message: {
    no_payment_notice: "支払通知書はありません"
  },
  label: {
    new_payment_notice: "最新の支払通知書",
    past_payment_notice: "過去の支払通知書",
    all_payment_notice: "支払通知書一覧",
    settings: "設定",
    email_password: "メールアドレス・パスワード",
    email_edit: "メールアドレスの変更",
    email_register: "メールアドレスの登録",
    login_data: "ログイン情報の登録"
  },
  button: {
    see_all: "すべてを見る"
  }
};
