import { actionTree, mutationTree, getterTree } from "nuxt-typed-vuex";
import { initialListState } from "~/utils/list";
import {
  NotificationEdge,
  ListNotificationsQueryVariables,
  ListNotificationsQueryRequest
} from "~/types/gen/api";
import { ListState } from "~/types/utils";
import { APIError } from "~/network/api-error";
import { resetState } from "~/store/utils";

export type NotificationsState = ListState<NotificationEdge>;

export const state: () => NotificationsState = initialListState;

export const mutations = mutationTree(state, {
  requestNotificationList(state) {
    state.isLoading = true;
  },
  receiveNotificationList(state, payload) {
    if (payload.paginatorInfo.currentPage === 1) {
      state.items = payload.edges;
    } else {
      state.items = [...state.items, ...payload.edges];
    }
    state.isLoading = false;
    state.pagination = payload.paginatorInfo;
  },
  fail(state) {
    state.isLoading = false;
  },
  reset: resetState(state)
});

export const getters = getterTree(state, {
  notifications(state) {
    return state.items.map((v) => v.node!);
  },
  nextPage(state) {
    if (state.pagination.hasMorePages) {
      return state.pagination.currentPage + 1;
    }
    return undefined;
  }
});

export const actions = actionTree(
  {
    state,
    getters,
    mutations
  },
  {
    // Load Notifications
    async loadNotifications(
      context,
      payload: ListNotificationsQueryVariables
    ): Promise<void> {
      const isRefresh = payload.page === 1;

      if (isRefresh) {
        // Initial load or refresh
        this.$accessor.presentation.showLoading(null);
      } else {
        // Load more
        context.commit("requestNotificationList");
      }

      try {
        // Make request
        const req = new ListNotificationsQueryRequest({
          page: payload.page
        });
        // Exec request
        const res = await this.$apiClient.query(req);

        // Update unread count
        this.$accessor.account.receiveNotificationCount(res.unreadCount);

        if (res.notificationList) {
          context.commit("receiveNotificationList", res.notificationList);
        } else {
          throw new APIError(500, "Invalid Response");
        }
      } catch (e) {
        this.$accessor.error.showError(e);
        context.commit("fail", e);
      } finally {
        // Dismiss refreshing
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
