




















import Vue from "vue";
import Icon from "~/components/ui/icon.vue";
import { oneOf } from "~/utils/helpers";

export type Type = "primary" | "secondary" | "line";
export type Size = "small" | "medium";
export type IconLayout = "left" | "right";

export default Vue.extend({
  components: {
    Icon
  },
  props: {
    type: {
      type: String as () => Type,
      validator: oneOf(["primary", "secondary", "line"]),
      default: "primary"
    },
    size: {
      type: String as () => Size,
      validator: oneOf(["small", "medium"]),
      default: "medium"
    },
    iconLayout: {
      type: String as () => IconLayout,
      validator: oneOf(["left", "right"]),
      default: "left"
    },
    nativeType: {
      type: String,
      default: "button"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autoWidth: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: ""
    }
  },
  computed: {
    typeClassName(): string {
      return `-${this.type}`;
    },
    sizeClassName(): string {
      return `-${this.size}`;
    },
    iconLayoutClassName(): string {
      return `-${this.iconLayout}`;
    }
  },
  methods: {
    handleClick(): void {
      this.$emit("click");
    }
  }
});
