import { actionTree, mutationTree, getterTree } from "nuxt-typed-vuex";
import { resetState } from "~/store/utils";
import {
  OfferProjectChecklistQueryVariables,
  OfferProjectChecklistQueryRequest,
  OfferProject,
  Payee,
  ShippingAddress
} from "~/types/gen/api";

export type ProjectChecklistState = {
  projectDetail: OfferProject | null;
  payee: Payee | null;
  shippingAddress: ShippingAddress | null;
};

export const state = (): ProjectChecklistState => ({
  projectDetail: null,
  payee: null,
  shippingAddress: null
});

export const getters = getterTree(state, {
  postPeriodEndAt(state) {
    return (
      state.projectDetail &&
      state.projectDetail.project &&
      state.projectDetail.project.postPeriodEndAt
    );
  },
  price(state) {
    return state.projectDetail && state.projectDetail.price;
  }
});

export const mutations = mutationTree(state, {
  recieveProjectChecklist(state, payload) {
    state.projectDetail = payload.offerProjectDetail;
    state.payee = payload.payee;
    state.shippingAddress = payload.shippingAddress;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async init(
      ctx,
      payload: OfferProjectChecklistQueryVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new OfferProjectChecklistQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res) {
          ctx.commit("recieveProjectChecklist", res);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
