export default {
  message: {
    attention_insight:
      "フォロワー数、投稿数の反映には数日かかります。お急ぎの方は、マイページより問い合わせください。",
    almost_done: "認証基準まであと少し！",
    description:
      "アカウントの認証基準を満たせるよう、フォロワー数または投稿数をチェックしましょう",
    verify_influencer: `
      あなたはインフルエンサーとして<br>
      認証されました
    `,
    check_your_account: "あなたのアカウントで合っていますか？",
    ID_name_on_mypage: `インスタグラムマイページ上部に<br>記載されているIDの表示名です`,
    verify_instagram_account:
      "サービスの利用には、インスタグラムアカウント認証が必要です！",
    add_checked_hashtag: `確認用ハッシュタグを最近の<br>投稿に追加してください`,
    open_instagram: "インスタグラムを開く",
    add_hashtag: "ハッシュタグを追加しました",
    step: {
      edit_new_post: "1. あなたの最新の投稿を編集します",
      tap_icon: "2. 右上のアイコンをタップします",
      tap_edit: "3. 編集をタップします",
      tap_edit_again: "4. 編集をタップします",
      check_and_complete: "5. チェックボタンを押し、編集を完了します。",
      done:
        "6. 以上で完了です 「ハッシュタグを追加しました」ボタンを押し報告してください"
    }
  },
  label: {
    attention_insight: "フォロワー数、投稿数の反映に関して",
    follower_count: "フォロワー数",
    post_count: "投稿数",
    verifying_account: "アカウント認証状況",
    check_account: "アカウント確認",
    way_of_adding_hashtag: "ハッシュタグの追加方法",
    instagram_user_ID: "インスタグラムユーザーID",
    checked_hashtag: "確認用ハッシュタグ",
    copy: "コピー"
  },
  button: {
    find_project: "さっそく案件を探す",
    research: "再検索する",
    yes: "はい",
    username: "ユーザー名"
  },
  format: {
    person: "人",
    post: "投稿"
  }
};
