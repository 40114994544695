import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { FaqQueryRequest, Faq, FaqQuery } from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type Response = FaqQuery["faqDetail"];

export type FaqState = {
  faq: Faq | null;
};

export const state = (): FaqState => ({
  faq: null
});

export const mutations = mutationTree(state, {
  receiveFaq(state: FaqState, payload: Response) {
    state.faq = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async init(context, id): Promise<void> {
      this.$accessor.presentation.showLoading(null);

      try {
        const req = new FaqQueryRequest({ id });
        const res = await this.$apiClient.query(req);
        context.commit("receiveFaq", res.faqDetail);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
