












import Vue from "vue";
import Loading from "~/components/ui/loading/loading.vue";
import ErrorDialog from "~/components/the/error-dialog.vue";

/*
  このレイアウトファイルは、
  画面スクロールが発生しないページで、
  画面の高さを全体に表示したい場合に使用してください。
*/
export default Vue.extend({
  components: {
    Loading,
    ErrorDialog
  },
  computed: {
    isLoading(): boolean {
      return this.$accessor.presentation.isLoading;
    },
    error(): Error | null {
      return this.$accessor.error.error;
    }
  },
  head: {
    bodyAttrs: {
      class: "BodyWrapStyle"
    }
  }
});
