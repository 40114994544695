import Vue from "vue";
import VueI18n from "vue-i18n";
import { Context } from "@nuxt/types";

import ja from "./locales/ja/index";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "ja",
  fallbackLocale: "ja",
  messages: {
    ja
  }
});

export default (
  { app }: Context,
  inject: (key: string, value: any) => void
) => {
  app.i18n = i18n;
  inject("i18n", i18n);
};
