import { VNode } from "vue";
import { DirectiveBinding } from "vue/types/options";

export const resetScroll = {
  bind(el: HTMLElement, _: DirectiveBinding, vnode: VNode) {
    const context = vnode.context;

    if (!context || !context.$router) {
      return;
    }

    const handler = context.$router.afterEach(() => {
      el.scrollTop = 0;
      el.scrollLeft = 0;
    });

    if ((el as any).__destroy) {
      console.warn("__destoroy event is already exists");
    } else {
      (el as any).__destroy = handler;
    }
  },
  unbind(el: HTMLElement) {
    if (!(el as any).__destroy) {
      return;
    }

    (el as any).__destroy();
  }
};
