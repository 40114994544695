import { actionTree, mutationTree, getterTree } from "nuxt-typed-vuex";
import {
  Acceptance,
  CreateAcceptanceMutationRequest,
  ProjectProgress,
  ProjectProgressQueryRequest,
  ProjectProgressQueryVariables
} from "~/types/gen/api";

import { APIError } from "~/network/api-error";
import { resetState } from "~/store/utils";

export type AcceptancePayload = { progressId: string } & Pick<
  Acceptance,
  "note" | "url"
>;

export type AcceptanceState = {
  acceptance: Acceptance | null;
  isComplete: boolean;
  progress: ProjectProgress | null;
};

export const state = (): AcceptanceState => ({
  acceptance: null,
  isComplete: false,
  progress: null
});

export const getters = getterTree(state, {});

export const mutations = mutationTree(state, {
  receiveProgress(state, payload: ProjectProgress) {
    state.progress = payload;
  },
  saveAcceptanceSuccess(state: AcceptanceState) {
    state.isComplete = true;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, getters, mutations },
  {
    async init(context, payload: ProjectProgressQueryVariables): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new ProjectProgressQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res.projectProgressDetail) {
          context.commit(
            "receiveProgress",
            res.projectProgressDetail as ProjectProgress
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async saveAcceptance(context, payload: AcceptancePayload): Promise<void> {
      this.$accessor.presentation.showLoading(null);
      try {
        const res = await this.$apiClient.mutate(
          new CreateAcceptanceMutationRequest(payload)
        );
        if (!res.saveAcceptance) {
          throw new APIError(500, "Invalid Response");
        }
        context.commit("saveAcceptanceSuccess");
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
