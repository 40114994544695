import { pushiOSEventObject } from "~/utils/analytics";

interface NativeBridgeAction {
    name: string;
    payload: Record<string, any>;
}

export class NativeBridge {
  public loggedIn(token: string) {
    this.call({
      name: "loggedIn",
      payload: {
        token
      }
    });
  }

  public loggedOut() {
    this.call({
      name: "loggedOut",
      payload: {}
    });
  }

  public SetUserId(userId: string) {
    this.call({
      name: "setUserId",
      payload: {
        userId
      }
    });
  }

  public FirebaseAnalyticsPush(payload: pushiOSEventObject) {
    this.call({
      name: "firebaseAnalyticsPush",
      payload
    });
  }

  public storeRatePush() {
    this.call({
      name: "storeRatePush",
      payload: {}
    });
  }

  public ForceUpdate() {
    this.call({
      name: "forceUpdate",
      payload: {}
    });
  }

  private call(action: NativeBridgeAction) {
    if (process.server) {
      return;
    }
    window.ReactNativeWebView?.postMessage(JSON.stringify(action));
  }
}

export const nativeBridge = new NativeBridge();
