/*
  SPAD Analytics Util
  静的なイベント名などをここにまとめています
*/

// web側のトラックイベントをGTMでフックするためのイベント名
export const webTrackPushEventName = "pageProgress";

type pushEventObject = {
  routeName?: string,
  pageUrl?: string,
  pageTitle?: string
};

export type pushiOSEventObject = pushEventObject & {
  pageEventType: pageEventType
};

export enum pageEventType {
  REGISTER = "register",
  REGISTER_LINE = "register_line",
  REGISTER_LINE_DONE = "register_line_done",
  REGISTER_APPLE = "register_apple",
  REGISTER_APPLE_DONE = "register_apple_done",
  REGISTER_NORMAL = "registener_normal",
  REGISTER_NORMAL_DONE = "regisitner_normal_done",
  ONBOARDING = "onboarding",
  PROJECT_SEARCH = "project_search",
  ENTRY_PROJECT_DETAIL = "entry_project_detail",
  PROJECT_ENTRY = "project_entry",
  PROJECT_ENTRY_REJECT = "project_entry_reject",
  PROJECT_IDENTIFICATION = "project_identification",
  PROJECT_REQUIREMENT = "project_requirement",
  PROJECT_ENTRY_CONFIRM = "project_entry_confirm",
  PROJECT_ENTRY_DONE = "project_entry_done",
  OFFER_PROJECT_DETAIL = "offer_project_detail",
  PROJECT_OFFER = "project_offer",
  PROJECT_OFFER_CONFIRM = "project_offer_confirm",
  PROJECT_OFFER_DONE = "project_offer_done",
  OTHER = "other"
};

export function getPageEventName(routeName: String): pageEventType {
  switch (routeName) {
    case "project-search": {
      return pageEventType.PROJECT_SEARCH;
    }
    case "signup": {
      return pageEventType.REGISTER;
    }
    case "onboarding": {
      return pageEventType.ONBOARDING;
    }
    case "entry-projects-id": {
      return pageEventType.ENTRY_PROJECT_DETAIL;
    }
    case "offer-projects-id": {
      return pageEventType.OFFER_PROJECT_DETAIL;
    }
    case "entry-projects-id-checklist-profile":
    case "offer-projects-id-checklist-profile":
    {
      return pageEventType.PROJECT_IDENTIFICATION;
    }
    case "entry-projects-id-checklist": {
      return pageEventType.PROJECT_ENTRY_CONFIRM;
    }
    case "offer-projects-id-checklist": {
      return pageEventType.PROJECT_OFFER_CONFIRM;
    }
    default: {
      return pageEventType.OTHER;
    }
  }
}
