import { actionTree, getterTree, mutationTree } from "nuxt-typed-vuex";
import { resetState } from "~/store/utils";
import {
  AnswerEnqueteMutationRequest,
  AnswerEnqueteMutationVariables,
  Enquete,
  EnqueteQueryRequest,
  EnqueteQueryVariables
} from "~/types/gen/api";

export type EnqueteState = {
  enquete: Enquete | null;
}

export const state = (): EnqueteState => ({
  enquete: null
});

export const getters = getterTree(state, {});

export const mutations = mutationTree(state, {
  receiveEnqueteDetail(state, payload: Enquete) {
    state.enquete = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async init(
      context,
      payload: EnqueteQueryVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new EnqueteQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res.enqueteDetail) {
          context.commit(
            "receiveEnqueteDetail",
            res.enqueteDetail
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async answerEnquete(
      _context,
      payload: AnswerEnqueteMutationVariables
    ): Promise<void> {
      this.$accessor.presentation.showLoading(null);
      try {
        await this.$apiClient.mutate(
          new AnswerEnqueteMutationRequest(payload)
        );
        const id = this.$router.currentRoute.params.id;
        const from = this.$router.currentRoute.query.from as string || "";
        const path = from.length > 0
          ? `/enquetes/${id}/complete?from=${from}`
          : `/enquetes/${id}/complete`;
        this.$router.replace(path);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
