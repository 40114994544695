import Exif from "exif-js";
import { readAsDataURL } from "./file";

export function toBlob(canvas: HTMLCanvasElement): Promise<Blob> {
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          reject(new Error("Cannot create Blob file"));
          return;
        }
        resolve(blob);
      },
      "image/jpeg",
      0.8
    );
  });
}

export function toDataURL(canvas: HTMLCanvasElement) {
  return canvas.toDataURL("image/jpeg", 0.8);
}

export async function normalizedImageData(
  file: File,
  width: number = 1200,
  height: number = 1200
): Promise<Blob> {
  const source = await readAsDataURL(file);
  const image = await loadImage(source);
  const orientation = await getOrientation(image);
  const context = getCanvasContext();

  if (image.width < width && image.height < height) {
    return normalizedOrientation(image, context, orientation);
  }

  if (image.width > image.height) {
    const resized = await resizedImage(
      image,
      context,
      width,
      (image.height * width) / image.width
    );
    return normalizedOrientation(resized, context, orientation);
  } else {
    const resized = await resizedImage(
      image,
      context,
      (image.width * height) / image.height,
      height
    );
    return normalizedOrientation(resized, context, orientation);
  }
}

export function resizedImage(
  image: HTMLImageElement,
  ctx: CanvasRenderingContext2D,
  width: number,
  height: number
) {
  const canvas = ctx.canvas;
  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, width, height);

  return loadImage(toDataURL(ctx.canvas));
}

/**
 * Get transformed image data
 * @param image
 * @param orientation
 */
export function normalizedOrientation(
  image: HTMLImageElement,
  ctx: CanvasRenderingContext2D,
  orientation: number
): Promise<Blob> {
  const canvas = ctx.canvas;

  if ([5, 6, 7, 8].indexOf(orientation) > -1) {
    canvas.width = image.height;
    canvas.height = image.width;
  } else {
    canvas.width = image.width;
    canvas.height = image.height;
  }
  switch (orientation) {
    case 2:
      ctx.transform(-1, 0, 0, 1, image.width, 0);
      break;
    case 3:
      ctx.transform(-1, 0, 0, -1, image.width, image.height);
      break;
    case 4:
      ctx.transform(1, 0, 0, -1, 0, image.height);
      break;
    case 5:
      ctx.transform(0, 1, 1, 0, 0, 0);
      break;
    case 6:
      ctx.transform(0, 1, -1, 0, image.height, 0);
      break;
    case 7:
      ctx.transform(0, -1, -1, 0, image.height, image.width);
      break;
    case 8:
      ctx.transform(0, -1, 1, 0, 0, image.width);
      break;
  }
  ctx.drawImage(image, 0, 0);
  return toBlob(ctx.canvas);
}

function loadImage(src: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = (_) => {
      resolve(image);
    };
    image.onerror = () => {
      reject(new Error("Failed to load image"));
    };
    image.src = src;
  });
}

function getOrientation(image: HTMLImageElement): Promise<number> {
  return new Promise((resolve) => {
    Exif.getData(image as any, function() {
      resolve((image as any).exifdata.Orientation || 1);
    });
  });
}

function getCanvasContext() {
  const canvas = document.createElement("canvas");
  return canvas.getContext("2d")!;
}
