















import Vue from "vue";
import Icon from "~/components/ui/icon.vue";
import { oneOf } from "~/utils/helpers";

export type Size = "small" | "medium";

export default Vue.extend({
  components: {
    Icon
  },
  props: {
    size: {
      type: String as () => Size,
      validator: oneOf(["small", "medium", "large"]),
      default: "medium"
    },
    hasNotification: {
      type: Boolean,
      default: false
    },
    nativeType: {
      type: String,
      default: "button"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    sizeClassName(): string {
      return `-${this.size}`;
    }
  },
  methods: {
    handleClick(): void {
      this.$emit("click");
    }
  }
});
