import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  LineLoginMutationRequest,
  LineLoginMutationVariables,
  LineCredential
} from "~/types/gen/api";
import { APIError } from "~/network/api-error";
import { resetState } from "~/store/utils";

export type LineLoginState = {
  lineCredential: LineCredential;
};

export const state = (): LineLoginState => ({
  lineCredential: {
    isFirstLogin: true,
    token: ""
  }
});

export const mutations = mutationTree(state, {
  lineLogin(state, payload: LineCredential) {
    state.lineCredential = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  {
    state,
    mutations
  },
  {
    async lineAuth(ctx, payload: LineLoginMutationVariables): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);

        const req = new LineLoginMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        // auth error
        if (!res.lineLogin) {
          throw new APIError(401, "Authentication failed");
        }
        ctx.commit("lineLogin", res.lineLogin);
        const cledential = res.lineLogin as Pick<LineCredential, "token">;
        this.$accessor.account.saveCredential(cledential);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
