/**
 * Read binary data from FileAsset
 * @param imageSource
 */
export function readAsDataURL(file: File | Blob): Promise<string> {
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onload = (event) => {
      if (event.target && fileReader.result) {
        resolve(fileReader.result as string);
        return;
      }
      reject(new Error("File not found"));
    };
    fileReader.onerror = (event) => {
      if (event.target && event.target.error) {
        reject(event.target.error);
        return;
      }
      reject(new Error("Unknown error"));
    };
    fileReader.readAsDataURL(file);
  });
}
