export default {
  label: {
    agency_name: "取引先",
    publish_date: "発行日",
    expire_date: "支払期日",
    post_date: "掲載日",
    sub_total: "小計",
    with_holding_tax: "源泉徴収税",
    taxed_total: "差引振込金額(税込)",
    consumption_tax: "消費税"
  },
  format: {
    paid_title: "{year}年{month}月分の支払通知書",
    unpaid_title: "{month}月分の支払通知書"
  }
};
