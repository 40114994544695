import { ImageAsset } from "~/utils/asset";
import { InstagramDraftAttachment, TwitterDraftAttachment, YouTubeDraftAttachment, TikTokDraftAttachment } from "~/types/gen/api";

type DraftAttachment = InstagramDraftAttachment | TwitterDraftAttachment | YouTubeDraftAttachment | TikTokDraftAttachment

export function toImageAsset<T extends DraftAttachment>(attachment: T): ImageAsset {
  if (attachment.__typename === "InstagramDraftAttachment") {
    return {
      id: attachment.id,
      type: "url",
      // @ts-ignore
      url: attachment.attachmentFileUrl
    };
  } else if (attachment.__typename === "TwitterDraftAttachment") {
    return {
      id: attachment.id,
      type: "url",
      // @ts-ignore
      url: attachment.attachmentFileUrl
    };
  } else if (attachment.__typename === "YouTubeDraftAttachment") {
    return {
      id: attachment.id,
      type: "url",
      // @ts-ignore
      url: attachment.thumbnailUrl
    };
  } else if (attachment.__typename === "TikTokDraftAttachment") {
    return {
      id: attachment.id,
      type: "url",
      // @ts-ignore
      url: attachment.attachmentFileUrl
    };
  } else {
    return {
      id: attachment.id,
      type: "url",
      url: ""
    };
  }
};
