import { actionTree, getterTree, mutationTree } from "nuxt-typed-vuex";
import {
  ListProgressAllQueryRequest,
  OfferProjectListConnection
} from "~/types/gen/api";
import * as progressList from "~/store/pages/projectProgressList/modules/projectProgress";
import * as entryList from "~/store/pages/projectProgressList/modules/entry";

export type ProjectProgressListState = {
  entry: entryList.State;
  incomplete: progressList.State;
  complete: progressList.State;
  hasOffer: boolean;
};

// This is workaround.
// In nuxt module mode, the state is defined in the dependent module,
// so it is not necessary, but a state function is required
// to access the state of the dependent module.
export const state = (): ProjectProgressListState => {
  return {
    hasOffer: false
  } as any;
};

export const modules = {
  entry: entryList,
  incomplete: progressList,
  complete: progressList
};

export const getters = getterTree(state, {
  appliedCount(state): number {
    return state.entry.pagination.total;
  },
  completeCount(state): number {
    return state.complete.pagination.total;
  },
  incompleteCount(state): number {
    return state.incomplete.pagination.total;
  }
});

export const mutations = mutationTree(state, {
  receiveOfferList(state, payload: OfferProjectListConnection) {
    state.hasOffer = payload.paginatorInfo.total > 0;
  }
});

export const actions = actionTree(
  { state, getters },
  {
    async getAll(context): Promise<void> {
      this.$accessor.presentation.showLoading(null);
      try {
        const req = new ListProgressAllQueryRequest({});
        const res = await this.$apiClient.query(req);

        if (res) {
          this.$accessor.pages.projectProgressList.entry.receiveItems(
            res.entry
          );
          this.$accessor.pages.projectProgressList.incomplete.receiveItems(
            res.incomplete
          );
          this.$accessor.pages.projectProgressList.complete.receiveItems(
            res.complete
          );
          context.commit("receiveOfferList", res.offerProjectList);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    reset(_): void {
      this.$accessor.pages.projectProgressList.entry.reset();
      this.$accessor.pages.projectProgressList.incomplete.reset();
      this.$accessor.pages.projectProgressList.complete.reset();
    }
  }
);
