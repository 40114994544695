import { Context } from "@nuxt/types";

export default function excludeAuth0(context: Context) {
  // introページだけ除外する introページは無条件でauth0のloginページにリダイレクトされるので
  if (context.route.path !== "/intro") {
    // @ts-ignore
    if (context.app.$auth.strategy.token && context.app.$auth.strategy.token.status().expired()) {
      // トークン期限切れなので再度ログインフローに
      context.redirect("/intro");
    }
  }
}
