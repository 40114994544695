import { Context } from "@nuxt/types";
import { nativeBridge } from "~/foundations/native-bridge/native-bridge";

export default function(context: Context) {
  if (process.server) {
    return;
  }
  const token = context.app.$accessor.account.token;
  if (token) {
    nativeBridge.loggedIn(token);
  } else {
    nativeBridge.loggedOut();
  }
}
