import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { LoginMutationRequest, LoginMutationVariables } from "~/types/gen/api";
import { APIError } from "~/network/api-error";
import { resetState } from "~/store/utils";

export const TOKEN_KEY = "spad-token";

export type LoginState = {
  error: APIError | null;
};

export const state = (): LoginState => ({
  error: null
});

export const mutations = mutationTree(state, {
  loginFailed(state, payload: APIError) {
    state.error = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  {
    state,
    mutations
  },
  {
    async login(context, payload: LoginMutationVariables): Promise<void> {
      // Reload if already logged in
      const token = this.$cookies.get(TOKEN_KEY);
      if (token) {
        location.reload();
        return;
      }
      try {
        this.$accessor.presentation.showLoading(null);

        // Login
        const res = await this.$apiClient.mutate(
          new LoginMutationRequest(payload)
        );

        if (!res.login) {
          throw new APIError(401, "Authentication failed");
        }

        this.$accessor.account.saveCredential(res.login);
      } catch (e) {
        context.commit("loginFailed", e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
