import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { resetState } from "~/store/utils";
import {
  ProjectProgressDetailQueryVariables,
  ProjectProgressDetailQueryRequest,
  ProjectProgressFragment
} from "~/types/gen/api";

export type ContractedProjectState = {
  contractedProject: ProjectProgressFragment | null;
};

export const state = (): ContractedProjectState => ({
  contractedProject: null
});

export const mutations = mutationTree(state, {
  receiveProjectDetail(state, payload: ProjectProgressFragment) {
    state.contractedProject = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async init(
      context,
      payload: ProjectProgressDetailQueryVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new ProjectProgressDetailQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res.projectProgressDetail) {
          context.commit("receiveProjectDetail", res.projectProgressDetail);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
