export default {
  message: {
    empty_list: "リストの中身がありません",
    loading: "ローディング中です...",
    upload_video: "動画像をアップロード中です"
  },
  label: {
    title: "タイトル",
    content: "Content",
    test: "TEST",
    first_tab: "タブ1つ目",
    second_tab: "タブ2つ目",
    first_content: "コンテンツ１",
    second_content: "コンテンツ２",
    choose_file: "ファイルを選択",
    sent: "送信が完了しました"
  },
  button: {
    send: "送信"
  }
};
