import { actionTree, getterTree, mutationTree } from "nuxt-typed-vuex";
import { resetState } from "~/store/utils";
import { TwitterAuth, TwitterAuthQueryRequest } from "~/types/gen/api";

export type LinkSocialMediaState = {
  twitterAuth: TwitterAuth | null;
};

export const state = (): LinkSocialMediaState => ({
  twitterAuth: null
});

export const getters = getterTree(state, {});

export const mutations = mutationTree(state, {
  receiveTwitterAuth(state, payload: TwitterAuth) {
    state.twitterAuth = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async moveToTwitterOauth(context): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new TwitterAuthQueryRequest({});
        const res = await this.$apiClient.query(req);
        if (res.twitterAuth?.oauthToken) {
          context.commit("receiveTwitterAuth", res.twitterAuth);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
