export default {
  message: {
    entry: "「案件を探す」から自分にあった案件を選びエントリーします",
    email_notification: "エントリーした案件に当選するとメールに通知が来ます",
    offer: {
      waiting: "運営者からの商品発送を待ちます",
      next_step: "商品発送のない案件は、5番へ",
      attention: "※案件によって、商品をご購入いただく場合がございます"
    },
    post: "ご自身のSNSへ画像・キャプションを投稿します",
    email_shipping_notification: "商品発送後にメールに通知が来ます",
    post_draft: {
      send: "SPADに下書きを送信します",
      next_step: "下書きの審査でOKが出たらSNSへ投稿しましょう！"
    },
    account: {
      payment: "指定の口座へ入金されます",
      billing:
        "案件終了後の翌月の20日前後に支払通知書が発行され問題がなければ入金がされます"
    }
  }
};
