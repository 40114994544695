import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2355d04a = () => interopDefault(import('../src/pages/app-info/index.vue' /* webpackChunkName: "pages/app-info/index" */))
const _380f756c = () => interopDefault(import('../src/pages/apple-social-auth/index.vue' /* webpackChunkName: "pages/apple-social-auth/index" */))
const _d914c528 = () => interopDefault(import('../src/pages/apple-social-auth-android/index.vue' /* webpackChunkName: "pages/apple-social-auth-android/index" */))
const _0e10fe15 = () => interopDefault(import('../src/pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _3fcbebf2 = () => interopDefault(import('../src/pages/deactivate-social-media/index.vue' /* webpackChunkName: "pages/deactivate-social-media/index" */))
const _07bbdf2e = () => interopDefault(import('../src/pages/delete-request/index.vue' /* webpackChunkName: "pages/delete-request/index" */))
const _593b268b = () => interopDefault(import('../src/pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _a65fc718 = () => interopDefault(import('../src/pages/faq-categories/index.vue' /* webpackChunkName: "pages/faq-categories/index" */))
const _734cbfe1 = () => interopDefault(import('../src/pages/intro/index.vue' /* webpackChunkName: "pages/intro/index" */))
const _2b6fa12c = () => interopDefault(import('../src/pages/link-social-media/index.vue' /* webpackChunkName: "pages/link-social-media/index" */))
const _dacc4b4a = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0db27988 = () => interopDefault(import('../src/pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _b6101508 = () => interopDefault(import('../src/pages/mypage/index.vue' /* webpackChunkName: "pages/mypage/index" */))
const _05c3095d = () => interopDefault(import('../src/pages/notifications/index.vue' /* webpackChunkName: "pages/notifications/index" */))
const _771e6a0c = () => interopDefault(import('../src/pages/offer-projects/index.vue' /* webpackChunkName: "pages/offer-projects/index" */))
const _63b1ae58 = () => interopDefault(import('../src/pages/old-payments/index.vue' /* webpackChunkName: "pages/old-payments/index" */))
const _2dcbb65c = () => interopDefault(import('../src/pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _6c6a2ca8 = () => interopDefault(import('../src/pages/onboarding2/index.vue' /* webpackChunkName: "pages/onboarding2/index" */))
const _44b58f04 = () => interopDefault(import('../src/pages/onetime-login/index.vue' /* webpackChunkName: "pages/onetime-login/index" */))
const _3ba72344 = () => interopDefault(import('../src/pages/password-reset/index.vue' /* webpackChunkName: "pages/password-reset/index" */))
const _38b7ac86 = () => interopDefault(import('../src/pages/payee/index.vue' /* webpackChunkName: "pages/payee/index" */))
const _55bb626e = () => interopDefault(import('../src/pages/payments/index.vue' /* webpackChunkName: "pages/payments/index" */))
const _1f147295 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _28efe6dd = () => interopDefault(import('../src/pages/project-search/index.vue' /* webpackChunkName: "pages/project-search/index" */))
const _4a45ea16 = () => interopDefault(import('../src/pages/shipping-address/index.vue' /* webpackChunkName: "pages/shipping-address/index" */))
const _4e127c19 = () => interopDefault(import('../src/pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _7d2ac206 = () => interopDefault(import('../src/pages/social-auth/index.vue' /* webpackChunkName: "pages/social-auth/index" */))
const _5558941f = () => interopDefault(import('../src/pages/social-auth-android/index.vue' /* webpackChunkName: "pages/social-auth-android/index" */))
const _1681e095 = () => interopDefault(import('../src/pages/terms-of-use.vue' /* webpackChunkName: "pages/terms-of-use" */))
const _499989ae = () => interopDefault(import('../src/pages/version-up.vue' /* webpackChunkName: "pages/version-up" */))
const _55dcb19e = () => interopDefault(import('../src/pages/account-settings/credentials/index.vue' /* webpackChunkName: "pages/account-settings/credentials/index" */))
const _7baf12c4 = () => interopDefault(import('../src/pages/account-settings/profile/index.vue' /* webpackChunkName: "pages/account-settings/profile/index" */))
const _7a692f7e = () => interopDefault(import('../src/pages/auth/callback/index.vue' /* webpackChunkName: "pages/auth/callback/index" */))
const _4bd757a2 = () => interopDefault(import('../src/pages/auth/error/index.vue' /* webpackChunkName: "pages/auth/error/index" */))
const _5eaddc42 = () => interopDefault(import('../src/pages/guide/flow.vue' /* webpackChunkName: "pages/guide/flow" */))
const _a377517a = () => interopDefault(import('../src/pages/guide/how-to-use.vue' /* webpackChunkName: "pages/guide/how-to-use" */))
const _496d6f1a = () => interopDefault(import('../src/pages/guide/instagram-url.vue' /* webpackChunkName: "pages/guide/instagram-url" */))
const _4ca9d635 = () => interopDefault(import('../src/pages/guide/tiktok-post-edit.vue' /* webpackChunkName: "pages/guide/tiktok-post-edit" */))
const _f6435de6 = () => interopDefault(import('../src/pages/guide/tiktok-url.vue' /* webpackChunkName: "pages/guide/tiktok-url" */))
const _2e1baff4 = () => interopDefault(import('../src/pages/guide/twitter-url.vue' /* webpackChunkName: "pages/guide/twitter-url" */))
const _3f2980a4 = () => interopDefault(import('../src/pages/guide/youtube-url.vue' /* webpackChunkName: "pages/guide/youtube-url" */))
const _7e15ec8f = () => interopDefault(import('../src/pages/link-social-media/instagram/index.vue' /* webpackChunkName: "pages/link-social-media/instagram/index" */))
const _04312ada = () => interopDefault(import('../src/pages/link-social-media/tiktok/index.vue' /* webpackChunkName: "pages/link-social-media/tiktok/index" */))
const _52fafa90 = () => interopDefault(import('../src/pages/link-social-media/twitter/index.vue' /* webpackChunkName: "pages/link-social-media/twitter/index" */))
const _57d75f40 = () => interopDefault(import('../src/pages/link-social-media/youtube/index.vue' /* webpackChunkName: "pages/link-social-media/youtube/index" */))
const _0ffdb50d = () => interopDefault(import('../src/pages/lp/cosfes/index.vue' /* webpackChunkName: "pages/lp/cosfes/index" */))
const _c018859e = () => interopDefault(import('../src/pages/lp/itchaku/index.vue' /* webpackChunkName: "pages/lp/itchaku/index" */))
const _7b991b5a = () => interopDefault(import('../src/pages/lp/projects/index.vue' /* webpackChunkName: "pages/lp/projects/index" */))
const _4d16ef58 = () => interopDefault(import('../src/pages/lp/sakama/index.vue' /* webpackChunkName: "pages/lp/sakama/index" */))
const _dce03dcc = () => interopDefault(import('../src/pages/lp/tiktok/index.vue' /* webpackChunkName: "pages/lp/tiktok/index" */))
const _4a2d5e06 = () => interopDefault(import('../src/pages/lp/welcia/index.vue' /* webpackChunkName: "pages/lp/welcia/index" */))
const _73065cfa = () => interopDefault(import('../src/pages/offer-projects/utils.ts' /* webpackChunkName: "pages/offer-projects/utils" */))
const _083eaed7 = () => interopDefault(import('../src/pages/onetime-login/cordinator.vue' /* webpackChunkName: "pages/onetime-login/cordinator" */))
const _0832edc6 = () => interopDefault(import('../src/pages/password-reset/verify.vue' /* webpackChunkName: "pages/password-reset/verify" */))
const _3c3d39ae = () => interopDefault(import('../src/pages/payee/utils.ts' /* webpackChunkName: "pages/payee/utils" */))
const _38229cfe = () => interopDefault(import('../src/pages/project-progress/list.vue' /* webpackChunkName: "pages/project-progress/list" */))
const _1a0e25fe = () => interopDefault(import('../src/pages/project-progress/list/index.vue' /* webpackChunkName: "pages/project-progress/list/index" */))
const _66e98d4c = () => interopDefault(import('../src/pages/project-progress/list/complete.vue' /* webpackChunkName: "pages/project-progress/list/complete" */))
const _0687fca1 = () => interopDefault(import('../src/pages/project-progress/list/entry.vue' /* webpackChunkName: "pages/project-progress/list/entry" */))
const _47dd4e82 = () => interopDefault(import('../src/pages/project-progress/list/incomplete.vue' /* webpackChunkName: "pages/project-progress/list/incomplete" */))
const _2fde3342 = () => interopDefault(import('../src/pages/signup/complete.vue' /* webpackChunkName: "pages/signup/complete" */))
const _c81d5c76 = () => interopDefault(import('../src/pages/signup/create.vue' /* webpackChunkName: "pages/signup/create" */))
const _5591470f = () => interopDefault(import('../src/pages/account-settings/credentials/password/index.vue' /* webpackChunkName: "pages/account-settings/credentials/password/index" */))
const _24ef5dd2 = () => interopDefault(import('../src/pages/account-settings/credentials/register/index.vue' /* webpackChunkName: "pages/account-settings/credentials/register/index" */))
const _1b79ba6e = () => interopDefault(import('../src/pages/account-settings/profile/resend-email.vue' /* webpackChunkName: "pages/account-settings/profile/resend-email" */))
const _557c220c = () => interopDefault(import('../src/pages/link-social-media/instagram/account-activation/index.vue' /* webpackChunkName: "pages/link-social-media/instagram/account-activation/index" */))
const _7fd584f0 = () => interopDefault(import('../src/pages/link-social-media/tiktok/account-activation/index.vue' /* webpackChunkName: "pages/link-social-media/tiktok/account-activation/index" */))
const _f45bab2a = () => interopDefault(import('../src/pages/link-social-media/twitter/account-activation/index.vue' /* webpackChunkName: "pages/link-social-media/twitter/account-activation/index" */))
const _9b12b08a = () => interopDefault(import('../src/pages/link-social-media/youtube/account-activation/index.vue' /* webpackChunkName: "pages/link-social-media/youtube/account-activation/index" */))
const _d2613286 = () => interopDefault(import('../src/pages/lp/tiktok/items.ts' /* webpackChunkName: "pages/lp/tiktok/items" */))
const _ff32230c = () => interopDefault(import('../src/pages/lp/welcia/items.ts' /* webpackChunkName: "pages/lp/welcia/items" */))
const _9cba8180 = () => interopDefault(import('../src/pages/account-settings/credentials/email/create.vue' /* webpackChunkName: "pages/account-settings/credentials/email/create" */))
const _58377600 = () => interopDefault(import('../src/pages/account-settings/credentials/email/verify/index.vue' /* webpackChunkName: "pages/account-settings/credentials/email/verify/index" */))
const _e6c8a9d6 = () => interopDefault(import('../src/pages/lp/projects/_slug/index.vue' /* webpackChunkName: "pages/lp/projects/_slug/index" */))
const _06967a54 = () => interopDefault(import('../src/pages/accepted-entries/_id/index.vue' /* webpackChunkName: "pages/accepted-entries/_id/index" */))
const _29dba5ec = () => interopDefault(import('../src/pages/announcements/_id/index.vue' /* webpackChunkName: "pages/announcements/_id/index" */))
const _10f76676 = () => interopDefault(import('../src/pages/contracted-projects/_id/index.vue' /* webpackChunkName: "pages/contracted-projects/_id/index" */))
const _170ceb10 = () => interopDefault(import('../src/pages/direct-messages/_id/index.vue' /* webpackChunkName: "pages/direct-messages/_id/index" */))
const _ca4acfd4 = () => interopDefault(import('../src/pages/enquetes/_id/index.vue' /* webpackChunkName: "pages/enquetes/_id/index" */))
const _6264e2f0 = () => interopDefault(import('../src/pages/entries/_id/index.vue' /* webpackChunkName: "pages/entries/_id/index" */))
const _1c1b3821 = () => interopDefault(import('../src/pages/entry-projects/_id/index.vue' /* webpackChunkName: "pages/entry-projects/_id/index" */))
const _4c8ff814 = () => interopDefault(import('../src/pages/faq/_id/index.vue' /* webpackChunkName: "pages/faq/_id/index" */))
const _055b9ef7 = () => interopDefault(import('../src/pages/offer-projects/_id/index.vue' /* webpackChunkName: "pages/offer-projects/_id/index" */))
const _3bbcd34e = () => interopDefault(import('../src/pages/payments/_id/index.vue' /* webpackChunkName: "pages/payments/_id/index" */))
const _34d87c8d = () => interopDefault(import('../src/pages/project-progress/_id/index.vue' /* webpackChunkName: "pages/project-progress/_id/index" */))
const _4f0c2726 = () => interopDefault(import('../src/pages/rejected-entries/_id/index.vue' /* webpackChunkName: "pages/rejected-entries/_id/index" */))
const _3536b74c = () => interopDefault(import('../src/pages/contacts/_id/reply/index.vue' /* webpackChunkName: "pages/contacts/_id/reply/index" */))
const _5acf3a25 = () => interopDefault(import('../src/pages/enquetes/_id/complete.vue' /* webpackChunkName: "pages/enquetes/_id/complete" */))
const _ad780390 = () => interopDefault(import('../src/pages/entry-projects/_id/checklist/index.vue' /* webpackChunkName: "pages/entry-projects/_id/checklist/index" */))
const _ce2b5ce4 = () => interopDefault(import('../src/pages/offer-projects/_id/checklist/index.vue' /* webpackChunkName: "pages/offer-projects/_id/checklist/index" */))
const _557053ca = () => interopDefault(import('../src/pages/offer-projects/_id/reject.vue' /* webpackChunkName: "pages/offer-projects/_id/reject" */))
const _15f0daaf = () => interopDefault(import('../src/pages/project-progress/_id/acceptance/index.vue' /* webpackChunkName: "pages/project-progress/_id/acceptance/index" */))
const _c62b0142 = () => interopDefault(import('../src/pages/project-progress/_id/draft/index.vue' /* webpackChunkName: "pages/project-progress/_id/draft/index" */))
const _61b5600f = () => interopDefault(import('../src/pages/entry-projects/_id/checklist/profile.vue' /* webpackChunkName: "pages/entry-projects/_id/checklist/profile" */))
const _011435e5 = () => interopDefault(import('../src/pages/offer-projects/_id/checklist/profile.vue' /* webpackChunkName: "pages/offer-projects/_id/checklist/profile" */))
const _5d3687e6 = () => interopDefault(import('../src/pages/project-progress/_id/draft/edit.vue' /* webpackChunkName: "pages/project-progress/_id/draft/edit" */))
const _296ae9bf = () => interopDefault(import('../src/pages/project-progress/_id/draft/reject-reason.vue' /* webpackChunkName: "pages/project-progress/_id/draft/reject-reason" */))
const _6e1b9178 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/app-info",
    component: _2355d04a,
    name: "app-info"
  }, {
    path: "/apple-social-auth",
    component: _380f756c,
    name: "apple-social-auth"
  }, {
    path: "/apple-social-auth-android",
    component: _d914c528,
    name: "apple-social-auth-android"
  }, {
    path: "/contact",
    component: _0e10fe15,
    name: "contact"
  }, {
    path: "/deactivate-social-media",
    component: _3fcbebf2,
    name: "deactivate-social-media"
  }, {
    path: "/delete-request",
    component: _07bbdf2e,
    name: "delete-request"
  }, {
    path: "/faq",
    component: _593b268b,
    name: "faq"
  }, {
    path: "/faq-categories",
    component: _a65fc718,
    name: "faq-categories"
  }, {
    path: "/intro",
    component: _734cbfe1,
    name: "intro"
  }, {
    path: "/link-social-media",
    component: _2b6fa12c,
    name: "link-social-media"
  }, {
    path: "/login",
    component: _dacc4b4a,
    name: "login"
  }, {
    path: "/logout",
    component: _0db27988,
    name: "logout"
  }, {
    path: "/mypage",
    component: _b6101508,
    name: "mypage"
  }, {
    path: "/notifications",
    component: _05c3095d,
    name: "notifications"
  }, {
    path: "/offer-projects",
    component: _771e6a0c,
    name: "offer-projects"
  }, {
    path: "/old-payments",
    component: _63b1ae58,
    name: "old-payments"
  }, {
    path: "/onboarding",
    component: _2dcbb65c,
    name: "onboarding"
  }, {
    path: "/onboarding2",
    component: _6c6a2ca8,
    name: "onboarding2"
  }, {
    path: "/onetime-login",
    component: _44b58f04,
    name: "onetime-login"
  }, {
    path: "/password-reset",
    component: _3ba72344,
    name: "password-reset"
  }, {
    path: "/payee",
    component: _38b7ac86,
    name: "payee"
  }, {
    path: "/payments",
    component: _55bb626e,
    name: "payments"
  }, {
    path: "/privacy-policy",
    component: _1f147295,
    name: "privacy-policy"
  }, {
    path: "/project-search",
    component: _28efe6dd,
    name: "project-search"
  }, {
    path: "/shipping-address",
    component: _4a45ea16,
    name: "shipping-address"
  }, {
    path: "/signup",
    component: _4e127c19,
    name: "signup"
  }, {
    path: "/social-auth",
    component: _7d2ac206,
    name: "social-auth"
  }, {
    path: "/social-auth-android",
    component: _5558941f,
    name: "social-auth-android"
  }, {
    path: "/terms-of-use",
    component: _1681e095,
    name: "terms-of-use"
  }, {
    path: "/version-up",
    component: _499989ae,
    name: "version-up"
  }, {
    path: "/account-settings/credentials",
    component: _55dcb19e,
    name: "account-settings-credentials"
  }, {
    path: "/account-settings/profile",
    component: _7baf12c4,
    name: "account-settings-profile"
  }, {
    path: "/auth/callback",
    component: _7a692f7e,
    name: "auth-callback"
  }, {
    path: "/auth/error",
    component: _4bd757a2,
    name: "auth-error"
  }, {
    path: "/guide/flow",
    component: _5eaddc42,
    name: "guide-flow"
  }, {
    path: "/guide/how-to-use",
    component: _a377517a,
    name: "guide-how-to-use"
  }, {
    path: "/guide/instagram-url",
    component: _496d6f1a,
    name: "guide-instagram-url"
  }, {
    path: "/guide/tiktok-post-edit",
    component: _4ca9d635,
    name: "guide-tiktok-post-edit"
  }, {
    path: "/guide/tiktok-url",
    component: _f6435de6,
    name: "guide-tiktok-url"
  }, {
    path: "/guide/twitter-url",
    component: _2e1baff4,
    name: "guide-twitter-url"
  }, {
    path: "/guide/youtube-url",
    component: _3f2980a4,
    name: "guide-youtube-url"
  }, {
    path: "/link-social-media/instagram",
    component: _7e15ec8f,
    name: "link-social-media-instagram"
  }, {
    path: "/link-social-media/tiktok",
    component: _04312ada,
    name: "link-social-media-tiktok"
  }, {
    path: "/link-social-media/twitter",
    component: _52fafa90,
    name: "link-social-media-twitter"
  }, {
    path: "/link-social-media/youtube",
    component: _57d75f40,
    name: "link-social-media-youtube"
  }, {
    path: "/lp/cosfes",
    component: _0ffdb50d,
    name: "lp-cosfes"
  }, {
    path: "/lp/itchaku",
    component: _c018859e,
    name: "lp-itchaku"
  }, {
    path: "/lp/projects",
    component: _7b991b5a,
    name: "lp-projects"
  }, {
    path: "/lp/sakama",
    component: _4d16ef58,
    name: "lp-sakama"
  }, {
    path: "/lp/tiktok",
    component: _dce03dcc,
    name: "lp-tiktok"
  }, {
    path: "/lp/welcia",
    component: _4a2d5e06,
    name: "lp-welcia"
  }, {
    path: "/offer-projects/utils",
    component: _73065cfa,
    name: "offer-projects-utils"
  }, {
    path: "/onetime-login/cordinator",
    component: _083eaed7,
    name: "onetime-login-cordinator"
  }, {
    path: "/password-reset/verify",
    component: _0832edc6,
    name: "password-reset-verify"
  }, {
    path: "/payee/utils",
    component: _3c3d39ae,
    name: "payee-utils"
  }, {
    path: "/project-progress/list",
    component: _38229cfe,
    children: [{
      path: "",
      component: _1a0e25fe,
      name: "project-progress-list"
    }, {
      path: "complete",
      component: _66e98d4c,
      name: "project-progress-list-complete"
    }, {
      path: "entry",
      component: _0687fca1,
      name: "project-progress-list-entry"
    }, {
      path: "incomplete",
      component: _47dd4e82,
      name: "project-progress-list-incomplete"
    }]
  }, {
    path: "/signup/complete",
    component: _2fde3342,
    name: "signup-complete"
  }, {
    path: "/signup/create",
    component: _c81d5c76,
    name: "signup-create"
  }, {
    path: "/account-settings/credentials/password",
    component: _5591470f,
    name: "account-settings-credentials-password"
  }, {
    path: "/account-settings/credentials/register",
    component: _24ef5dd2,
    name: "account-settings-credentials-register"
  }, {
    path: "/account-settings/profile/resend-email",
    component: _1b79ba6e,
    name: "account-settings-profile-resend-email"
  }, {
    path: "/link-social-media/instagram/account-activation",
    component: _557c220c,
    name: "link-social-media-instagram-account-activation"
  }, {
    path: "/link-social-media/tiktok/account-activation",
    component: _7fd584f0,
    name: "link-social-media-tiktok-account-activation"
  }, {
    path: "/link-social-media/twitter/account-activation",
    component: _f45bab2a,
    name: "link-social-media-twitter-account-activation"
  }, {
    path: "/link-social-media/youtube/account-activation",
    component: _9b12b08a,
    name: "link-social-media-youtube-account-activation"
  }, {
    path: "/lp/tiktok/items",
    component: _d2613286,
    name: "lp-tiktok-items"
  }, {
    path: "/lp/welcia/items",
    component: _ff32230c,
    name: "lp-welcia-items"
  }, {
    path: "/account-settings/credentials/email/create",
    component: _9cba8180,
    name: "account-settings-credentials-email-create"
  }, {
    path: "/account-settings/credentials/email/verify",
    component: _58377600,
    name: "account-settings-credentials-email-verify"
  }, {
    path: "/lp/projects/:slug",
    component: _e6c8a9d6,
    name: "lp-projects-slug"
  }, {
    path: "/accepted-entries/:id",
    component: _06967a54,
    name: "accepted-entries-id"
  }, {
    path: "/announcements/:id",
    component: _29dba5ec,
    name: "announcements-id"
  }, {
    path: "/contracted-projects/:id",
    component: _10f76676,
    name: "contracted-projects-id"
  }, {
    path: "/direct-messages/:id",
    component: _170ceb10,
    name: "direct-messages-id"
  }, {
    path: "/enquetes/:id",
    component: _ca4acfd4,
    name: "enquetes-id"
  }, {
    path: "/entries/:id",
    component: _6264e2f0,
    name: "entries-id"
  }, {
    path: "/entry-projects/:id",
    component: _1c1b3821,
    name: "entry-projects-id"
  }, {
    path: "/faq/:id",
    component: _4c8ff814,
    name: "faq-id"
  }, {
    path: "/offer-projects/:id",
    component: _055b9ef7,
    name: "offer-projects-id"
  }, {
    path: "/payments/:id",
    component: _3bbcd34e,
    name: "payments-id"
  }, {
    path: "/project-progress/:id",
    component: _34d87c8d,
    name: "project-progress-id"
  }, {
    path: "/rejected-entries/:id",
    component: _4f0c2726,
    name: "rejected-entries-id"
  }, {
    path: "/contacts/:id?/reply",
    component: _3536b74c,
    name: "contacts-id-reply"
  }, {
    path: "/enquetes/:id?/complete",
    component: _5acf3a25,
    name: "enquetes-id-complete"
  }, {
    path: "/entry-projects/:id?/checklist",
    component: _ad780390,
    name: "entry-projects-id-checklist"
  }, {
    path: "/offer-projects/:id?/checklist",
    component: _ce2b5ce4,
    name: "offer-projects-id-checklist"
  }, {
    path: "/offer-projects/:id?/reject",
    component: _557053ca,
    name: "offer-projects-id-reject"
  }, {
    path: "/project-progress/:id?/acceptance",
    component: _15f0daaf,
    name: "project-progress-id-acceptance"
  }, {
    path: "/project-progress/:id?/draft",
    component: _c62b0142,
    name: "project-progress-id-draft"
  }, {
    path: "/entry-projects/:id?/checklist/profile",
    component: _61b5600f,
    name: "entry-projects-id-checklist-profile"
  }, {
    path: "/offer-projects/:id?/checklist/profile",
    component: _011435e5,
    name: "offer-projects-id-checklist-profile"
  }, {
    path: "/project-progress/:id?/draft/edit",
    component: _5d3687e6,
    name: "project-progress-id-draft-edit"
  }, {
    path: "/project-progress/:id?/draft/reject-reason",
    component: _296ae9bf,
    name: "project-progress-id-draft-reject-reason"
  }, {
    path: "/",
    component: _6e1b9178,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
