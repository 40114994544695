import { actionTree, mutationTree, getterTree } from "nuxt-typed-vuex";
import { resetState } from "~/store/utils";
import {
  OfferProjectDetailQueryVariables,
  OfferProjectDetailQueryRequest,
  OfferProject,
  OfferNgReasonListQueryVariables,
  OfferNgReasonListQueryRequest,
  OfferNgReason,
  RejectOfferMutationVariables,
  RejectOfferMutationRequest,
  AcceptOfferMutationVariables,
  AcceptOfferMutationRequest,
  Enquete,
  EnquetePageType
} from "~/types/gen/api";

export type OfferProjectState = {
  offerProject: OfferProject | null;
  offerNgReasonList: OfferNgReason[];
};

export const state = (): OfferProjectState => ({
  offerProject: null,
  offerNgReasonList: []
});

export const getters = getterTree(state, {
  shouldRegisterShippingAddress(state, _s, _g, rg) {
    const hasShippingAddress = rg["account/hasShippingAddress"];
    return state.offerProject!.project.hasShippingStep && !hasShippingAddress;
  },
  shouldRegisterPayee(state, _s, _g, rg) {
    const hasPayee = rg["account/hasPayee"];
    return state.offerProject!.hasPayment && !hasPayee;
  },
  mediaType(state) {
    return state.offerProject!.project.mediaType;
  },
  postPeriodEndAt(state) {
    return state.offerProject!.project.postPeriodEndAt;
  },
  enquete(state): Enquete | null {
    return state.offerProject?.enquetes.find((v) =>
      v.pageType === EnquetePageType.BeforeEntryProject
    ) || null;
  }
});

export const mutations = mutationTree(state, {
  receiveProjectDetail(state, payload: OfferProject) {
    state.offerProject = payload;
  },
  receiveNgReason(state, payload: OfferNgReason[]) {
    state.offerNgReasonList = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async init(
      context,
      payload: OfferProjectDetailQueryVariables
    ): Promise<void> {
      this.$accessor.presentation.showLoading(null);
      try {
        const req = new OfferProjectDetailQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res.offerProjectDetail) {
          context.commit("receiveProjectDetail", res.offerProjectDetail);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async loadNgReason(
      context,
      payload: OfferNgReasonListQueryVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new OfferNgReasonListQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res.offerNgReasonList) {
          context.commit("receiveNgReason", res.offerNgReasonList);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async rejectOffer(_, payload: RejectOfferMutationVariables): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new RejectOfferMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res) {
          this.$router.replace("/offer-projects");
          this.$accessor.toast.show({
            type: "success",
            message: this.$i18n.tc("domain.project.message.reject_project")
          });
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async acceptOffer(_, payload: AcceptOfferMutationVariables): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new AcceptOfferMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res.acceptOffer!.id) {
          if (res.acceptOffer?.enquetes) {
            const enquete = res.acceptOffer?.enquetes.find((v) =>
              v.pageType === EnquetePageType.AfterEntryProject
            );
            const path = enquete
              ? `/enquetes/${enquete.id}?from=/project-progress/${res.acceptOffer!.id}`
              : `/project-progress/${res.acceptOffer!.id}`;
            this.$router.replace(path);
          } else {
            this.$router.replace(`/project-progress/${res.acceptOffer!.id}`);
          }
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
