import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../src/store/index.ts'), 'store/index.ts')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../src/store/toast/index.ts'), 'toast/index.ts')
  resolveStoreModules(require('../src/store/systemSetting/index.ts'), 'systemSetting/index.ts')
  resolveStoreModules(require('../src/store/spadApp/index.ts'), 'spadApp/index.ts')
  resolveStoreModules(require('../src/store/presentation/index.ts'), 'presentation/index.ts')
  resolveStoreModules(require('../src/store/error/index.ts'), 'error/index.ts')
  resolveStoreModules(require('../src/store/appBanner/index.ts'), 'appBanner/index.ts')
  resolveStoreModules(require('../src/store/account/index.ts'), 'account/index.ts')
  resolveStoreModules(require('../src/store/service/satisfiedProjectRequrement.ts'), 'service/satisfiedProjectRequrement.ts')
  resolveStoreModules(require('../src/store/pages/youtubeRegister/index.ts'), 'pages/youtubeRegister/index.ts')
  resolveStoreModules(require('../src/store/pages/youtubeActivation/index.ts'), 'pages/youtubeActivation/index.ts')
  resolveStoreModules(require('../src/store/pages/twitterRegister/index.ts'), 'pages/twitterRegister/index.ts')
  resolveStoreModules(require('../src/store/pages/twitterActivation/index.ts'), 'pages/twitterActivation/index.ts')
  resolveStoreModules(require('../src/store/pages/tiktokRegister/index.ts'), 'pages/tiktokRegister/index.ts')
  resolveStoreModules(require('../src/store/pages/tiktokActivation/index.ts'), 'pages/tiktokActivation/index.ts')
  resolveStoreModules(require('../src/store/pages/signUp/index.ts'), 'pages/signUp/index.ts')
  resolveStoreModules(require('../src/store/pages/shippingAddress/index.ts'), 'pages/shippingAddress/index.ts')
  resolveStoreModules(require('../src/store/pages/resetPassword/index.ts'), 'pages/resetPassword/index.ts')
  resolveStoreModules(require('../src/store/pages/resendEmail/index.ts'), 'pages/resendEmail/index.ts')
  resolveStoreModules(require('../src/store/pages/rejectedEntry/index.ts'), 'pages/rejectedEntry/index.ts')
  resolveStoreModules(require('../src/store/pages/registerCredentials/index.ts'), 'pages/registerCredentials/index.ts')
  resolveStoreModules(require('../src/store/pages/projectSearch/index.ts'), 'pages/projectSearch/index.ts')
  resolveStoreModules(require('../src/store/pages/projectProgressList/index.ts'), 'pages/projectProgressList/index.ts')
  resolveStoreModules(require('../src/store/pages/projectProgress/index.ts'), 'pages/projectProgress/index.ts')
  resolveStoreModules(require('../src/store/pages/profile/index.ts'), 'pages/profile/index.ts')
  resolveStoreModules(require('../src/store/pages/payments/index.ts'), 'pages/payments/index.ts')
  resolveStoreModules(require('../src/store/pages/payment/index.ts'), 'pages/payment/index.ts')
  resolveStoreModules(require('../src/store/pages/payee/index.ts'), 'pages/payee/index.ts')
  resolveStoreModules(require('../src/store/pages/password/index.ts'), 'pages/password/index.ts')
  resolveStoreModules(require('../src/store/pages/oldPayments/index.ts'), 'pages/oldPayments/index.ts')
  resolveStoreModules(require('../src/store/pages/offerProjectList/index.ts'), 'pages/offerProjectList/index.ts')
  resolveStoreModules(require('../src/store/pages/offerProjectChecklist/index.ts'), 'pages/offerProjectChecklist/index.ts')
  resolveStoreModules(require('../src/store/pages/offerProject/index.ts'), 'pages/offerProject/index.ts')
  resolveStoreModules(require('../src/store/pages/notifications/index.ts'), 'pages/notifications/index.ts')
  resolveStoreModules(require('../src/store/pages/mypage/index.ts'), 'pages/mypage/index.ts')
  resolveStoreModules(require('../src/store/pages/lpProjectList/index.ts'), 'pages/lpProjectList/index.ts')
  resolveStoreModules(require('../src/store/pages/lpProject/index.ts'), 'pages/lpProject/index.ts')
  resolveStoreModules(require('../src/store/pages/login/index.ts'), 'pages/login/index.ts')
  resolveStoreModules(require('../src/store/pages/linkSocialMedia/index.ts'), 'pages/linkSocialMedia/index.ts')
  resolveStoreModules(require('../src/store/pages/lineAuth/index.ts'), 'pages/lineAuth/index.ts')
  resolveStoreModules(require('../src/store/pages/instagramRegister/index.ts'), 'pages/instagramRegister/index.ts')
  resolveStoreModules(require('../src/store/pages/instagram/index.ts'), 'pages/instagram/index.ts')
  resolveStoreModules(require('../src/store/pages/faqList/index.ts'), 'pages/faqList/index.ts')
  resolveStoreModules(require('../src/store/pages/faqCategoryList/index.ts'), 'pages/faqCategoryList/index.ts')
  resolveStoreModules(require('../src/store/pages/faq/index.ts'), 'pages/faq/index.ts')
  resolveStoreModules(require('../src/store/pages/entryProjectChecklist/index.ts'), 'pages/entryProjectChecklist/index.ts')
  resolveStoreModules(require('../src/store/pages/entryProject/index.ts'), 'pages/entryProject/index.ts')
  resolveStoreModules(require('../src/store/pages/entry/index.ts'), 'pages/entry/index.ts')
  resolveStoreModules(require('../src/store/pages/enquete/index.ts'), 'pages/enquete/index.ts')
  resolveStoreModules(require('../src/store/pages/emailVerify/index.ts'), 'pages/emailVerify/index.ts')
  resolveStoreModules(require('../src/store/pages/emailRegistration/index.ts'), 'pages/emailRegistration/index.ts')
  resolveStoreModules(require('../src/store/pages/email/index.ts'), 'pages/email/index.ts')
  resolveStoreModules(require('../src/store/pages/draftEdit/index.ts'), 'pages/draftEdit/index.ts')
  resolveStoreModules(require('../src/store/pages/draft/index.ts'), 'pages/draft/index.ts')
  resolveStoreModules(require('../src/store/pages/directMessage/index.ts'), 'pages/directMessage/index.ts')
  resolveStoreModules(require('../src/store/pages/deleteRequest/index.ts'), 'pages/deleteRequest/index.ts')
  resolveStoreModules(require('../src/store/pages/deactivateSocialAccount/index.ts'), 'pages/deactivateSocialAccount/index.ts')
  resolveStoreModules(require('../src/store/pages/contractedProject/index.ts'), 'pages/contractedProject/index.ts')
  resolveStoreModules(require('../src/store/pages/contactReply/index.ts'), 'pages/contactReply/index.ts')
  resolveStoreModules(require('../src/store/pages/contact/index.ts'), 'pages/contact/index.ts')
  resolveStoreModules(require('../src/store/pages/appleAuth/index.ts'), 'pages/appleAuth/index.ts')
  resolveStoreModules(require('../src/store/pages/announcement/index.ts'), 'pages/announcement/index.ts')
  resolveStoreModules(require('../src/store/pages/acceptance/index.ts'), 'pages/acceptance/index.ts')
  resolveStoreModules(require('../src/store/pages/projectProgressList/complete.ts'), 'pages/projectProgressList/complete.ts')
  resolveStoreModules(require('../src/store/pages/projectProgressList/entry.ts'), 'pages/projectProgressList/entry.ts')
  resolveStoreModules(require('../src/store/pages/projectProgressList/incomplete.ts'), 'pages/projectProgressList/incomplete.ts')
  resolveStoreModules(require('../src/store/pages/projectSearch/utils.ts'), 'pages/projectSearch/utils.ts')
  resolveStoreModules(require('../src/store/pages/projectProgressList/modules/projectProgress/index.ts'), 'pages/projectProgressList/modules/projectProgress/index.ts')
  resolveStoreModules(require('../src/store/pages/projectProgressList/modules/entry/index.ts'), 'pages/projectProgressList/modules/entry/index.ts')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const storeModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(storeModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
