














import Vue from "vue";
import Loading from "~/components/ui/loading/loading.vue";
import ErrorDialog from "~/components/the/error-dialog.vue";

export default Vue.extend({
  middleware: ["excludeAuth"],
  components: {
    Loading,
    ErrorDialog
  },
  computed: {
    isLoading(): boolean {
      return this.$accessor.presentation.isLoading;
    },
    error(): Error | null {
      return this.$accessor.error.error;
    }
  }
});
