









import Vue from "vue";

export default Vue.extend({
  props: {
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    iconPath(): string {
      return `/svg/${this.name}.svg#root`;
    }
  },
  methods: {
    onClick(e: MouseEvent) {
      this.$emit("click", e);
    }
  }
});
