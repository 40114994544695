import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { resetState } from "~/store/utils";
import {
  ListFaqCategoryQueryRequest,
  FaqCategory,
  ListFaqCategoryQuery
} from "~/types/gen/api";

export type Response = ListFaqCategoryQuery["faqCategoryList"];

export type FaqCategoryListState = {
  items: FaqCategory[];
};

export const state = (): FaqCategoryListState => ({
  items: []
});

export const mutations = mutationTree(state, {
  receiveFaqCategoryList(state: FaqCategoryListState, payload: Response) {
    state.items = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async loadFaqCategoryList(context): Promise<void> {
      this.$accessor.presentation.showLoading(null);

      try {
        const req = new ListFaqCategoryQueryRequest({});
        const res = await this.$apiClient.query(req);
        context.commit("receiveFaqCategoryList", res.faqCategoryList);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
