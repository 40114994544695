import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { resetState } from "~/store/utils";
import { VerifyEmailMutationRequest } from "~/types/gen/api";

export enum Step {
  Initial,
  Error,
  Verified
}

export type EmailVerifyState = {
  step: Step;
};

export const state = (): EmailVerifyState => ({
  step: Step.Initial
});

export const mutations = mutationTree(state, {
  success(state) {
    state.step = Step.Verified;
  },
  failed(state) {
    state.step = Step.Error;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async verify(context): Promise<void> {
      const token = this.$router.currentRoute.query.token as string;

      // Invalid access
      if (!token) {
        context.commit("failed");
        return;
      }

      this.$accessor.presentation.showLoading(null);

      try {
        const res = await this.$apiClient.mutate(
          new VerifyEmailMutationRequest({
            token
          })
        );

        if (!res.verifyEmail) {
          context.commit("failed");
          return;
        }

        context.commit("success");

        // Reload account on memory
        await this.$accessor.account.fetchAccountBackground();
      } catch (e) {
        context.commit("failed");
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
