export default {
  button: {
    close: "閉じる",
    contact: "お問い合わせ",
    send: "送信する",
    back_top: "トップへ戻る",
    go_top: "トップへ",
    register: "ログイン情報を登録する",
    faq_mail_not_found: "メールが届かない場合は?",
    download: "ダウンロード",
    here: "こちらに",
    change: "変更する",
    save: "保存する",
    select: "選択する",
    show_all: "すべて見る",
    register_account: "アカウントを登録する",
    resend_email: "確認メールを再送信する",
    register_line: "LINEで登録する",
    to_mypage: "マイページへ",
    login_with_line: "LINEでログインする",
    login_with_apple: "APPLEでログインする",
    next: "次へ",
    skip: "スキップ"
  },
  error: {
    status_code: {
      302: "アップデートをお願いします",
      404: "ページが見つかりませんでした",
      500: "原因不明のエラーが発生しました",
      401: "認証に失敗しました"
    },
    message: "エラーが発生しました"
  },
  format: {
    name_honorific: "{name}様",
    relative_time_ago: {
      years: "{0}年前",
      months: "{0}か月前",
      days: "{0}日前",
      hours: "{0}時間前",
      minutes: "{0}分前",
      seconds: "{0}秒前",
      just_now: "たった今"
    },
    age: "{age}歳",
    end_at: "まで"
  },
  label: {
    term: "利用規約",
    email: "メールアドレス",
    password: "パスワード",
    password_confirmation: "パスワード再確認",
    not_entry: "まだ案件にエントリーできません",
    not_resister_social_media: "アカウント審査をお願いします",
    not_resister_social_media_detail: `
      アカウント審査前は案件にエントリーすることができません<br>
      アカウント審査には、数日以上かかる場合がございますので、
      お早めにSNSの審査を完了してください
    `,
    monitor: "商品のみ",
    reward: "報酬あり",
    recommend: "おすすめ",
    registered_email: "登録メールアドレス",
    alphanumeric: "半角英数字",
    entry: "エントリー",
    payment_notes: "支払通知書",
    name: "宛名",
    last_name: "姓",
    first_name: "名",
    kana_last_name: "姓(カナ)",
    kana_first_name: "名(カナ)",
    zipcode: "郵便番号",
    address: "住所",
    phone_number: "電話番号",
    birth_date: "生年月日",
    sex: "性別",
    male: "男",
    female: "女",
    about_company: "会社概要",
    requirement: "必須",
    tab: "タブ",
    age: "年齢",
    man: "男性",
    woman: "女性",
    others: "その他",
    city: "都市名",
    prefecture: "県名",
    content: "内容",
    influencer_name: "名前",
    building: "建物",
    text: "テキスト",
    bank_name: "金融機関名",
    branch_name: "支店名",
    account_holder: "口座名義",
    has_withholding_tax: "個人または法人の選択",
    account_number: "口座番号",
    user_id: "ユーザーID",
    account_type: "口座の種類",
    posted_url: "投稿したURL",
    ng_reason: "お断りの理由",
    year: "年",
    month: "月",
    day: "日",
    covid_support: "コロナ支援",
    birthday: "生年月日",
    or: "または",
    next: "次へ",
    start_app: "アプリをはじめる",
    people_without_account: "アカウントをお持ちでない方",
    response_period: "回答期限",
    limited_url: "限定公開した動画のURL"
  },
  message: {
    thanks_registered: "ご登録ありがとうございます！",
    email_verified: "メールアドレスの確認ができました！",
    free_download: "アプリを無料でダウンロード",
    check_email_span_box: "迷惑メールフォルダに振り分けられている場合がございますので、一度ご確認ください。",
    email_not_found: "メールが届かない場合は、{here}お問い合わせください。",
    recieve_project: "※記入すると、案件が受けやすくなります",
    sent_email: "メールを送信しました",
    register_login_info: "ログイン情報の登録をお願いいたします",
    check_email: "メールアドレスの確認をお願いいたします。",
    not_found_data: "データが見つかりません",
    fail_to_upload: "アップロードに失敗しました",
    not_found_post: "投稿が見つかりませんでした。",
    register_email: `
        ご登録いただいたメールアドレスに本人確認用のリンクが送信されます。<br>
        メール内のURLをクリックしてください。
    `,
    resend_email: `
      メールアドレスを確認できないと、ログイン情報を忘れてしまった際に再度ログインできなくなる可能性がございます。
      確認メールを再送信して、メールに記載されたURLをクリックしてください。
    `
  },
  placeholder: {
    password: "6文字以上の半角英数字",
    content: "内容を記入してください"
  },
  // common.title.[name] are generally used as
  // the page titles, breadcrumbs, etc.
  title: {
    announcement: "お知らせ",
    notification_list: "お知らせ",
    update_password: "パスワードの変更",
    register_email: "メールアドレスの登録",
    update_email: "メールアドレスの変更",
    register_credentials: "アカウント登録",
    project_progress_list: "案件管理",
    project_progress: "案件の進捗状況",
    payment: "お支払通知書一覧",
    payment_list: "過去のお支払通知書一覧",
    payment_detail: "{month}月分のお支払通知書",
    faq_category_list: "質問のカテゴリーをお選びください",
    faq_list: "よくある質問",
    privacy_policy: "プライバシーポリシー",
    terms_of_use: "利用規約",
    contact: "お問い合わせ",
    app_info: "アプリ情報",
    project_flow: "案件の流れ",
    instagram_url: "InstagramでのURLリンクコピー方法",
    twitter_url: "TwitterでのURLリンクコピー方法",
    youtube_url: "YouTubeでのURLリンクコピー方法",
    tiktok_url: "TikTokでのURLリンクコピー方法",
    tiktok_edit_post: "TikTok投稿編集方法",
    search_project: "案件検索",
    project_types: "モニター型・投稿報酬型とは？",
    contact_form: "お問い合わせフォーム",
    delete_request_form: "退会",
    find_project: "さがす",
    manage_project: "案件管理",
    mypage: "マイページ",
    spad: "SPAD",
    login: "ログイン",
    signup_complete: "メールアドレスの確認",
    instagram_activation: "Instagramアカウントの認証",
    project_detail: "案件詳細",
    project_reject: "お断りフォーム",
    offer_projects: "あなたへのオファー一覧",
    shipping_address: "発送先住所",
    payee: "お支払口座",
    project_progress_draft: "下書きの投稿",
    project_progress_draft_show: "下書きの確認",
    project_progress_draft_reject: "棄却内容の確認",
    profile_account: "プロフィール",
    profile_mail_password: "メールアドレス・パスワード",
    your_project: "あなたの案件",
    logout: "ログアウト",
    connect_sns: "SNSアカウント連携",
    connect_instagram: "Instagram",
    connect_twitter: "Twitter",
    connect_youtube: "YouTube",
    connect_tiktok: "TikTok",
    instagram_activation_input_id: "IDを入力してください",
    instagram_activation_add_hashtag:
      "確認用ハッシュタグを最新の投稿に追加してください",
    entry_checklist: "エントリーの確認事項",
    job_requirements: "仕事に必要な設定事項",
    resend_email: "確認メールの再送信",
    login_data_registration: "ログイン情報",
    enquete: "アンケート",
    youtube_limited_url: "YouTube URL"
  },

  help: {
    project_type: "案件の種類について",
    spad: "SPADの使い方"
  }
};
