import { actionTree, mutationTree, getterTree } from "nuxt-typed-vuex";
import { resetState } from "~/store/utils";
import {
  InfluencerEntryProjectDetailQueryVariables,
  InfluencerEntryProjectDetailQueryRequest,
  InfluencerEntryProject,
  ApplyForEntryProjectMutationVariables,
  ApplyForEntryProjectMutationRequest,
  EnquetePageType,
  Enquete
} from "~/types/gen/api";

export type EntryProjectState = {
  influencerProject: InfluencerEntryProject | null;
};

export const state = (): EntryProjectState => ({
  influencerProject: null
});

export const getters = getterTree(state, {
  shouldRegisterShippingAddress(state, _s, _g, rg) {
    const hasShippingAddress = rg["account/hasShippingAddress"];
    return (
      state.influencerProject!.entryProject.project.hasShippingStep &&
      !hasShippingAddress
    );
  },
  shouldRegisterPayee(state, _s, _g, rg) {
    const hasPayee = rg["account/hasPayee"];
    return state.influencerProject!.entryProject.hasPayment && !hasPayee;
  },
  shouldSatisfyFollower(state) {
    return state.influencerProject!.isSatisfiedMinFollowerCount;
  },
  enquete(state): Enquete | null {
    return state.influencerProject?.enquetes.find((v) =>
      v.pageType === EnquetePageType.BeforeEntryProject
    ) || null;
  }
});

export const mutations = mutationTree(state, {
  receiveProjectDetail(state, payload: InfluencerEntryProject) {
    state.influencerProject = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async init(
      context,
      payload: InfluencerEntryProjectDetailQueryVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new InfluencerEntryProjectDetailQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res.influencerEntryProjectDetail) {
          context.commit(
            "receiveProjectDetail",
            res.influencerEntryProjectDetail
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async applyForEntryProject(
      _,
      payload: ApplyForEntryProjectMutationVariables
    ): Promise<void> {
      const req = new ApplyForEntryProjectMutationRequest(payload);
      try {
        this.$accessor.presentation.showLoading(null);
        const res = await this.$apiClient.mutate(req);
        if (res.applyForEntryProject!.id) {
          if (res.applyForEntryProject?.entryProject?.enquetes) {
            const enquete = res.applyForEntryProject?.entryProject?.enquetes.find((v) =>
              v.pageType === EnquetePageType.AfterEntryProject
            );
            const path = enquete
              ? `/enquetes/${enquete.id}?from=/entries/${res.applyForEntryProject!.id}`
              : `/entries/${res.applyForEntryProject!.id}`;
            this.$router.replace(path);
          } else {
            this.$router.replace(`/entries/${res.applyForEntryProject!.id}`);
          }
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
