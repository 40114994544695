export default {
  attention_note: {
    different_cases_for_account_change: "アカウントの変更は、認証済みの場合とそれ以外の場合で操作が異なります。",
    confirm_ownership_of_sns_account: "変更したいSNSアカウントの投稿情報を編集し、本人確認をおこないます。",
    deactivating_account_allows_to_activate_a_new_one: "認証済みのアカウントを解除をすることで変更・再連携が可能になります。",
    by_deleting_linked_account_its_possible_to_link_a_new_one: "アカウントの変更は、認証済みのアカウントを解除をすることで変更・再連携が可能になります。",
    need_email_account_to_deactivate_media: "アカウントの変更にはメールアドレスを登録してください。",
    press_the_button_to_apply_for_change_account: "つきましては、以下のボタンを押してアカウントの切り替え申請をお願いします。<br>\n" +
        "切り替え申請に関するご返信をさせていただきます。"
  },
  attention_head: {
    linked_case: "認証済みの場合",
    others: "それ以外"
  },
  button: {
    apply_for_change: "切り替え申請を出す"
  },
  attention_box: {
    title: "アカウント切り替をできない場合があります",
    content: "お客様に進行中の案件がある場合、またはお客様のアカウントが重複登録されている場合に、すぐに切り替ができない場合がございます。"
  },
  title: "アカウントの変更について"
};
