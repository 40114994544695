import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { LinkAccountToTwitterMutationVariables, LinkAccountToTwitterMutationRequest } from "~/types/gen/api";

export type TwitterRegisterState = {
  result: Boolean;
};

export const state = (): TwitterRegisterState => ({
  result: false
});

export const mutations = mutationTree(state, {
  setLinkAccountResponse(state, payload) {
    state.result = payload.linkAccountToTwitter;
  }
});

export const actions = actionTree(
  {
    state,
    mutations
  },
  {
    async linkAccountToTwitter(
      context,
      payload: LinkAccountToTwitterMutationVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new LinkAccountToTwitterMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        context.commit("setLinkAccountResponse", res);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
