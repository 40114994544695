const middleware = {}

middleware['excludeAuth'] = require('../src/middleware/excludeAuth.ts')
middleware['excludeAuth'] = middleware['excludeAuth'].default || middleware['excludeAuth']

middleware['excludeAuth0'] = require('../src/middleware/excludeAuth0.ts')
middleware['excludeAuth0'] = middleware['excludeAuth0'].default || middleware['excludeAuth0']

middleware['loadAccount'] = require('../src/middleware/loadAccount.ts')
middleware['loadAccount'] = middleware['loadAccount'].default || middleware['loadAccount']

middleware['requireAuth'] = require('../src/middleware/requireAuth.ts')
middleware['requireAuth'] = middleware['requireAuth'].default || middleware['requireAuth']

export default middleware
