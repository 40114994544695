import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { ListFaqQueryRequest, Faq, ListFaqQuery } from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type Response = ListFaqQuery["faqList"];

export type FaqListState = {
  items: Faq[];
};

export const state = (): FaqListState => ({
  items: []
});

export const mutations = mutationTree(state, {
  receiveFaqList(state: FaqListState, payload: Response) {
    state.items = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async loadFaqList(context, categoryId): Promise<void> {
      this.$accessor.presentation.showLoading(null);

      try {
        const req = new ListFaqQueryRequest({ categoryId });
        const res = await this.$apiClient.query(req);
        context.commit("receiveFaqList", res.faqList);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
