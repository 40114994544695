export default {
  title: {
    terms_of_use: "利用規約"
  },
  description: {
    main_first_content: `
      本利用規約（以下「本規約」といいます。）は、株式会社OMOT（以下「当社」といいます。）が提供するスマートフォン向けアプリケーションソフト及びWebサイト「SPAD」（以下「本アプリ」といいます。）の利用条件及び当社と利用者との間の権利義務関係を定めたものです。本アプリの利用に際しては、本規約の全文をお読みいただいたうえで、その内容を遵守することに同意して本サービスを利用するものとし、本サービスを利用した場合には、当該利用者は本規約を遵守することに同意したものとみなします。
      <br>
      <br>一. 総則
      <br>第1条 （目的）
      <br>1. 本規約は、本アプリの利用条件及び本アプリの利用に関する当社と利用者との間の権利義務関係を定めることを目的とし、利用者と当社との間の本アプリの利用に関わる一切の関係に適用されます。
      <br>
      `,
    main_second_content:
      "2. 本アプリの利用者は、本規約及び当社が別途定める{privacy_policy}(以下「当社プライバシーポリシー」といいます。）の内容を十分理解した上で、本規約の内容を遵守することに同意して本アプリを利用するものとし、本アプリを利用した場合には、当該利用者は本規約を遵守することを同意したものとみなされます。",
    main_third_content: `
      3. 当社が当社ウェブサイト上に掲載する本アプリ及び本サービス利用に関する諸規程等は、本規約の一部を構成するものとします。
      <br>
      <br>第2条 （定義）
      <br>本規約において使用する以下の用語は、それぞれ以下に定める意味を有するものとします。
      <br>(1) 「本サービス」とは、本アプリ上において、第7条に定める業務の委託及び当該委託業務内容の管理並びに決済手続を内容としたサービスその他当社が提供する一切のサービスをいいます。
      <br>(2) 「ユーザー」とは、本アプリ上で次節に定める会員登録手続を行い、当社から登録の承諾を受けた個人又は法人をいいます。
      <br>(3) 「広告主」とは、当社のクライアントであり、当社を通じて、ユーザーに対し、委託業務を委託し、または委託しようとする個人又は法人をいいます。
      <br>(4) 「アカウント等」とは、ソーシャルプラットフォーム内でインフルエンサーが動画・写真等のコンテンツをアップロードする自己又は自己が所属する組織が管理する自己の専用チャンネル又はアカウントをいいます。
      <br>(5) 「インフルエンサー」とは、ソーシャルプラットフォームにおいて自己の専用のアカウント等を有し、当社から委託業務を受託し、又は受託しようとする個人もしくは法人、又はマネージャーに所属し、マネージャーが当社から受託する委託業務の全部又は一部をマネージャーの監督のもとに遂行し、又は遂行しようとする個人又は法人をいいます。
      <br>(6) 「マネージャー」とは、インフルエンサーが所属するマネジメント会社等であって、本サービスを通じて委託業務を受託し、又は受託しようとする個人もしくは法人をいいます。
      <br>(7) 「ソーシャルプラットフォーム」とは、「Instagram」、「Twitter」、「Facebook」、「YouTube」その他これらに類する写真、動画等のコンテンツを共有する機能を有するソーシャルプラットフォームをいいます。なお、「YouTube」は、Google Inc.の、「Instagram」及び「Facebook」は、Facebook Inc.の、「Twitter」は、Twitter Inc.の商標又は登録商標です。
      <br>(8) 「オファー案件」とは、当社が特定のユーザーに対して第7条に定める委託業務を依頼し、ユーザーがこれを承諾して行う業務の形態をいいます。
      <br>(9) 「エントリー案件」とは、当社が本サービスを通じて第7条に定める委託業務を実施するユーザーを募集し、これに応募をしたユーザーの中から特定のユーザーを選定・決定して行う業務の形態をいいます。
      <br>
      <br>二. 会員登録・退会
      <br>第3条 （会員登録）
      <br>1. 本アプリの登録の申請を行うことができるのは、本サービスを利用する本人に限られ、当社が許可する場合を除き、代理人による申請は認められません。
      <br>2. 本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規約のすべての事項を遵守することに同意し、当社の定める一定の情報（以下「登録情報」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。
      <br>3. 登録希望者は、登録情報の提供にあたり、提供した情報がすべて真実であることを保証するものとします。
      <br>4. 登録希望者がマネジメント会社に所属する場合は当該マネジメント会社の、未成年者の場合は親権者の同意を得ていることを保証するものとします。
      <br>5. 登録希望者は、登録情報の提供に伴い、本アプリ及び本サービスの利用に必要な限度で、自身が知的財産権を保有する画像、素材等の非独占的使用を当社に無償で許諾するものとし、著作者人格権、肖像権その他の人格権を当社に対して行使しないことを約するものとします
      <br>6. 登録希望者は、本アプリ内で、登録情報のほか、SNSでの投稿傾向やフォロワー等の統計情報、想定単価等の当社が独自に推定する情報が掲載されることに同意し、客観的な根拠のある場合を除き、これらの表示について一切の異議を申し立てないものとします。
      <br>7. 当社は、当社の基準に従って、第1項に基づいて登録申請を行った登録希望者（以下「登録申請者」といいます。）の登録の可否を判断し、当社が登録を認める場合にはその旨を登録申請者に通知します。
      <br>8. 前項に定める登録の完了時、サービス利用契約がユーザーと当社の間に成立し、ユーザーは本サービスを本規約に従い利用することができます。
      <br>9. 当社は、登録申請者が以下の各号のいずれかの事由に該当する場合、その登録を承諾しない場合があります。また、登録後であっても、ユーザーが以下の各号に該当する事実が判明した場合には、当社は、登録を取り消すことがあります。
      <br>(1) 登録希望者が登録する際、当社登録基準を満たさない場合
      <br>(2) 登録希望者がマネジメント会社等に所属しているときで、当該マネジメント会社の承諾を得ていない場合
      <br>(3) 登録希望者が未成年者であるときで、親権者の同意を得ていない場合
      <br>(4) 本規約に違反するおそれがあると当社が判断した場合
      <br>(5) 当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
      <br>(6) 過去に本サービス又は当社が提供する他のサービスの利用の登録を取り消された者又は抹消された者である場合
      <br>(7) 電子メールアドレスを保有していない者である場合
      <br>(8) 既にユーザーとしての登録がなされている者である場合
      <br>(9) 未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人、保佐人又は補助人の同意等を得ていない場合
      <br>(10) 反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者をいう。以下同じ。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等、反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合
      <br>(11) その他、当社が登録を適当でないと合理的に判断した場合
      <br>
      <br>第4条 （登録事項の変更）
      <br>ユーザーは、登録情報に変更があった場合には、遅滞なく当社の定める方法により、当該変更事項を当社に通知するものとします。
      <br>
      <br>第5条 （パスワード及びユーザーIDの管理）
      <br>1. ユーザーは、自己の責任において、登録したパスワード、メールアドレスその他当社が付与するユーザーＩＤ等の本アプリ利用のために必要な情報一切を管理及び保管し、盗難防止のための措置を自ら講じるものとします。
      <br>2. ユーザーは、パスワード及びユーザーIDを第三者に利用させたり、貸与、譲渡、承継、名義変更、売買、担保提供等をしてはならないものとします。
      <br>3. パスワード又はユーザーIDの管理不十分、使用上の過誤、第三者の使用等による損害の責任はユーザーが負うものとし、当社は一切の責任を負わないものとします。
      <br>4. ユーザーは、パスワード又はユーザーIDが盗まれたり、第三者に使用されたりしていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
      <br>
      <br>三. 本サービスの内容
      <br>第6条 （本サービスの利用）
      <br>1. ユーザーは、本サービス利用契約の有効期間中、本規約の目的の範囲内で、かつ本規約に違反しない範囲内で、当社の定める方法に従い、本サービスを利用することができます。
      <br>2. 本サービスの提供を受けるために必要なコンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、ユーザーの責任と負担で行うものとします。
      <br>3. ユーザーは、自己の本サービスの利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの責任と負担で講じるものとします。
      <br>
      <br>第7条 （業務の委託）
      <br>1. ユーザーは、本サービスを通じて、当社より、次の業務（以下「委託業務」という。）の依頼を受けることができます。
      <br>(1) 広告主の商品又はサービスを紹介する動画、写真等のコンテンツの制作
      <br>(2) 自己のアカウント等を通じてソーシャルプラットフォーム上に動画、写真等のコンテンツをアップロードする業務（以下、委託業務により制作される動画、写真等のコンテンツを「本件コンテンツ」といいます。）
      <br>(3) 広告主の商品又はサービスに関するPRイベントへの参加、イメージモデルとしての出演
      <br>(4) 前各号に準ずるその他一切の業務
      <br>2. 本サービスによる委託業務の委託は、当社が広告主から委託を受け、当社がこれを特定のユーザーに再委託し、当該ユーザーが当該委託業務を行う形式によるものとします。
      <br>
      <br>第8条 （委託業務の管理）
      <br>当社は、本サービスを通じて、ユーザーに対し、前条の規定により委託した委託業務（以下「本件案件」といいます。）の管理（第11条及び第12条に定める本件コンテンツの納入及び検収に関する手続を含む。）及び業務委託料の支払手続に関するサービスを提供します。
      <br>
      <br>第9条 （オファー案件における個別契約の成立）
      <br>1. オファー型案件においては、当社がユーザーに対して委託業務を依頼し、ユーザーがこれを承諾した時点で個別契約が成立します。
      <br>2.  個別契約の成立後は、ユーザーは個別契約を途中解約することはできません。ユーザーは、個別契約に基づき再委託された本件案件を自ら誠実に遂行するものとし、当社との間で別途書面で合意しない限り、本件案件の全部又は一部を第三者に再々委託することはできません。
      <br>3. 個別契約には、別段の特約なき限り、本規約の各条項の定めが適用されるものとします。
      <br>4. ユーザーは、個別契約について自己が下請代金支払遅延等防止法（昭和三十一年法律第百二十号）の下請事業者にあたる場合には、同法第3条第1項の規定による書面を本アプリ内からダウンロードしてユーザーが使用する電子計算機に保存する方法又はその他当社が定める方法により、親事業者である当社からの電磁的記録の提供を受けることを承諾するものとします。
      <br>
      <br>第10条 （エントリー案件における個別契約の成立）
      <br>1. エントリー案件においては、当社が本サービスを通じて募集をした委託業務に対して応募のあったユーザーの中から、当社がユーザーの性別や年齢等諸般の事情を考慮した上で委託するユーザーを選定し、選定結果をユーザーに通知した時点で個別契約が成立するものとします。
      <br>2. エントリー案件に応募するためには、ユーザーは、ソーシャルプラットフォーム上のフォロワー数など当社が定める基準を満たす必要があります。
      <br>3. ユーザーは、委託業務への応募後の撤回又は辞退をすることはできないものとします。
      <br>4. 前条第2項から第5項までの各規定は、エントリー型案件について準用するものとします。
      <br>
      <br>第11条 （本件コンテンツの制作）
      <br>1. ユーザーは、本件案件を遂行するにあたり、広告主及び当社の企業イメージの保持に努めるものとし、広告主及び当社の企業イメージを毀損するおそれのあるいかなる行為も行ってはならないものとします。
      <br>2. ユーザーは、本件案件を遂行するにあたり、広告内容や方法の規制を定める法令、条例その他の業界団体の自主規制又は公序良俗に違反する行為を行ってはならないものとします。
      <br>3. ユーザーは、当社に対し、適宜、本件コンテンツの制作状況等の業務の進捗状況を報告し、協議の上、本件案件を遂行するものとします。
      <br>第12条 （本件コンテンツの納入・検収・報告）
      <br>1. ユーザーは、第7条1項（1）（2）の委託業務において、個別契約に定められた掲載期間の間に、本件コンテンツを制作し、自己のアカウントを通じてソーシャルプラットフォーム上で公開するものとします。ユーザーは、本件コンテンツの公開をもって、本件コンテンツを納入したものとします。
      <br>2. 当社は、本件コンテンツの納入日から5営業日以内（以下「検査期間」といいます。）に、当該本件コンテンツの検査を行い、本サービスを通じてその結果をユーザーに通知するものとし、当社からの検査合格の通知をもって、当該本件コンテンツの検収は完了されたものとします。ただし、当社が検査期間内に次項に定める修正を求めなかった場合は、本件コンテンツは合格基準を満たし、検収は完了したものとみなされます。
      <br>3. 当社は、前項の受入検査において本件コンテンツが合格基準を満たしていないと合理的に判断した場合、検査期間内に、本サービスを通じて、合格基準に満たない事項を指摘したうえで、本件コンテンツの修正を求めることができるものとします。この場合、ユーザーは、直ちに本件コンテンツの公開を停止するとともに、自己の責任と負担において、当社の指定する期日までに（以下「修正期間」といいます。）、本件コンテンツを修正し、再度、本件コンテンツを納入するものとします。
      <br>4. 前項に従い再度納入された本件コンテンツの検収については、前2項の規定が準用されるものとし、以後も同様とします。
      <br>5. 当社は、ユーザーが個別契約に定めた掲載日までに本件コンテンツをソーシャルプラットフォーム上に公開しないこと等により検収が完了しない場合、又は修正期間までに前2項に従った本件コンテンツの検収が完了しなかった場合、当該個別契約を解除することができるものとし、これにより個別契約に基づく当社のユーザーに対する業務委託料の支払債務も消滅するものとします。
      <br>
      <br>第13条 （本件コンテンツの公開）
      <br>1. ユーザーは、個別契約に定める掲載日までに本件コンテンツをソーシャルプラットフォーム上に公開するものとし、これをもって個別契約に基づく委託業務は完了するものとします。
      <br>2. 前項の規定にかかわらず、ユーザーは、個別契約に基づく付随的義務として、本件コンテンツを公開した後6か月間は、本件コンテンツがソーシャルプラットフォーム上に掲載されている状態を維持するものとします。
      <br>
      <br>第14条 （支払）
      <br>当社は、ユーザーに対し、本件案件の業務委託料として個別契約において定められた金額を、本件コンテンツの検収が完了した日の翌月末日までに、登録インフルエンサーが個人の場合には所定の税額の源泉徴収を行った上、ユーザーが指定する金融機関口座に振込む方法により支払うものとし、振込手数料は当社の負担とします。
      <br>
      <br>四. 権利の帰属
      <br>第15条 （知的財産権）
      <br>1. 本アプリ及び本サービスに関する知的財産権はすべて当社に帰属しており、本規約に基づく本サービスの利用許諾は、本アプリ及び本サービスに関する当社の知的財産権の使用許諾を意味するものではありません。
      <br>2. ユーザーは、ユーザーが制作、公開する動画、画像等のコンテンツについて、自らがソーシャルプラットフォーム上での公開その他送信することについての適法な権利を有していること、及び本コンテンツが第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。
      <br>3. 本コンテンツの著作権及び肖像権等の知的財産権は、原則として、当該コンテンツを作成したユーザーに帰属するものとします。ただし、個別契約において別途の定めがある場合には、当該定めを優先して適用するものとします。
      <br>4. 当社は、本サービスの広告宣伝や広告主に対する情報提供の利用目的のために必要な範囲において、ユーザーが納入したコンテンツやプロフィールに掲載した画像等ユーザーが本サービスを通じて作成、提供及び掲載した一切の動画、画像及びテキスト等を、無償で永続的に利用できるものとし、ユーザーは当社に対して著作者人格権、肖像権その他の人格権を行使しないものとします。
      <br>
      <br>五. その他
      <br>第16条 （禁止事項）
      <br>1. ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為又は該当すると当社が判断する行為をしてはなりません。
      <br>(1) 第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為（かかる侵害を直接又は間接に惹起する行為を含む。）
      <br>(2) 犯罪行為に関連する行為又は公序良俗に反する行為
      <br>(3) 本サービスを通じ、以下に該当し、該当すると当社が判断する情報を当社に送信すること
      <br>・過度に暴力的または残虐な表現を含む情報
      <br>・猥褻な情報又は青少年に有害な情報
      <br>・当社及び第三者の名誉または信用を毀損する表現を含む情報
      <br>・反社会的な表現を含む情報
      <br>・その他公序良俗に反する表現を含む情報
      <br>(4) コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為
      <br>(5) 法令又は当社もしくはユーザーが所属する業界団体の内部規則に違反する行為
      <br>(6) 本サービスに関し利用しうる情報を改ざんする行為
      <br>(7) 当社が定める一定のデータ容量以上のデータを、本サービスを通じて送信する行為
      <br>(8) 当社による本サービスの運営を妨害するおそれのある行為
      <br>(9) 氏名・住所・電話番号・メールアドレス等第三者が見て個人を特定できる情報を第三者に提供する行為
      <br>(10) 複数のメールアドレス等を登録して重複してユーザーの登録を行う行為
      <br>(11) ユーザーの登録を取り消されたユーザーに代わり登録をする行為
      <br>(12) 他のユーザーもしくは第三者を差別もしくは誹謗中傷し、又は他者の名誉もしくは信用を毀損する行為
      <br>(13) 当社、他のユーザー又は第三者になりすます行為
      <br>(14) 他者に対し、無断で、広告・宣伝・勧誘等の電子メール又はメッセージ（以下「電子メール等」という。）もしくは嫌悪感を抱く電子メール等（そのおそれのある電子メール等を含みます。）を送信する行為、他者の電子メール等の受信を妨害する行為又は連鎖的な電子メール等の転送を依頼する行為及び当該依頼に応じて転送する行為その他、当社のクライアントである広告主に対し、本サービス・本アプリ経由しない直接の取引を勧誘する一切の行為
      <br>(15) 当社又は第三者の設備もしくは本サービス用設備（当社が本サービスを提供するために用意する通信設備、電子計算機、その他の機器及びソフトウェアを言い、以下同様とします。）に無権限でアクセスし、又はポートスキャン、DOS攻撃もしくは大量のメール送信等により、その利用もしくは運営に支障を与える行為、又は支障を与えるおそれのある行為
      <br>(16) サーバ等のアクセス制御機能を解除又は回避するための情報、機器、ソフトウェア等を流通させる行為
      <br>(17) 当社が承認した場合を除き、本サービス、または当社が本サービスを通じて提供する一切の情報を使用した営業活動、営利を目的とした本サービスの利用、又はその準備を目的とした本サービスの利用をする行為
      <br>(18) 長時間の架電、同様の問い合わせの繰り返しを過度に行い、又は義務や理由のないことを強要し、当社の業務に支障を来たす行為
      <br>(19) ソーシャルプラットフォームその他のインターネットサイト等において、ある商品又はサービスについて実際のものより著しく優良・有利だと誤認させる内容を記載する行為
      <br>(20) 上記各号のいずれかに該当する行為（当該行為を第三者が行っている場合を含む。）を助長する目的で他のサイトにリンクを張る行為
      <br>(21) その他、当社が不適切と合理的に判断する行為
      <br>2. 当社は、本サービスにおけるユーザーの行為が前項各号のいずれかに該当し、又は該当するおそれがあると当社が合理的に判断した場合には、ユーザーに事前に通知することなく、当該情報の全部又は一部を削除することができるものとする。当社は、本項に基づき当社が行った措置に基づきユーザーに生じた損害について一切の責任を負わないものとします。
      <br>
      <br>第17条 （本サービスの停止等）
      <br>1. 当社は、以下のいずれかに該当する場合には、ユーザーに事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。
      <br>(1) 本サービスに係るコンピューター・システムの点検又は保守作業を緊急に行う場合
      <br>(2) コンピューター、通信回線等が事故により停止した場合
      <br>(3) 火災、停電、天災地変等の不可抗力により本サービスの運営ができなくなった場合
      <br>(4) その他、当社が停止又は中断を必要と判断した場合
      <br>2. 当社は、当社の都合により、本サービスの提供を終了することができるものとします。この場合、当社はユーザーに事前に通知するものとします。
      <br>3. 当社は、本条に基づき当社が行った措置に基づきユーザーに生じた損害について一切の責任を負わないものとします。
      <br>
      <br>第18条 （ダウンロードにおける注意事項）
      <br>ユーザーは、本サービスの利用開始に際し又は本サービスの利用中に、本サイトからのダウンロードその他の方法によりソフトウェア等をユーザーのコンピューター等にインストールする場合には、ユーザーが保有する情報の消滅もしくは改変又は機器の故障、損傷等が生じないよう十分な注意を払うものとし、当社は、ユーザーに発生した当該損害又は損失等について一切責任を負わないものとします。
      <br>
      <br>第19条 （登録抹消等）
      <br>1. 当社は、ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、当該ユーザーについて、本サービスの利用を一時的に停止したり、ユーザーとしての登録を抹消したり、又はサービス利用契約を解除し、もしくは個別契約の全部又は一部を解除することができます。なお、ユーザー登録の抹消又はサービス利用権の停止ないし喪失があった場合、個別契約も当然に解除されたものとみなします。
      <br>(1) 本規約のいずれかの条項又は個別契約に違反した場合
      <br>(2) 登録情報に虚偽の事実があることが判明した場合
      <br>(3) 当社、他のユーザーその他の第三者に損害を生じさせるおそれのある目的又は方法で本サービスを利用した、又は利用しようとした場合
      <br>(4) 手段の如何を問わず、本サービスの運営を妨害した場合
      <br>(5) 支払停止もしくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始もしくはこれらに類する手続の開始の申立てがあった場合
      <br>(6) 差押え、強制執行又は競売の申立てがあった場合、または仮差押え、仮処分、又は租税公課の滞納処分を受けた場合
      <br>(7) 他のユーザーや第三者から受ける苦情が、故意・過失を問わず、当社が定める一定水準を超えた場合。
      <br>(8) 第3条第6項各号に定める登録拒否事由のいずれかに該当する場合
      <br>(9) その他、当社がユーザーとしての登録の継続を適当でないと合理的に判断した場合
      <br>2. 前項の規定により個別契約の全部又は一部が解除された場合、当社のユーザーに対する当該解除にかかる業務委託料の支払債務も消滅するものとします。
      <br>3. 当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について一切の責任を負いません。
      <br>
      <br>第20条 （退会）
      <br>1. ユーザーが登録取消を希望する場合は、本アプリを通じてその旨を当社に連絡し、当社所定の退会手続を行うことができます。ただし、 ユーザーが登録取消の手続を行った時点で、個別契約に定められた委託業務が終了していない場合など登録を取消すことが第三者の不利益になると当社が判断する場合は、即座の登録取消はできず、当該不利益を解消すべくユーザーが一連の手続を遅滞なく円滑に進め完了させた後に、退会手続を行うものとします。
      <br>2. 本条に基づきユーザーの登録が取り消された場合、当該ユーザーは、当社の指示に基づき、当社から提供を受けた本サービスに関連するソフトウェア、マニュアルその他の物につき、返還、廃棄その他の処分を行うものとします。
      <br>
      <br>第21条 （保証・免責）
      <br>1. 当社は、本サービスを通じて、ユーザーが委託業務の依頼を受けることができることにつき、明示又は黙示を問わず、何ら保証するものではありません。また、本サービスで提供される情報に関しても、明示又は黙示を問わず、何ら保証するものではありません。
      <br>2. 当社は、本サービスがユーザーの特定の目的に適合すること、期待する機能・有用性を有すること、ユーザーによる本サービスの利用がユーザーに適用のある法令、業界団体の規則、ガイドライン等に適合することを何ら保証するものではありません。
      <br>3. 本サービス又は本サイトに関連して、ユーザー間、またはユーザーとその他の第三者との間において生じた取引、連絡、紛争等については、ユーザーの責任において処理及び解決するものとし、当社は、かかる事項について一切責任を負わないものとします。
      <br>4. 当社は、ユーザー又はインフルエンサーが広告主の商品を使用（飲食を含む）することに起因して発生したトラブル、紛争については、ユーザーの責任において処理及び解決するものとし、当社は、かかる事項について一切責任を負わないものとします。
      <br>5. 当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、ユーザーのメッセージ又は情報の削除又は消失､ユーザーの登録の取消、本サービスの利用によるデータの消失又は機器の故障もしくは損傷、その他本サービスに関連してユーザーが被った損害又は損失等につき、賠償する責任を一切負わないものとします。
      <br>6. 本サイトから他のウェブサイトへのリンク又は他のウェブサイトから本サイトへのリンクが提供されている場合でも、当社は、本サイト以外のウェブサイト及びそこから得られる情報に関して如何なる理由に基づいても一切の責任を負わないものとします。
      <br>
      <br>第22条 （紛争処理及び損害賠償）
      <br>1. ユーザーは、本規約及び個別契約に違反することにより、または本サービスの利用に関連して当社に何らかの損害又は損失を与えた場合、当社に対し、委託料の2倍又は10万円のいずれか大きい金額を当該損害及び損失を賠償しなければなりません。
      <br>2. ユーザーが本サービスに関連して他のユーザー、広告主その他の第三者からクレームを受け、又はそれらの者との間で紛争を生じた場合には、直ちにその内容を当社に通知するとともに、ユーザーの費用と責任において当該クレーム又は紛争を処理し、当社からの要請に基づき、その経過及び結果を当社に報告するものとします。
      <br>3. ユーザーによる本サービスの利用に関連して、当社が他のユーザー、広告主その他の第三者から権利侵害その他の理由により何らかの請求を受けた場合、ユーザーは、当該請求に基づき当社が当該第三者に支払を余儀なくされた金額を賠償しなければなりません。
      <br>4. 当社は、ユーザーに発生した損害が当社の債務不履行又は不法行為に基づく場合、当社は当該ユーザーが直接被った損害（付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害は含まないものとします。）を上限として損害賠償責任を負うものとします。ただし、当社に故意又は重過失がある場合に限ります。
      <br>
      <br>第23条 （秘密保持）
      <br>1. 本規約において「秘密情報」とは、本サービス利用契約又は本サービスに関連して、ユーザーが、当社より書面、口頭もしくは記録媒体等により提供もしくは開示されたか、又は知り得た、当社の技術、営業、業務、財務、組織、その他の事項に関する全ての情報をいいます。なお、他のユーザーや広告主等第三者の情報については、当社の秘密情報として取り扱われるものとします。但し、以下のいずれかに該当する情報は、秘密情報から除くものとします。
      <br>(1)当社から提供もしくは開示がなされたとき又は知得したときに、既に一般に公知となっていた、又は既に正当に保有していた情報
      <br>(2)当社から提供もしくは開示又は知得した後、自己の責めに帰せざる事由により刊行物その他により公知となった情報
      <br>(3)提供又は開示の権限のある第三者から秘密保持義務を負わされることなく適法に取得した情報
      <br>(4)秘密情報によることなく単独で開発した情報
      <br>(5)当社から秘密保持の必要なき旨書面で確認された情報
      <br>2. ユーザーは、秘密情報を本サービスの利用の目的のみに利用するとともに、当社の書面による承諾なしに第三者に当社の秘密情報を提供、開示又は漏洩しないものとします。
      <br>
      <br>第24条 （個人情報）
      <br>1. 当社は、個人情報及びそれに類する情報を当社プライバシーポリシーに基づき、適切に取り扱うものとします。ユーザーは、当社プライバシーポリシーに基づき個人情報が取り扱われることについて同意するものとします。
      <br>2. 当社は、広告主から、広告主の商品をインフルエンサーに直接発送する必要がある場合に限り、事前にその旨をユーザー又はインフルエンサーに通知したうえで、当該広告主に対して、ユーザー又はインフルエンサーの個人情報を提供する場合があります。
      <br>
      <br>第25条 （有効期間）
      <br>本サービス利用契約は、ユーザーについて第3条に基づく登録が完了した日に効力を生じ、当該ユーザーの登録が取り消された日又は本サービスの提供が終了した日のいずれか早い日まで、当社とユーザーとの間で有効に存続するものとする。
      <br>
      <br>第26条 （本規約等の変更）
      <br>1. 当社は、本サービスの内容を自由に変更できるものとします。
      <br>2. 当社は、必要に応じて本規約を変更できるものとします。本規約を変更した場合には、ユーザーに当該変更内容を通知するものとし、当該変更内容の通知後、ユーザーが本サービスを利用した場合には、ユーザーは、本規約の変更に同意したものとみなします。
      <br>
      <br>第27条 （連絡・通知）
      <br>本サービスに関する問い合わせ、その他ユーザーから当社に対する連絡又は通知、及び本規約の変更に関する通知、その他当社からユーザーに対する連絡又は通知は、当社の定める方法で行うものとします。
      <br>
      <br>第28条 （サービス利用契約上の地位の譲渡等）
      <br>1. ユーザーは、当社の書面による事前の承諾なく、本サービス利用契約上の地位又は本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
      <br>2. 当社は、本サービスにかかる事業を他社に譲渡等（会社分割、その他の事業再編を含む。）をした場合、当該事業譲渡等に伴い本サービス利用契約上の地位、本規約に基づく権利及び義務並びにユーザーの登録情報その他の顧客情報を当該事業譲渡等の譲受人に譲渡することができるものとし、ユーザーは、かかる譲渡につき本項において予め同意したものとし、異議を述べないものとします。
      <br>
      <br>第29条 （存続規定）
      <br>本規約第5条第3項、第10条第3項、第11条、第13条から第19条並びに第23条から第27条までの規定は、本サービス利用契約の終了後も有効に存続するものとし、ユーザーを拘束するものとする。
      <br>
      <br>第30条 （準拠法及び管轄裁判所）
      <br>本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
      <br>
      <br>第31条 （協議解決）
      <br>当社及びユーザーは、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
    `
  }
};
