import payment from "./payment";
import payee from "./payee";
import signup from "./signup";
import faq from "./faq";
import shipping_address from "./shipping-address";
import contact from "./contact";
import rejected_entry from "./rejected-entry";
import accepted_entry from "./accepted-entry";
import contact_reply from "./contact-reply";
import account_settings from "./account-settings";
import guide_flow from "./guide-flow";
import instagram_url from "./instagram-url";
import twitter_url from "./twitter-url";
import tiktok_url from "./tiktok-url";
import tiktok_edit_posts from "./tiktok-edit-post";
import account_activation from "./account-activation";
import project_type from "./project-type";
import spad_flow from "./spad-flow";
import login from "./login";
import reset_password from "./reset-password";
import entry_project from "./entry-project";
import covid19 from "./covid19";
import entry_checklist from "./entry-checklist";
import contracted_project from "./contracted-project";
import debug from "./debug";
import entries from "./entries";
import mypage from "./mypage";
import project_progress from "./project-progress";
import privacy_policy from "./privacy-policy";
import terms_of_use from "./terms-of-use";
import intro from "./intro";
import link_social_media from "./link-social-media";
import deactivate_social_media from "./deactivate-social-media";
import enquete from "./enquete";
import youtube_url from "./youtube-url";
import request_delete_account from "./request-delete-account";

export default {
  login,
  payment,
  payee,
  signup,
  faq,
  shipping_address,
  contact,
  rejected_entry,
  accepted_entry,
  contact_reply,
  account_settings,
  guide_flow,
  instagram_url,
  twitter_url,
  tiktok_url,
  tiktok_edit_posts,
  account_activation,
  project_type,
  spad_flow,
  reset_password,
  entry_project,
  covid19,
  entry_checklist,
  contracted_project,
  debug,
  entries,
  mypage,
  project_progress,
  privacy_policy,
  terms_of_use,
  intro,
  link_social_media,
  deactivate_social_media,
  enquete,
  youtube_url,
  request_delete_account
};
