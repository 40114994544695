import { actionTree, getterTree, mutationTree } from "nuxt-typed-vuex";

export interface ToastPayload {
  type: "success" | "warning" | "info" | "error";
  message: String;
}

export interface ToastState {
  isShow: Boolean;
  toastPayload: ToastPayload;
}

export const state = (): ToastState => {
  return {
    isShow: false,
    toastPayload: {
      type: "success",
      message: ""
    }
  };
};

export const mutations = mutationTree(state, {
  setPayload(state: ToastState, payload: ToastPayload) {
    state.toastPayload = payload;
    state.isShow = true;
  },
  hide(state: ToastState) {
    state.isShow = false;
  }
});

export const getters = getterTree(state, {
  isShow(state): Boolean {
    return state.isShow;
  },
  toastPayload(state): ToastPayload {
    return state.toastPayload;
  }
});

export const actions = actionTree(
  {
    state,
    getters,
    mutations
  },
  {
    show(context, payload: ToastPayload): void {
      context.commit("setPayload", payload);
      setTimeout(() => {
        context.commit("hide");
      }, 3000);
    },
    setState(context, payload: ToastPayload): void {
      context.commit("setPayload", payload);
    }
  }
);
