export default {
  title: {
    privacy_policy: "個人情報保護方針"
  },
  description: {
    main_content: `
        FISM株式会社 (以下、「当社」という。)及び当社のグループ会社（以下「当社グループ」といいます。）は、インフルエンサーマーケティングを営む企業として、当社グループの事業の用に供するすべての個人情報を適切に取扱うため、当社グループ全従業者が遵守すべき行動基準として本個人情報保護方針を定め、その遵守の徹底を図ることといたします。
        <br>
        <br>
        1. 当社グループは、個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守します。そのため、日本産業規格「個人情報保護マネジメントシステム 要求事項」— (JIS Q 15001)に準拠した個人情報保護マネジメントシステムを策定し、適切に運用いたします。<br>
        <br>
        2. 当社グループは、事業の内容及び規模を考慮した適切な個人情報の取得、利用及び提供を行います。それには特定された利用目的の達成に必要な範囲を超えた個人情報の取扱いを行わないこと及びそのための措置を講じることを含みます。<br>
        <br>
        3. 当社グループは、個人情報の取扱いの全部又は一部を委託する場合は、その取扱いを委託された個人情報の安全管理が図られるよう、委託を受けた者に対する必要かつ適切な監督を行います。<br>
        <br>
        4. 当社グループは、本人の同意がある場合又は法令に基づく場合を除き、個人情報を第三者に提供することはありません。<br>
        <br>
        5. 当社グループは、個人情報の漏えい、滅失又はき損の防止及び是正のための措置を講じます。<br>
        <br>
        6. 当社グループは、個人情報の取扱いに関する苦情及び相談への適切かつ迅速な対応に努めます。また、当社グループが保有する保有個人データの開示等の請求(利用目的の通知、開示、訂正・追加又は削除、利用又は提供の停止）を受け付けます。開示等の請求の手続きにつきましては、以下の「個人情報苦情及び相談窓口」までご連絡ください。<br>
        <br>
        7. 当社グループは、個人情報保護マネジメントシステムの継続的改善を行ないます。<br>
        <br>

        <br>
        ＜個人情報保護方針、個人情報苦情及び相談窓口＞<br>
        FISM株式会社<br>
        〒113-0033<br>
        東京都文京区本郷1-33-13 春日町ビル６F<br>
        TEL：03-6455-5181<br>
        メール:privacy@fisminc.com<br>
        <br>
        制定日：2018年11月1日<br>
        改定日：2023年6月1日<br>
        FISM株式会社<br>
        代表取締役CEO<br>
        <br>

        <h3>個人情報の取扱いについて</h3>

        1. 個人情報の利用目的<br>
        <br>
        当社グループは、以下の目的（以下「利用目的」といいます）のために個人情報を適切に取得し、これを利用します。また、当社グループは、お客様ご本人及びお取引様からお預かりした個人情報を、利用目的の達成に必要な範囲内で利用させて頂きます。<br>
        引様からお預かりした個人情報を、利用目的の達成に必要な範囲内で利用させて頂きます。<br>
        全ての保有個人データは、以下(1)～(5)とし開示等の請求に応じます。<br>
        <br>
        (1)インフルエンサーマーケティング事業において取引先様ご本人の個人情報<br>
        <span style="margin-left:5px">1)インフルエンサーマーケティングの取引内容の検討のため</span><br>
        (2)インフルエンサーご本人から直接取得した個人情報<br>
        <span style="margin-left:5px">1)インフルエンサーへの業務委託料等の契約履行後の支払調書の税務処理や情報管理を行うため</span><br>
        <span style="margin-left:5px">2)インフルエンサーのデータベースを作成し、ご本人の同意の上公開に供するため</span><br>
        (3)電話でお問合せいただいた方の個人情報<br>
        <span style="margin-left:5px">1)お問合せに対して適切に対応し管理するため</span><br>
        (4)従業者等に関する管理業務<br>
        <span style="margin-left:5px">1)従業員の皆様への連絡、情報のご提供、および人事・労務管理を行うため</span><br>
        <span style="margin-left:5px">・外国籍社員の在留期限、在留資格確認のため</span><br>
        <span style="margin-left:5px">2)個人事業主への連絡、情報のご提供、および支払調書等の税務処理や情報管理を行うため</span><br>
        (5)当社グループのWebサイト、電話連絡やメール等にて取得した採用応募者情報<br>
        <span style="margin-left:5px">1)直接入社を希望される方への募集情報等の提供・連絡及び採用選考業務のため</span><br>
        <br>
        以下の(6)～(8)は取引先様等から委託や提供で取得した個人情報の利用目的で開示等の対象外となります。(間接的に取得するもの、又は直接書面以外で取得するもの)<br>
        (6)お取引先様から委託を受ける業務において取り扱う個人情報<br>
        <span style="margin-left:5px">1)クライアント企業様からの委託を受けた個人情報保護及びインフルエンサーマーケティング業務を契約内容に従って適切に遂行するため</span><br>
        (7)協力会社から提供を受け取り扱う個人情報<br>
        <span style="margin-left:5px">1)協力会社インフルエンサーを当社グループのインフルエンサー事業の契約履行に利用するため</span><br>
        (8)人材紹介企業を通じて取得した応募者情報<br>
        <span style="margin-left:5px">1)採用、募集情報等の提供・連絡及び採用選考業務のため</span><br>
        <br>

        2. 共同利用<br>
        <br>
        当社グループは、上記1記載の利用目的の達成のため、次の範囲内でご本人の個人データを共同利用いたします。<br>
        <br>
        1)共同して利用される個人データの項目<br>
        <span style="margin-left:5px">共同利用される個人データは「1.個人情報の利用目的」に記載されるものと同一です。</span><br>
        2)共同して利用する者の範囲<br>
        <span style="margin-left:5px">共同利用者の範囲は当社グループの各社となります。</span><br>
        3)共同して利用する者の利用目的<br>
        <span style="margin-left:5px">「1.個人情報の利用目的」に記載されるものと同一です。</span><br>
        4)当該個人データの管理について責任を有する者の名称および住所ならびに代表者の氏名<br>
        <span style="margin-left:5px">共同利用における管理責任者は、FISM株式会社となります。具体的なお問い合わせにつきましては、相談窓口までご連絡ください。</span><br>

        3．個人情報の第三者提供<br>
        お預かりした個人情報を、ご本人の同意がある場合又は法令等に基づく場合を除き第三者に提供することはありません。<br>
        <br>
        4．個人情報の委託<br>
        当社グループは、お客様へのより良いサービスの提供のために、個人情報の取り扱いの全部又は一部を委託することがあります。その場合は、当社グループの個人情報保護規程に従い適切な委託先の監督を実施します。<br>
        <br>
        5．個人情報を提供されることの任意性<br>
        個人情報のご提供は、任意判断となりますが、ご提供いただけない場合又は情報の記載に誤り等がありますと、お客様へのサービスが一部または全部をご提供できない場合があります。<br>
        <br>
        6．保有個人データ又は第三者提供記録に関する事項の周知など<br>
        当社グループが保有するご本人の個人情報について開示等（保有個人データの利用目的の通知、開示又は第三者提供記録の開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止）の請求に応じます。開示等へのご請求につきましては、下記の「個人情報苦情及び相談窓口」まで、ご連絡ください。「開示等の請求等申請書」（以下、「開示等申請書」という）を送付します。<br>
        「開示等申請書」の内容をご確認頂き項目に従って記入しご返送下さい。ご請求の際には、ご本人又は代理人を証明する書類等のご提示を求めることがあります。開示等のご請求に対しては、当社グループから電話、又は、書面等で回答させて頂きます。 なお、「利用目的の通知」または、「開示」をご請求の場合は1,000円分の郵便小為替又は切手を開示等申請書に同封して送付して下さい。（手数料及び回答をお送りする書留郵便料金として使用します）。<br>
        <br>
        開示等のご請求の際に、ご請求された方がご本人、または代理人であることを確認するための下記の書類をご提出して頂く場合があります。<br>
        ①開示等をご請求される方がご本人の場合、次のいずれか1点の添付をお願いいたします。<br>
        ・運転免許証、旅券、個人番号カード(氏名と写真の表示がある面のみ)などの写し<br>
        ・国家資格証、健康保険証、年金手帳、住民票（3ヶ月以内、個人番号の記載がないもの)の写し<br>
        ②開示等をご請求される方が代理人様の場合<br>
        上記書類のいずれか１点とご本人による委任状<br>
        （ご本人による捺印と当該印鑑の印鑑登録証明書をご提出ください。<br>
        代理人様が親権者などの法定代理人の場合は、ご本人との関係がわかるものご提出ください。<br>
        又、弁護士などの有資格者の場合はその職業名と登録番号を記載ください。<br>

        7．開示等をしない場合の取扱い<br>
        次に定める場合は、開示等の対応を致しかねますので、予めご了承願います。開示等をしないことを決定した場合は、その旨理由を付して通知申し上げます。また、開示等をしなかった場合についても、所定の手数料を頂きます。<br>
        ・ご本人の確認ができない場合<br>
        ・代理人によるご依頼に際して、代理権が確認できない場合<br>
        ・所定の依頼書類に不備があった場合<br>
        ・所定の期間内に手数料（郵便小為替又は切手）のお支払いがない場合<br>
        ・ご依頼のあった情報項目が、当社開示対象個人情報に該当しない場合<br>
        ・本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合<br>
        ・当社グループの業務の適正な実施に著しい支障を及ぼすおそれがある場合<br>
        ・他の法令に違反することとなる場合<br>
        <br>

        8. 安全管理措置<br>
        当社グループの保有個人データ及びお客様からお預かりする個人データの取扱いについては、安全管理措置として以下の対策を実施しています。<br>
        1)組織的安全管理措置<br>
        当社グループは、安全管理について従業者の責任及び権限を明確に定め、安全管理に対する規程及び手順書を整備運用しその実施状況を確認します。又、漏えい事案等に対応する体制を整備します。<br>
        2)人的安全管理措置<br>
        当社グループは、従業者に個人データの適切な取扱いを周知徹底するとともに適切な教育を行います。<br>
        3)物理的安全管理措置<br>
        当社グループは、個人データを取り扱う区域の管理、個人データを取扱う機器及び書類等の盗難又は紛失等の防止、個人データの削除及び機器、電子媒体等の廃棄などの措置を講じます。<br>
        4)技術的安全管理措置<br>
        当社グループは、個人データ及びそれを取り扱う情報システムへのアクセス制御、アクセス者の識別と認証、外部からの不正アクセス等の防止、情報システムの使用に伴う個人データの漏えい等を防止するための措置を講じます。<br>
        <br>

        9.本人が容易に認識できない方法による個人情報の取得<br>
        当ウェブサイトでは、利用者が当ウェブサイトを閲覧した状況を分析するためにCookieを利用しています。個人情報の取得はございません。また、取得したCookie情報を第三者に提供することもございません。<br>
        <br>

        10.個人情報保護管理者<br>
        管理部部長 ＊連絡先は下記のお問合せ窓口となります。<br>
        <br>

        11. 個人情報苦情及び相談窓口<br>
        個人情報の取扱いに関する苦情相談、開示等の請求につきましては、以下のお客様窓口まで、ご連絡ください。<br>
        ＦＩＳＭ株式会社 苦情相談窓口担当<br>
        〒113-0033 東京都文京区本郷1-33-13 春日町ビル６F<br>
        TEL：03-6455-5181<br>
        メール:privacy@fisminc.com<br>
        制定日：2018年11月1日<br>
        改定日：2023年6月1日<br>
        FISM株式会社<br>
        代表取締役CEO<br>
        <br>
        【認定個人情報保護団体の名称及び、苦情の解決の申出先】<br>
        一般財団法人日本情報経済社会推進協会<br>
        認定個人情報保護団体事務局<br>
        電話番号：03-5860-7565 0120-700-779<br>
        住所：東京都港区六本木一丁目９番９号六本木ファーストビル内<br>個人情報保護方針
        ※個人情報の取り扱いに関する苦情のみを受付けています
    `
  }
};
