export default {
  label: {
    offer_period: "オファー締め切り",
    offer_period_today: "本日まで！",
    write_note: "備考がありましたらご記入ください"
  },
  message: {
    offer_exists: "オファーが届いています",
    select_reject_reason: "お断りの理由を選択してください"
  },
  format: {
    offer_period_date: "あと{0}日！"
  },
  button: {
    reject: "断る",
    accept: "受領する",
    send: "送信する"
  },
  placeholder: {
    write_content: "内容を記入してください"
  }
};
