export default {
  message: {
    about_delete: "一度退会するとアカウントの復元ができません。<br>" +
      "退会申請後、1週間以内に反映され、完了時にはメールでお知らせいたします。<br>" +
      "※進行中の案件がある場合など、退会処理の受付ができない場合があります。",
    requested: "退会申請送信完了"
  },
  label: {
    content: "退会理由"
  },
  placeholder: {
    content: "理由を記入して下さい"
  },
  button: {
    send: "退会申請を出す"
  }
};
