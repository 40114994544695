






























































import Vue from "vue";
import NavigationBar from "~/components/ui/misc/navigation-bar.vue";
import NavigationBarButton from "~/components/ui/buttons/navigation-bar-button.vue";

export default Vue.extend({
  components: {
    NavigationBar,
    NavigationBarButton
  },
  computed: {
    isVisible(): boolean {
      return !this.$accessor.spadApp.isIosInApp;
    },
    hasAccount(): boolean {
      return this.$accessor.account.hasAccount;
    },
    hasNotification(): boolean {
      return this.$accessor.account.unreadNotificationCount > 0;
    }
  }
});
