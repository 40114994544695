import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { resetState } from "~/store/utils";
import {
  ProjectProgressQueryVariables,
  ProjectProgressQueryRequest,
  ProjectProgress
} from "~/types/gen/api";

export type ProjectProgressState = {
  progress: ProjectProgress | null;
};

export const state = (): ProjectProgressState => ({
  progress: null
});

export const mutations = mutationTree(state, {
  receiveProgress(state, payload: ProjectProgress) {
    state.progress = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async init(context, payload: ProjectProgressQueryVariables): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new ProjectProgressQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res.projectProgressDetail) {
          context.commit(
            "receiveProgress",
            res.projectProgressDetail as ProjectProgress
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
