import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { Billing, BillingFragment, BillingQueryRequest } from "~/types/gen/api";
import { APIError } from "~/network/api-error";
import { resetState } from "~/store/utils";

export type PaymentState = {
  billing: Billing | null;
};

export const state = (): PaymentState => ({
  billing: null
});

export const mutations = mutationTree(state, {
  receiveBilling(state, payload: BillingFragment) {
    state.billing = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    // Load Billing
    async init(context, id: string): Promise<void> {
      this.$accessor.presentation.showLoading(null);

      try {
        // Make request
        const req = new BillingQueryRequest({ id });
        // Exec request
        const res = await this.$apiClient.query(req);

        if (res.billingDetail) {
          context.commit("receiveBilling", res.billingDetail);
        } else {
          throw new APIError(404, "");
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        // Dismiss refreshing
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
