

































import Vue from "vue";
import Icon from "~/components/ui/icon.vue";
import Button from "~/components/ui/buttons/button.vue";
export default Vue.extend({
  components: {
    Icon,
    Button
  },
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  }
});
