import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { LinkAccountToTikTokMutationRequest, LinkAccountToTikTokMutationVariables } from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type TikTokRegisterState = {
  result: Boolean;
};

export const state = (): TikTokRegisterState => ({
  result: false
});

export const mutations = mutationTree(state, {
  setLinkAccountResponse(state, payload) {
    state.result = payload.linkAccountToTikTok;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  {
    state,
    mutations
  },
  {
    async linkAccountToTikTok(
      context,
      payload: LinkAccountToTikTokMutationVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new LinkAccountToTikTokMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        context.commit("setLinkAccountResponse", res);
      } catch (e) {
        this.$accessor.toast.setState({
          type: "error",
          message: e.message
        });
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
