export default {
  format: {
    remaining_day: "あと{0}日",
    by_today: "本日まで",
    follower: "フォロワー以上",
    count_people: "名様",
    yen: "￥"
  },
  label: {
    end: "終了しました",
    applied: "エントリー済み",
    unsatisfied: " 応募条件を満たしていません"
  },
  message: {
    unfilled_profile: "プロフィールが入力されてません",
    unsatisfied_condition: "次の応募条件を満たしていません"
  },
  button: {
    input_profile: "プロフィールを入力する"
  }
};
