import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  DeactivateSocialAccountMutationRequest,
  DeactivateSocialAccountMutationVariables
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type DeactivateSocialAccountState = {
  success: boolean;
};

export const state = (): DeactivateSocialAccountState => ({
  success: false
});

export const mutations = mutationTree(state, {
  requestSucceeded(state) {
    state.success = true;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async deactivate(
      _,
      payload: DeactivateSocialAccountMutationVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new DeactivateSocialAccountMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        const media = req.options.variables?.mediaType;
        const mediaSlug = media?.toLowerCase();

        if (res.deactivateSocialAccount) {
          this.$accessor.toast.show({
            message: "アカウント",
            type: "success"
          });
          this.$router.push(`/link-social-media/${mediaSlug}`);
        } else {
          this.$accessor.toast.show({
            message: "エラー",
            type: "error"
          });
          this.$router.push(`/link-social-media/${mediaSlug}`);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
