import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  ShippingAddressQueryRequest,
  ZipCode,
  ShippingAddress,
  ZipCodeDetailQueryRequest,
  UpdateShippingAddressMutationVariables,
  UpdateShippingAddressMutationRequest
} from "~/types/gen/api";
import { resetState } from "~/store/utils";
import { APIError } from "~/network/api-error";

export type ShippingAddressState = {
  shippingAddress: ShippingAddress | null;
  zipcode: ZipCode | null;
};

export const state = (): ShippingAddressState => ({
  shippingAddress: null,
  zipcode: null
});

export const mutations = mutationTree(state, {
  receiveShippingAddress(state, payload: ShippingAddress) {
    state.shippingAddress = payload;
  },
  receiveZipcode(state, payload: ZipCode) {
    state.shippingAddress = {
      ...state.shippingAddress,
      ...payload
    } as ShippingAddress;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async saveShippingAddress(
      context,
      payload: UpdateShippingAddressMutationVariables
    ): Promise<void> {
      this.$accessor.presentation.showLoading(null);
      try {
        const req = new UpdateShippingAddressMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);

        if (res.saveShippingAddress) {
          context.commit("receiveShippingAddress", res.saveShippingAddress);

          this.$accessor.account.receiveShippingAddress(
            res.saveShippingAddress
          );

          this.$accessor.toast.show({
            message: this.$i18n.tc("page.shipping_address.message.saved"),
            type: "success"
          });

          const from = this.$router.currentRoute.query.from;
          if (typeof from === "string") {
            this.$router.replace(from);
          }
        } else {
          throw new APIError(500, "Invalid Response");
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async fetchZipCode(context, payload: string): Promise<void> {
      this.$accessor.presentation.showLoading(null);

      try {
        const req = new ZipCodeDetailQueryRequest({
          zipcode: payload
        });

        const res = await this.$apiClient.query(req);

        if (res.zipCodeDetail) {
          context.commit("receiveZipcode", res.zipCodeDetail);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async loadShippingAddress(context): Promise<void> {
      this.$accessor.presentation.showLoading(null);

      try {
        const req = new ShippingAddressQueryRequest({});
        const res = await this.$apiClient.query(req);

        if (res.shippingAddress) {
          context.commit("receiveShippingAddress", res.shippingAddress);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
