import { ListState } from "../types/utils";

export const initialListState = <T>(): ListState<T> => {
  return {
    items: [],
    isLoading: true,
    pagination: {
      total: 0,
      perPage: 10,
      currentPage: 1,
      hasMorePages: false
    }
  };
};
