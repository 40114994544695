import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { Announcement, AnnouncementQueryRequest } from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type AnnouncementState = {
  announcement: Announcement | null;
};

export const state = (): AnnouncementState => ({
  announcement: null
});

export const mutations = mutationTree(state, {
  receiveAnnouncement(state: AnnouncementState, payload: Announcement) {
    state.announcement = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async init(context, payload: string): Promise<void> {
      this.$accessor.presentation.showLoading(null);
      try {
        const req = new AnnouncementQueryRequest({ id: payload });
        const res = await this.$apiClient.query(req);
        context.commit("receiveAnnouncement", res.announcementDetail);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
