import project_progress from "./project-progress";
import billing from "./billing";
import bank_account from "./bank-account";
import draft from "./draft";
import project from "./project";
import offer from "./offer";
import contractable_projects from "./contractable-projects";
import entry_condition from "./entry-condition";

export default {
  project_progress,
  billing,
  draft,
  project,
  bank_account,
  offer,
  contractable_projects,
  entry_condition
};
