import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  AccountFragment,
  UpdatePasswordMutationRequest,
  UpdatePasswordMutationVariables
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type EmailState = {
  account: AccountFragment | null;
};

export const state = (): EmailState => ({
  account: null
});

export const mutations = mutationTree(state, {
  receiveAccount(state: EmailState, payload: AccountFragment) {
    state.account = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    init(context): void {
      const account = this.$accessor.account.account;
      if (account) {
        context.commit("receiveAccount", account);
      }
    },
    async updatePassword(
      _context,
      payload: UpdatePasswordMutationVariables
    ): Promise<void> {
      this.$accessor.presentation.showLoading(null);
      try {
        const res = await this.$apiClient.mutate(
          new UpdatePasswordMutationRequest(payload)
        );
        if (res.updatePassword) {
          this.$accessor.toast.show({
            type: "success",
            message: this.$i18n.tc(
              "page.account_settings.message.update_password_success"
            )
          });
          this.$router.replace("/mypage");
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
