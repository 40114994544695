export default {
  label: {
    status: {
      ENTRY: "エントリー",
      INCOMPLETE: "進行中",
      COMPLETE: "完了"
    },
    project_flow: {
      waiting: "受領",
      shipping: "発送",
      draft: "下書き",
      publication: "投稿",
      payment: "お支払"
    }
  },
  message: {
    thanks_for_cooperation:
      "この度は、ご協力いただきありがとうございました。引き続き、アプリより魅力ある案件を提供させていただきますのでよろしくお願いいたします。",
    current_step: {
      wait_for_shipping: "商品発送までお待ち下さい",
      contact_by_email: "メールにてお知らせしますので、お待ちください",
      send_email_after_shipping:
        "商品発送が完了しましたら、アプリ内の通知にてお知らせいたしますので、お待ち下さい。",
      submit_draft_by_deadline:
        "案件詳細にある、訴求内容・ハッシュタグを確認して投稿期限３日前までに下書き提出しましょう。",
      report_by_deadline: "期限までにSNSへの投稿・報告をしましょう",
      post_by_deadline:
        "期限までに、案件に指定されているSNSへ投稿をアップロードしてください。アップロードが完了したら投稿報告ボタンから投稿報告をしましょう。",
      get_paid_and_wait:
        "指定口座へ報酬をお支払いいたします。少々お待ちください。",
      wait_by_payment: "入金までお待ち下さい",
      submit_draft: "下書きを提出しましょう",
      fix_and_submit_draft: "下書きを修正・提出しましょう",
      submitted_draft: "下書きを提出しました",
      checking_draft: "下書きを確認中です",
      reject_draft: "下書きが棄却されました",
      reject_acceptance: "投稿報告が棄却されました<br/>NG理由を確認してください。",
      rewrite_and_report: "投稿を修正、報告しましょう",
      overdue_post: "投稿期間を過ぎています",
      reported_post: "投稿を報告しました",
      checking_post: "投稿確認中です",
      checking_for_a_week:
        "確認には、1週間ほどいただく場合がございます。<br/>" +
        "確認後アプリ内の通知にてお知らせいたします。",
      overdue_post_next_message:
        "期限切れの投稿は、ページ下部の「お問い合わせ」ボタンよりご連絡ください"
    },
    previous_step: {
      start_project: "案件がスタートしました！",
      shipped_product: "商品が発送されました！",
      paied_reward: "報酬をお支払いいたしました",
      checked_draft: "下書きの確認が取れました！",
      complete_post_check: "投稿の確認がとれました！"
    },
    complete_project: "これで案件は完了です!"
  }
};
