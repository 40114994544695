import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { Entry, EntryQueryRequest, EntryQueryVariables } from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type EntryState = {
  entry: Entry | null;
};

export const state = (): EntryState => ({
  entry: null
});

export const mutations = mutationTree(state, {
  receiveEntry(state, payload: Entry) {
    state.entry = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async init(context, payload: EntryQueryVariables): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new EntryQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res.entryDetail) {
          context.commit("receiveEntry", res.entryDetail);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
