import { SocialAccount, ProjectMediaType } from "~/types/gen/api";

export function isProjectSocialMediaMatched(
  projectMediaType: ProjectMediaType | null,
  socialAccount: SocialAccount | null
): boolean {
  if (!socialAccount) {
    return false;
  }

  const ownedSocialAccount = [
    socialAccount.instagramAccount ? ProjectMediaType.Instagram : null,
    socialAccount.twitterAccount ? ProjectMediaType.Twitter : null,
    socialAccount.youtubeAccount ? ProjectMediaType.Youtube : null,
    socialAccount.tiktokAccount ? ProjectMediaType.Tiktok : null
  ];

  return ownedSocialAccount.includes(projectMediaType);
}
