import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { LpProjectQueryRequest, LpProject, LpProjectQuery } from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type Response = LpProjectQuery["lpProjectDetail"];

export type LpProjectState = {
  lpProject: LpProject | null;
};

export const state = (): LpProjectState => ({
  lpProject: null
});

export const mutations = mutationTree(state, {
  receiveLpProject(state: LpProjectState, payload: Response) {
    state.lpProject = payload!;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async init(context, slug): Promise<void> {
      this.$accessor.presentation.showLoading(null);

      try {
        const req = new LpProjectQueryRequest({ slug });
        const res = await this.$apiClient.query(req);
        context.commit("receiveLpProject", res.lpProjectDetail);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
