import { kana, bankAccountNameKana } from "./unicodes";

const kanaRegExp = new RegExp(`^[${kana}]+$`);
const bankAccountNameKanaRegExp = new RegExp(`^[${bankAccountNameKana}]+$`);

export const rules = {
  kana: (value: string) => kanaRegExp.test(value),
  bankAccountName: (value: string) => bankAccountNameKanaRegExp.test(value),
  password: (value: string) => /^[!-~]{6,128}$/.test(value),
  phoneNumber: (value: string) => /^[0-9]{10,11}$/.test(value)
};
