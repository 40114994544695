import { ManagedUpload, Body } from "aws-sdk/clients/s3";
import AWS from "aws-sdk/global";
import { AwsStsCredential } from "../types/gen/api";
import { NetworkClient } from "./network-client";
import ProgressPromise from "~/utils/progress-promise";

export type UploadPayload = {
  fileKey: string;
  data: Body;
};

export class S3Client implements NetworkClient {
  constructor(credentials: AwsStsCredential) {
    // Register Credentials
    AWS.config.update({
      region: "ap-northeast-1",
      credentials
    });
  }

  multipartUploadFiles(
    files: UploadPayload[]
  ): ProgressPromise<any, ManagedUpload.Progress[]> {
    return ProgressPromise.allProgress(
      files.map((v) => this.multipartUploadFile(v))
    );
  }

  private multipartUploadFile(
    file: UploadPayload
  ): ProgressPromise<any, ManagedUpload.Progress> {
    const task = new ManagedUpload({
      params: {
        Body: file.data,
        Key: file.fileKey,
        Bucket: process.env.S3_BUCKET
      }
    });

    return new ProgressPromise((resolve, reject, notify) => {
      notify({
        loaded: 0,
        total: 0
      });
      task.on("httpUploadProgress", (progress: ManagedUpload.Progress) => {
        notify(progress);
      });
      task
        .promise()
        .then(resolve)
        .catch(reject);
    });
  }

  dispose() {
    AWS.config.credentials = null;
  }
}
