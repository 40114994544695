/*
  SPAD Analytics plugin
  spad-web-frontendのanalytics処理をまとめています。(iOS/Android/Web)

  ※ Nuxtのバックエンド処理ではどのメソッドも発火しません
  ※ バックエンド処理ができない理由としてandroidのfirebaseイベントのpush処理(ネイティブブリッジ)は、基本的にwindowオブジェクト経由で渡すことを前提としていたため、
  NuxtSSRのバックエンド処理ではwindowを渡せないためです。(別の方法があるかもですが現在対応してません)
  ※ Nuxt.configでpluginをmode:frontにするのはやめました。理由としてはinjectしてるのでvue側でSSRのタイミングでもコード自体はかけてしまい
  SSR時のみエラーページになり、レビュー漏れでバグが発生しそうなのでメソッドごとにバックエンドで実行しないようガードしてます。
*/

import { Context } from "@nuxt/types";
import { webTrackPushEventName, getPageEventName, pageEventType } from "~/utils/analytics";
import { nativeBridge } from "~/foundations/native-bridge/native-bridge";

export default function(
  ctx: Context,
  inject: (key: string, value: any) => void
) {
  /*
  analyticsのメソッド群
  ここのメソッドがvueファイル内でthis.$analyticsModuleという形で参照可能となる
  */
  const analyticsModule = {
    logAndroidEvent: (name:string, params:object) => {
      if (!name) { return; }
      if (process.server) { return; }

      const globalWindow = window as any;

      if (globalWindow.AnalyticsWebInterface) {
        // Call Android interface
        globalWindow.AnalyticsWebInterface.logEvent(name, JSON.stringify(params));
      } else if (globalWindow.webkit &&
          globalWindow.webkit.messageHandlers &&
          globalWindow.webkit.messageHandlers.firebase) {
        // Call iOS interface
        const message = {
          command: "logEvent",
          name,
          parameters: params
        };
        globalWindow.webkit.messageHandlers.firebase.postMessage(message);
      } else {
        // No Android or iOS interface found
        console.log("No native APIs found.");
      }
    },
    pushAnalyticsEvent: (eventType: pageEventType) => {
      if (process.server) { return; }
      const { isAndroidInApp, isIosInApp } = ctx.app.$accessor.spadApp;
      // webのトラッキングイベント
      if (!isAndroidInApp && !isIosInApp) {
        ctx.app.$gtm.push({
          event: webTrackPushEventName,
          pageEvent: eventType,
          pageTitle: (typeof document !== "undefined" && document.title) || "",
          pageUrl: window ? window.location.href : ""
        });
      }
      // webviewネイティブのトラッキングイベント
      if (isAndroidInApp) {
        analyticsModule.logAndroidEvent(eventType, {
          pageTitle: (typeof document !== "undefined" && document.title) || "",
          pageUrl: window ? window.location.href : ""
        });
      } else if (isIosInApp) {
        nativeBridge.FirebaseAnalyticsPush({
          pageEventType: eventType,
          pageTitle: (typeof document !== "undefined" && document.title) || "",
          pageUrl: window ? window.location.href : ""
        });
      }
    }
  };

  /*
    vue routerを監視して、定義したページのeventNameをpushする
  */
  ctx.app.router!.afterEach((to) => {
    if (process.server) { return; }
    setTimeout(() => {
      if (!to.name) { return; }
      const { isAndroidInApp, isIosInApp } = ctx.app.$accessor.spadApp;
      const pageEventName = getPageEventName(to.name);

      // webトラッキング(ネイティブアプリでは発火しない)
      if (!isAndroidInApp && !isIosInApp) {
        ctx.app.$gtm.push({
          event: webTrackPushEventName,
          pageEventName,
          routeName: to.name,
          pageUrl: to.fullPath,
          pageTitle: (typeof document !== "undefined" && document.title) || ""
        });
      }

      // webviewネイティブのトラッキングイベント
      if (isAndroidInApp) {
        analyticsModule.logAndroidEvent(pageEventName, {
          routeName: to.name,
          pageUrl: to.fullPath,
          pageTitle: (typeof document !== "undefined" && document.title) || ""
        });
      } else if (isIosInApp) {
        nativeBridge.FirebaseAnalyticsPush({
          pageEventType: pageEventName,
          routeName: to.name,
          pageUrl: to.fullPath,
          pageTitle: (typeof document !== "undefined" && document.title) || ""
        });
      }
    }, 250);
  });

  inject("analyticsModule", analyticsModule);
};
