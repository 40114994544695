import { Context } from "@nuxt/types";
import { ActionContext } from "vuex";

const IS_ANDROID_WEBVIEW = "is_android_webview";
export const CLOSE_BANNER_BY_USER = "close_banner";
const SPAD_CUSTOM_UA_RULE_IOS = /SPAD\/([0-9]+\.[0-9]\.[0-9])\/1/;
const SPAD_CUSTOM_UA_RULE_ANDROID = /SPAD\/([0-9]+\.[0-9]\.[0-9])\/2/;

function isIosNativeApp(ctx: Context): boolean {
  const ua = process.server
    ? ctx.req.headers["user-agent"]
    : window.navigator.userAgent;

  return SPAD_CUSTOM_UA_RULE_IOS.test(ua || "");
}

function hasAndroidUa(ctx: Context): boolean {
  const ua = process.server
    ? ctx.req.headers["user-agent"]
    : window.navigator.userAgent;

  return SPAD_CUSTOM_UA_RULE_ANDROID.test(ua || "");
}

function isAndroidNativeApp(ctx: Context): boolean {
  return ctx.route.query[IS_ANDROID_WEBVIEW] === "true" || hasAndroidUa(ctx);
}

export function initIosNativeAppSetting(
  ctx: ActionContext<{}, {}>,
  nuxtCtx: Context
): void {
  // Open with SPAD iOS native app
  ctx.commit("spadApp/setIosAppFlag", isIosNativeApp(nuxtCtx));
}

export function initAndroidNativeAppSetting(
  ctx: ActionContext<{}, {}>,
  nuxtCtx: Context
): void {
  // Open with SPAD Android native app
  ctx.commit("spadApp/setAndroidAppFlag", isAndroidNativeApp(nuxtCtx));
}

// Initial settings of Web app banner
export function initWebAppBannerSetting(
  ctx: ActionContext<{}, {}>,
  nuxtCtx: Context
): void {
  const isIos = nuxtCtx.app.$device.isIos;
  ctx.commit("appBanner/setIosFlag", isIos && !isIosNativeApp(nuxtCtx));

  // Load closed iOS banner info from cookies
  const isBannerClosed = nuxtCtx.app.$cookies.get(CLOSE_BANNER_BY_USER);
  if (isBannerClosed) {
    ctx.commit("appBanner/closeBanner");
  }
}
