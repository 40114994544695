import Vue from "vue";
import { addClass, removeClass, isHTMLElement } from "~/utils/dom";

export enum EventTypes {
  Change = "change",
  Show = "show",
  Shown = "shown",
  Hide = "hide",
  Hidden = "hidden"
}

export default Vue.extend({
  model: {
    prop: "visible",
    event: EventTypes.Change
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    closeOnEsc: {
      type: Boolean,
      default: false
    },
    closeOnBackdrop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isVisible: false
    };
  },
  watch: {
    visible: {
      handler(newVal, oldVal) {
        if (newVal === oldVal) {
          return;
        }
        newVal ? this.show() : this.hide();
      },
      immediate: true
    }
  },
  destroyed() {
    removeClass(document.body, "modal-open");
  },
  methods: {
    // public method
    show() {
      if (this.isVisible) {
        return;
      }
      this.isVisible = true;
      this.$emit(EventTypes.Change, true);
      this.$emit(EventTypes.Show);
    },

    // public method
    hide() {
      if (!this.isVisible) {
        return;
      }
      this.isVisible = false;
      this.$emit(EventTypes.Change, false);
      this.$emit(EventTypes.Hide);
    },

    onClickBackdrop() {
      if (this.closeOnBackdrop) {
        this.hide();
      }
    },

    onEsc() {
      if (this.closeOnEsc) {
        this.hide();
      }
    },

    onFocusOut(evt: FocusEvent) {
      const container = this.$refs.container;

      // focus が container or container 内の要素 から外れたら
      if (
        isHTMLElement(container) &&
        !container.contains(evt.relatedTarget as any)
      ) {
        container.focus();
      }
    },

    /**
     * transition hooks
     */
    onAfterEnter() {
      const container = this.$refs.container;
      if (isHTMLElement(container)) {
        container.focus();
      }
      this.$emit(EventTypes.Shown);
    },
    onAfterLeave() {
      // enable body scrolling
      removeClass(document.body, "modal-open");
      this.$emit(EventTypes.Hidden);
    },
    onBeforeEnter() {
      // disable body scrolling
      addClass(document.body, "modal-open");
    }
  }
});
