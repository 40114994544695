export default {
  message: {
    copy_hash_tag: "ハッシュタグをコピーしました",
    congrats_for_passing_requirements:
      "おめでとうございます！認証基準に達しています",
    a_little_to_achieve: " あと少しで達成です！頑張ろう！",
    register_draft:
      "この案件であなたが投稿する画像とキャプションを下書きとして登録します。",
    upload_posts: "最大10枚までアップロードできます",
    upload_posts_youtube_url: "動画の公開方法を「限定公開」に設定してURLを貼り付けてください。",
    upload_posts_youtube_thumbnail: "サムネイル画像を貼り付けてください。",
    upload_posts_tiktok_url: "動画の公開方法を「限定公開」に設定してURLを貼り付けてください。",
    upload_posts_tiktok_thumbnail: "サムネイル画像を貼り付けてください。",
    include_hashtag: "※案件で指定されている必要ハッシュタグを含めてください",
    write_content: "内容を記入してください",
    send_by_email: "当選後、アプリ内の通知でお知らせいたします",
    help: "お困りの事はございませんか？",
    finished: "終了しました",
    applied: "エントリー済み",
    unsatisfied_condition: "応募条件を満たしていません",
    instagram_project: "Instagramの案件です",
    twitter_project: "Twitterの案件です",
    youtube_project: "YouTubeの案件です",
    tiktok_project: "TikTokの案件です",
    within_five_days_after_post: "投稿後5日以内",
    copy_hashtag: "ハッシュタグをコピーする",
    apply_with_the_content: "この内容でエントリーしますか？",
    agree_and_apply: "に同意して応募する",
    provide_only_product: `
      モニター案件のため<br>
      商品提供のみとなります
    `,
    reply_offer_request: "オファーにご返答お願いします！",
    need_to_register_shipping_address:
      "商品発送が必要な案件に関しては、住所の登録が必須となります。",
    include_product_shipping: "この案件は商品発送を含みます",
    include_reward: "この案件は報酬を含みます",
    register_account_information:
      "報酬の入金につき、口座情報の登録をしてください。",
    accept_offer: "この内容で受領しますか？",
    agree: "に同意する",
    reject_project: "案件を断りました",
    unsatisfy_follower_number: "フォロワー数が足りていません",
    need_to_link_social_account: "指定SNSのアカウント連携が必要です",
    reviewing_social_account: `案件で指定された<br>SNSアカウントは現在審査中です`,
    register_email_address: "メールアドレスを登録してください",
    register_email_login_requirements: "ログイン情報を登録してください"
  },
  format: {
    current: "現在",
    remianing: "あと",
    count_people: "名様",
    yen: "円",
    until: "まで",
    more_than_people: "人以上",
    yen_without_tax: "円(税別)"
  },
  label: {
    requirement: "認証基準",
    post_term: "投稿期間",
    product_image: "商品画像",
    post_image: "投稿画像",
    caption: "キャプション",
    deadline: "投稿期限",
    project_info: "案件情報",
    check_project_flow: "案件の流れを確認",
    project_detail: "商品概要",
    product_name: "商品名",
    product_description: "商品説明",
    request_detail: "依頼内容",
    client: "依頼主",
    application_period: "募集期間",
    media: "募集メディア",
    check_post: "投稿確認",
    entry_requirement: "エントリー条件",
    follower: "フォロワー数",
    availability_number: "募集人数",
    residence: "居住地",
    how_to_take_picture: "画像の撮り方",
    appeals: "訴求内容",
    hashtag: "ハッシュタグ",
    note: "備考",
    payment: "金額",
    contact: "お問い合わせ",
    download_brief: "案件依頼書のダウンロード",
    influencer: "受領者",
    planned_post_date: "投稿予定日",
    payment_amount: "お支払金額",
    payment_deadline: "お支払期日",
    next_month_after_post: "投稿月の翌月末",
    answer_deadline: "回答期限",
    acceptance_date: "受領日",
    today: "本日",
    registered: "登録済み",
    not_confirm: "未確定",
    ng_requirement: "NG要件",
    video_described_in_overview: "動画概要欄への記載",
    about_secondary_use_of_video: "動画の二次利用について",
    post_video: "投稿動画URL",
    select_video: "投稿動画",
    post_thumbnail: "サムネイル画像"
  },
  title: {
    what_is_draft_report: "下書きの報告とは?"
  },
  button: {
    send_draft: "下書きを送信する",
    register_shipping_address: "発送先の住所を登録する",
    register_account: "口座を登録する",
    accept: "受領する",
    link_social_media: "SNSを連携する",
    check_authorized_status: "SNS連携状況の確認",
    register_email_address: "メールアドレスを登録する",
    register_email_login_requirements: "ログイン情報を登録する"
  }
};
