import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { resetState } from "~/store/utils";
import {
  AccountActivationQueryRequest,
  RequestInstagramReviewMutationRequest
} from "~/types/gen/api";

export type InstagramAccountState = {
  canRequestReview: boolean;
};

export const state = (): InstagramAccountState => ({
  // TODO meクエリにこのフィールドが追加されたら削除し、accountストアを参照するようにする。
  canRequestReview: false
});

export const mutations = mutationTree(state, {
  setReviewAvailability(
    state: InstagramAccountState,
    canRequestReview: boolean
  ) {
    state.canRequestReview = canRequestReview;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async getReviewAvailability({ commit }): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new AccountActivationQueryRequest({});
        const { accountActivation } = await this.$apiClient.query(req);
        if (
          accountActivation &&
          typeof accountActivation.canRequestReview === "boolean"
        ) {
          commit("setReviewAvailability", accountActivation.canRequestReview);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async requestReview(): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new RequestInstagramReviewMutationRequest({});
        await this.$apiClient.mutate(req);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
