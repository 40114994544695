import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  DeleteAccount,
  DeleteAccountQuery,
  DeleteAccountQueryRequest,
  RequestDeleteAccountMutationRequest,
  RequestDeleteAccountMutationVariables
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type Response = DeleteAccountQuery["deleteAccountDetail"];

export type DeleteAccountState = {
  deleteAccount: DeleteAccount | null | undefined;
};

export const state = (): DeleteAccountState => ({
  deleteAccount: null
});

export const mutations = mutationTree(state, {
  receiveDeleteAccount(state: DeleteAccountState, payload: Response) {
    state.deleteAccount = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async init(context): Promise<void> {
      this.$accessor.presentation.showLoading(null);
      try {
        const req = new DeleteAccountQueryRequest({});
        const res = await this.$apiClient.query(req);
        context.commit("receiveDeleteAccount", res.deleteAccountDetail);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async requestDeleteAccount(
      _,
      payload: RequestDeleteAccountMutationVariables
    ): Promise<void> {
      this.$accessor.presentation.showLoading(null);
      try {
        const req = new RequestDeleteAccountMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res.requestDeleteAccount) {
          this.$router.replace("/mypage");
          this.$accessor.toast.show({
            message: this.$i18n.tc("page.request_delete_account.message.requested"),
            type: "success"
          });
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
