import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  UpdateEmailMutationVariables,
  UpdateEmailMutationRequest
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type EmailState = {
  success: boolean;
};

export const state = (): EmailState => ({
  success: false
});

export const mutations = mutationTree(state, {
  requestSucceeded(state) {
    state.success = true;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async updateEmail(
      context,
      payload: UpdateEmailMutationVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const res = await this.$apiClient.mutate(
          new UpdateEmailMutationRequest(payload)
        );
        if (res.updateEmail) {
          context.commit("requestSucceeded");
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
