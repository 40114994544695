export default {
  title: {
    password_setting: "パスワードの再設定",
    reset_password: "パスワードリセット"
  },
  label: {
    input_password: "パスワード",
    reinput_password: "パスワードの確認入力",
    input_email: "メールアドレス"
  },
  button: {
    done: "再設定する",
    send: "再設定用リンクを送る"
  },
  message: {
    pw_change_success: "パスワードの変更が成功しました",
    input_email: `
      アカウントの登録メールアドレスをご入力ください。パスワードリセット用のリンクをメールで送信いたします。
    `,
    send_reset_url:
      "ご入力いただいたメールアドレスにパスワードリセット用のリンクが送信されます。メール内のURLをクリックしてください。"
  }
};
