import { actionTree, getterTree, mutationTree } from "nuxt-typed-vuex";
import { SocialMediaSetting, SystemSettingQueryRequest } from "~/types/gen/api";

export type SystemSettingState = {
  socialMediaSetting: Pick<SocialMediaSetting, "line" | "apple" | "instagram">;
};

export const state = (): SystemSettingState => ({
  socialMediaSetting: {
    line: {
      clientId: "",
      redirectUri: ""
    },
    apple: {
      clientId: "",
      redirectUri: ""
    },
    instagram: {
      clientId: "",
      redirectUri: ""
    }
  }
});

export const mutations = mutationTree(state, {
  setSocialMediaSetting(state, payload: SocialMediaSetting) {
    state.socialMediaSetting = payload;
  }
});

export const getters = getterTree(state, {
  hasSettings(state): boolean {
    const { socialMediaSetting } = state;
    let hasSettings = true;
    Object.keys(socialMediaSetting).forEach((key: string) => {
      const socialMediaSettingKey = key as keyof typeof socialMediaSetting;
      if (socialMediaSetting[socialMediaSettingKey]?.redirectUri === "" || socialMediaSetting[socialMediaSettingKey]?.clientId === "") {
        hasSettings = false;
        return false;
      }
    });
    return hasSettings;
  }
});

export const actions = actionTree(
  { state, getters, mutations },
  {
    async loadSystemSetting(context): Promise<void> {
      try {
        const {
          systemSetting: { socialMediaSetting }
        } = await this.$apiClient.query(new SystemSettingQueryRequest({}));
        context.commit("setSocialMediaSetting", socialMediaSetting);
      } catch (e) {
        this.$accessor.error.showError(e);
      }
    }
  }
);
