import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  ResendEmailActivationMutationRequest,
  ResendEmailActivationMutationVariables
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type resendEmailState = {};

export const state = (): resendEmailState => ({});

export const mutations = mutationTree(state, {
  reset: resetState(state)
});

export const actions = actionTree(
  { state, mutations },
  {
    async resendEmail(
      _,
      payload: ResendEmailActivationMutationVariables
    ): Promise<void> {
      this.$accessor.presentation.showLoading(null);
      const req = new ResendEmailActivationMutationRequest(payload);
      try {
        const res = await this.$apiClient.mutate(req);
        if (res.resendEmailActivation) {
          const from = this.$router.currentRoute.query.from;
          if (typeof from === "string") {
            this.$router.replace(from);
          } else {
            this.$router.replace("/mypage");
          }
          this.$accessor.toast.show({
            type: "success",
            message: this.$i18n.tc("page.account_settings.message.resent_email")
          });
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
