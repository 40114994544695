export default {
  label: {
    title: "選考結果のお知らせ"
  },
  button: {
    to_progress: "案件管理を見る"
  },
  message: {
    body: `
        <p class="Content__Message">
            この度は、{date} <br />
            {type}「{projectTitle}」にエントリーいただき、誠にありがとうございました。
        </p>
        <p class="Content__Message">
            選考の結果、今回は当選とはなりませんでした。
        </p>
        <p class="Content__Message">
            ご希望にお応えすることができず申し訳ありません。
            今後も魅力的なキャンペーンをアプリよりご案内させていただきますので、引き続きよろしくお願いいたします。
        </p>
    `
  }
};
