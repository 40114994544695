import { actionTree, mutationTree, getterTree } from "nuxt-typed-vuex";
import { ListState } from "~/types/utils";
import { initialListState } from "~/utils/list";
import { APIError } from "~/network/api-error";
import { resetState } from "~/store/utils";
import {
  BillingEdge,
  BillingStatus,
  ListBillingsQueryRequest,
  ListBillingsQueryVariables
} from "~/types/gen/api";

export type PaymentsState = ListState<BillingEdge>;

export const state: () => PaymentsState = initialListState;

export const mutations = mutationTree(state, {
  requestBillingList(state: PaymentsState) {
    state.isLoading = true;
  },
  receiveBillingList(state: PaymentsState, payload) {
    if (payload.paginatorInfo.currentPage === 1) {
      state.items = payload.edges;
    } else {
      state.items = [...state.items, ...payload.edges];
    }
    state.isLoading = false;
    state.pagination = payload.paginatorInfo;
  },
  fail(state: PaymentsState) {
    state.isLoading = false;
  },
  reset: resetState(state)
});

export const getters = getterTree(state, {
  billings(state) {
    return state.items.map((v) => v.node!);
  },
  nextPage(state) {
    if (state.pagination.hasMorePages) {
      return state.pagination.currentPage + 1;
    }
    return undefined;
  }
});

export const actions = actionTree(
  { state, getters, mutations },
  {
    // Load Billings
    async loadBillings(
      context,
      payload: ListBillingsQueryVariables
    ): Promise<void> {
      const isRefresh = payload.page === 1;

      if (isRefresh) {
        // Initial load or refresh
        this.$accessor.presentation.showLoading(null);
      } else {
        // Load more
        context.commit("requestBillingList");
      }

      try {
        // Make request
        const req = new ListBillingsQueryRequest({
          status: BillingStatus.Complete,
          page: payload.page
        });
        // Exec request
        const res = await this.$apiClient.query(req);

        if (res.billingList) {
          context.commit("receiveBillingList", res.billingList);
        } else {
          throw new APIError(500, "Invalid Response");
        }
      } catch (e) {
        this.$accessor.error.showError(e);
        context.commit("fail", e);
      } finally {
        // Dismiss refreshing
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
