import { actionTree, getterTree, mutationTree } from "nuxt-typed-vuex";
import { initialListState } from "~/utils/list";
import { ListState } from "~/types/utils";
import { resetState } from "~/store/utils";
import {
  ProjectProgressEdgeFragment,
  ListProgressQueryRequest,
  ListProgressQuery,
  ListProgressQueryVariables
} from "~/types/gen/api";

export type State = ListState<ProjectProgressEdgeFragment>;

export const state = (): State => {
  return initialListState();
};

export const getters = getterTree(state, {
  nextPage(state) {
    if (state.pagination.hasMorePages) {
      return state.pagination.currentPage + 1;
    }
    return undefined;
  }
});

export const mutations = mutationTree(state, {
  requestList(state) {
    state.isLoading = true;
  },
  receiveItems(state, payload: ListProgressQuery["projectProgressList"]) {
    if (payload.paginatorInfo.currentPage === 1) {
      state.items = payload.edges;
    } else {
      state.items = [...state.items, ...payload.edges];
    }
    state.isLoading = false;
    state.pagination = payload.paginatorInfo;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  {
    state,
    mutations,
    getters
  },
  {
    // Load Contractst
    async loadContracts(
      context,
      payload: ListProgressQueryVariables
    ): Promise<void> {
      const isRefresh = payload.page === 1;

      if (isRefresh) {
        // Initial load or refresh
        this.$accessor.presentation.showLoading(null);
      } else {
        // Load more
        context.commit("requestList");
      }

      try {
        // Make request
        const req = new ListProgressQueryRequest(payload);
        // Exec request
        const res = await this.$apiClient.query(req);
        context.commit("receiveItems", res.projectProgressList);
      } catch (e) {
        // Handle Error
        this.$accessor.error.showError(e);
      } finally {
        // Dismiss refreshing
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
